import PropTypes from 'prop-types';
import React from 'react';
import Chart from 'react-apexcharts';
import { merge } from 'lodash-es';
import { LoaderText } from '../Loader';
import Error from '../Error';

const TimeSeriesChart = (props) => {
  const {
    aggregations,
    isLoading,
    error,
    chartType = 'line',
    chartOptions = {},
    chartProps = {},
  } = props;
  if (isLoading) {
    return <LoaderText />;
  }
  if (error) {
    return <Error error={error} />;
  }
  if (!aggregations) {
    return null;
  }
  const serie = aggregations.data?.buckets?.map((b) => [b.key, b.doc_count]);
  const options = {
    xaxis: {
      tooltip: {
        enabled: false,
      },
      type: 'datetime',
    },
    yaxis: {
      min: 0,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      x: {
        format: 'MMMM yyyy',
      },
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
  };
  return (
    <Chart
      type={chartType}
      height={350}
      options={merge(options, chartOptions)}
      series={[{ name: 'Issues', data: serie }]}
      {...chartProps}
    />
  );
};

TimeSeriesChart.propTypes = {
  aggregations: PropTypes.shape({
    data: PropTypes.shape({
      buckets: PropTypes.array,
    }),
  }),
  error: PropTypes.object,
  isLoading: PropTypes.bool,
  chartType: PropTypes.string,
  chartOptions: PropTypes.object,
};

export default TimeSeriesChart;
