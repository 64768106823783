import { ReactiveChart } from '@appbaseio/reactivesearch';
import React from 'react';
import { LoaderText } from '../Loader';
import TogglePanel from '../SimpleTogglePanel';

const customQuery = (value, { dataField }) => {
  if (value?.mainLabel) {
    return {
      query: {
        range: {
          [dataField]: {
            gte: `${value.mainLabel}||/y`,
            lte: `${value.mainLabel}||/y`,
            format: 'yyyy',
          },
        },
      },
    };
  }
  /* workaround for clicking on a date in the chart */
  /* needed the value of x-axis, but actually get bar value -> error */
  if (!Array.isArray(value) || value.length !== 2) {
    return null;
  }
  // check values
  try {
    if (!Number.isFinite(+value[0]) || !Number.isFinite(+value[1])) {
      return null;
    }
  } catch (e) {
    return null;
  }
  return value
    ? {
      query: {
        range: {
          [dataField]: {
            gte: `${value[0]}||/y`,
            lte: `${value[1]}||/y`,
          },
        },
      },
    }
    : null;
};

const defaultQuery = (value, { dataField }) => ({
  query: {
    range: {
      [dataField]: {
        lte: 'now/y',
      },
    },
  },
  size: 0,
  aggs: {
    [dataField]: {
      date_histogram: {
        field: dataField,
        calendar_interval: '1y',
        format: 'yyyy',
      },
    },
  },
});

const PRIMARY_COLOR = 'rgba(108, 186, 206, 1)';

function buildChartOptions({
  title,
  xAxisName,
  yAxisName,
  aggregationData,
  value,
}) {
  try {
    let chartTitle;
    if (title) {
      if (typeof title === 'string') {
        chartTitle = {
          text: title,
        };
      } else {
        chartTitle = title;
      }
    }
    const xAxisData = aggregationData.map((item) => ({
      value: item.key_as_string,
      name: item.key_as_string,
    }));
    let startIndex = -1;
    let endIndex = -1;
    if (value && Array.isArray(value)) {
      startIndex = xAxisData.findIndex((i) => i.value === value[0]);
      endIndex = xAxisData.findIndex((i) => i.value === value[1]);
    }
    const hasData = aggregationData.length > 0;
    return {
      title: hasData
        ? { text: chartTitle }
        : {
          textStyle: {
            color: 'grey',
            fontSize: 12,
          },
          text: 'No data',
          left: 'center',
          top: 'center',
        },
      grid: {
        top: '5%',
      },
      tooltip: {
        trigger: 'axis',
        formatter: '{b}: {c}',
        padding: [1, 10],
        textStyle: {
          fontSize: 12,
        },
      },
      xAxis: {
        show: hasData,
        type: 'category',
        data: xAxisData,
        name: xAxisName,
        silent: false,
        splitLine: {
          show: false,
        },
        splitArea: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        show: false,
        splitArea: {
          show: false,
        },
        name: yAxisName,
      },
      dataZoom: hasData && [
        {
          type: 'inside',
          startValue: startIndex > -1 ? startIndex : undefined,
          endValue: endIndex > -1 ? endIndex : undefined,
        },
        {
          type: 'slider',
          startValue: startIndex > -1 ? startIndex : undefined,
          endValue: endIndex > -1 ? endIndex : undefined,
          height: '20',
          realtime: false,
          showDetail: false,
          emphasis: {
            handleStyle: {
              color: PRIMARY_COLOR,
            },
            moveHandleStyle: {
              color: PRIMARY_COLOR,
            },
          },
          selectedDataBackground: {
            lineStyle: {
              color: PRIMARY_COLOR,
            },
            areaStyle: {
              color: PRIMARY_COLOR,
            },
          },
          backgroundColor: 'rgba(0, 0, 0, 0)',
          fillerColor: 'rgba(0, 0, 0, 0)',
        },
      ],
      series: [
        {
          type: 'bar',
          barCategoryGap: 0,
          animation: false,
          data: aggregationData.map((item) => item.doc_count),
          lasrge: true,
          itemStyle: {
            color: '#6cbace',
          },
        },
      ],
    };
  } catch (e) {
    console.error(e);
  }
  return {};
}

async function validateDateRange(value) {
  if (!value) {
    return Promise.resolve();
  }
  if (
    !Array.isArray(value)
    || value.length !== 2
    || !Number.isFinite(+value[0])
    || !Number.isFinite(+value[1])
  ) {
    throw Error(`invalid date range ${value}`);
  }
  return Promise.resolve();
}

export default Object.assign(
  (props) => {
    const { label } = props;
    return (
      <TogglePanel stateful initialOpen label={label}>
        <div style={{ height: '10rem' }}>
          <ReactiveChart
            beforeValueChange={validateDateRange}
            chartType="histogram"
            defaultQuery={defaultQuery}
            customQuery={customQuery}
            setOption={(args) => buildChartOptions(args)}
            loader={<LoaderText />}
            {...props}
          />
        </div>
      </TogglePanel>
    );
  },
  { propTypes: {} },
);
