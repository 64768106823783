import React from 'react';
import { useTranslation } from '../../utils/Localization';
import InputRefinement from './InputRefinement';

const RefineId = ({ componentId }: { componentId: string }): JSX.Element => {
  const i18n = useTranslation();
  return (
    <InputRefinement
      componentId={componentId}
      dataField="_id"
      URLParams
      showFilter
      filterLabel={i18n('Internal ID')}
      render={() => null}
      queryType="ids"
      inputProps={{ className: 'visually-hidden' }}
    />
  );
};

export default RefineId;
