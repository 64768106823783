import { children } from '@appbaseio/reactivesearch/lib/types';
import React, { ReactElement, cloneElement, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface IModalDialogProps
  extends Omit<React.ComponentProps<typeof Modal>, children> {
  title?: string | ReactElement;
  children: ReactElement | (() => ReactElement);
  closeElement?: ReactElement;
  triggerElement?: ReactElement;
  show?: boolean;
  onClose?: () => void;
  [key: string]: any;
}

export default function ModalDialog({
  title,
  children,
  closeElement,
  triggerElement,
  show = false,
  onClose = () => {},
  ...rest
}: IModalDialogProps): JSX.Element {
  const { t } = useTranslation('modal');
  const [modalShow, setModalShow] = React.useState(show);

  const handleClose = () => {
    setModalShow(false);
    onClose?.();
  };
  const handleShow = () => {
    setModalShow(true);
  };

  let content: ReactElement;
  if (modalShow && typeof children === 'function') {
    content = children();
  } else {
    content = children as ReactElement;
  }

  useEffect(() => {
    if (show) {
      setModalShow(true);
    }
  }, [show]);

  return (
    <>
      {triggerElement &&
        cloneElement(triggerElement, {
          onClick: handleShow
        })}
      <Modal
        show={modalShow}
        size="lg"
        aria-label={title}
        centered
        animation={false}
        scrollable
        onShow={null}
        onHide={handleClose}
        keyboard
        {...rest}
      >
        <Modal.Header closeButton>
          {title && <Modal.Title>{title}</Modal.Title>}
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer>
          {closeElement &&
            cloneElement(closeElement, {
              onClick: handleClose
            })}
          {!closeElement && (
            <Button variant="secondary" size="sm" onClick={handleClose}>
              {t('Close')}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
