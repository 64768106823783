import { useLocation } from 'react-router-dom';

export const createEntityLink = (id: string, pathname: string): string => {
  if (!id) return null;
  const currentUrl = new URL(pathname, window.location.href);
  const sideLink = `${currentUrl.pathname}?refine_id="${encodeURIComponent(
    id
  )}"`;
  return sideLink;
};

export const useEntityLink = (id: string): string => {
  const { pathname } = useLocation();
  return createEntityLink(id, pathname);
};
