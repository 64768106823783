import { useAccessToken } from '@/auth';
import { useElastic } from '@/elasticsearch';
import SuggestInput from '@/components/SearchCases/SuggestInput';
import React, { useState } from 'react';
import BadgeList from './BadgeList';

export const InputQuerySuggestion = ({
  index,
  dataField,
  ...rest
}: {
  index: string;
  dataField?: string;
} & any) => {
  const accessToken = useAccessToken();
  const { error, data } = useElastic(
    index,
    {
      size: 0,
      aggs: {
        [dataField]: { terms: { field: dataField, size: 1000 } }
      }
    },
    [!!accessToken, index, !!dataField]
  );

  function transformData(data: any) {
    if (!data) {
      return [];
    }
    const buckets = data?.aggregations?.[dataField]?.buckets;
    return buckets?.map((b: any) => b.key);
  }

  if (error) {
    console.error(error);
  }

  return <SuggestInput {...(rest as any)} suggestions={transformData(data)} />;
};

const FacetSearchInput = ({
  index,
  dataField,
  values,
  onChange,
  editable
}: {
  index: string;
  dataField?: string;
  values: string[];
  onChange: (v: string[]) => void;
  editable?: boolean;
}) => {
  const [value, setValue] = useState('');
  return (
    <div className="d-flex flex-row flex-wrap align-items-center">
      <BadgeList values={values} onChange={onChange} editable={editable} />
      {editable && (
        <div style={{ width: '10rem' }}>
          <InputQuerySuggestion
            index={index}
            value={value}
            dataField={dataField}
            onChange={(e: any) => setValue(e.target.value)}
            onAccept={(v: string) => {
              v = v.trim();
              !v || values.includes(v) || onChange([...values, v]);
              setValue('');
            }}
            acceptOnEnter
          />
        </div>
      )}
    </div>
  );
};

export default FacetSearchInput;
