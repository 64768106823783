import { useAccessToken } from '@/auth';
import { appConfig } from '@/Config';
import { SearchResponse } from '@/elasticsearch/types';
import EventBus, { IEventBus } from '@/events/eventbus';
import React, { createContext, useMemo } from 'react';
import { buildElasticSearchQuery } from './SearchCases.queries';
import { ISearchCase } from './SearchCases.types';

export const endPoint = `${appConfig?.api_url}/v1/searchcases/${appConfig?.instance_name}`;
const elasticEndPoint = `${appConfig?.connection}`;

export interface ISearchCasesApi {
  available: boolean;
  context: string;
  eventBus: IEventBus;
  suggestionIndex: string;
  getSearchCases: (from?: number, size?: number) => Promise<ISearchCase[]>;
  getSearchCase: (id: ISearchCase['id']) => Promise<ISearchCase>;
  saveSearchCase: (searchCase: ISearchCase) => Promise<void>;
  deleteSearchCase: (id: ISearchCase['id']) => Promise<void>;
  testQuery: (searchCase: ISearchCase) => Promise<SearchResponse>;
}

const ctx = createContext<ISearchCasesApi | null>(null);

export const useSearchCasesApi = () => React.useContext(ctx);

export const SearchCasesApi = ({
  context,
  suggestionIndex,
  children
}: {
  context: string;
  suggestionIndex: string;
  children: React.ReactNode;
}) => {
  const accessToken = useAccessToken();
  const headers = useMemo(
    () =>
      accessToken
        ? {
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        : null,
    [accessToken]
  );

  const api = useMemo(
    () => ({
      available: !!accessToken,
      context,
      eventBus: EventBus('searchcases'),
      suggestionIndex,
      getSearchCases: (
        from: number = 0,
        size: number = 100
      ): Promise<ISearchCase[]> =>
        headers
          ? fetch(`${endPoint}?type=${context}&from=${from}&size=${size}`, {
              method: 'GET',
              headers
            })
              .then((response) => {
                if (response.ok) return response.json();
                else throw response;
              })
              .then((data) => data.results)
          : Promise.reject('No access token'),
      getSearchCase: (id: ISearchCase['id']): Promise<ISearchCase> =>
        headers
          ? fetch(`${endPoint}/${id}`, { method: 'GET', headers }).then(
              (response) => {
                if (response.ok) return response.json();
                else throw response;
              }
            )
          : Promise.reject('No access token'),
      saveSearchCase: (searchCase: ISearchCase): Promise<void> => {
        if (!headers) return Promise.reject('No access token');
        if (searchCase.id) {
          return fetch(`${endPoint}/${searchCase.id}`, {
            method: 'PUT',
            headers,
            body: JSON.stringify(searchCase)
          }).then((response) => {
            if (response.ok) return;
            else throw response;
          });
        } else {
          return fetch(`${endPoint}`, {
            method: 'POST',
            headers,
            body: JSON.stringify(searchCase)
          }).then((response) => {
            if (response.ok) return;
            else throw response;
          });
        }
      },
      deleteSearchCase: (id: ISearchCase['id']): Promise<void> =>
        headers
          ? fetch(`${endPoint}/${id}`, { method: 'DELETE', headers }).then(
              (response) => {
                if (response.ok) return;
                else throw response;
              }
            )
          : Promise.reject('No access token'),
      testQuery: (searchCase: ISearchCase): Promise<SearchResponse> =>
        headers
          ? fetch(`${elasticEndPoint}${suggestionIndex}/_search`, {
              method: 'POST',
              headers,
              body: JSON.stringify({
                query: buildElasticSearchQuery(searchCase)
              })
            }).then((response) => {
              if (response.ok) return response.json();
              else throw response;
            })
          : Promise.reject('No access token')
    }),
    [headers]
  );

  return <ctx.Provider value={api}>{children}</ctx.Provider>;
};
