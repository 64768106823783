import Prism from 'prismjs';
import {
  node, object, oneOfType, string, 
} from 'prop-types';
import React, { useMemo, useEffect } from 'react';

export default function Source({ sourceObject, title }) {
  const orderedKeys = (obj) => Object.keys(obj)
    .sort()
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});

  useEffect(() => {
    Prism.highlightAll();
  }, [sourceObject]);

  const code = useMemo(
    () => (typeof sourceObject === 'string'
      ? sourceObject
      : JSON.stringify(orderedKeys(sourceObject), null, 4)),
    [sourceObject],
  );

  return (
    <details>
      <summary>{title || 'Source'}</summary>
      <pre style={{ maxWidth: '50vw' }}>
        <code
          className="language-json"
          style={{ whiteSpace: 'break-spaces' }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: code }}
        />
      </pre>
    </details>
  );
}

Source.propTypes = {
  sourceObject: oneOfType([string, object, node]).isRequired,
  title: string,
};
