export default function mapCustomerInstances(instance: string) {
  let instanceName;
  switch (instance) {
    case 'bw':
      instanceName = 'borgwarner';
      break;
    case 'yfi':
      instanceName = 'yanfeng';
      break;
    default:
      instanceName = instance;
      break;
  }
  return instanceName;
}