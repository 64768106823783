import logo from '@assets/images/logo.png';
import { useAuth0 } from '@auth0/auth0-react';
import { string } from 'prop-types';
import React, { useState } from 'react';
import {
  Container, Nav, NavDropdown, Navbar, 
} from 'react-bootstrap';
import styled from 'styled-components';
import { useConfig } from './Config';
import { NavigationItem } from './NavigationItem';
import { UserMenu } from './UserMenu';
import ContactFormPanel from './components/ContactFormPanel';
import { Sidebar } from './components/Sidebar';
import { ViewportProvider } from './react/ViewPort';
import { useV3Menu } from './useV3Menu';
import { useTranslation } from './utils/Localization';
import ConnectionState from './components/ConnectionState';

const StyledLogoText = styled.div`
  font-size: 1.4rem;
  font-weight: bolder;
`;

const StyledLogo = styled.img`
  height: 25px;
  margin-right: 0.5rem;
`;

// eslint-disable-next-line import/prefer-default-export
export const Navigation = ({ className }: { className?: string }) => {
  const { menu, other } = useV3Menu();
  const config = useConfig();
  const i18n = useTranslation();
  const auth0 = useAuth0();
  const [expanded, setExpanded] = useState(false); // navbar state

  const variant = 'dark';

  if (!auth0.isAuthenticated) return null;

  const onClickHandler = () => setExpanded(false);

  return (
    <ViewportProvider>
      <Navbar
        id="cw-main-nav"
        expand="xl"
        expanded={expanded}
        bg={variant}
        sticky="top"
        data-bs-theme="dark"
        className={className}
      >
        <Container fluid>
          <Navbar.Brand href="/v3/welcome">
            <div className="d-flex">
              <div>
                <StyledLogo src={logo} alt="Carwatch logo" />
              </div>
              <StyledLogoText>Carwatch</StyledLogoText>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle onClick={() => setExpanded(!expanded)}>
            <i className="bi bi-list" />
          </Navbar.Toggle>
          <Navbar.Collapse>
            <Nav className="me-auto">
              {menu.map((mi) => {
                if (mi.demo) {
                  return process.env.NODE_ENV === 'development'
                    || config.customer.name === 'demo' ? (
                      <NavigationItem
                        key={mi.pathname}
                        {...mi}
                        onClick={onClickHandler}
                      />
                    ) : null;
                }
                return (
                  <NavigationItem
                    key={mi.pathname}
                    {...mi}
                    onClick={onClickHandler}
                  />
                );
              })}
              <NavDropdown
                title={i18n('Other')}
                menuVariant={variant}
                className="nav-item"
              >
                {other.map((o) => {
                  if (o.demo) {
                    return process.env.NODE_ENV === 'development'
                      || config.customer.name === 'demo' ? (
                        <NavDropdown.Item key={o.pathname} as="div">
                          <NavigationItem {...o} onClick={onClickHandler} />
                        </NavDropdown.Item>
                      ) : null;
                  }
                  return (
                    <NavDropdown.Item key={o.pathname} as="div">
                      <NavigationItem {...o} onClick={onClickHandler} />
                    </NavDropdown.Item>
                  );
                })}
              </NavDropdown>
            </Nav>
            {!expanded && (
              <Nav className="justify-content-end me-2">
                <div className="d-flex gap-1">
                  <ConnectionState />
                  <Sidebar
                    toggle={(
                      <button
                        className="btn btn-sm"
                        type="button"
                        aria-label="Sidebar"
                      >
                        <i className="text-warning bi bi-layout-sidebar-inset-reverse" />
                      </button>
                    )}
                  />
                  <ContactFormPanel />
                </div>
              </Nav>
            )}
            <UserMenu variant={variant} />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </ViewportProvider>
  );
};

Navigation.propTypes = {
  className: string,
};

export const StyledNavigation = styled(Navigation)`
  box-shadow: var(--bs-box-shadow);
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--bs-border-color);
`;
