import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { useConfig } from '../../Config';
import { useElastic } from '../../react/Elastic';
import EllipsisText from '../EllipsisText';
import SimpleSpinner from '../SimpleSpinner';

const query = (odino) => ({
  query: {
    terms: { 'misc.odino.keyword': isArray(odino) ? odino : [odino] },
  },
  _source: ['facets.*', 'complaint'],
});

export default function RelatedInvComplaints({ odinos }) {
  const config = useConfig();
  const { isLoading, data, error } = useElastic(
    config.getIndexName('complaints', 'cw3_cst_'),
    query(odinos),
    !!odinos,
  );

  if (!odinos) return <span>n.a.</span>;
  if (isLoading) return <SimpleSpinner />;
  if (error) throw error;
  if (data?.hits.hits.length === 0) return <span>n.a.</span>;
  return (
    <ListGroup>
      {data?.hits.hits.map((hit) => (
        <ListGroupItem key={hit._id}>
          <div>
            <strong>{hit._id}</strong>
          </div>
          <EllipsisText showShowAll length={200} text={hit._source.complaint} />
        </ListGroupItem>
      ))}
    </ListGroup>
  );
}
RelatedInvComplaints.propTypes = {
  odinos: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};
