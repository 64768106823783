export { default as useIdToken } from './useIdToken';
export { default as useIsPathAllowed } from './useIsPathAllowed';
export { default as useUserPaths } from './useUserPaths';
export { default as useUserProfile } from './useUserProfile';
export { default as useUserRoutes } from './useUserRoutes';
export { default as useUserSites } from './useUserSites';

import {
  Auth0Provider,
  Auth0ProviderOptions,
  useAuth0
} from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import authConfig from '../auth0_config.json';
import { getBrowserLocales } from '../utils/Localization';
import useIdToken from './useIdToken';
import useIsPathAllowed from './useIsPathAllowed';

const atc = React.createContext(undefined);
export const useAccessToken = () => React.useContext(atc);
export const isTokenExpired = (token: string) =>
  Date.now() >= JSON.parse(window.atob(token.split('.')[1])).exp * 1000;

function AccessTokenContext({ children }: { children: React.ReactNode }) {
  const { isLoading, error, getAccessTokenSilently, isAuthenticated } =
    useAuth0();
  const [accessToken, setAccessToken] = React.useState<string | undefined>();

  React.useEffect(() => {
    async function getAccessToken() {
      try {
        const at = await getAccessTokenSilently({
          authorizationParams: {
            audience: authConfig.audience,
            scope: 'read:current_user'
          }
        });
        setAccessToken(at);
      } catch (e) {
        setAccessToken(undefined);
      }
    }
    if (isAuthenticated && !error) getAccessToken();
  }, [isAuthenticated, error, getAccessTokenSilently]);

  if (isLoading) {
    return null;
  }
  if (error) {
    return (
      <div>
        An error occured:
        {error.message}
      </div>
    );
  }
  return <atc.Provider value={accessToken}>{children}</atc.Provider>;
}

export const auth0AuthorizationScope = 'openid profile read:current_user';

export const auth0ProviderOptions: Auth0ProviderOptions = {
  domain: authConfig.domain,
  clientId: authConfig.clientID,
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
  authorizationParams: {
    audience: authConfig.audience,
    scope: auth0AuthorizationScope,
    redirect_uri: window.location.origin,
    ui_locales: getBrowserLocales()[0]
  }
};

export const Auth0Wrapper = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  return (
    <Auth0Provider
      {...auth0ProviderOptions}
      onRedirectCallback={(appState) => {
        navigate(appState?.targetUrl || window.location.pathname);
      }}
    >
      <AccessTokenContext>{children}</AccessTokenContext>
    </Auth0Provider>
  );
};

export const withPathAllowed =
  (Component: React.ComponentType<any>) =>
  (props: React.PropsWithChildren<any>) => {
    const { path } = props;
    const isPathAllowed = useIsPathAllowed(path);
    return <Component isPathAllowed={isPathAllowed} {...props} />;
  };

export const withAccessToken =
  (Component: React.ComponentType<any>) =>
  (props: React.PropsWithChildren<any>) => {
    const accessToken = useAccessToken();
    return <Component accessToken={accessToken} {...props} />;
  };

export const withIdToken =
  (Component: React.ComponentType<any>) =>
  (props: React.PropsWithChildren<any>) => {
    const idToken = useIdToken();
    return <Component idToken={idToken} {...props} />;
  };

export default Auth0Wrapper;
