import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import * as t from '../utils/Localization';

class NotFound extends React.Component {
  render() {
    const { className } = this.props;
    return <div className={className}>{t.t('Page not found')}</div>;
  }
}

NotFound.propTypes = {
  className: string.isRequired,
};

export default styled(NotFound)`
  font-size: 1.2em;
  font-weight: bold;
  padding-top: 5em;
  text-align: center;
`;
