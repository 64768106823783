import React from 'react';
import Error from '../Error';
import ReactApexChart from 'react-apexcharts';
import { toHeatmapSeries, heatmapOptions } from '../Reports/HeatmapChartsUtils';

const HeatMapCharts = (props) => {
    const { aggregations } = props;
    if (!aggregations) return null;
    const data = toHeatmapSeries(aggregations, 'default').data;
    const options = { ...heatmapOptions };
    options.colors = ['#008FFB'];
    options.plotOptions.treemap.enableShades = true;
    return (
        <ReactApexChart options={options} series={[{ data }]} type="treemap" height={350} />
    )
}

export default HeatMapCharts;