import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../utils/Localization';

const Loading = ({ className }: { className?: string }) => {
  const i18n = useTranslation();
  return (
    <div className={className}>
      <div className="spinner-border" role="status">
        <span className="visually-hidden">{i18n('Loading...')}</span>
      </div>
      <div className="loading__text">{i18n('Loading application...')}</div>
    </div>
  );
};

export default styled(Loading)`
  height: 200px;
  left: 50%;
  margin-left: -200px;
  margin-top: -100px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 400px;

  .loading__text {
    font-size: 1.5em;
    font-weight: bold;
    padding: 1em;
  }
`;
