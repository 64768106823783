export interface SearchInputFieldProps
{
  value?: string;
  acceptOnEnter?: boolean;
  onAccept: (qs: string) => void;
  innerClass?: {
    invalidQueryClass?: string;
    input?: string;
  }
  placeholder?: string;
  onError?: (error: string) => void;
  onLoading?: (loading: boolean) => void;
}