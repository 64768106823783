import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { useUserProfile } from '../../auth/Auth0';
import { useTranslation } from '../../utils/Localization';
import SidebarFavorites from './SidebarFavorites';
import SidebarMentions from './SidebarMentions';

export default function Sidebar({ toggle: Toggle, ...props }) {
  const i18n = useTranslation('sidebar');
  const [show, setShow] = useState(false);
  const offCanvasRef = useRef();
  const profile = useUserProfile();

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);
  const closeOffCanvas = () => {
    offCanvasRef.current.backdrop.click();
  };

  return (
    <>
      {React.cloneElement(Toggle, { onClick: toggleShow })}
      <Offcanvas
        ref={offCanvasRef}
        data-bs-theme="dark"
        show={show}
        onHide={handleClose}
        placement="end"
        scroll
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{profile.name}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <h6>
            <i className="bi bi-star pe-2" />
            {i18n('Favorites')}
          </h6>
          <SidebarFavorites onLinkClick={closeOffCanvas} />
          <hr />
          <h6>
            <i className="bi bi-chat-left-text pe-2" />
            {i18n('Mentioned')}
          </h6>
          <SidebarMentions onLinkClick={closeOffCanvas} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

Sidebar.propTypes = {
  toggle: PropTypes.element.isRequired,
};
