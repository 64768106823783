/*
const envApiUrl = process.env.appconfig?.api_url;
export const API_ENDPOINT = `${envApiUrl}/v1/forum/ratings`;
 */
export const API_ENDPOINT = `${
  process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:5001'
}/v1/forum/ratings`;

export async function saveRating(
  accessToken,
  idToken,
  ratingValue,
  postAuthor,
  ratedBy,
  postId,
  postIndex,
) {
  const data = {
    post_index: postIndex,
    post_id: postId,
    post_author: postAuthor,
    rated_by: ratedBy,
    rated_value: ratingValue,
  };
  const id = encodeURIComponent(`${postIndex}#${postId}`);
  try {
    const response = await fetch(`${API_ENDPOINT}/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        id_token: idToken,
      },
      body: JSON.stringify(data),
    });
    return { result: await response.json(), error: null };
  } catch (error) {
    if (process.NODE_ENV === 'development') {
      console.log(error);
    }
    return { result: null, error };
  }
}

export async function getRating(
  accessToken,
  idToken,
  postId,
  postIndex,
  ratedBy,
) {
  const id = encodeURIComponent(`${postIndex}#${postId}`);
  try {
    const response = await fetch(`${API_ENDPOINT}/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        id_token: idToken,
      },
    });
    return { result: await response.json(), error: null };
  } catch (error) {
    /* noop */
    if (process.NODE_ENV === 'development') {
      console.log(error);
    }
    return { result: null, error };
  }
}
