import React from 'react';
import { Navbar, OverlayTrigger, Tooltip, TooltipProps } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useIsPathAllowed } from './auth/Auth0';
import { isSmallScreen } from './isSmallScreen';
import { useViewport } from './react/ViewPort';
import { INavigationItemProps } from './types';
import { useTranslation } from './utils/Localization';

const renderTooltip = (props: TooltipProps, key: string, label: string) => (
  <Tooltip id={key} {...props}>
    {label}
  </Tooltip>
);

// eslint-disable-next-line import/prefer-default-export
export function NavigationItem({
  label,
  pathname,
  search,
  ...rest
}: INavigationItemProps) {
  const isPathAllowed = useIsPathAllowed(pathname);
  const i18n = useTranslation();
  const { width } = useViewport();

  const ttTextKey = `${label}-tooltip`;
  const ttText = i18n(ttTextKey);

  const smallScreen = isSmallScreen(width);

  const menuItem = isPathAllowed ? (
    <NavLink
      className="nav-item nav-link"
      to={{ pathname, search }}
      key={pathname}
      {...rest}
    >
      {/* no tooltip in "small mode" */}
      <span title={smallScreen ? ttText : undefined}>{i18n(label)}</span>
    </NavLink>
  ) : (
    <Navbar.Text className="nav-item disabled nav-link" {...rest}>
      {i18n(label)}
    </Navbar.Text>
  );

  return smallScreen ? (
    menuItem
  ) : (
    <OverlayTrigger
      delay={500}
      placement="bottom"
      // render function seems to be the only way to avoid flickering
      overlay={(props) => renderTooltip(props, ttTextKey, ttText)}
    >
      {menuItem}
    </OverlayTrigger>
  );
}
