import React from 'react';
import { Badge } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';

export default ({
  values,
  onChange,
  editable
}: {
  values: string[];
  onChange: (values: string[]) => void;
  editable?: boolean;
}) => {
  return (
    <>
      {values.map((v) => (
        <Badge key={v} className="me-1 mb-1" pill bg="secondary">
          <span style={{ letterSpacing: '0.1rem' }}>
            {v}
            {editable && (
              <X
                role="button"
                onClick={() => onChange(values.filter((vv) => vv !== v))}
              />
            )}
          </span>
        </Badge>
      ))}
    </>
  );
};
