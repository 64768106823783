import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { ReactiveComponentProps } from '@appbaseio/reactivesearch/lib/components/basic/ReactiveComponent';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TogglePanel from '../SimpleTogglePanel';
import { SearchCasesApi, useSearchCasesApi } from './SearchCases.api';
import { buildElasticSearchQuery } from './SearchCases.queries';
import { ISearchCase } from './SearchCases.types';
import SearchCasesList from './SearchCasesList';

const SearchCasesRefinement = (props: ReactiveComponentProps) => {
  const scApi = useSearchCasesApi();
  const [searchCases, setSearchCases] = React.useState<ISearchCase[]>([]);

  useEffect(() => {
    const unsubscribe = scApi.eventBus.subscribe(
      'update-searchcases',
      () => scApi.available && scApi.getSearchCases().then(setSearchCases)
    );
    if (scApi.available) {
      scApi.getSearchCases().then(setSearchCases);
    }
    return () => {
      unsubscribe();
    };
  }, [scApi]);

  return (
    <ReactiveComponent
      {...props}
      defaultQuery={() => ({
        highlight: {
          pre_tags: ['<mark>'],
          post_tags: ['</mark>'],
          fields: {
            '*': {}
          }
        }
      })}
      render={({ setQuery }) => (
        <SearchCasesList
          searchCases={searchCases}
          onSelect={(sc: ISearchCase) => {
            setQuery({
              query: buildElasticSearchQuery(sc, scApi.context),
              value: sc.name
            });
          }}
        />
      )}
    />
  );
};

const SearchCasesRefinementWithApi = ({
  context,
  suggestionIndex,
  ...componentProps
}: {
  context: string;
  suggestionIndex: string;
} & ReactiveComponentProps) => {
  return (
    <SearchCasesApi context={context} suggestionIndex={suggestionIndex}>
      <SearchCasesRefinement {...componentProps} />
    </SearchCasesApi>
  );
};

export default SearchCasesRefinementWithApi;

export const SearchCasesRefinementWithPanel = (props: any) => {
  const { t } = useTranslation('searchcases');
  return (
    <TogglePanel stateful initialOpen={false} label={t('Search cases')}>
      <SearchCasesRefinementWithApi {...props} />
    </TogglePanel>
  );
};
