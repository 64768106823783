import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { Accordion, ListGroup } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useAccessToken } from '../../auth/Auth0';
import { useConfig } from '../../Config';
import { getByIdsQuery, useElastic } from '../../elasticsearch';
import { useTranslation } from '../../utils/Localization';
import { capitalCase } from '../../utils/StringTools';
import { useSocket, WebSocketContext } from '../../websocket';
import EllipsisText from '../EllipsisText';
import { LoaderText } from '../Loader';
import { MetaData, MetaDate } from '../ResultListItem';
import { StyledAccordion } from './StyledAccordion';

const SidebarMentions = ({ onLinkClick = () => {} }) => {
  const { socket, subscribeEvent } = useSocket();

  const [mentionedComments, setMentionedComments] = React.useState([]);
  const i18n = useTranslation('comments');

  useEffect(() => {
    const unsubscribeUserEmailMentioned = subscribeEvent(
      'user-email-mentioned',
      (value) => {
        if (value.error) {
          // setError(value.error);
        } else {
          setMentionedComments(value.comments);
        }
      },
    );
    socket.then((s) => s?.emit('user-email-mentioned'));
    return () => {
      unsubscribeUserEmailMentioned();
    };
  }, [socket, subscribeEvent]);

  const paths = ['recalls', 'complaints', 'investigations', 'bulletins'];
  const sides = mentionedComments.reduce(
    (acc, comment) => {
      const path = paths.find((p) => comment.entityref.includes(`/${p}`));
      if (path) {
        acc[path].push(comment);
      }
      return acc;
    },
    {
      recalls: [],
      complaints: [],
      investigations: [],
      bulletins: [],
    },
  );

  return (
    <StyledAccordion flush>
      {Object.keys(sides)
        .filter((s) => sides[s].length > 0)
        .map((k) => (
          <Accordion.Item key={k} eventKey={k}>
            <Accordion.Header as="div">{i18n(capitalCase(k))}</Accordion.Header>
            <Accordion.Body>
              <MentionedSection
                key={k}
                section={k}
                mentionedComments={sides[k]}
                onLinkClick={onLinkClick}
              />
            </Accordion.Body>
          </Accordion.Item>
        ))}
    </StyledAccordion>
  );
};

SidebarMentions.propTypes = {
  onLinkClick: PropTypes.func,
};

export const MentionedSection = (props) => {
  const { section, mentionedComments = [], onLinkClick = () => {} } = props;
  const config = useConfig();
  const i18n = useTranslation();
  const i18nDS = useTranslation('datasources');
  const { search } = useLocation();

  const mcMap = useMemo(
    () => mentionedComments.reduce((acc, c) => {
      let mid = /refine_id="([^"]*)"/.exec(c.entityref)?.[1];
      if (mid) {
        mid = decodeURIComponent(mid);
        // assign latest comment of document
        if (
          !acc[mid]
            || String(c.modified_at) > String(acc[mid]?.modified_at)
        ) {
          acc[mid] = c;
        }
      }
      return acc;
    }, {}),
    [mentionedComments],
  );

  const fetch = useElastic(
    config.getIndexName(section, 'cw3_cst_'),
    getByIdsQuery(Object.keys(mcMap)),
    mcMap,
  );

  if (fetch.isLoading) return <LoaderText />;

  const hits = fetch.data?.hits.hits;
  const moreHref = new URLSearchParams(search);
  moreHref.set('tab', 'mentioned');

  return (
    <ListGroup variant="flush">
      {hits
        && hits.map((hit) => {
          const doc = hit._source;
          const title = `${doc.facets.make || i18n('Various Makes')}: ${
            doc.facets.model || i18n('Various Models')
          }`;
          const href = mcMap[hit._id].entityref;
          return (
            <ListGroup.Item key={hit._id} className="d-flex flex-column">
              <div className="d-flex">
                <MetaData label="Source" value={i18nDS(hit.datasource)} />
                <MetaDate value={hit.publication_date} />
              </div>
              <Link
                onClick={onLinkClick}
                to={href}
                style={{ flex: 1 }}
                reloadDocument
                title={title}
              >
                <EllipsisText showShowAll={false} length={30} text={title} />
              </Link>
              <div>
                &quot;
                <EllipsisText
                  showShowAll
                  length={100}
                  text={mcMap[hit._id].comment}
                />
                &quot;
              </div>
            </ListGroup.Item>
          );
        })}
      <ListGroup.Item className="d-flex flex-column">
        <Link
          to={`?${moreHref.toString()}`}
          onClick={onLinkClick}
          title={i18n('View All')}
          reloadDocument
        >
          {i18n('View All')}
        </Link>
      </ListGroup.Item>
    </ListGroup>
  );
};

MentionedSection.propTypes = {
  section: PropTypes.string.isRequired,
  mentionedComments: PropTypes.arrayOf(PropTypes.object).isRequired,
  onLinkClick: PropTypes.func,
};

export default (props) => {
  const accessToken = useAccessToken();
  return (
    <WebSocketContext namespace="comments" jwtToken={accessToken}>
      <SidebarMentions {...props} />
    </WebSocketContext>
  );
};
