import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Button,
  Form,
  FormSelect,
  InputGroup,
  OverlayTrigger,
  Popover
} from 'react-bootstrap';
import { FiletypeCsv } from 'react-bootstrap-icons';
import { useAppState } from '../../AppStateProvider';
import { useApiCall } from '../../react/useApi';
import { useTranslation } from '../../utils/Localization';
import { downloadCSV } from '../../utils/downloadContent';

const apiUrl = process.env.appconfig?.api_url;
const instance = process.env.appconfig?.instance_name;

const DefaultCsvExportIcon = <FiletypeCsv size={16} />;

const CsvExport = ({
  icon: ExportIcon = () => DefaultCsvExportIcon,
  type,
  filters = {}
}) => {
  const i18n = useTranslation();
  const [size, setSize] = useState(10);
  const apiCall = useApiCall(
    `${apiUrl}/v1/csvexport/${instance}?type=${type}&size=${size}`,
    { method: 'post' }
  )();
  const { showError } = useAppState();

  const execute = () => {
    apiCall(filters)
      .then((resp) => {
        downloadCSV(resp);
      })
      .catch((e) => {
        showError(`Error while exporting CSV: ${e.message}`);
      });
  };

  const popover = (
    <Popover id="popover-basic" className="bg-light">
      <Popover.Body>
        <Form>
          <InputGroup className="mb-3" size="sm">
            <InputGroup.Text>#</InputGroup.Text>
            <FormSelect
              value={size}
              onChange={(e) => setSize(e.target.value)}
              size="sm"
            >
              <option value={10}>10</option>
              <option value={100}>100</option>
              <option value={1000}>1000</option>
            </FormSelect>
          </InputGroup>
          <center>
            <Button variant="primary" size="sm" onClick={execute}>
              {i18n('Export')}
            </Button>
          </center>
        </Form>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      overlay={popover}
      rootClose
    >
      <div
        className="inline-block"
        role="button"
        aria-label="Export CSV"
        tabIndex={0}
        title={i18n('Export CSV')}
      >
        <ExportIcon />
      </div>
    </OverlayTrigger>
  );
};

CsvExport.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  type: PropTypes.string.isRequired,
  filters: PropTypes.object
};

export default CsvExport;
