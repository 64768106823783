import React, { useMemo } from 'react';
import { EnvelopePaper } from 'react-bootstrap-icons';
import { downloadBlob } from '../../utils/downloadContent';
import Comments from '../comments/Comments';
import Dev from '../Develop/DevComponent';
import { IBodyOptions } from './rli.interfaces';
import { useEntityLink } from './useEntityLink';

export function BodyOptions({
  itemId,
  openComments,
  children
}: IBodyOptions): JSX.Element | null {
  const sideLink = useEntityLink(itemId);
  const body = useMemo(
    () => `To:
Subject: Carwatch Link
X-Unsent: 1
Content-Type: text/html

<html><head /><body><a href="${window.origin}${sideLink}">${window.origin}${sideLink}</a></body></html>`,
    [sideLink]
  );

  const downloadEML = () =>
    downloadBlob(new Blob([body], { type: 'text/plain' }), 'message.eml');
  const controls = (
    <>
      {/* <PinAngle size={16} title="Coming soon" /> */}
      <Comments entityRef={sideLink} onClick={openComments} />
      <Dev>
        <EnvelopePaper
          role="menuitem"
          size={16}
          title="Coming soon"
          onClick={downloadEML}
        />
      </Dev>
    </>
  );

  return (
    <div className="d-grid gap-1">
      {controls}
      {typeof children === 'function' ? children() : children}
    </div>
  );
}

export default BodyOptions;
