/* eslint-disable react/prop-types */
import {
  DataSearch,
  DateRange,
  DynamicRangeSlider,
  ReactiveComponent,
  ReactiveList,
  SelectedFilters,
} from '@appbaseio/reactivesearch';
import React from 'react';

import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';

import { toDate } from 'date-fns';

import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import join from 'lodash/join';
import map from 'lodash/map';
import uniqueId from 'lodash/uniqueId';
import without from 'lodash/without';

import {
  Bar, BarChart, ResponsiveContainer, Tooltip, XAxis,
} from 'recharts';
import { useConfig } from '../Config';
import ControlledTabs from '../components/ControlledTabs';
import { Page } from '../components/Layout/Page';
import { MetaData, MetaDate, MetaText } from '../components/ResultListItem';
import ReactiveApp from '../reactivesearch/ReactiveApp';
import * as t from '../utils/Localization';
// import PieChartComponent from '../components/Charts/PieChartComponent';
// import BarChartComponent from '../components/Charts/BarChartComponent';
import NoResults from '../components/NoResults';
import MultiListRefinement from '../components/Refinements/MultiListRefinement';

const PieChartComponent = React.lazy(() => import('../components/Charts/PieChartComponent'));
const BarChartComponent = React.lazy(() => import('../components/Charts/BarChartComponent'));

const interactiveComponentIds = [
  'make',
  'model',
  'component',
  'fulltextsearch',
  'chart_models',
  'chart_months',
  'publication_date',
];

const PublicationYearChart = (args) => {
  const data = map(get(args.aggregations, 'data.buckets'), (b) => ({
    name: b.key_as_string || b.key,
    value: b.doc_count,
  }));
  if (isEmpty(data)) return <div />;
  return (
    <div
      style={{
        width: '100%',
        height: args.height || '100px',
        opacity: args.loading ? 0.3 : 1,
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} isAnimationActive={false}>
          <XAxis dataKey="name" />
          <Bar
            name="Issues"
            dataKey="value"
            fill="#6cbace"
            isAnimationActive={false}
          />
          <Tooltip isAnimationActive={false} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

function ResultListItem(hit) {
  return (
    <Card key={uniqueId('card-rl')} className="card-rl mb-3">
      <Card.Header>
        {hit.facets.make}
        {' '}
        {isArray(hit.facets.model)
          ? join(hit.facets.model, ' / ')
          : hit.facets.model}
      </Card.Header>
      <Card.Body>
        <div className="d-flex flex-row flex-wrap">
          <MetaDate label="Date received" value={hit.publication_date} />
          <MetaData label="Source" value={t.datasources(hit.datasource)} />
        </div>
        <div className="d-flex flex-row flex-wrap">
          <MetaData label="Model Year(s)" value={hit.model_year} />
          <MetaDate
            label="Begin manufacturing"
            value={hit.manufacturing_start_date}
          />
          <MetaDate
            label="End manufacturing"
            value={hit.manufacturing_end_date}
          />
        </div>
        <div className="d-flex flex-row flex-wrap">
          <MetaData
            label="Manufacturer Reference"
            value={hit.manufacturer_reference}
          />
          <MetaData
            label="Manufacturer"
            value={get(hit, 'misc.manufacturer')}
          />
          <MetaData label="Recall ID" value={hit.campaign_number} />
          <MetaData
            label="Units affected"
            value={hit.affected_units < 0 ? undefined : hit.affected_units}
          />
          <MetaData label="Component(s)" value={hit.component} />
        </div>
        <div className="d-flex flex-row flex-wrap">
          <MetaText label="Defect" value={hit.defect} />
          <MetaText label="Consequences" value={hit.defect_consequence} />
          <MetaText label="Corrective Actions" value={hit.remedy} />
          <MetaText label="Notes" value={hit.notes} />
        </div>
      </Card.Body>
    </Card>
  );
}

function Test() {
  const config = useConfig();
  return (
    <ReactiveApp app={config.getIndexName('recalls')}>
      <Page>
        <Page.Refinements>
          <DynamicRangeSlider
            componentId="publication_date"
            dataField="publication_date"
            title={t.t('Publication Date')}
            renderNoResults={NoResults}
            react={{ and: interactiveComponentIds }}
            rangeLabels={(min, max) => ({
              start: toDate(min).getFullYear(),
              end: toDate(max).getFullYear(),
            })}
            includeNullValues
          />
          <ReactiveComponent
            componentId="test"
            defaultQuery={() => ({
              query: {
                range: {
                  publication_date: {
                    gte: 'now-20y/y',
                  },
                },
              },
              size: 0,
              aggs: {
                data: {
                  date_histogram: {
                    field: 'publication_date',
                    calendar_interval: '1y',
                    format: 'yyyy',
                  },
                },
              },
            })}
            render={PublicationYearChart}
            /* react={{ and: interactiveComponentIds }} */
          />
          <DateRange
            componentId="refine_publication_date_range"
            dataField="publication_date"
            URLParams
            dayPickerInputProps={{
              inputProps: {
                style: { padding: '0.1rem 0.3rem', minHeight: 'inherit' },
              },
            }}
          />
          <MultiListRefinement
            componentId="make"
            dataField="facets.make.keyword"
            title={t.t('Make')}
            renderNoResults={NoResults}
            react={{ and: without(interactiveComponentIds, 'make') }}
          />
          <MultiListRefinement
            componentId="model"
            dataField="facets.model.keyword"
            title={t.t('Model')}
            renderNoResults={NoResults}
            react={{ and: without(interactiveComponentIds, 'model') }}
          />
          <MultiListRefinement
            componentId="component"
            dataField="facets.component.keyword"
            title={t.t('Component')}
            renderNoResults={NoResults}
            react={{ and: without(interactiveComponentIds, 'component') }}
          />
        </Page.Refinements>
        <Page.Content>
          <DataSearch
            componentId="fulltextsearch"
            highlight
            highlightField={['defect', 'defect_consequence', 'remedy']}
            className="mb-2"
            URLParams
            debounce={300}
            searchOperators
          />
          <SelectedFilters className="mb-2" />
          <ControlledTabs param="tab" defaultTab="searchresults">
            <Tab eventKey="globals" title={t.t('Globals')}>
              <div className="container fluid">
                <div className="row">
                  <div className="col">
                    <ReactiveComponent
                      componentId="chart_models"
                      defaultQuery={() => ({
                        aggs: {
                          data: {
                            terms: {
                              field: 'facets.model.keyword',
                              size: 10,
                            },
                          },
                        },
                        size: 0,
                      })}
                      react={{ and: interactiveComponentIds }}
                      render={({ aggregations, setQuery, ...rest }) => (
                        <PieChartComponent
                          aggs={aggregations}
                          onClick={(value) => {
                            setQuery({
                              query: {
                                term: { 'facets.model.keyword': value },
                              },
                              value,
                            });
                          }}
                          {...rest}
                        />
                      )}
                      /* filter funktionieren aktuell nicht bei Reload der Seite */
                      URLParams
                      showFilter
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <ReactiveComponent
                      componentId="chart_months"
                      defaultQuery={() => ({
                        query: {
                          range: {
                            publication_date: {
                              gte: 'now-20y/y',
                            },
                          },
                        },
                        size: 0,
                        aggs: {
                          data: {
                            date_histogram: {
                              field: 'publication_date',
                              calendar_interval: '1M',
                              format: 'yyyy-MM',
                            },
                          },
                        },
                      })}
                      react={{ and: interactiveComponentIds }}
                      render={({ aggregations, setQuery, ...rest }) => (
                        <BarChartComponent
                          aggs={aggregations}
                          onClick={(selected, { timestamp }) => {
                            timestamp && setQuery({
                              query: {
                                range: {
                                  publication_date: {
                                    gte: `${timestamp}||/M`,
                                    lt: `${timestamp}||+1M/M`,
                                  },
                                },
                              },
                              value: selected,
                            });
                          }}
                          {...rest}
                        />
                      )}
                      /* filter funktionieren aktuell nicht bei Reload der Seite */
                      URLParams
                      showFilter
                    />
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="searchresults" title={t.t('Search Results')}>
              <ReactiveList
                componentId="resultlist"
                dataField="publication_date"
                sortBy="desc"
                defaultQuery={() => ({
                  timeout: '500ms',
                  script_fields: {
                    is_new: {
                      script:
                        'doc["@created"].size()!==0?(doc["@created"].value.getMillis()==doc["@updated"].value.getMillis()):false',
                    },
                  },
                })}
                renderItem={ResultListItem}
                renderError={(error) => (
                  <div>
                    Something went wrong with ResultList!
                    <br />
                    Error details
                    <br />
                    {JSON.stringify(error)}
                  </div>
                )}
                react={{ and: interactiveComponentIds }}
              />
            </Tab>
          </ControlledTabs>
        </Page.Content>
      </Page>
    </ReactiveApp>
  );
}

export default Test;
