// eslint-disable-next-line no-unused-vars
import { uniqueId } from 'lodash-es';
import React, {
  cloneElement,
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef
} from 'react';
import {
  Accordion,
  AccordionContext,
  Card,
  ListGroup,
  Stack,
  useAccordionButton
} from 'react-bootstrap';
import { ChatSquareDots } from 'react-bootstrap-icons';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import CommentList from '../comments/CommentList';
import Header from './Header';
import { useEntityLink } from './useEntityLink';

interface IToggleableListItemProps {
  label: string;
  icon: React.ReactElement;
  eventKey: string;
  children?: React.ReactNode | (() => React.ReactNode);
}

const ToggleableListItem: React.FC<IToggleableListItemProps> = ({
  label,
  icon,
  eventKey,
  children
}) => {
  const accordionButton = useAccordionButton(eventKey, () => {});
  const { activeEventKey } = useContext(AccordionContext);
  return (
    <ListGroup.Item>
      <Stack gap={1} direction="horizontal">
        <div>{icon}</div>
        <div
          role="button"
          className="fw-bold"
          onKeyUp={accordionButton}
          onClick={accordionButton}
          tabIndex={0}
        >
          {label}
        </div>
      </Stack>
      <Accordion.Collapse eventKey={eventKey}>
        <div className="mt-2">
          {activeEventKey === eventKey &&
            (typeof children === 'function' ? children() : children)}
        </div>
      </Accordion.Collapse>
    </ListGroup.Item>
  );
};

enum FooterEventKeys {
  COMMENTS = '0'
}

interface IFooterHandle {
  open: (key: string) => void;
}

interface IFooterProps {
  t: (s: string) => string;
  entityId: string;
}

const Footer = forwardRef(
  ({ t, entityId }: IFooterProps, ref): React.ReactElement => {
    const [activeKey, setActiveKey] = React.useState<AccordionEventKey>();
    const siteRef = useEntityLink(entityId);
    const accordionId = `${entityId}-comments`;
    useImperativeHandle(ref, () => ({
      open: (key: string) => {
        setActiveKey(key);
        // scrollToPanel(accordionId);
        document
          .getElementById(accordionId)
          ?.scrollIntoView({ behavior: 'smooth' });
      }
    }));
    return (
      <Accordion
        id={accordionId}
        flush
        activeKey={activeKey}
        onSelect={setActiveKey}
      >
        <ListGroup>
          <ToggleableListItem
            label={t('Comments')}
            icon={<ChatSquareDots size={16} />}
            eventKey={FooterEventKeys.COMMENTS}
          >
            <CommentList entityRef={siteRef} />
          </ToggleableListItem>
        </ListGroup>
      </Accordion>
    );
  }
);

interface IResultListItemProps {
  title: string;
  options?: React.ReactElement;
  bodyOptions?: React.ReactElement;
  entityId?: string;
  children?: React.ReactNode | (() => React.ReactNode);
}

export default function ResultListItem(props: IResultListItemProps) {
  const { title, children, options, bodyOptions, entityId } = props;
  const { t } = useTranslation('resultlistitem');
  const footerRef = useRef<IFooterHandle>();

  return (
    <Card key={uniqueId('resultlistitem')} className="card-rl mb-3">
      <Card.Header>
        <Header title={title} optionElement={options} />
      </Card.Header>
      <Card.Body>
        <div className="d-flex">
          <div className="flex-grow-1">
            {typeof children === 'function' ? children() : children}
          </div>
          <div className="text-secondary p-1">
            {/* expose footer api to the body options */}
            {bodyOptions &&
              cloneElement(bodyOptions, {
                openComments: () =>
                  footerRef.current.open(FooterEventKeys.COMMENTS)
              })}
          </div>
        </div>
      </Card.Body>
      <Card.Footer>
        <Footer ref={footerRef} t={t} entityId={entityId} />
      </Card.Footer>
    </Card>
  );
}
