
import { 
    mappingInjStatus, 
    mappingMAIS, 
    mappingMortality 
} from './Mappings/OccupantsMappings';

import { findLabelInKVMap } from './HelperFunctions';

export function getLabelInjStatus (intInjStatus) {
    return findLabelInKVMap(mappingInjStatus, intInjStatus);
}

export function getLabelMAIS (intMAIS) {
    return findLabelInKVMap(mappingMAIS, intMAIS);
}

export function getLabelMortality (intMortality) {
    return findLabelInKVMap(mappingMortality, intMortality);
}
