import { jwtDecode } from 'jwt-decode';
import { useMemo } from 'react';
import { JWTPayloadWithPermissions } from './types';
import { useAccessToken } from './Auth0';
// import useAccessToken from './useAccessToken';

export default function useUserSites() {
  const accessToken = useAccessToken();

  const sites = useMemo(() => {
    const decoded: JWTPayloadWithPermissions | undefined =
      accessToken && jwtDecode(accessToken);
    const permissions = decoded && decoded.permissions;
    if (Array.isArray(permissions)) {
      return permissions
        .filter((p) => p.startsWith('site:'))
        .map((p) => p.substring(5));
    }
    return [];
  }, [accessToken]);

  return sites;
}
