import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from '../../utils/Localization';
import { joinValue } from '../../utils/StringTools';
import { renderDev } from '../Develop/DevComponent';
import Source from '../Develop/Source';
import { Section } from '../Layout/Card';
import {
  Favorite,
  MetaData,
  MetaDate,
  MetaText,
  useEntityLink,
} from '../ResultListItem';
import ResultListItem from '../ResultListItem/ResultListItem';

function getLocation(hit) {
  if (hit.city) {
    if (hit.state) {
      return `${startCase(hit.city)}, ${hit.state}`;
    }
    return startCase(hit.city);
  }
  if (hit.state) {
    return hit.state;
  }
  if (hit.address) {
    return startCase(hit.address);
  }
  return 'n.a.';
}

function getComponents(component) {
  let components = null;
  if (Array.isArray(component)) {
    components = joinValue(
      component.map((c) => startCase(c.toLowerCase())),
      ', ',
    );
  } else if (component) {
    components = startCase(component.toLowerCase());
  }
  return components;
}

function ComplaintsResultListItem({ hit }) {
  const {
    facets,
    publication_date,
    datasource,
    component,
    complaint,
    complaint_description,
  } = hit;
  const i18nDS = useTranslation('datasources');

  const title = `${facets?.make} ${joinValue(facets?.model, ' / ')}`;
  const sideLink = useEntityLink(hit._id);

  return (
    <ResultListItem
      entityId={hit._id}
      title={title}
      options={<Favorite entityRef={sideLink} name={title} />}
    >
      <Section>
        <MetaDate label="Date received" value={publication_date} />
        <MetaData label="Source" value={i18nDS(datasource)} />
      </Section>
      <Section>
        <MetaData label="Location" value={getLocation(hit)} />
        <MetaData label="Component(s)" value={getComponents(component)} />
      </Section>
      <Section>
        <MetaText value={complaint} />
        <MetaText value={complaint_description} />
      </Section>
      {renderDev(<Source sourceObject={hit} />)}
    </ResultListItem>
  );
}

ComplaintsResultListItem.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default ComplaintsResultListItem;
