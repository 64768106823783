import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import * as t from '../utils/Localization';

class NoAccess extends React.Component {
  render() {
    const { className } = this.props;
    return <div className={className}>{t.t('No access to this module.')}</div>;
  }
}

NoAccess.propTypes = {
  className: string.isRequired,
};

export default styled(NoAccess)`
  font-size: 1.2em;
  font-weight: bold;
  padding-top: 5em;
  text-align: center;
`;
