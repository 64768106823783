/* eslint-disable max-len */
import { useMemo } from 'react';
import useFetch from 'react-fetch-hook';
import { useAccessToken } from '../auth/Auth0';

const useApi = (url, options = {}, depends = []) => {
  const accessToken = useAccessToken();
  const authorization = `Bearer ${accessToken}`;
  const dependsArr = useMemo(
    () => [!!accessToken].concat(depends),
    [accessToken, depends],
  );

  options.headers = {
    ...options?.headers,
    Authorization: authorization,
    'Content-Type': 'application/json',
  };

  return useFetch(url, options, { depends: dependsArr });
};
/**
 * Returns a function that makes an authenticated API call with the provided body.
 *
 * @param {!string} url - The URL to make the API call to.
 * @param {object} options - Additional options for the API call (fetch options).
 * @Param {Array} depends - Array of dependencies for the API call. Called, when all dependencies are truthy.
 * @return {function} A async function that, when called, makes the API call with the provided body.
 */
export const useApiCall = (url, options = {}, depends = []) => {
  const accessToken = useAccessToken();
  const authorization = `Bearer ${accessToken}`;

  options.headers = {
    ...options?.headers,
    'Content-Type': 'application/json',
  };

  if (accessToken) {
    options.headers.Authorization = authorization;
  }

  return () => async (body) => {
    const fetchOptions = {
      ...options,
    };
    if (body) {
      fetchOptions.body = JSON.stringify(body);
    }
    const execute = depends?.every((d) => !!d);
    return (
      execute
        && fetch(url, fetchOptions).then((res) => {
          if (!res.ok) throw new Error(res.statusText);
          const ct = res.headers.get('Content-type');
          if (ct?.startsWith('text')) {
            return res.text();
          }
          if (ct?.startsWith('application/json')) {
            return res.json();
          }
          return res;
        })
    );
  };
};

export default useApi;
