import React from 'react';
import PropTypes from 'prop-types';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Surface,
  Symbols,
  Tooltip,
} from 'recharts';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import uniqueId from 'lodash/uniqueId';
import includes from 'lodash/includes';
import { TEN_COLORS } from '../../utils/cssColors';
import { range10 } from '../../utils/colors';

export default function PieChartComponent({
  value,
  loading,
  aggregations,
  width,
  height,
  onClick = () => {},
}) {
  const diagram = React.useRef(null);
  const [data, setData] = React.useState(null);
  const [disabled, setDisabled] = React.useState([]);
  React.useEffect(() => {
    if (value) {
      onClick(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // SM: no dependencies!!
  React.useEffect(() => {
    if (diagram.current) {
      setData(
        map(get(aggregations, 'data.buckets'), (b) => ({
          name: b.key_as_string || b.key,
          value: b.doc_count,
        })),
      );
    }
  }, [aggregations]);

  const onLegendClick = (event) => {
    const dataKey = event.value;
    if (includes(disabled, dataKey)) {
      setDisabled(disabled.filter((obj) => obj !== dataKey));
    } else {
      setDisabled(disabled.concat(dataKey));
    }
  };

  const renderLegend = (props) => {
    const { payload, onClick = () => {} } = props;
    return (
      <div className="vstack gap-1">
        {payload.map((entry) => {
          const inactive = includes(disabled, entry.value);
          const style = {
            color: inactive ? 'gray' : entry.color,
          };
          return (
            <span
              role="link"
              key={uniqueId('legend')}
              style={style}
              onClick={() => onLegendClick(entry)}
              onKeyDown={() => {}}
              tabIndex="0"
            >
              <Surface width={10} height={10} viewBox="0 0 10 10">
                <Symbols cx={5} cy={5} type="square" size={50} fill={style.color} />
                {inactive && <Symbols cx={5} cy={5} type="square" size={25} fill="#FFF" />}
              </Surface>
              <span className="ms-2">{entry.value}</span>
            </span>
          );
        })}
      </div>
    );
  };

  return (
    <div
      ref={diagram}
      style={{ width: width || '100%', height: height || '300px', opacity: loading ? 0.3 : 1 }}
    >
      {!isEmpty(data) && (
        <ResponsiveContainer>
          <PieChart>
            <Pie
              isAnimationActive
              dataKey="value"
              data={data.map((d) => (includes(disabled, d.name) ? { ...d, value: 0 } : d))}
              innerRadius={10}
              onClick={({ name }) => !loading && onClick(name)}
            >
              {data.map((entry, index) => (
                <Cell key={uniqueId('cell')} fill={range10[index % TEN_COLORS.length]} />
              ))}
            </Pie>
            <Legend layout="vertical" align="right" verticalAlign="middle" content={renderLegend} />
            <Tooltip isAnimationActive={false} />
          </PieChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}

PieChartComponent.propTypes = {
  value: PropTypes.any,
  loading: PropTypes.bool,
  aggregations: PropTypes.any,
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};
