/* eslint-disable react/prop-types */
import { TreeList } from '@appbaseio/reactivesearch';
import React, { useState } from 'react';
import { CaretDown, CaretRight } from 'react-bootstrap-icons';
import { toCaseInsensitivePrefixExpr } from './helpers';

const switcherIcon = (isExpanded) => (isExpanded ? <CaretDown /> : <CaretRight />);

const SEPARTOR = ' > ';
const recLookup = (obj, path) => {
  try {
    const parts = path.split(SEPARTOR);
    if (parts.length === 1) {
      return obj[parts[0]];
    }
    return recLookup(obj[parts[0]], parts.slice(1).join(SEPARTOR));
  } catch (e) {
    return false;
  }
};

const TreeNode = ({
  nodeData,
  selectedValues,
  parentPath = '',
  onClick = () => {},
}) => {
  const { key: label, count, list } = nodeData;
  const encodedLabel = label;
  const path = parentPath
    ? `${parentPath}${SEPARTOR}${encodedLabel}`
    : encodedLabel;
  const isSelected = !!recLookup(selectedValues, path);
  /* console.log(
    'label',
    label,
    'selectedValues',
    selectedValues,
    'parentPath',
    parentPath,
  ); */
  const isLeaf = list?.length === 0;
  return (
    <li className="d-flex flex-column">
      <span>
        <span
          role="menuitem"
          tabIndex={0}
          onKeyUp={() => {}}
          onClick={() => onClick(encodedLabel, parentPath, isLeaf)}
        >
          {label}
        </span>
        <sup className="ms-1">{count}</sup>
      </span>
      {isSelected && !isLeaf && (
        <ul>
          {list.map((item) => (
            <TreeNode
              key={item.key}
              nodeData={item}
              selectedValues={selectedValues}
              parentPath={path}
              onClick={onClick}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

const TreeVisualisation = (props) => {
  const {
    /* eslint-disable no-unused-vars */
    data,
    rawData,
    error,
    handleClick,
    value,
    loading,
  } = props;
  // console.log(props);
  const handleClickWrapper = (label, parentPath, isLeaf) => {
    console.log('handleClickWrapper', label, parentPath, isLeaf);
    handleClick(label, parentPath, isLeaf);
  };
  return (
    <ul>
      {[].map.call(data, (d) => (
        <TreeNode
          key={d.key}
          nodeData={d}
          selectedValues={value}
          onClick={handleClickWrapper}
        />
      ))}
    </ul>
  );
};

function TreeRefinement(props) {
  const [inputFilter, setInputFilter] = useState('');
  const [treeState, setTreeState] = useState();
  return (
    <div>
      <input
        type="text"
        placeholder="Filter"
        value={inputFilter}
        onChange={(e) => {
          setInputFilter(e.target.value);
        }}
      />
      <TreeList
        value={treeState}
        onChange={(value) => setTreeState(value)}
        setDefaultQuery={() => {}} // function is missing in TreeList
        setCustomQuery={() => {}} // function is missing in TreeList
        defaultQuery={(value, { dataField }) => (inputFilter
          ? {
            query: {
              bool: {
                should: dataField.map((df) => ({
                  regexp: {
                    [df]: {
                      value: toCaseInsensitivePrefixExpr(inputFilter),
                      // case_insensitive: true, // -- not supported in Elasticsearch 7.9
                      flags: 'ALL',
                    },
                  },
                })),
              },
            },
          }
          : {
            // the internal aggregation is added in query-part per default -> error
            // so add a default query to make it work
            query: {
              match_all: {},
            },
          })}
        /* switcherIcon={switcherIcon} */
        /* beforeValueChange={(value) => console.log('beforeValueChange', value)} */
        onValueChange={(value) => console.log('onValueChange', value)}
        {...props}
      >
        {/* {(propData) => {
          const {
            data, rawData, error, handleClick, value, loading, 
          } = propData;
          return <TreeVisualisation {...propData} />;
        }} */}
      </TreeList>
    </div>
  );
}

export default TreeRefinement;
