import { ReactiveComponent } from '@appbaseio/reactivesearch';
import {
  arrayOf, element, shape, string, 
} from 'prop-types';
import React from 'react';
import ReactiveApp from '../../reactivesearch/ReactiveApp';
import { useTranslation } from '../../utils/Localization';
import { formatDate } from '../FormatDate';
import ModalDialog from '../ModalDialog';
import Dev from '../Develop/DevComponent';

const threeDayMillis = 3 * 24 * 60 * 60 * 1000;
const sevenDayMillis = 7 * 24 * 60 * 60 * 1000;

const IndexStatusVisualization = ({ buckets }) => {
  const i18nIndices = useTranslation('indices');
  const data = buckets.map((b) => {
    const index = b.key;
    const lastUpdated = b.last_executed.hits.hits[0]._source['@timestamp'];
    let tsColor = 'green';
    if (lastUpdated) {
      const tsDiff = Date.now() - new Date(lastUpdated).getTime();
      if (tsDiff >= sevenDayMillis) tsColor = 'red';
      else if (tsDiff >= threeDayMillis) tsColor = 'yellow';
    }
    return (
      <tr key={b.key}>
        <td>{i18nIndices(index)}</td>
        <td>{formatDate(lastUpdated)}</td>
        <td style={{ color: tsColor }}>{tsColor}</td>
      </tr>
    );
  });
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>Index</th>
            <th>Index Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>{data}</tbody>
      </table>
      <hr />
      <div className="p-3">
        <h4>Legend</h4>
        <dl>
          <dt>green</dt>
          <dd>Index Date &lt; 3 days</dd>
          <dt>yellow</dt>
          <dd>Index Date 3 to 7 days</dd>
          <dt>red</dt>
          <dd>Index Date &gt; 7 days</dd>
        </dl>
      </div>
    </>
  );
};

IndexStatusVisualization.propTypes = {
  buckets: arrayOf(shape({})).isRequired,
};

const DatasourceStatus = ({ buckets }) => {
  const i18nDS = useTranslation('datasources');
  const filter = (b) => {
    const { key } = b;
    const translated = i18nDS(key);
    return translated !== key;
  };
  const map = [...buckets].filter(filter).map((b) => (
    <tr>
      <td>{b.key}</td>
      <td className="text-end">{formatDate(b.created.value)}</td>
    </tr>
  ));
  return (
    <table className="table">
      <tbody>{map}</tbody>
    </table>
  );
};

const IndexStatus = () => (
  <>
    <ReactiveApp app="carwatch-monitoring">
      <ReactiveComponent
        componentId="indexfilter"
        customQuery={() => ({
          query: {
            regexp: {
              'data.reindex.source.keyword': {
                value:
                  'cw.?_src_[a-zA-z0-9]*_(recalls|complaints|investigations|socialweb|bulletins)',
              },
            },
          },
        })}
      />
      <ReactiveComponent
        componentId="indexStatus"
        react={{ and: ['indexfilter'] }}
        defaultQuery={() => ({
          aggs: {
            group_by_source: {
              terms: {
                field: 'data.reindex.source.keyword',
                size: 100,
              },
              aggs: {
                last_executed: {
                  top_hits: {
                    sort: [
                      {
                        '@timestamp': {
                          order: 'desc',
                        },
                      },
                    ],
                    size: '1',
                  },
                },
              },
            },
          },
        })}
      >
        {({ aggregations, loading, error }) => {
          if (loading) return null;
          if (error) {
            console.error(error);
            return null;
          }
          if (!aggregations) return null;
          return (
            <IndexStatusVisualization
              buckets={aggregations.group_by_source.buckets}
            />
          );
        }}
      </ReactiveComponent>
    </ReactiveApp>
    <Dev>
      <ReactiveApp app="cw3_src__all_*">
        <ReactiveComponent
          componentId="dataSourceStatus"
          defaultQuery={() => ({
            size: 0,
            aggs: {
              group_by_datasource: {
                terms: {
                  field: 'datasource.keyword',
                  size: 100,
                },
                aggs: {
                  created: {
                    max: {
                      field: '@created',
                    },
                  },
                },
              },
            },
          })}
        >
          {({ aggregations, loading, error }) => {
            if (loading) return null;
            if (error) {
              console.error(error);
              return null;
            }
            if (!aggregations) return null;
            return (
              <DatasourceStatus
                buckets={aggregations.group_by_datasource.buckets}
              />
            );
          }}
        </ReactiveComponent>
      </ReactiveApp>
    </Dev>
  </>
);

const IndexStatusModal = ({ title, trigger }) => (
  <ModalDialog title={title} triggerElement={trigger}>
    {() => <IndexStatus />}
  </ModalDialog>
);

IndexStatusModal.propTypes = {
  title: string,
  trigger: element.isRequired,
};

export default IndexStatusModal;
