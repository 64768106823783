import loadable from '@loadable/component';
import React from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import { Layout } from '../Layout';
import { PrivateSection } from '../access/Sections';
import PrivateRoute from '../auth/PrivateRoute';
import Loading from '../components/Loading';
import { ComingSoon, Login as LoginScreen, NoAccess, NotFound } from '../pages';
import { customerRoutes as cRoutes } from './customers';
import mapCustomerInstances from './mapCustomerInstances';

const loadPage = (importFunc: any) =>
  loadable(importFunc, {
    fallback: <Loading />
  });

export default (instance: string) => {
  const customerRoutes = [];
  if (instance) {
    let instanceName = mapCustomerInstances(instance);
    customerRoutes.push(
      ...(cRoutes[instanceName] || []).map(({ path, Component }) => ({
        path,
        Component
      }))
    );
  }

  return createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        { index: true, element: <Navigate to="/v3/welcome" /> },
        { path: 'logout', element: <LoginScreen /> },
        { path: 'noaccess', element: <NoAccess /> },
        {
          path: '/litigations',
          element: <PrivateRoute component={ComingSoon} />
        },
        {
          path: 'v3',
          element: <PrivateSection renderNoAccess={<NoAccess />} />,
          children: [
            { index: true, element: <Navigate replace to="/v3/welcome" /> },
            {
              path: 'welcome',
              Component: loadPage(
                () =>
                  import(
                    /* webpackChunkName: "welcome" */ '../pages/v3/Welcome'
                  )
              )
            },
            {
              path: 'recalls',
              Component: loadPage(
                () =>
                  import(
                    /* webpackChunkName: "recalls" */ '../pages/v3/Recalls'
                  )
              )
            },
            {
              path: 'complaints',
              Component: loadPage(
                () =>
                  import(
                    /* webpackChunkName: "complaints" */ '../pages/v3/Complaints'
                  )
              )
            },
            {
              path: 'mentions',
              Component: loadPage(
                () =>
                  import(
                    /* webpackChunkName: "mentions" */ '../pages/v3/Mentions'
                  )
              )
            },
            {
              path: 'investigations',
              Component: loadPage(
                () =>
                  import(
                    /* webpackChunkName: "investgations" */ '../pages/v3/Investigations'
                  )
              )
            },
            {
              path: 'bulletins',
              Component: loadPage(
                () =>
                  import(
                    /* webpackChunkName: "bulletins" */ '../pages/v3/Bulletins'
                  )
              )
            },
            {
              path: 'reports',
              children: [
                {
                  index: true,
                  Component: loadPage(
                    () =>
                      import(
                        /* webpackChunkName: "reports" */ '../pages/v3/Reports'
                      )
                  )
                }
              ]
            },
            {
              ...(process.env.NODE_ENV === 'development'
                ? {
                    path: 'test',
                    Component: loadPage(
                      () =>
                        import(
                          /* webpackChunkName: "test" */ '../pages/v3/Test'
                        )
                    )
                  }
                : {})
            },
            {
              path: 'faq',
              Component: loadPage(
                () => import(/* webpackChunkName: "faq" */ '../pages/v3/Faq')
              )
            }
          ]
        },
        ...customerRoutes,
        { path: '*', element: <NotFound /> }
      ]
    }
  ]);
};
