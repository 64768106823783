import React from 'react';
import { useConfig } from '../../Config';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from '../../utils/Localization';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { comboHistoryChartsQuery as comboHistoryQuery, toComboHistorySeries, getOptions } from './ComboHistoryChartUtils';

const PlotComboHistoryChart = ({ dataSeries, categories }) => {
    const i18n = useTranslation();
    const options = getOptions(categories);
    if (dataSeries.length === 0) {
        return (
            <div
                style={{
                    textAlign: 'center',
                }}
            >
                {i18n('No incidents found')}
            </div>
        );
    }
    return (
        <ReactApexChart options={options} series={dataSeries} type="line" height={350} />
    )
}

export default function ComboHistoryCharts({ componentId, react, index }) {
    const config = useConfig();
    const prefix = `cw3_cst_${config.customer.name}`;
    return (
        <div>
            <ReactiveComponent
                componentId={componentId}
                defaultQuery={() => comboHistoryQuery(prefix, index)}
                react={react}
                render={(args) => {
                    if (!args.aggregations) return null;
                    const { categories, dataSeries } = toComboHistorySeries(args.aggregations);
                    return (
                        <>
                            <PlotComboHistoryChart dataSeries={dataSeries} categories={categories} />
                        </>
                    );
                }}
            />
        </div>
    )
}