import { ReactiveComponent } from '@appbaseio/reactivesearch';
import React, { useRef } from 'react';
import {
  Card, Col, Container, Row, Tab, 
} from 'react-bootstrap';

import { without } from 'lodash-es';
import { definedSearchFields } from '@/components/Search/FullTextSearch';
import { SearchCasesRefinementWithPanel } from '@/components/SearchCases/SearchCasesRefinement';
import { EPageContext, withPageContext } from '@/context/PageContext';
import { useConfig } from '../../Config';
import GeoMapUSA from '../../components/Charts/GeoMap';
import HeatMapCharts from '../../components/Charts/HeatMapCharts';
import TimeSeriesChart from '../../components/Charts/TimeSeriesChart';
import {
  ComplaintsResultListItem,
  MileageChartCard,
} from '../../components/Complaints';
import ComboAnalysis from '../../components/Complaints/ComboAnalysis';
import ControlledTabs from '../../components/ControlledTabs';
import ErrorBoundary, { renderError } from '../../components/ErrorBoundary';
import { Page } from '../../components/Layout/Page';
import {
  CWSelectedFilters,
  ComponentRefinement,
  DateHistogramRefinement,
  DefinedFilters,
  MakeRefinement,
  ModelRefinement,
  SavedFilters,
} from '../../components/Refinements';
import {
  CustomerRefinements,
  useCustomerRefinementIds,
} from '../../components/Refinements/CustomerRefinements';
import DateRangeRefinement from '../../components/Refinements/DateRangeRefinement';
import { componentIds as customRefinementsIds } from '../../components/Refinements/DetectedRefinements';
import MultiListRefinement from '../../components/Refinements/MultiListRefinement';
import RefineId from '../../components/Refinements/RefineId';
import ScrollTarget from '../../components/Refinements/ScrollTarget';
import {
  ExportResults,
  ResultStats,
} from '../../components/ResultListItem/ResultStats';
import { FullTextSearch } from '../../components/Search';
import SearchResultList, {
  transformFilters,
} from '../../components/SearchResultList';
import { ResultListSpinner } from '../../components/Spinners';
import ReactiveApp from '../../reactivesearch/ReactiveApp';
import { useTranslation } from '../../utils/Localization';
import { usePageTitle } from './PageTitle';

const defaultInteractiveComponentIds = [
  'q',
  'refine_id',
  'refine_publication_date',
  'refine_publication_date_range',
  'refine_datasource',
  'refine_region',
  'refine_make',
  'refine_model',
  'refine_component',
  'refine_searchcases',
  ...customRefinementsIds,
];

const Complaints = () => {
  const interactiveComponentIds = useCustomerRefinementIds(
    defaultInteractiveComponentIds,
  );
  const config = useConfig();
  const i18n = useTranslation();
  const i18nDS = useTranslation('datasources');
  usePageTitle(i18n('Complaints'));
  const i18nComplaints = useTranslation('complaints');
  const indexName = config.getIndexName('complaints', 'cw3_cst_');
  const [selectedCombo, setSelectedCombo] = React.useState(null);
  const filters = useRef({});

  const [selectedMake, selectedModel, selectedComponent] = selectedCombo?.split('|') || [];

  return (
    <ReactiveApp app={indexName}>
      <Page>
        <Page.Refinements>
          <ScrollTarget targetId="customer-refinements" />
          <SavedFilters />
          <DefinedFilters context="complaints" />
          <hr className="border border-3 rounded-pill" />
          <RefineId componentId="refine_id" />
          <ErrorBoundary>
            <SearchCasesRefinementWithPanel
              componentId="refine_searchcases"
              context="complaints"
              suggestionIndex={indexName}
              showFilter
              filterLabel="SearchCase"
            />
            {/* <SearchCases
              context="complaints"
              componentId="refine_searchcases"
              react={{
                and: without(interactiveComponentIds, 'refine_searchcases'),
              }}
            /> */}
          </ErrorBoundary>
          <hr className="border border-3 rounded-pill" />
          <DateHistogramRefinement
            componentId="refine_publication_date"
            react={{
              and: without(interactiveComponentIds, 'refine_publication_date'),
            }}
            dataField="publication_date"
            label={i18n('Publication Date')}
            filterLabel={i18n('Publication Date')}
            URLParams
            loader="Loading ..."
          />
          <ErrorBoundary>
            <DateRangeRefinement
              componentId="refine_publication_date_range"
              dataField="publication_date"
              startLabel="Start Date"
              endLabel="End Date"
              filterLabel="Publication Date Range"
              URLParams
            />
            {/* <DateRangeSC
              componentId="refine_publication_date_range"
              dataField="publication_date"
              URLParams
              dayPickerInputProps={{
                inputProps: {
                  style: { padding: '0.1rem 0.3rem', minHeight: 'inherit' },
                },
              }}
              react={{
                and: without(
                  interactiveComponentIds,
                  'refine_publication_date_range',
                ),
              }}
            /> */}
          </ErrorBoundary>

          <hr />

          <MultiListRefinement
            componentId="refine_datasource"
            dataField="datasource.keyword"
            title={i18n('Data Source')}
            filterLabel={i18n('Data Source')}
            translateFunc={i18nDS}
            URLParams
            react={{
              and: without(interactiveComponentIds, 'refine_datasource'),
            }}
          />
          <MakeRefinement
            react={{ and: without(interactiveComponentIds, 'refine_make') }}
          />
          <ModelRefinement
            react={{ and: without(interactiveComponentIds, 'refine_model') }}
          />
          <ComponentRefinement
            react={{
              and: without(interactiveComponentIds, 'refine_component'),
            }}
          />
          <hr id="customer-refinements" />
          <ErrorBoundary>
            <CustomerRefinements react={interactiveComponentIds} />
          </ErrorBoundary>
        </Page.Refinements>
        <Page.Content>
          <FullTextSearch
            componentId="q"
            className="mb-3"
            dataField={definedSearchFields('complaints')}
            ignoreNested
          />
          <CWSelectedFilters
            onChange={(args) => {
              filters.current = transformFilters(args);
            }}
            context="complaints"
          />
          <ControlledTabs param="tab" defaultTab="searchresults">
            <Tab eventKey="globals" title={i18n('Globals')}>
              <ErrorBoundary>
                <MileageChartCard
                  className="mb-2"
                  react={{ and: [...interactiveComponentIds] }}
                />
              </ErrorBoundary>
              <ErrorBoundary>
                <Card className="mb-2">
                  <Card.Header>
                    <span className="fw-bold">
                      {i18n('No. of Issues Timeline')}
                    </span>
                  </Card.Header>
                  <Card.Body>
                    <ReactiveComponent
                      componentId="chart_timeline_overview"
                      defaultQuery={() => ({
                        query: {
                          range: {
                            publication_date: {
                              gte: 'now-10y/y',
                            },
                          },
                        },
                        size: 0,
                        aggs: {
                          data: {
                            date_histogram: {
                              field: 'publication_date',
                              calendar_interval: '1M',
                              format: 'yyyy-MM',
                            },
                          },
                        },
                      })}
                      render={(args) => (
                        <TimeSeriesChart {...args} chartType="bar" />
                      )}
                      react={{ and: [...interactiveComponentIds] }}
                    />
                  </Card.Body>
                </Card>
              </ErrorBoundary>
              <ErrorBoundary>
                <Card className="mb-2" style={{ height: 500 }}>
                  <Card.Header>
                    <span className="fw-bold">
                      {i18n('USA Issues, States Overview')}
                    </span>
                  </Card.Header>
                  <Card.Body>
                    <ReactiveComponent
                      componentId="geomap_usa"
                      // get no of issues per state
                      defaultQuery={() => ({
                        query: { term: { 'datasource.keyword': 'US' } },
                        size: 0,
                        aggs: {
                          geo: { terms: { field: 'state.keyword', size: 1000 } },
                        },
                      })}
                      render={(args) => {
                        // eslint-disable-next-line no-unused-vars
                        const { aggregations, isLoading, error } = args;
                        if (!aggregations) return null;
                        // map to name value pairs
                        const data = aggregations.geo.buckets.map((b) => ({
                          name: b.key,
                          value: b.doc_count,
                        }));
                        return <GeoMapUSA data={data} />;
                      }}
                      react={{ and: [...interactiveComponentIds] }}
                    />
                  </Card.Body>
                </Card>
              </ErrorBoundary>
            </Tab>
            <Tab eventKey="comboanalysis" title={i18n('Combinations')}>
              <ErrorBoundary>
                <Container fluid>
                  <Row>
                    <Col>
                      <ReactiveComponent
                        componentId="combo_make_model_component"
                        defaultQuery={() => ({
                          aggs: {
                            combo: {
                              terms: {
                                field: 'combo.make_model_component',
                                size: 20,
                              },
                            },
                          },
                          size: 0,
                        })}
                        react={{
                          and: [
                            'q',
                            'refine_id',
                            'refine_datasource',
                            'refine_make',
                            'refine_model',
                            'refine_component',
                          ],
                        }}
                      >
                        {(args) => {
                          const { aggregations, error, loading } = args;
                          if (error) return renderError(error);
                          if (loading) {
                            return <ResultListSpinner />;
                          }
                          if (!aggregations) return null;
                          return (
                            <ComboAnalysis
                              data={aggregations.combo.buckets}
                              onSelect={setSelectedCombo}
                            />
                          );
                        }}
                      </ReactiveComponent>
                    </Col>
                  </Row>
                  {selectedCombo && (
                    <Row>
                      <Col className="col-6">
                        <div className="fw-bold">
                          {i18nComplaints('Heatmap for combo', {
                            combo: `${selectedMake} / ${selectedModel}`,
                          })}
                        </div>
                        <ErrorBoundary>
                          <ReactiveComponent
                            componentId="selected_combo_analysis"
                            defaultQuery={() => ({
                              query: {
                                bool: {
                                  must: [
                                    {
                                      match: {
                                        'facets.make': selectedMake,
                                      },
                                    },
                                    {
                                      match: {
                                        'facets.model': selectedModel,
                                      },
                                    },
                                  ],
                                },
                              },
                              aggs: {
                                data: {
                                  terms: {
                                    field: 'facets.component.keyword',
                                    size: 25,
                                  },
                                },
                              },
                              size: 0,
                            })}
                            render={(args) => <HeatMapCharts {...args} />}
                          />
                        </ErrorBoundary>
                      </Col>
                      <Col className="col-6">
                        <div className="fw-bold">
                          {i18nComplaints('Timeseries for combo', {
                            combo: `${selectedMake} / ${selectedModel} / ${selectedComponent}`,
                          })}
                        </div>
                        <ErrorBoundary>
                          <ReactiveComponent
                            componentId="selected_combo_months"
                            defaultQuery={() => ({
                              query: {
                                bool: {
                                  must: [
                                    {
                                      range: {
                                        publication_date: {
                                          gte: 'now-20y/y',
                                        },
                                      },
                                    },
                                    {
                                      term: {
                                        'combo.make_model_component':
                                          selectedCombo,
                                      },
                                    },
                                  ],
                                },
                              },
                              size: 0,
                              aggs: {
                                data: {
                                  date_histogram: {
                                    field: 'publication_date',
                                    calendar_interval: '1M',
                                    format: 'yyyy-MM',
                                  },
                                },
                              },
                            })}
                            render={(args) => (
                              <TimeSeriesChart {...args} chartType="bar" />
                            )}
                          />
                        </ErrorBoundary>
                      </Col>
                    </Row>
                  )}
                </Container>
              </ErrorBoundary>
            </Tab>
            <Tab eventKey="searchresults" title={i18n('Search Results')}>
              <ErrorBoundary>
                <Container fluid>
                  <Row>
                    <Col>
                      <SearchResultList
                        componentId="resultlist"
                        pagination
                        dataField="publication_date"
                        exportElement={(
                          <ExportResults
                            type="complaints"
                            filters={filters.current}
                          />
                        )}
                        statsElement={<ResultStats />}
                        react={{ and: interactiveComponentIds }}
                        renderResultStats={() => {}}
                        innerClass={{ sortOptions: 'mb-2' }}
                      >
                        {({ loading, error, data }) => {
                          if (error) return renderError(error);
                          if (loading) {
                            return <ResultListSpinner />;
                          }
                          return (
                            <div>
                              {data.map((hit) => (
                                <ComplaintsResultListItem
                                  key={hit._id}
                                  hit={hit}
                                />
                              ))}
                            </div>
                          );
                        }}
                      </SearchResultList>
                    </Col>
                  </Row>
                </Container>
              </ErrorBoundary>
            </Tab>
          </ControlledTabs>
        </Page.Content>
      </Page>
    </ReactiveApp>
  );
};

export default withPageContext(
  Complaints,
  EPageContext.COMPLAINTS,
  'Complaints',
);
