import React, { Suspense, useRef } from 'react';
import LoaderHourglass from './LoaderHourglass';

export interface IAsyncLoader<T> {
  resolved: boolean;
  rejected: Error;
  result: T;
  promise: Promise<void | T>;
}

/**
 * Creates a function that can be used to load a value asynchronously, e.g. loading data from an API.
 * If the value is not yet loaded, calling the function will throw a promise that resolves when the value is loaded
 * (to use with React.Suspense).
 * If the value is already loaded, calling the function will return the value immediately.
 * If the value failed to load, calling the function will throw the error immediately.
 *
 * The function returned by useAsyncLoader is safe to call multiple times. If the value is already being loaded,
 * subsequent calls will simply throw the same promise as the first call.
 *
 * @example
 * const loader = useAsyncLoader(async () => {
 *   const value = await fetchApiData();
 *   return value;
 * });
 *
 * @param asyncMethod - a function that returns a promise that resolves to the value to be loaded.
 * @returns an object with a single property, `loader`, which is a function that can be used to load the value.
 */
export function useAsyncLoader<T>(asyncMethod: (...args: any) => Promise<T>): {
  loader: () => T;
} {
  const storage = useRef<IAsyncLoader<T>>({
    resolved: false,
    rejected: undefined,
    result: undefined,
    promise: undefined
  });

  return {
    loader: () => {
      if (storage.current.rejected) throw storage.current.rejected;
      if (storage.current.resolved) return storage.current.result;
      if (!storage.current.promise) {
        storage.current.promise = asyncMethod()
          .then((res: T) => {
            storage.current.promise = undefined;
            storage.current.resolved = true;
            storage.current.result = res;
            return res;
          })
          .catch((err) => {
            storage.current.promise = undefined;
            storage.current.rejected = err;
          });
      }
      throw storage.current.promise;
    }
  };
}

export const AwaitComponent = ({
  loader,
  render
}: {
  loader: () => any;
  render: (r: any) => any;
}) => {
  const result: any = loader();
  return render(result);
};

/**
 * AsyncComponentLoader is a higher-order component that takes an async function
 * and a render function as props. The async function should return a promise
 * that resolves to a value, which is then passed to the render function.
 *
 * AsyncComponentLoader will render the result of the render function, or render
 * the renderLoader function if it is provided while the async function is
 * loading. If the async function rejects, AsyncComponentLoader will rethrow the
 * error.
 * (Fetch-Then-Render)
 *
 * @param {Function} asyncFunction A function that returns a promise.
 * @param {Function} render A function that takes the result of the async function
 *   and returns a React component.
 * @param {Function} [renderLoader] A function that returns a React component to
 *   render while the async function is loading.
 * @return {React.Component} A component that renders the result of the render
 *   function, or renders the renderLoader function if it is provided while the
 *   async function is loading.
 *
 * sample usage:
 * @example
 *    <AsyncComponentLoader
 *      asyncFunction={async () => {
 *        const response = await fetch('https://jsonplaceholder.typicode.com/todos/1');
 *        return response.json();
 *      }}
 *      render={(data) => <div>{data.title}</div>}
 *    />
 * @example
 *    <AsyncComponentLoader
 *      asyncFunction={async () => {
 *        const response = await fetch('https://jsonplaceholder.typicode.com/todos/1');
 *        return response.json();
 *      }}
 *      renderLoader={() => <LoaderHourglass />}
 *      render={(data) => <div>{data.title}</div>}
 *    />
 */
const AsyncComponentLoader = ({
  asyncFunction,
  renderLoader,
  render
}: {
  asyncFunction: () => Promise<any>;
  renderLoader?: () => JSX.Element;
  render: (r: any) => JSX.Element;
}): JSX.Element => {
  const { loader } = useAsyncLoader(asyncFunction);
  return (
    <Suspense fallback={renderLoader ? renderLoader() : <LoaderHourglass />}>
      <AwaitComponent loader={loader} render={render} />
    </Suspense>
  );
};

export default AsyncComponentLoader;
