import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useConfig } from '../../Config';
import { useElastic } from '../../react/Elastic';
import SimpleSpinner from '../SimpleSpinner';

const query = (campaignNo) => ({
  query: {
    terms: {
      'campaign_number.keyword': isArray(campaignNo) ? campaignNo : [campaignNo],
    },
  },
  _source: ['campaign_number'],
});

export default function RelatedInvRecalls({ campaignNo }) {
  const config = useConfig();
  const toCN = (cn) => cn && (cn.length <= 6 ? `${cn}000` : cn);
  const { isLoading, data, error } = useElastic(
    config.getIndexName('recalls', 'cw3_cst_'),
    query(
      Array.isArray(campaignNo)
        ? [].map.call(campaignNo || [], toCN)
        : toCN(campaignNo),
    ),
    !!campaignNo,
  );

  if (!campaignNo) return <span className="me-2">n.a.</span>;
  if (isLoading) {
    return (
      <span className="me-2">
        <SimpleSpinner />
      </span>
    ); 
  }
  if (error) throw error;

  return (
    <span className="me-2">
      {data?.hits.hits.length === 0 && <span>{campaignNo}</span>}
      {data?.hits.hits.map((hit) => {
        const cn = hit._source.campaign_number;
        return (
          <NavLink
            key={hit._id}
            to={{
              pathname: '/v3/recalls',
              search: `refine_campaign="${encodeURIComponent(
                cn,
              )}"&tab=searchresults`,
            }}
          >
            {cn}
          </NavLink>
        );
      })}
    </span>
  );
}

RelatedInvRecalls.propTypes = {
  campaignNo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};
