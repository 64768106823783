import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useAccessToken, useUserProfile } from '../auth/Auth0';
import { useTranslation } from '../utils/Localization';

function useInput(defaultValue: string = '') {
  const [value, setValue] = useState(defaultValue);
  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }
  return {
    value,
    onChange,
    setValue
  };
}

const ContactFormPanel = () => {
  const [open, setOpen] = useState(false);
  const userProfile = useUserProfile();
  const accessToken = useAccessToken();
  const emailProps = useInput();
  const textProps = useInput();
  const [success, setSuccess] = useState<string>();
  const [error, setError] = useState<string>();
  const i18n = useTranslation('email');
  const i18nBtn = useTranslation('controls');

  useEffect(() => {
    emailProps.setValue(userProfile?.email);
  }, [userProfile, emailProps]);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    (document.getElementById('sendemailbutton') as HTMLButtonElement).disabled =
      true;

    const body = {
      from: emailProps.value,
      subject: i18n('Carwatch UI Support'),
      html: `Message from ${emailProps.value}:<br/>${textProps.value}`
    };

    const url =
      process.env.NODE_ENV === 'production'
        ? 'api/v1/mail'
        : 'http://localhost:5000/v1/mail/plain';

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => {
        if (response.status === 200) {
          setSuccess(i18n('Message sent.'));
          textProps.setValue('');
        } else {
          setError(
            `${i18n('Message failed to send.')} ${response.statusText}`
          );
        }
      })
      .catch((err) => setError(err.message))
      .finally(() => {
        (
          document.getElementById('sendemailbutton') as HTMLButtonElement
        ).disabled = false;
      });
  }

  if (!userProfile) return null;

  const onClose = () => {
    setOpen(false);
    setSuccess(undefined);
  };

  return (
    <>
      <button
        className="btn btn-sm"
        type="button"
        onClick={() => setOpen(true)}
        aria-label="Toggle Contact Form"
      >
        <i className="bi bi-envelope" />
      </button>
      <Modal
        show={open}
        onHide={onClose}
        animation={false}
        backdrop="static"
        centered
      >
        {/* eslint-disable-next-line react/jsx-no-bind */}
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{i18n('Contact Pumacy')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="small">
            <Form.Group className="mb-3">
              <Form.Label>{i18n('Your Email')}</Form.Label>
              <Form.Control
                type="email"
                {...emailProps}
                placeholder={i18n('Your Email')}
                disabled={userProfile.email.length > 0}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{i18n('Your Message')}</Form.Label>
              <Form.Control
                as="textarea"
                {...textProps}
                placeholder={i18n('Your Message')}
                cols={2}
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {success && (
              <Alert
                variant="success"
                show={success !== undefined}
                onClose={() => setSuccess(undefined)}
                className="small"
                style={{ flex: 1 }}
              >
                {success}
                <hr />
                <div className="d-flex justify-content-end">
                  <Button onClick={onClose} size="sm" variant="outline-success">
                    {i18nBtn('Close')}
                  </Button>
                </div>
              </Alert>
            )}
            {error && (
              <Alert
                variant="danger"
                show={error !== undefined}
                onClose={() => setError(undefined)}
                className="small"
                style={{ flex: 1 }}
              >
                {error}
                <hr />
                <div className="d-flex justify-content-end">
                  <Button onClick={onClose} size="sm" variant="outline-danger">
                    {i18nBtn('Close')}
                  </Button>
                </div>
              </Alert>
            )}
            {!(success || error) && (
              <>
                <Button
                  id="sendemailbutton"
                  as="button"
                  type="submit"
                  size="sm"
                  variant="primary"
                >
                  {i18nBtn('Send')}
                </Button>
                <Button
                  as="button"
                  type="button"
                  size="sm"
                  variant="secondary"
                  onClick={onClose}
                >
                  {i18nBtn('Cancel')}
                </Button>
              </>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ContactFormPanel;
