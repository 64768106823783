import 'bootstrap-icons/font/bootstrap-icons.css';
import 'core-js';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import 'regenerator-runtime/runtime';
import '../sass/style.scss';
import Main from './Main';
import resources from './locale/translations';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['navigator', 'localStorage', 'cookie'],
    },
    fallbackLng: 'en',
    resources,
    interpolation: {
      escapeValue: false,
    },
  });

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
);
