import React, { useEffect, useState } from 'react';
import { LoaderText } from './Loader';

async function pageExists(url: string) {
  const response = await fetch(url, {
    method: 'HEAD'
  });
  return response.status === 200;
}

const IFrame = ({
  title,
  source
}: {
  title: string;
  source?: string;
}): JSX.Element => {
  // 0: loading, -1: error, 1: available
  const [pageStatus, setPageStatus] = useState(0);

  useEffect(() => {
    if (source) {
      pageExists(source)
        .then((bool) => setPageStatus(bool ? 1 : -1))
        .catch(() => setPageStatus(-1));
    }
  }, [source]);

  if (!source) return null;

  switch (pageStatus) {
    case 0:
      return <LoaderText />;
    case 1:
      return (
        <div className="ratio ratio-16x9 emdeb-responsive">
          <iframe
            className="embed-responsive-item"
            src={source}
            title={title}
            allowFullScreen
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      );
    default:
      return null;
  }
};

export default IFrame;
