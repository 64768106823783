/* eslint-disable react/prop-types */
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useConfig } from '../../Config';
import { useTranslation } from '../../utils/Localization';
import {
  regionBarChartOptions,
  regionChartsQuery,
  toSeries,
} from './RegionBarChartsUtils';
import * as css from './RegionBarCharts.module.scss';
import ErrorBoundary from '../ErrorBoundary';

const colors = { oi: '#00a86b', ep: '#FFD700', pi: '#FF6347' };

function setSearchParams(params) {
  const searchParams = new URLSearchParams(window.location.search);
  // eslint-disable-next-line no-restricted-syntax
  for (const k in params) {
    if (params[k] === undefined) searchParams.delete(k);
    else searchParams.set(k, params[k]);
  }
  return searchParams.toString();
}

const StyledRow = ({ children }) => (
  <Row className="justify-content-md-center" style={{}}>
    {children}
  </Row>
);

const StyledColumn = ({ onClick, children }) => (
  <Col className="m-2">
    <div
      className={`border p-2 ${css.barchart}`}
      onClick={onClick}
      onKeyUp={() => {}}
      role="link"
      tabIndex="-1"
    >
      {children}
    </div>
  </Col>
);

const RegionBarChart = ({ series, color }) => {
  const i18n = useTranslation();

  if (series.length === 0 || series[0].data.length === 0) {
    return (
      <div
        style={{
          textAlign: 'center',
        }}
      >
        {i18n('No incidents found')}
      </div>
    );
  }

  const options = { ...regionBarChartOptions };
  options.colors = color;
  return (
    <ReactApexChart type="bar" series={series} options={options} height={150} />
  );
};

export default function RegionBarCharts({ react }) {
  const config = useConfig();
  const i18n = useTranslation();
  const prefix = `cw3_cst_${config.customer.name}`;
  const renderColumn = (label, data, color) => (
    <StyledColumn key={label}>
      <b>{label}</b>
      <ErrorBoundary>
        <RegionBarChart series={[toSeries(data)]} color={color} />
      </ErrorBoundary>
    </StyledColumn>
  );
  const renderSection = (title, data, color) => (
    <>
      <StyledRow>
        <Col>
          <b>{title}</b>
        </Col>
      </StyledRow>
      <StyledRow>
        {data.map((item) => renderColumn(item.label, item.data, color))}
      </StyledRow>
      <br />
    </>
  );
  return (
    <ReactiveComponent
      componentId="region_charts"
      defaultQuery={() => regionChartsQuery(prefix)}
      react={react}
      render={(args) => {
        if (!args.aggregations) return null;
        const { recalls_etc, complaints, mentions } = args.aggregations;
        const priorityIncidentsData = [
          {
            label: i18n('Recalls / Investigations / Bulletins'),
            type: 'recalls-investigation-bulletins',
            category: 'pi',
            data: recalls_etc.found_catastrophic_problem,
          },
          {
            label: i18n('Complaints'),
            type: 'complaints',
            category: 'pi',
            data: complaints.found_catastrophic_problem,
          },
          {
            label: i18n('Mentions'),
            type: 'mentions',
            category: 'pi',
            data: mentions.found_catastrophic_problem,
          },
        ];
        const emergingProblemsData = [
          {
            label: i18n('Recalls / Investigations / Bulletins'),
            type: 'recalls',
            category: 'ep',
            data: recalls_etc.found_emerging_problem,
          },
          {
            label: i18n('Complaints'),
            type: 'complaints',
            category: 'ep',
            data: complaints.found_emerging_problem,
          },
          {
            label: i18n('Mentions'),
            type: 'mentions',
            category: 'ep',
            data: mentions.found_emerging_problem,
          },
        ];
        const otherIssuesData = [
          {
            label: i18n('Recalls / Investigations / Bulletins'),
            type: 'recalls',
            category: 'oi',
            data: recalls_etc.found_other,
          },
          {
            label: i18n('Complaints'),
            type: 'complaints',
            category: 'oi',
            data: complaints.found_other,
          },
          {
            label: i18n('Mentions'),
            type: 'mentions',
            category: 'pi',
            data: mentions.found_other,
          },
        ];
        return (
          <Container fluid className="p-3">
            {renderSection(
              'Priority Incidents',
              priorityIncidentsData,
              colors.pi,
            )}
            {renderSection(
              'Emerging Problems',
              emergingProblemsData,
              colors.ep,
            )}
            {renderSection('Other Issues', otherIssuesData, colors.oi)}
          </Container>
        );
      }}
    />
  );
}
