import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useMemo, useState, 
} from 'react';

import { useAppState } from '../../AppStateProvider';
import { useConfig } from '../../Config';
import { useAccessToken } from '../../auth/Auth0';
import { useTranslation } from '../../utils/Localization';

export const SearchCasesEventBus = document;

const ctx = React.createContext(/* no default */);
export const useSearchCases = () => React.useContext(ctx);

const asArray = (d) => {
  if (!d) {
    return undefined;
  }

  if (Array.isArray(d)) {
    return d;
  }

  return Array.from(String(d).split(/,/), (v) => v.trim()).filter(
    (v) => v.length,
  );
};

const useHeaders = () => {
  const accessToken = useAccessToken();
  const [headers, setHeaders] = useState();

  useEffect(() => {
    if (accessToken) {
      setHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      });
    }
  }, [accessToken]);
  return headers;
};

export const SearchCasesEvents = Object.freeze({
  Refresh: 'searchcases:refresh',
  Add: 'searchcases:add',
});

/**
 * Creates a context component for searching cases.
 *
 * @param {object} options - The options for the context component.
 * @param {string} options.context - The context for the search (e.g., "recalls", "mentions").
 * @param {boolean} options.immediateFetch - Whether to immediately fetch search cases.
 * @param {React.ReactNode} options.children - The child components to render.
 * @return {React.ReactNode} The rendered child components.
 */
export const SearchCasesContext = ({
  context = '', // recalls, mentions, etc
  immediateFetch = false,
  children,
}) => {
  const accessToken = useAccessToken();
  const [searchCases, setSearchCases] = React.useState([]);
  const {
    customer: { name: customerName },
  } = useConfig();
  const headers = useHeaders();
  const { showSuccess, showError } = useAppState();
  const i18n = useTranslation('searchcases');

  /* const endPoint = `${
    process.env.NODE_ENV === 'production'
      ? '/api/v1'
      : 'http://localhost:5001/v1'
  }/customer/${customerName}/socialweb/cases`; */

  const apiUrl = process.env.appconfig?.api_url;
  const endPoint = `${apiUrl}/v1/searchcases/${customerName}`;

  const fetchSearchCases = React.useCallback(
    async (from = 0, size = 20) => {
      if (!headers) {
        return;
      }

      const response = await fetch(
        `${endPoint}?from=${from}&size=${size}&type=${context}`,
        {
          method: 'GET',
          headers,
        },
      );

      if (response.ok) {
        const json = await response.json();
        const searchcases = [].map.call(
          json.results || json.hits.hits || [],
          (sc) => {
            if (sc._source) {
              return sc;
            }
            return { _source: sc, _id: sc.name };
          },
        );
        setSearchCases(searchcases);
      } else {
        showError(await response.text());
      }
    },
    [headers, endPoint, context, showError],
  );

  const updateSearchCase = useCallback(
    async (id, data) => {
      data.make = asArray(data.make);
      data.model = asArray(data.model);
      data.component = asArray(data.component);
      data.issue = asArray(data.issue);
      data.context = asArray(context);

      const response = await fetch(`${endPoint}/${id}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify(data),
      });
      if (response.ok) {
        showSuccess(i18n('Searchcase updated.'));
        await fetchSearchCases();
      } else {
        throw new Error(await response.text());
      }
    },
    [context, endPoint, fetchSearchCases, headers, i18n, showSuccess],
  );

  const deleteSearchCase = useCallback(
    async (id) => {
      const response = await fetch(`${endPoint}/${id}`, {
        method: 'DELETE',
        headers,
      });
      if (response.ok) {
        await fetchSearchCases();
      } else {
        throw new Error(await response.text());
      }
    },
    [endPoint, fetchSearchCases, headers],
  );

  useEffect(() => {
    const SearchCasesFetchEvent = () => {
      fetchSearchCases();
    };
    const SearchCasesUpdateEvent = (e) => {
      updateSearchCase(e.detail.name, e.detail);
    };

    SearchCasesEventBus.addEventListener(
      SearchCasesEvents.Refresh,
      SearchCasesFetchEvent,
    );
    SearchCasesEventBus.addEventListener(
      SearchCasesEvents.Add,
      SearchCasesUpdateEvent,
    );

    return () => {
      SearchCasesEventBus.removeEventListener(
        SearchCasesEvents.Refresh,
        SearchCasesFetchEvent,
      );
      SearchCasesEventBus.removeEventListener(
        SearchCasesEvents.Add,
        SearchCasesUpdateEvent,
      );
    };
  }, [fetchSearchCases, updateSearchCase]);

  React.useEffect(() => {
    const func = async () => {
      if (accessToken) {
        await fetchSearchCases();
      }
    };
    if (immediateFetch) func();
  }, [accessToken, fetchSearchCases, immediateFetch]);

  return (
    <ctx.Provider
      value={useMemo(
        () => ({
          searchCases,
          deleteSearchCase,
          fetchSearchCases,
          updateSearchCase,
        }),
        [searchCases, fetchSearchCases, updateSearchCase, deleteSearchCase],
      )}
    >
      {children}
    </ctx.Provider>
  );
};

SearchCasesContext.propTypes = {
  children: PropTypes.node.isRequired,
  context: PropTypes.string,
  immediateFetch: PropTypes.bool,
};
