import { ReactiveComponent } from '@appbaseio/reactivesearch';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import React from 'react';
import Chart from 'react-apexcharts';
import Card from 'react-bootstrap/Card';
import { range10 } from '../../utils/colors';
import { LoaderText } from '../Loader';
import TimeSeriesChart from './TimeSeriesChart';

const CWApexCharts = {
  defaults: {
    chart: { animations: { enabled: true }, fontFamily: 'inherit' },
    colors: range10,
    noData: { text: 'no data available' },
  },
};

export const PieChartHelper = {
  defaultOptions: {
    showOther: false,
    othersLabel: 'Others',
  },
  transformData(aggregationData, options = {}) {
    const localOptions = { ...this.defaultOptions, ...options };
    const labels = [];
    const serie = aggregationData.buckets.map((b) => {
      labels.push(b.key);
      return b.doc_count;
    });
    if (localOptions.showOther && aggregationData.sum_other_doc_count > 0) {
      labels.push(localOptions.othersLabel);
      serie.push(aggregationData.sum_other_doc_count);
    }
    return { labels, serie };
  },
  getFilterQuery(field, value) {
    let theValue;
    if (isArray(value)) {
      theValue = value.filter((v) => !!v);
    } else if (value) {
      theValue = [value];
    } else return {};
    if (isEmpty(theValue)) return {};
    return {
      query: { terms: { [field]: isArray(theValue) ? theValue : [theValue] } },
      value,
    };
  },
  getSelection(config) {
    const { labels } = config.w.config;
    const selected = config.selectedDataPoints.map((s) => labels[s]);
    return selected;
  },
};

function arrayEquals(a, b) {
  return (
    Array.isArray(a)
    && Array.isArray(b)
    && a.length === b.length
    && a.every((val, index) => val === b[index])
  );
}

const PieChart = (props) => {
  const {
    aggregations, isLoading, setQuery, field, value, title, 
  } = props;
  const chartId = React.useRef(uniqueId('apexchart'));
  const [data, setData] = React.useState(null);
  const diagram = React.useRef(null);

  /**
    try to synchronize the chart state with the state provided by ReactiveSearch
   */
  React.useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    const chart = window.Apex._chartInstances
      && window.ApexCharts.getChartByID(chartId.current);
    if (!chart) return;
    // SM: selected points will be available until page is reloded
    const { selectedDataPoints } = chart.w.globals;
    const { labels } = chart.w.config;
    const pieSeries = selectedDataPoints[0]; // pie has only one series

    // SM: on page reload 'value' will hold the selected data (see URLParams in ReactiveComponent)
    if (!isEmpty(value)) {
      // SM: find the given values in 'data' and trigger selection in rendered chart
      // toggleDataPointSelection only accepts datapoint indices, not values
      const indices = value.map((v) => labels.findIndex((v1) => v1 === v));
      if (value && !arrayEquals(indices, pieSeries)) {
        /* 
        SM: value is different than selected data points 
        -> this was a page reload 
        -> trigger selection js event to update chart, which triggers the update of RS filters
        */
        chart.toggleDataPointSelection(indices[0]);
      } else {
        // SM: just update ReactiveSearch filters
        setQuery(PieChartHelper.getFilterQuery(field, value));
      }
    } else if (pieSeries && pieSeries.length > 0) {
      // SM: clear all case
      chart.toggleDataPointSelection(pieSeries[0]);
    }
  });

  React.useEffect(() => {
    if (aggregations) {
      setData(PieChartHelper.transformData(aggregations.data));
    }
  }, [aggregations]);

  if (isLoading) {
    return <LoaderText />;
  }
  if (!data) {
    return null;
  }
  const { labels, serie } = data;
  return (
    <Card className="h-100 w-100" ref={diagram}>
      <Card.Header>
        <b>{title}</b>
      </Card.Header>
      <Card.Body>
        <Chart
          type="donut"
          options={{
            ...CWApexCharts.defaults,
            plotOptions: {
              pie: { expandOnClick: false, donut: { size: '20%' } },
            },
            chart: {
              id: chartId.current,
              events: {
                dataPointSelection(event, chartContext, config) {
                  const selection = PieChartHelper.getSelection(config);
                  setQuery(PieChartHelper.getFilterQuery(field, selection));
                }, /* ,
                legendClick(chartContext, seriesIndex, config) {}, */
              },
              height: 350,
            },
            legend: {
              position: 'bottom',
            },
            dataLabels: {
              enabled: false,
            },
            /* title: { text: title }, */
            labels,
            noData: {
              text: 'Loading...',
            },
          }}
          series={serie}
        />
      </Card.Body>
    </Card>
  );
};

PieChart.propTypes = {
  aggregations: PropTypes.shape({
    data: PropTypes.any,
  }),
  field: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  setQuery: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.any,
};

export const PieChartComponent = (props) => {
  const {
    field, title, size = 10, ...rest 
  } = props;
  return (
    <ReactiveComponent
      {...rest}
      defaultQuery={() => ({
        aggs: {
          data: {
            terms: {
              field,
              size,
            },
          },
        },
        size: 0,
      })}
      render={(renderProps) => (
        <PieChart {...renderProps} field={field} title={title} />
      )}
    />
  );
};

PieChartComponent.propTypes = {
  field: PropTypes.string.isRequired,
  size: PropTypes.number,
  title: PropTypes.string.isRequired,
};

CWApexCharts.PieChartComponent = PieChartComponent;
CWApexCharts.TimeSeriesChart = TimeSeriesChart;

if (window && window.Apex) {
  Object.assign(window.Apex, CWApexCharts.defaults);
  // console.log('apex', window.Apex);
}

export default CWApexCharts;
