export function prepareRequest(req, accessToken) {
  const r = { ...req };
  delete r.headers['X-Search-Client'];
  delete r.headers['x-search-client'];
  // eslint-disable-next-line no-unused-expressions
  accessToken && (r.headers.Authorization = `Bearer ${accessToken}`);
  /* below parameters are not valid for _msearch request */
  // r.url = String(req.url).concat('timeout=100ms&allow_partial_search_results=true');
  r.headers['x-application-id'] = 'carwatch';
  return r;
}

export default {
  prepareRequest,
};
