import { without } from 'lodash-es';
import React from 'react';
import { useTranslation } from '../../utils/Localization';
import NoResults from '../NoResults';
import { FilterableMultiListRefinement } from './MultiListRefinement';

export const withCostumerTitle = (Component, title) => (props) => {
  const i18n = useTranslation();
  const ctitle = `${process.env.appconfig?.customer_name?.toUpperCase()} - ${i18n(
    title,
  )}`;
  return <Component title={ctitle} {...props} />;
};

function renderChildren(children, passedProps) {
  return React.Children.map(children, (child) => React.cloneElement(child, passedProps));
}

export const CustomerNameTitle = (props) => {
  const i18n = useTranslation();
  const ctitle = `${process.env.appconfig?.customer_name?.toUpperCase()} - ${i18n(
    props.title,
  )}`;

  if (typeof props.children === 'function') {
    const childs = props.children({ title: ctitle });
    return childs;
  }

  const renderChilds = renderChildren(props.children, { title: ctitle });
  return renderChilds;
};

export const FoundComponentsRefinement = (props) => {
  const i18n = useTranslation();
  return (
    <CustomerNameTitle title="Detected Components">
      <FilterableMultiListRefinement
        filterField="facets.found_components"
        dataField="facets.found_components.keyword"
        renderNoResults={NoResults}
        filterLabel={i18n('Detected Component')}
        URLParams
        {...props}
      />
    </CustomerNameTitle>
  );
};

export const FoundEmergingProblemsRefinement = (props) => {
  const i18n = useTranslation();
  return (
    <CustomerNameTitle title="Emerging Problems">
      <FilterableMultiListRefinement
        filterField="facets.found_emerging_problem"
        dataField="facets.found_emerging_problem.keyword"
        renderNoResults={NoResults}
        filterLabel={i18n('Emerging Problems')}
        URLParams
        {...props}
      />
    </CustomerNameTitle>
  );
};

export const FoundEvidentProblemsRefinement = (props) => {
  const i18n = useTranslation();
  return (
    <CustomerNameTitle title="Evident Problems">
      <FilterableMultiListRefinement
        filterField="facets.found_catastrophic_problem"
        dataField="facets.found_catastrophic_problem.keyword"
        renderNoResults={NoResults}
        filterLabel={i18n('Evident Problems')}
        URLParams
        {...props}
      />
    </CustomerNameTitle>
  );
};

export const FoundComponentManufacturerRefinement = (props) => {
  const i18n = useTranslation();
  return (
    <CustomerNameTitle title="Component Manufacturer">
      <FilterableMultiListRefinement
        filterField="facets.component_manufacturer"
        dataField="facets.component_manufacturer.keyword"
        renderNoResults={NoResults}
        filterLabel={i18n('Component Manufacturer')}
        URLParams
        {...props}
      />
    </CustomerNameTitle>
  );
};

export const componentIds = [
  'refine_found_component_manufacturer',
  'refine_found_components',
  'refine_found_emerging_problem',
  'refine_found_evident_problem',
];

// eslint-disable-next-line react/prop-types
export default ({ react }) => (
  <>
    <FoundComponentsRefinement
      componentId="refine_found_components"
      react={{
        and: without(react, 'refine_found_components'),
      }}
    />
    <FoundComponentManufacturerRefinement
      componentId="refine_found_component_manufacturer"
      react={{
        and: without(react, 'refine_found_component_manufacturer'),
      }}
    />
    <FoundEmergingProblemsRefinement
      componentId="refine_found_emerging_problem"
      react={{
        and: without(react, 'refine_found_emerging_problem'),
      }}
    />
    <FoundEvidentProblemsRefinement
      componentId="refine_found_evident_problem"
      react={{
        and: without(react, 'refine_found_evident_problem'),
      }}
    />
  </>
);
