/* eslint-disable max-len */
import {
  useCallback, useEffect, useRef, useState, 
} from 'react';

/**
 * Returns a function that checks if the component is mounted.
 *
 * @return {function} Function that checks if the component is mounted
 */
export const useIsMounted = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return useCallback(() => isMounted.current, []);
};

/**
 * Custom hook for managing an async state if component is unmounted.
 *
 * @param {any} defaultValue - the initial value for the state
 * @return {Array} an array containing the current value and a function to update it
 */
export const useAsyncState = (defaultValue) => {
  const isMounted = useIsMounted();
  const [value, setRawValue] = useState(defaultValue);
  const setValue = useCallback(
    (newValue) => {
      if (isMounted()) {
        setRawValue(newValue);
      }
    },
    [isMounted],
  );
  return [value, setValue];
};

/**
 * Custom hook for making asynchronous calls and managing loading and error states.
 *
 * @param {function} asyncFunction - The asynchronous function to be executed.
 * @param {boolean} immediate - Flag to indicate whether the function should be executed immediately.
 * @return {object} An object containing the value, busy state, error, and execute function.
 */
export const useAsyncCall = (asyncFunction, immediate = false) => {
  const [value, setValue] = useState();
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState();

  const execute = useCallback(() => {
    setBusy(true);
    setValue(null);
    setError(null);

    return asyncFunction()
      .then(async (response) => {
        if (!response) {
          setError('Invalid response');
        } else {
          setValue(response.json ? await response.json() : response);
        }
        setBusy(false);
      })
      .catch((err) => {
        setError(err);
        setBusy(false);
      });
  }, [asyncFunction]);

  useEffect(() => {
    if (immediate) execute();
  }, [execute, immediate]);

  return {
    value,
    busy,
    error,
    execute,
  };
};

const parseJson = (str, defaultValue) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return defaultValue;
  }
};

export const useLocalState = (key, defaultValue) => {
  const [value, setValue] = useState(
    parseJson(localStorage.getItem(key), defaultValue),
  );

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
};

export const useRenderCount = () => {
  const rendersNo = useRef(0);
  useEffect(() => {
    rendersNo.current += 1;
  });
  return rendersNo.current;
};

export const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    // eslint-disable-next-line no-unused-expressions
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};
