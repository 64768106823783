import { useUserProfile } from './Auth0';

export const ADMIN_ROLE_NAME = 'cwadmin';

export function useIsAdmin() {
  const profile = useUserProfile();
  const roles = profile?.authorization?.roles;
  return roles && roles.includes(ADMIN_ROLE_NAME);
}

export default ({ children }: { children: React.ReactNode }) => {
  const isAdmin = useIsAdmin();
  return isAdmin ? children : null;
};
