import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { isArray, isEmpty } from 'lodash-es';
import PropTypes, { oneOfType, string, arrayOf } from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useConfig } from '../../Config';
import { useElastic } from '../../react/Elastic';
import ReactiveApp from '../../reactivesearch/ReactiveApp';
import { useTranslation } from '../../utils/Localization';
import { LazyPanel } from '../LazyPanel';
import { LoaderText } from '../Loader';

const campaignQuery = (campaignNumber) => ({
  query: {
    terms: {
      'recall_campaign_number.keyword': isArray(campaignNumber)
        ? [...campaignNumber]
        : [campaignNumber],
    },
  },
});

export const RelatedInvestigationsPanel = ({ campaignNumber }) => {
  const config = useConfig();
  const i18n = useTranslation();
  const index = config.getIndexName('investigations', 'cw3_cst_');
  const triggerQueryFlag = !!campaignNumber && !isEmpty(campaignNumber);
  const fetch = useElastic(
    index,
    campaignQuery(campaignNumber),
    triggerQueryFlag,
  );

  if (fetch.isLoading) return <LoaderText />;

  if (!fetch.data?.hits.total.value) return null;
  return (
    <LazyPanel
      collapsed
      title={`${i18n('Related investigations')} (${
        fetch.data?.hits.total.value
      })`}
    >
      <RelatedInvestigations campaignNumber={campaignNumber} />
    </LazyPanel>
  );
};

RelatedInvestigationsPanel.propTypes = {
  campaignNumber: oneOfType([string, arrayOf(string)]),
};

const RelatedInvestigations = ({ campaignNumber }) => {
  const i18n = useTranslation();
  const config = useConfig();
  if (!campaignNumber || isEmpty(campaignNumber)) return <div>{i18n('n.a.')}</div>;
  return (
    <ReactiveApp app={config.getIndexName('investigations', 'cw3_cst_')}>
      <ReactiveComponent
        componentId="relatedInvestigations"
        defaultQuery={() => campaignQuery(campaignNumber)}
        render={({ data }) => {
          if (!data?.length) return <div>{i18n('n.a.')}</div>;
          return (
            <NavLink
              to={{
                pathname: '/v3/investigations',
                search: `tab=searchresults&refine_recall_id="${campaignNumber}"`,
              }}
            >
              {i18n('Show these investigations')}
            </NavLink>
          );
        }}
      />
    </ReactiveApp>
  );
};

RelatedInvestigations.propTypes = {
  campaignNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default RelatedInvestigations;
