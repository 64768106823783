import { isArray, size, uniqueId } from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from '../../utils/Localization';
import { joinValue } from '../../utils/StringTools';
import { renderDev } from '../Develop/DevComponent';
import Source from '../Develop/Source';
import { Section } from '../Layout/Card';
import { LazyPanel } from '../LazyPanel';
import {
  Favorite,
  getHighlightValue,
  MetaData,
  MetaDate,
  MetaText,
  ResultListAttachments,
  useEntityLink,
} from '../ResultListItem';
import ResultListItem from '../ResultListItem/ResultListItem';

const BulletinsResultListItem = ({ hit }) => {
  const i18n = useTranslation();
  const i18nDS = useTranslation('datasources');
  const attachments = hit.documents;
  const innerHits = (hit.inner_hits && hit.inner_hits.documents?.hits?.hits) || [];
  const cardTitle = `${joinValue(hit.facets.make, ' / ')}: ${joinValue(
    hit.facets.model,
    ' / ',
  )}`;
  let summaries = [];
  if (typeof hit.summary === 'string') {
    summaries.push(hit.summary);
  } else if (isArray(hit.summary)) {
    summaries = hit.summary;
  }

  const sideLink = useEntityLink(hit._id);

  return (
    <ResultListItem
      entityId={hit._id}
      title={cardTitle}
      options={<Favorite entityRef={sideLink} name={cardTitle} />}
    >
      <Section>
        <MetaDate label="Date received" value={hit.publication_date} />
        <MetaData label="Source" value={i18nDS(hit.datasource)} />
      </Section>
      <Section>
        <MetaData label="Make" value={joinValue(hit.facets?.make, ' / ')} />
        <MetaData label="Model" value={joinValue(hit.facets?.model, ' / ')} />
        <MetaData
          label="Model Year(s)"
          value={hit.model_year === '9999' ? null : hit.model_year}
        />
      </Section>
      <Section>
        <MetaData label="Bulletin ID" value={hit.bulletin_number} />
        <MetaData label="NHTSA ID" value={hit.nhtsa_number} />
      </Section>
      <Section>
        <MetaData
          label="Component(s)"
          value={getHighlightValue(hit, 'facets.component')}
        />
      </Section>
      <Section>
        <MetaText label="Defect" value={hit.defect} />
      </Section>
      <Section>
        <MetaText label="Consequence" value={hit.consequence} />
      </Section>
      <Section>
        <MetaText label="Measures" value={hit.remark || hit.measures} />
      </Section>

      {size(hit.summary) > 0 ? (
        <Section>
          <MetaData label={i18n('Summary')}>
            {summaries.map((s) => (
              <p
                key={uniqueId(hit._id)}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: s }}
              />
            ))}
          </MetaData>
        </Section>
      ) : null}
      {size(attachments) > 0 ? (
        <LazyPanel
          collapsed
          rendered={size(attachments) > 0}
          title={`${i18n('Service Bulletin Attachments')} (${size(
            attachments,
          )})`}
        >
          <ResultListAttachments.Wrapper
            attachments={attachments}
            innerHits={innerHits}
          />
        </LazyPanel>
      ) : null}
      {renderDev(<Source sourceObject={hit} />)}
    </ResultListItem>
  );
};

BulletinsResultListItem.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default BulletinsResultListItem;
