import React, { useEffect, useRef } from 'react';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

const debouncedInput = (
  input: HTMLInputElement,
  debounceMillis: number
): Observable<string> => {
  return fromEvent(input, 'keyup').pipe(
    map((e: KeyboardEvent) => (e.target as HTMLInputElement).value),
    distinctUntilChanged(),
    debounceTime(debounceMillis)
  );
};

export interface IDebouncedInputProps {
  inputElement?: JSX.Element;
  inputComponent?: React.ComponentType<any>;
  value: string;
  onChange: (value: string) => void;
  debounceMillis?: number;
  [key: string]: any;
}

const DelayedInput = ({
  inputElement: Input = <input type="text" />,
  inputComponent,
  value,
  onChange,
  debounceMillis = 500,
  ...props
}: IDebouncedInputProps): JSX.Element => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.value = value;
    const subscription = debouncedInput(
      inputRef.current,
      debounceMillis
    ).subscribe((v) => onChange(v));
    return () => {
      subscription.unsubscribe();
    };
  }, [debounceMillis, onChange, value]);

  if (inputComponent) {
    return React.createElement(inputComponent, {
      ...props,
      ref: inputRef
    });
  }

  return Input
    ? React.cloneElement(Input, {
        ...Input.props,
        ...props,
        ref: inputRef
      })
    : null;
};

export default DelayedInput;
