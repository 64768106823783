import i18n from 'roddeh-i18n';
import i18nContent from './i18n.json';

export function t(text, context) {
  if (context !== undefined) {
    const translation = `#${context}#${text}`;
    return i18n(translation).replace(`#${context}#`, '');
  }
  return i18n(text);
}
/**
 * Retrieves the data source based on the provided name.
 *
 * @deprecated
 * @param {string} name - The name of the data source.
 * @return {string|object} - The corresponding data source string
 * or the entire data source object if no name is provided.
 */
export function datasources(name) {
  console.log('datasources function is deprecated');
  const ds = {
    US: 'US (NHTSA)',
    KBA: 'DE (KBA)',
    CN: 'CN (DPAC)',
    CN_qiche365: 'CN (Qiche)',
    EU: 'EU (RAPEX)',
    KR: 'KR (Safety Korea)',
    adac: 'DE (ADAC)',
    CA: 'CA (Transport Canada)',
    AU: 'AU (ACCC)',
    mx: 'MX',
    br: 'BR',
    accp: 'ACCP',
  };
  return name ? ds[name] || name : ds;
}

export function getLanguage() {
  const nav = window.navigator;
  const lang = nav.language || nav.userLanguage;
  if (lang !== undefined) {
    return lang.toLowerCase().substring(0, 2);
  }
  return 'en';
}

export function getBrowserLocales() {
  const browserLocales = navigator.languages === undefined
    ? [navigator.language]
    : navigator.languages;
  if (!browserLocales) {
    return getLanguage();
  }
  return browserLocales.map((locale) => {
    const trimmedLocale = locale.trim();
    return trimmedLocale.split(/-|_/)[0];
  });
}

/** initialize translator on load  */
// (async () => {
// const i18nContent = await import('./i18n.json');
const langSupported = i18nContent[getLanguage()] !== undefined;
i18n.translator.add(
  langSupported ? i18nContent[getLanguage()] : i18nContent.en,
);
// })();

export { default as i18n } from 'roddeh-i18n'; // alias for direct import
export const useTranslation = (context) => (...args) => {
  if (context === undefined) return i18n(...args);
  if (typeof context === 'string' || context instanceof String) {
    return args.length === 1
      ? i18n(...args, {}, { context })
      : i18n(...args, { context });
  }
  if (typeof context === 'object') {
    return args.length === 1
      ? i18n(...args, {}, context)
      : i18n(...args, context);
  }
  throw new Error('lost in translation');
};

export function selectedFiltersDatasourceMapping(
  o,
  componentId = 'refine_datasource',
) {
  const refine_datasource = o[componentId];
  if (refine_datasource?.value) {
    refine_datasource.value = [].map.call(refine_datasource.value, (v) => i18n(v, {}, { context: 'datasources' }));
  }
}
