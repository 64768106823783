import rca from 'rainbow-colors-array';

export const TEN_COLORS = [
  'brown',
  'red',
  'orangered',
  'orange',
  'green',
  'darkcyan',
  'blue',
  'lightskyblue',
  'violet',
  'purple',
];

export function getColorArray(num) {
  const result = [];
  for (let i = 0; i < num; i += 1) {
    const letters = '0123456789ABCDEF'.split('');
    let color = '#';
    for (let j = 0; j < 6; j += 1) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    result.push(color);
  }
  return result;
}

export function htmlRainbowColors(len, type = 'hex', pastel = false) {
  return rca(len, type, pastel).map((c) => `#${c.hex}`);
}

export function randomColor() {
  return `#${Math.random()
    .toString(16)
    .substr(-6)}`;
}
