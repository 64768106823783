/* eslint-disable react/prop-types */
/* eslint-disable react/no-danger */
import without from 'lodash-es/without';
import React, { useRef } from 'react';
import Tab from 'react-bootstrap/Tab';

import { definedSearchFields } from '@/components/Search/FullTextSearch';
import { SearchCasesRefinementWithPanel } from '@/components/SearchCases/SearchCasesRefinement';
import { EPageContext, withPageContext } from '@/context/PageContext';
import { useConfig } from '../../Config';
import DataSourceStats from '../../components/Charts/DataSourceStats';
import ControlledTabs from '../../components/ControlledTabs';
import ErrorBoundary from '../../components/ErrorBoundary';
import { Page } from '../../components/Layout/Page';
import { MentionsResultListItem } from '../../components/Mentions/MentionsResultListItem';
import { useDefinitions } from '../../components/Mentions/VehicleChain/Definitions';
import NoResults from '../../components/NoResults';
import {
  CWSelectedFilters,
  ComponentRefinement,
  DateHistogramRefinement,
  DefinedFilters,
  MakeRefinement,
  ModelRefinement,
  SavedFilters,
} from '../../components/Refinements';
import StyledCustomerRefinements, {
  useCustomerRefinementIds,
} from '../../components/Refinements/CustomerRefinements';
import DateRangeRefinement from '../../components/Refinements/DateRangeRefinement';
import { componentIds as customRefinementsIds } from '../../components/Refinements/DetectedRefinements';
import MultiListRefinement from '../../components/Refinements/MultiListRefinement';
import RefineId from '../../components/Refinements/RefineId';
import ScrollTarget from '../../components/Refinements/ScrollTarget';
import { ResultStats } from '../../components/ResultListItem/ResultStats';
import { FullTextSearch } from '../../components/Search';
import SearchResultList, {
  transformFilters,
} from '../../components/SearchResultList';
import ReactiveApp from '../../reactivesearch/ReactiveApp';
import { useTranslation } from '../../utils/Localization';
import { usePageTitle } from './PageTitle';

const defaultInteractiveComponentIds = [
  'refine_id',
  'refine_make',
  'refine_model',
  'refine_component',
  'refine_datasource',
  'resultlist',
  'q',
  'refine_searchcases',
  'refine_publication_date',
  'refine_publication_date_range',
  ...customRefinementsIds,
];

function renderError(error) {
  return (
    <div>
      Something went wrong with ResultList!
      <br />
      Error details
      <br />
      {JSON.stringify(error)}
    </div>
  );
}

function ResultListItem({ hits }) {
  const { definitions /* , error */ } = useDefinitions();
  return (
    <div>
      {hits
        && [].map.call(hits, (hit) => (
          <MentionsResultListItem
            key={hit._id}
            hit={hit}
            definitions={definitions}
          />
        ))}
    </div>
  );
}

function Mentions() {
  const interactiveComponentIds = useCustomerRefinementIds(
    defaultInteractiveComponentIds,
  );
  const i18n = useTranslation();
  const i18nDS = useTranslation('datasources');
  usePageTitle(i18n('Mentions'));
  const config = useConfig();
  const indexName = config.getIndexName('socialweb*', 'cw3_cst_');
  // hold selected filter values
  const filters = useRef({});

  return (
    <ReactiveApp app={indexName}>
      <Page>
        <Page.Refinements>
          <ScrollTarget targetId="customer-refinements" />
          <SavedFilters />
          <DefinedFilters context="mentions" />
          <hr className="border border-3 rounded-pill" />
          <RefineId componentId="refine_id" />
          <ErrorBoundary>
            <SearchCasesRefinementWithPanel
              componentId="refine_searchcases"
              context="mentions"
              suggestionIndex={indexName}
              showFilter
              filterLabel="SearchCase"
            />
            {/* <SearchCases
              context="mentions"
              componentId="refine_searchcases"
              react={{
                and: without(interactiveComponentIds, 'refine_searchcases'),
              }}
            /> */}
          </ErrorBoundary>
          <hr className="border border-3 rounded-pill" />
          <ErrorBoundary silent={false}>
            <RefineId componentId="refine_id" />
            <DateHistogramRefinement
              componentId="refine_publication_date"
              react={{
                and: without(interactiveComponentIds, 'refine_publication_date'),
              }}
              dataField="timestamp"
              label={i18n('Publication Date')}
              filterLabel={i18n('Publication Date')}
              URLParams
              loader="Loading ..."
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <DateRangeRefinement
              componentId="refine_publication_date_range"
              dataField="timestamp"
              startLabel="Start Date"
              endLabel="End Date"
              filterLabel="Publication Date Range"
              URLParams
            />
          </ErrorBoundary>
          <hr />
          <MultiListRefinement
            componentId="refine_datasource"
            dataField="datasource.keyword"
            title={i18n('Data Source')}
            renderNoResults={NoResults}
            translateFunc={i18nDS}
            size={10}
            filterLabel={i18n('Data Source')}
            react={{
              and: without(interactiveComponentIds, 'refine_datasource'),
            }}
            URLParams
          />
          <MakeRefinement
            react={{ and: without(interactiveComponentIds, 'refine_make') }}
          />
          <ModelRefinement
            react={{ and: without(interactiveComponentIds, 'refine_model') }}
          />
          <ComponentRefinement
            react={{
              and: without(interactiveComponentIds, 'refine_component'),
            }}
          />
          <hr id="customer-refinements" />
          <ErrorBoundary>
            <StyledCustomerRefinements react={interactiveComponentIds} />
          </ErrorBoundary>
        </Page.Refinements>
        <Page.Content>
          <FullTextSearch
            componentId="q"
            className="mb-3"
            dataField={definedSearchFields('mentions')}
            ignoreNested
          />
          <CWSelectedFilters
            onChange={(args) => {
              filters.current = transformFilters(args);
            }}
            context="mentions"
          />
          <ControlledTabs param="tab" defaultTab="searchresults">
            <Tab eventKey="datasources" title={i18n('Datasources')}>
              <ErrorBoundary>
                <DataSourceStats
                  componentId="stats_forums"
                  dateField="timestamp"
                  react={{ and: [...interactiveComponentIds] }}
                />
              </ErrorBoundary>
            </Tab>
            <Tab eventKey="searchresults" title={i18n('Search Results')}>
              <SearchResultList
                componentId="resultlist"
                pagination
                dataField="timestamp"
                sortOptions={[
                  {
                    label: 'Relevance',
                    dataField: '_score',
                    sortBy: 'desc',
                    key: 'score#desc',
                  },
                  {
                    label: 'Newest',
                    dataField: 'timestamp',
                    sortBy: 'desc',
                    key: 'date#desc',
                  },
                  {
                    label: 'Oldest',
                    dataField: 'timestamp',
                    sortBy: 'asc',
                    key: 'date#asc',
                  },
                ]}
                statsElement={<ResultStats />}
                react={{ and: interactiveComponentIds }}
                render={(props) => {
                  // eslint-disable-next-line react/prop-types
                  const { error, loading, data } = props;
                  if (error) return renderError(error);
                  return (
                    <div
                      className="mt-1"
                      style={{ opacity: loading ? '0.5' : '1.0' }}
                    >
                      <ResultListItem hits={data} />
                    </div>
                  );
                }}
                renderResultStats={() => {}}
                innerClass={{ sortOptions: 'mb-2' }}
              />
            </Tab>
          </ControlledTabs>
        </Page.Content>
      </Page>
    </ReactiveApp>
  );
}

export default withPageContext(Mentions, EPageContext.MENTIONS, 'Mentions');
