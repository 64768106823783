import { useMemo } from 'react';
import { useConfig } from '../Config';
import useUserProfile from './useUserProfile';

export default function useUserPaths() {
  const config = useConfig();
  const profile = useUserProfile();

  const userPaths = useMemo(
    () => profile?.app_metadata?.[config.customer.name]?.paths || [],
    [config.customer.name, profile?.app_metadata]
  );

  return userPaths;
}
