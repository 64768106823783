import {
  AggregationsBuckets,
  AggregationsStringTermsBucket
} from '@/elasticsearch/types';
import { range10 } from '@/utils/colors';
import { ApexOptions } from 'apexcharts';
import deepmerge from 'deepmerge';
import { uniqueId } from 'lodash-es';
import millify from 'millify';
import React, { useEffect, useRef } from 'react';
import Chart from 'react-apexcharts';

/* 
declare global {
  interface Window {
    Apex: any;
  }
}
*/

// global opts
const globalChartOpts: ApexOptions['chart'] = {
  fontFamily: 'sans-serif',
  animations: { enabled: false }
};

const globalDataLabelsOpts: ApexOptions['dataLabels'] = {
  style: {
    fontSize: '0.7em',
    fontWeight: 'normal',
    colors: range10
  },
  background: {
    enabled: true,
    foreColor: '#fff',
    borderRadius: 2,
    padding: 4,
    opacity: 0.8,
    borderWidth: 1,
    borderColor: '#fff'
  }
};

export function transformTermsBuckets(
  buckets: AggregationsBuckets<AggregationsStringTermsBucket>
): { labels: string[]; series: number[] } {
  return (buckets as AggregationsStringTermsBucket[]).reduce(
    (acc: any, b) => {
      acc.labels.push(b.key);
      acc.series.push(b.doc_count);
      return acc;
    },
    { labels: [], series: [] }
  );
}

export function SimpleBarChart(props: {
  buckets: AggregationsStringTermsBucket[];
  bucketLabel?: string;
  value?: any[];
  onSelect?: (value: any[]) => void;
  multiSelect?: boolean;
  chartProps?: {
    height?: string | number;
    width?: string | number;
  };
  chartOptions?: ApexOptions;
}): JSX.Element {
  const chartId = useRef(uniqueId('barchart-'));
  const chartRef = useRef(null);

  const {
    buckets,
    value,
    onSelect,
    multiSelect,
    bucketLabel = 'Issues'
  } = props;

  if (!buckets) {
    return <></>;
  }

  const transformedData = transformTermsBuckets(buckets);

  const chartOptions: ApexOptions = deepmerge(
    // 1st object
    {
      chart: {
        id: chartId.current,
        type: 'bar',
        height: props.chartProps.height,
        width: props.chartProps.width,
        ...globalChartOpts,
        events: {
          dataPointSelection: function (_event, _chartContext, opts) {
            const { selectedDataPoints, seriesIndex } = opts;
            const bucketKeys = buckets
              .filter((_b, i: number) =>
                selectedDataPoints[seriesIndex].includes(i)
              )
              .map((b) => b.key);
            onSelect && onSelect(bucketKeys);
          } /* ,
          xAxisLabelClick: function(event, chartContext, opts) {
            console.log('xAxisLabelClick', event, chartContext, opts);
            const { labelIndex } = opts;
            const bucketKeys = buckets
              .filter((_b, i) => i === labelIndex)
              .map((b) => b.key);
            onSelect(bucketKeys);
          } */
        }
      },
      dataLabels: {
        ...globalDataLabelsOpts,
        ...{
          formatter: (value: number) => millify(value)
        }
      },
      colors: range10,
      labels: transformedData.labels,
      states: {
        active: {
          allowMultipleDataPointsSelection: multiSelect
        }
      },
      series: [{ name: bucketLabel, data: transformedData.series }]
    },
    // 2nd object
    props.chartOptions || {},
    {
      /* deepmerge options */
    }
  );

  useEffect(() => {
    if (chartRef.current && value) {
      const chart = ApexCharts.getChartByID(chartId.current) as unknown;
      if (chart) {
        const { w } = chart as any;
        const labels = w.globals.labels;
        // collect selected buckets
        const selectedDataPoints = value.reduce((acc, key) => {
          const index = labels.findIndex((b: unknown) => b === key);
          if (index !== -1) {
            acc.push(index);
          }
          return acc;
        }, [] as number[]);
        // set selection
        w.globals.selectedDataPoints[0] = selectedDataPoints;
        onSelect?.(value);
      }
    }
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      const chart = ApexCharts.getChartByID(chartId.current);
      if (chart && !value?.length) {
        // reset selection
        const { w } = chart as unknown as any;
        w.globals.selectedDataPoints[0] = [];
      }
    }
  }, [value]);

  return (
    <Chart
      ref={chartRef}
      type="bar"
      {...props.chartProps}
      options={chartOptions}
      series={chartOptions.series}
    />
  );
}

export const TermsAggChart = (props: {
  type?: 'bar' | 'line';
  buckets: AggregationsStringTermsBucket[];
  value?: any[];
  onSelect?: (value: any[]) => void;
  multiSelect?: boolean;
  height?: string | number;
  width?: string | number;
  plotOptions?: ApexOptions['plotOptions'];
}): JSX.Element => {
  const {
    type = 'bar',
    buckets,
    value,
    onSelect,
    multiSelect,
    height,
    width = '100%',
    plotOptions
  } = props;

  const chartId = useRef(uniqueId('barchart-'));
  const chartRef = useRef(null);

  if (!buckets) {
    return <></>;
  }

  const transformedData = transformTermsBuckets(buckets);

  const chartData: ApexOptions = {
    chart: {
      id: chartId.current,
      type,
      height,
      width,
      ...globalChartOpts
    },
    dataLabels: globalDataLabelsOpts,
    colors: range10,
    labels: transformedData.labels,
    states: {
      active: {
        allowMultipleDataPointsSelection: multiSelect
      }
    },
    plotOptions: plotOptions ?? {}
  };

  if (type === 'bar' || type === 'line') {
    chartData.series = [{ name: 'Issues', data: transformedData.series }];
  } else if (type === 'pie') {
    // test
    chartData.series = transformedData.series;
    // chartData.labels.length > 4 && (chartData.legend = { position: 'bottom' });
  }

  chartData.chart.events = {
    dataPointSelection: function (_event, _chartContext, opts) {
      const { selectedDataPoints, seriesIndex } = opts;
      const bucketKeys = buckets
        .filter((_b, i) => selectedDataPoints[seriesIndex].includes(i))
        .map((b) => b.key);
      onSelect(bucketKeys);
    }
  };

  useEffect(() => {
    if (chartRef.current && value) {
      const chart = ApexCharts.getChartByID(chartId.current);
      if (chart) {
        // collect selected buckets
        const bucketIndexes = value.reduce((acc, key) => {
          const index = buckets.findIndex((b) => b.key === key);
          if (index !== -1) {
            acc.push(index);
          }
          return acc;
        }, [] as number[]);
        // set selection
        const { w } = chart as unknown as any;
        w.globals.selectedDataPoints[0] = bucketIndexes;
        onSelect?.(value);
      }
    }
  }, []);

  useEffect(() => {
    if (chartRef.current && buckets) {
      const chart = ApexCharts.getChartByID(chartId.current);
      if (chart && !value?.length) {
        // reset selection
        const { w } = chart as unknown as any;
        w.globals.selectedDataPoints[0] = [];
      }
    }
  }, [value]);

  return (
    <Chart
      ref={chartRef}
      options={chartData}
      series={chartData.series}
      type={type}
      width={width}
      height={height}
    />
  );
};
