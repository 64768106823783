import { PropTypes } from 'prop-types';
import React from 'react';

export default function Content(props) {
  const { children } = props;
  return (
    <div className="bg-white p-1 flex-grow-1 rounded-1">
      <main
        className="col p-2 pe-4"
        style={{ overflow: 'auto', height: '85vh' }}
      >
        {children}
      </main>
    </div>
  );
}

Content.propTypes = {
  children: PropTypes.node.isRequired,
};
