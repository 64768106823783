import { useAuth0 } from '@auth0/auth0-react';
import useUserPaths from './useUserPaths';
import { useMemo, useRef } from 'react';
import useUserRoutes from './useUserRoutes';

export default function useIsPathAllowed(path = '') {
  const { isAuthenticated } = useAuth0();
  const paths = useUserPaths();
  const sections = useUserRoutes();
  const allowedPaths = useRef(new Map());

  const userPaths = useMemo(() => {
    allowedPaths.current.clear(); // clear cache on new paths or sections
    return paths && sections && [...paths, ...sections];
  }, [paths, sections]);

  let pathAllowed = false;
  if (allowedPaths.current.has(path)) {
    pathAllowed = isAuthenticated && allowedPaths.current.get(path);
  } else {
    pathAllowed =
      isAuthenticated &&
      userPaths &&
      userPaths.findIndex(
        (p) =>
          p === path ||
          (String(p).endsWith('*')
            ? String(path).startsWith(String(p).slice(0, -1))
            : false)
      ) !== -1;
    allowedPaths.current.set(path, pathAllowed);
  }

  return pathAllowed;
}
