import { DataSearch } from '@appbaseio/reactivesearch';
import { isArray } from 'lodash-es';
import React from 'react';
import PropTypes from 'prop-types';
import TogglePanel from '../SimpleTogglePanel';

const InputFieldRefinement = ({
  componentId,
  dataField,
  react,
  label,
  filterLabel,
  ...rest
}) => (
  <TogglePanel initialOpen label={label}>
    <DataSearch
      componentId={componentId}
      stateful
      dataField={dataField}
      className="mb-2"
      URLParams
      filterLabel={filterLabel}
      autosuggest={false}
      showFilter
      showIcon={false}
      placeholder=""
      react={react}
      innerClass={{
        input: 'refine_search-input',
      }}
      customQuery={(value, props) => {
        if (!value?.length) return null;
        return {
          query: {
            query_string: {
              query: value,
              fields: [
                ...(isArray(props.dataField)
                  ? props.dataField
                  : [props.dataField]),
              ],
            },
          },
        };
      }}
      {...rest}
    />
  </TogglePanel>
);

InputFieldRefinement.propTypes = {
  componentId: PropTypes.string.isRequired,
  dataField: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  react: PropTypes.object,
  label: PropTypes.string.isRequired,
  filterLabel: PropTypes.string,
};

export default InputFieldRefinement;
