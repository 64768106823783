/* eslint-disable react/prop-types */
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import styled from 'styled-components';

function ControlledTabs({
  param, defaultTab, children, ...rest 
}: {
  param: string;
  defaultTab: string;
  children: React.ReactNode;
}): JSX.Element {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [tab, setTab] = React.useState(
    new URLSearchParams(search).get(param) || defaultTab,
  );

  React.useEffect(() => {
    const params = new URLSearchParams(search);
    params.set(param, tab);
    navigate({ search: params.toString() });
  }, [tab, param, search, navigate]);

  return (
    <Tabs
      activeKey={tab}
      onSelect={setTab}
      mountOnEnter
      unmountOnExit
      {...rest}
    >
      {children}
    </Tabs>
  );
}

export default styled(ControlledTabs)`
  .nav-item {
    margin: 0 0.1rem;
  }
  .nav-link {
    &.active {
      background: linear-gradient(
        180deg,
        rgba(var(--bs-primary-rgb), 0.4),
        rgba(var(--bs-primary-rgb), 0) 20%
      ) !important;
    }
  }
`;
