import { Hourglass, IconProps } from 'react-bootstrap-icons';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
`;

interface LoaderHourglassProps extends IconProps {
  readonly $animationSpeed?: string;
}

const LoaderHourglass = styled(Hourglass)<LoaderHourglassProps>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  animation: ${rotate} ${(props) => props.$animationSpeed || '1s'} ease-in-out infinite;
`;

export default LoaderHourglass;
