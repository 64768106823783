/* eslint-disable no-unused-vars */
import { SelectedFilters } from '@appbaseio/reactivesearch';
import React from 'react';
import { Badge } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from '../../utils/Localization';
import FilterValue from './FilterValue';
import SaveFilters from './SaveFilters';

const StyledBadge = styled(Badge)`
  color: white;
  font-size: inherit;
  font-weight: normal;
`;

const StyledFilterButton = styled(StyledBadge)`
  max-width: 200px;
  white-space: wrap;

  &:hover {
    text-decoration: line-through;
    color: white;
  }

  span:last-of-type:hover {
    text-decoration: none;
  }

  .filter-item-clear,
  .filter-item-clear:hover {
    color: white;
  }
`;

interface ISelectedFilters {
  showClearAll?: boolean;
  showSave?: boolean;
  onChange?: (...args: any[]) => any;
  context?: string;
  className?: string;
  showSaveFilters?: boolean;
}

/**
 * CWSelectedFilters is a component that displays the current filters selected by the user
 * in the search interface. It also provides a button to clear all the selected filters
 * and, if showSaveFilters is true, a button to save the selected filters as a new filter
 * set.
 *
 * @param {object} props component props
 * @param {boolean} [props.showClearAll=true] whether to show the clear all button
 * @param {boolean} [props.showSave=true] (deprecated) whether to show the save button
 * @param {function} [props.onChange=() => {}] callback function to be called when the selected filters change
 * @param {string} [props.context] (deprecated) the context of the filter
 * @param {string} [props.className] the class name of the component
 * @param {boolean} [props.showSaveFilters=true] whether to show the save as filter set button
 */
const CWSelectedFilters = ({
  showClearAll = true,
  showSave = true,
  onChange = () => {},
  context,
  className,
  showSaveFilters = true
}: ISelectedFilters) => {
  const i18n = useTranslation();
  return (
    <div className={`${className} d-flex`}>
      <div className="d-flex flex-wrap align-items-start flex-grow-1">
        <SelectedFilters
          onChange={onChange}
          className="flex-grow-1"
          render={(props) => {
            const { selectedValues, clearValue, clearValues } = props;
            const filters = Object.keys(selectedValues)
              .filter((componentId) => selectedValues[componentId].showFilter)
              .map((componentId) => {
                if (componentId === 'resultlistsortOption') return null;
                const { label, value } = selectedValues[componentId];
                if (!value) return null;
                return (
                  <StyledFilterButton
                    key={componentId}
                    role="button"
                    size="sm"
                    onClick={() => clearValue(componentId)}
                    className="mx-1 my-1"
                  >
                    <span>
                      {`${i18n(label)}: `}
                      <FilterValue value={value} />
                    </span>
                    <span className="ms-1" aria-hidden="true">
                      <i className="bi bi-x" />
                    </span>
                  </StyledFilterButton>
                );
              });

            return (
              <>
                {filters}
                {showClearAll &&
                  filters.filter((filter) => filter != null).length > 1 && (
                    <StyledBadge
                      role="button"
                      onClick={() => clearValues()}
                      className="filter-item-clear mx-1 my-1"
                    >
                      <span>{i18n('Clear all')}</span>
                    </StyledBadge>
                  )}
              </>
            );
          }}
        />
      </div>
      {showSaveFilters && <SaveFilters context={context} />}
    </div>
  );
};

export default CWSelectedFilters;
