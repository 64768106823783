/* eslint-disable react/prop-types */
import {
  MultiList,
  ReactiveComponent,
  SingleDataList,
} from '@appbaseio/reactivesearch';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import Chart from 'react-apexcharts';
import {
  Col, Container, Modal, Row, 
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactiveApp from '../../reactivesearch/ReactiveApp';
import { renderDev } from '../Develop/DevComponent';
import { LazyPanel } from '../LazyPanel';
import ModalDialog from '../ModalDialog';
import style from './IndexMonitor.module.css';

const IndexMonitorComponent = () => {
  const { t } = useTranslation('indices');
  const [timespan, setTimespan] = useState(t('last 30 days'));
  const interval = useRef('d');

  const timespanData = [
    { label: t('last 30 days'), value: 'now-30d/d' },
    { label: t('last 60 days'), value: 'now-60d/d' },
    { label: t('last 3 months'), value: 'now-3M/M' },
    { label: t('last 6 months'), value: 'now-6M/M' },
    { label: t('last year'), value: 'now-1y/M' },
  ];

  const onTimespanChange = (label) => {
    interval.current = timespanData.find((d) => d.label === label)?.value.slice(-1) || 'd';
    setTimespan(label);
  };
  return (
    <ReactiveApp app="carwatch-monitoring">
      <Container fluid>
        <Row>
          <Col xs={2} md={2} lg={2}>
            <ReactiveComponent
              componentId="indexfilter"
              customQuery={() => ({
                query: {
                  regexp: {
                    'data.reindex.source.keyword': {
                      value:
                        'cw.?_src_[a-zA-z0-9]*_(recalls|complaints|investigations|socialweb|bulletins)',
                    },
                  },
                },
              })}
            />
            <MultiList
              componentId="sourceIndex"
              dataField="data.reindex.source.keyword"
              size={100}
              showSearch={false}
              showCount={false}
              title={t('Index')}
              innerClass={{ title: 'fw-bold', list: 'w-100' }}
              customQuery={(value, props) => {
                if (!Array.isArray(value)) return null;
                return value[0] === props.selectAllLabel
                  ? { query: { match_all: {} } }
                  : {
                    query: {
                      terms: { [props.dataField]: value },
                    },
                  };
              }}
              react={{ and: ['timespan', 'indexfilter'] }}
              transformData={(data) => data.sort((a, b) => t(a.key).localeCompare(t(b.key)))}
              render={({
                loading, error, data, handleChange, 
              }) => {
                if (loading) {
                  return <div>Fetching Results.</div>;
                }
                if (error) {
                  return (
                    <div>
                      Something went wrong! Error details
                      {' '}
                      {JSON.stringify(error)}
                    </div>
                  );
                }
                return (
                  <div
                    className="vstack gap-1"
                    style={{ maxHeight: '300px', overflowY: 'auto' }}
                  >
                    <div className="me-2">
                      {data.map((item) => (
                        <div key={item.key} className="d-flex">
                          <input
                            type="checkbox"
                            value={item.key}
                            onChange={handleChange}
                          />
                          <div className="flex-grow-1 w-100 mx-2">
                            {t(item.key)}
                          </div>
                          {/* <div className="flex-shrink-1">{item.doc_count}</div> */}
                        </div>
                      ))}
                    </div>
                  </div>
                );
              }}
            />
          </Col>
          <Col xs={10} md={10} lg={10}>
            <SingleDataList
              componentId="timespan"
              data={timespanData}
              value={timespan}
              onChange={onTimespanChange}
              dataField="@timestamp"
              customQuery={(value, props) => ({
                query: {
                  range: {
                    [props.dataField]: {
                      gte: value,
                    },
                  },
                },
              })}
              showSearch={false}
              displayAsVertical={false}
              defaultValue={t('last 30 days')}
            />
            <ReactiveComponent
              componentId="indexMonitor"
              react={{ and: ['sourceIndex', 'timespan', 'indexfilter'] }}
              defaultQuery={() => ({
                query: {
                  prefix: { 'data.reindex.target': 'cw3_' },
                },
                size: 0,
                aggs: {
                  group_by_source: {
                    terms: {
                      field: 'data.reindex.source.keyword',
                      size: 100,
                    },
                    aggs: {
                      timeline: {
                        date_histogram: {
                          field: '@timestamp',
                          calendar_interval: `1${interval.current}`,
                          format: 'yyyy-MM-dd',
                          order: {
                            _key: 'desc',
                          },
                        },
                        aggs: {
                          created: {
                            sum: { field: 'data.reindex.result.created' },
                          },
                          updated: {
                            sum: { field: 'data.reindex.result.updated' },
                          },
                        },
                      },
                    },
                  },
                },
              })}
            >
              {({ aggregations, loading, error }) => {
                if (loading) return null;
                if (error) {
                  console.error(error);
                  return null;
                }
                if (!aggregations) return null;

                const groupBySourceBuckets = aggregations.group_by_source.buckets;
                const series = [];
                groupBySourceBuckets.forEach((b) => {
                  const { key, timeline } = b;
                  const timelineBuckets = timeline.buckets;
                  const timelineStats = timelineBuckets.map((t) => {
                    const time = t.key_as_string;
                    const updated = t.updated.value;
                    const created = t.created.value;
                    return [time, created, updated];
                  });
                  series.push({ name: t(key), data: timelineStats });
                });

                return (
                  <>
                    <Chart
                      type="bar"
                      height={300}
                      options={{
                        chart: {
                          zoom: {
                            autoScaleYaxis: true,
                          },
                        },
                        xaxis: {
                          type: 'datetime',
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        stroke: { width: 1 },
                        tooltip: {
                          x: {
                            show: true,
                            format: 'dd MMM',
                            formatter: undefined,
                          },
                          y: {
                            formatter(value) {
                              return `${value} created`;
                            },
                            title: {
                              formatter: (seriesName) => `${seriesName}:`,
                            },
                          },
                          z: {
                            formatter(value) {
                              return `${value} updated`;
                            },
                            title: '',
                          },
                        },
                      }}
                      series={series}
                    />
                    {renderDev(
                      <LazyPanel collapsed title="Stats">
                        <small>
                          <pre>
                            <code>{JSON.stringify(aggregations, null, 1)}</code>
                          </pre>
                        </small>
                      </LazyPanel>,
                    )}
                  </>
                );
              }}
            </ReactiveComponent>
          </Col>
        </Row>
      </Container>
    </ReactiveApp>
  );
};

const IndexStatsModal = ({ title, trigger }) => (
  <ModalDialog
    title={title}
    triggerElement={trigger}
    dialogClassName={`${style['modal-90w']}`}
  >
    {() => <IndexMonitorComponent />}
  </ModalDialog>
);

export default IndexStatsModal;
