import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { array, bool, func, number, object, oneOf, string } from 'prop-types';
import React, { forwardRef, useEffect, useRef } from 'react';
import { ButtonGroup, Form, InputGroup } from 'react-bootstrap';
import { Filter, InfoCircleFill, X } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import countFormatter from '../../utils/CountFormatter';
import * as t from '../../utils/Localization';
import DebouncedInput from '../DebouncedInput';
import Error from '../Error';
import { LoaderText } from '../Loader';
import NoResults from '../NoResults';
import TogglePanel from '../SimpleTogglePanel';
import { filteredCaseInsensitiveTermsAgg } from './helpers';

const StyledMultiListRefinementItem = styled.li.attrs<{
  $temp?: boolean;
  checked?: boolean;
}>(({ $temp, checked }) => ({
  className: `d-flex align-items-stretch${$temp ? ' text-secondary' : ''}${
    checked ? ' fw-bold' : ''
  }`
}))``;

const LessMoreButton = ({
  onClick,
  className,
  children
}: React.PropsWithChildren<any>) => (
  <Button
    as="a"
    size="sm"
    variant="link"
    onClick={onClick}
    className={className}
  >
    {children}
  </Button>
);

const EMPTY_FUNC = () => {};
const MAP_SELF_FUNC = (v: any) => v;

const MultiListRefinementVisualisation = forwardRef<any, any>(
  (props, scrollTarget) => {
    const {
      aggregations,
      loading,
      error,
      setQuery = EMPTY_FUNC,
      value,
      dataField,
      onSizeChange = EMPTY_FUNC,
      stepSize = 10,
      translateFunc = MAP_SELF_FUNC
    } = props;
    const [selected, setSelected] = React.useState(value || []);

    const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const { value: v, checked } = target;
      checked
        ? setSelected([...selected, v])
        : setSelected(selected.filter((s: string) => s !== v));
    };

    React.useEffect(() => {
      if (value === null) setSelected([]); // clear filter case
    }, [value]);

    React.useEffect(() => {
      if (selected.length > 0) {
        setQuery({
          query: {
            terms: {
              [dataField]: selected
            }
          },
          value: selected
        });
      } else {
        setQuery({});
      }
    }, [dataField, selected, setQuery]);

    if (loading) return <LoaderText />;
    if (error) return <Error error={error} />;
    if (!aggregations) return <NoResults />;

    let tempValues = [...selected];
    const { buckets, sum_other_doc_count } =
      aggregations[`${dataField}_filtered`][dataField];
    if (buckets.length === 0) return <NoResults />;

    const items = buckets.map((b: any) => {
      const cbProps: any = {
        checked: ''
      };
      let isChecked = false;
      if (selected.findIndex((s: string) => s === b.key) !== -1) {
        isChecked = true;
        cbProps.checked = 'checked';
        tempValues = tempValues.filter((tv) => tv !== b.key);
      }
      const descKey = `${b.key}_description`;
      const desc = translateFunc(descKey);
      return (
        <StyledMultiListRefinementItem key={b.key} checked={isChecked}>
          <div style={{ flex: '0 0 1rem' }}>
            <input
              className="form-check-input"
              type="checkbox"
              value={b.key}
              onChange={handleChange}
              {...cbProps}
            />
          </div>
          <div className="ps-2 flex-fill text-break">
            {translateFunc ? translateFunc(b.key) : b.key}
          </div>
          <div style={{ flex: '0 0 3rem' }} className="text-end">
            {countFormatter(b.doc_count, 1)}
          </div>
          <div
            role="link"
            style={{ flex: '0 0 1rem', width: '1em' }}
            className="ps-2 text-end"
          >
            {desc === descKey ? null : (
              <InfoCircleFill size={16} title={desc} />
            )}
          </div>
        </StyledMultiListRefinementItem>
      );
    });

    tempValues.forEach((tv) =>
      items.unshift(
        <StyledMultiListRefinementItem key={tv} $temp>
          <div style={{ flex: '0 0 1rem' }}>
            <input
              className="form-check-input"
              type="checkbox"
              value={tv}
              onChange={handleChange}
              checked
            />
          </div>
          <div className="ps-2 flex-fill text-break">
            {translateFunc ? translateFunc(tv) : tv}
          </div>
          <div style={{ flex: '0 0 3rem' }} className="text-end" />
          <div
            style={{ flex: '0 0 1rem', width: '1em' }}
            className="ps-2 text-end"
          />
        </StyledMultiListRefinementItem>
      )
    );

    return (
      <>
        <ul className="refinementlist p-0 mb-0">{items}</ul>
        <ButtonGroup>
          <LessMoreButton
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              onSizeChange(buckets.length + stepSize);
            }}
            className={sum_other_doc_count === 0 && 'd-none'}
          >
            {t.t('More')}
          </LessMoreButton>
          <span ref={scrollTarget} style={{ width: '1px' }} />
          <LessMoreButton
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              onSizeChange(Math.max(buckets.length - stepSize, stepSize));
            }}
            className={buckets.length <= stepSize && 'd-none'}
          >
            {t.t('Less')}
          </LessMoreButton>
        </ButtonGroup>
      </>
    );
  }
);

MultiListRefinementVisualisation.propTypes = {
  aggregations: object,
  dataField: string.isRequired,
  error: object,
  loading: bool,
  onSizeChange: func,
  setQuery: func,
  stepSize: number,
  value: array,
  translateFunc: func
};

const filter_match_all = {
  match_all: {}
};

const MultiListRefinement = (props: any) => {
  const {
    componentId,
    dataField,
    defaultQuery,
    translateFunc,
    sortBy = '_count',
    sortOrder = 'desc',
    filter = filter_match_all,
    filterValue,
    ...rest
  } = props;
  // eslint-disable-next-line react/destructuring-assignment
  const [size, setSize] = React.useState(props.size || 10);
  const scrollHelper = useRef();
  const didMount = useRef(false);

  const thedefault = React.useMemo(() => {
    const aggs = filteredCaseInsensitiveTermsAgg({
      dataField,
      filterQuery: filter,
      aggFilterValue: filterValue,
      aggSize: size,
      sortBy,
      sortOrder
    });
    const termsQuery = {
      size: 0,
      aggs
    };
    return () =>
      defaultQuery
        ? {
            ...{
              ...termsQuery
            },
            ...defaultQuery()
          }
        : termsQuery;
  }, [dataField, defaultQuery, size, sortBy, sortOrder, filter, filterValue]);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    if (scrollHelper.current) {
      /* const pos = scrollHelper.offsetTop;
      const container = document.getElementById('refinements');
      container.scrollTop = pos; */
      /* scrollHelper.current?.scrollIntoView({ behavior: 'smooth' }); */
    }
  }, [size]);

  return (
    <ReactiveComponent
      componentId={componentId}
      render={(args) => (
        <MultiListRefinementVisualisation
          ref={scrollHelper}
          dataField={dataField}
          onSizeChange={setSize}
          {...args}
          translateFunc={translateFunc}
        />
      )}
      defaultQuery={thedefault}
      {...rest}
    />
  );
};

MultiListRefinement.propTypes = {
  componentId: string.isRequired,
  dataField: string.isRequired,
  size: number,
  defaultQuery: func,
  translateFunc: func,
  sortBy: oneOf(['_count', '_key']),
  sortOrder: oneOf(['asc', 'desc']),
  filter: object,
  filterValue: string
};

/**
 * Renders a filterable multi-list refinement component.
 *
 * @param {Object} props - The props object containing the component's properties.
 * @param {string} props.title - The title of the component.
 * @param {Object} props.panelProps - The props for the panel component.
 * @param {string} props.filterField - The field to filter on.
 * @param {Array} props.rest - The rest of the properties.
 * @return {JSX.Element} The rendered filterable multi-list refinement component.
 */
export const FilterableMultiListRefinement = (props: any) => {
  const [filterValue, setFilterValue] = React.useState('');
  const { title, panelProps = {}, filterField, ...rest } = props;

  const clearFilterValue = () => {
    setFilterValue('');
  };

  return (
    <TogglePanel stateful initialOpen label={title} {...panelProps}>
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text id="basic-addon1">
          <Filter size={16} />
        </InputGroup.Text>
        <DebouncedInput
          value={filterValue}
          type="text"
          onChange={setFilterValue}
          inputElement={<Form.Control />}
        />
        {/* <Form.Control onChange={debounceSetFilterValue} ref={inputRef} /> */}
        <InputGroup.Text onClick={clearFilterValue} role="button">
          <X size={16} />
        </InputGroup.Text>
      </InputGroup>
      <MultiListRefinement {...rest} filterValue={filterValue} />
    </TogglePanel>
  );
};

FilterableMultiListRefinement.propTypes = {
  title: string,
  panelProps: object,
  filterField: string
};

const ExpandableMultiListRefinement = (props: any) => {
  const { title = '', panelProps = {}, ...rest } = props;
  return (
    <TogglePanel stateful initialOpen label={title} {...panelProps}>
      <MultiListRefinement {...rest} />
    </TogglePanel>
  );
};

export default ExpandableMultiListRefinement;
