import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { ReactiveComponentProps } from '@appbaseio/reactivesearch/lib/components/basic/ReactiveComponent';
import React from 'react';
import { DateRange } from '../Dates';
import { IDateRangeRef } from '../Dates/DateRange';

interface IDateRangeRefinementProps extends ReactiveComponentProps {
  startLabel?: string;
  endLabel?: string;
  dataField: string;
}

const DateRangeRefinement = (props: IDateRangeRefinementProps): JSX.Element => {
  const dateRangeRef = React.useRef<IDateRangeRef>();
  return (
    <>
      <ReactiveComponent
        {...props}
        customQuery={(v) =>
          v && {
            query: {
              range: {
                [props.dataField]: {
                  gte: v.start,
                  lte: v.end
                }
              }
            }
          }
        }
      >
        {({ value, setQuery }) => {
          return (
            <DateRange
              ref={dateRangeRef}
              value={value}
              onChange={(v) => {
                // setDateRange(v);
                if (v.start && v.end)
                  setQuery({
                    query: {
                      range: {
                        [props.dataField]: {
                          gte: v.start,
                          lte: v.end
                        }
                      }
                    },
                    value: `${v.start} - ${v.end}`
                  });
              }}
            />
          );
        }}
      </ReactiveComponent>
    </>
  );
};

export default DateRangeRefinement;
