import React from 'react';
import PropTypes from 'prop-types';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import Card from 'react-bootstrap/Card';
import * as t from '../../utils/Localization';
import { DefaultRenderWrapper } from '../../reactivesearch/ReactComponentRenderWrapper';

export default function SimpleChartComponent({
  componentId,
  react = {},
  label,
  field,
  chart: Chart,
  chartProps,
}) {
  if (!field) {
    throw Error('missing field property!');
  }
  if (!componentId) {
    throw Error('missing component id!');
  }
  if (!Chart) {
    throw Error('missing chart component!');
  }
  return (
    <ReactiveComponent
      componentId={componentId}
      defaultQuery={() => ({
        aggs: {
          data: {
            terms: {
              field,
              size: 10,
            },
          },
        },
        size: 0,
      })}
      react={react}
      render={(props) => {
        // eslint-disable-next-line react/prop-types
        const { setQuery, ...other } = props;
        return (
          <Card>
            {label && (
              <Card.Header>
                <div className="fw-bold">{label}</div>
              </Card.Header>
            )}
            <Card.Body>
              <DefaultRenderWrapper
                {...props}
                loader={<div>{t.t('Loading chart...')}</div>}
                element={(
                  <Chart
                    onClick={(value) => {
                      setQuery({
                        query: { term: { [field]: value } },
                        value,
                      });
                    }}
                    {...other}
                    {...chartProps}
                  />
                )}
              />
            </Card.Body>
          </Card>
        );
      }}
      URLParams
      showFilter
    />
  );
}

SimpleChartComponent.propTypes = {
  componentId: PropTypes.string.isRequired,
  react: PropTypes.object,
  label: PropTypes.string,
  field: PropTypes.string.isRequired,
  chart: PropTypes.func.isRequired,
  chartProps: PropTypes.object,
};
