/* eslint-disable react/prop-types */
import styled from 'styled-components';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { useForm } from 'react-hook-form';
import * as t from '../../utils/Localization';
import { SimpleTogglePanel } from '../SimpleTogglePanel';

const daterangemap = {
  none: '',
  'last week': 'now/d-7d',
  'last month': 'now/M-1M',
  'last year': 'now/y-1y',
};

function SearchCaseEdit({
  open,
  onSubmit,
  onClose,
  onDelete,
  data,
  className, /* pass classname from styled component */
}) {
  const { register, reset, handleSubmit } = useForm({ defaultValues: data });
  const [dialogContentOpen] = useState(true);

  React.useEffect(() => {
    reset(data);
  }, [data, reset]);

  const { name: caseName } = data;

  return (
    <Modal
      show={open}
      onHide={onClose}
      animation={false}
      centered
      dialogClassName={className}
      style={{ opacity: dialogContentOpen ? '1' : '0.5' }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {caseName
              ? `${t.t('Edit case', 'searchcase')} "${caseName}"`
              : t.t('New case', 'searchcase')}
          </Modal.Title>
        </Modal.Header>

        <SimpleTogglePanel>
          <Modal.Body>
            {!caseName && (
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">
                  {t.t('Name', 'searchcase')}
                </Form.Label>
                <Form.Control
                  {...register('name')}
                  placeholder="Searchcase Name"
                  required
                />
              </Form.Group>
            )}
            <Form.Group className="mb-3">
              <Form.Label column sm={2} className="fw-bold">
                Date Range
              </Form.Label>
              {Object.keys(daterangemap).map((r) => (
                <Form.Check
                  size="sm"
                  inline
                  key={r}
                  {...register('daterange')}
                  type="radio"
                  label={t.t(r, 'searchcase')}
                  value={daterangemap[r]}
                />
              ))}
            </Form.Group>

            <Card>
              <Card.Header>{t.t('Field Search', 'searchcase')}</Card.Header>
              <Card.Body>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2} className="fw-bold">
                    {t.t('Make', 'searchcase')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      size="sm"
                      as="textarea"
                      cols="2"
                      {...register('make')}
                      placeholder="Ford, BMW, ..."
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2} className="fw-bold">
                    {t.t('Model', 'searchcase')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      size="sm"
                      as="textarea"
                      cols="2"
                      {...register('model')}
                      placeholder="Mustang, ..."
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2} className="fw-bold">
                    {t.t('Component', 'searchcase')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      size="sm"
                      as="textarea"
                      cols="2"
                      {...register('component')}
                      placeholder="wheels, seat*, ..."
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2} className="fw-bold">
                    {t.t('Issue', 'searchcase')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      size="sm"
                      as="textarea"
                      cols="2"
                      {...register('issue')}
                      placeholder="defect, fire, ..."
                    />
                  </Col>
                </Form.Group>
              </Card.Body>
            </Card>
            <hr />
            <Card>
              <Card.Header>
                Full Text Search
                {' '}
                <span className="fs-6">
                  <i
                    className="bi bi-info-circle"
                    title="help is coming soon :)"
                  />
                </span>
              </Card.Header>
              <Card.Body>
                <Form.Group className="mb-3">
                  <Form.Control
                    size="sm"
                    as="textarea"
                    cols="2"
                    {...register('fulltext')}
                    placeholder="wheel AND (defect OR flat)"
                  />
                  <Form.Text muted>
                    {t.t('fulltext_explanation', 'searchcase')}
                  </Form.Text>
                </Form.Group>
              </Card.Body>
            </Card>
          </Modal.Body>

          <Modal.Footer>
            <Button as="button" type="submit" size="sm" variant="primary">
              {t.t('Save', 'btn')}
            </Button>
            <Button
              as="button"
              type="button"
              size="sm"
              variant="secondary"
              onClick={onClose}
            >
              {t.t('Cancel', 'btn')}
            </Button>
            {caseName && (
              <Button
                as="button"
                type="button"
                size="sm"
                variant="danger"
                onClick={() => onDelete(data)}
              >
                {t.t('Delete', 'btn')}
              </Button>
            )}
          </Modal.Footer>
        </SimpleTogglePanel>
      </Form>
    </Modal>
  );
}

export default styled(SearchCaseEdit)`
  width: 50vw;
  max-width: 50vw;

  .form-control {
    font-size: inherit;
  }
  .form-control::placeholder {
    opacity: 0.5;
  }
`;
