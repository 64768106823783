import {
  AggregateName,
  AggregationsAggregate,
  MsearchMultiSearchItem,
  SearchHit
} from '@/elasticsearch/types';
import { IReactComponentRenderArgs } from '@/reactivesearch/types';
import ReactiveComponent, {
  ReactiveComponentProps
} from '@appbaseio/reactivesearch/lib/components/basic/ReactiveComponent';
import React from 'react';
import Error from './Error';
import { LoaderSpinner } from './Loader';

type TReactiveComponentRendererProps = Pick<
  IReactComponentRenderArgs,
  'setQuery' | 'value' | 'setQuery' | 'value' | 'resultStats'
> & {
  data?: SearchHit[];
  aggregations?: Record<AggregateName, AggregationsAggregate>;
  children?: React.ReactNode;
};

const RenderDataComponent = ({
  children,
  ...rest
}: TReactiveComponentRendererProps): JSX.Element => {
  const childs = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement<any>, {
        ...rest
      });
    }
  });
  return <React.Fragment>{childs}</React.Fragment>;
};

export interface IReactiveComponentRendererProps
  extends Omit<ReactiveComponentProps, 'children' | 'render'> {
  children?: React.ReactNode;
  render?: (args: TReactiveComponentRendererProps) => JSX.Element;
}

export const ReactiveComponentRenderer = (
  props: IReactiveComponentRendererProps
) => {
  const { children, ...reactiveComponentProps } = props;
  return (
    <ReactiveComponent
      {...reactiveComponentProps}
      render={(renderArgs) => {
        const {
          loading,
          error,
          setQuery,
          value,
          rawData,
          resultStats
        }: IReactComponentRenderArgs = renderArgs;

        // handle loading state and errors
        if (loading === undefined || loading === true) {
          return <LoaderSpinner />;
        }
        if (error) {
          return <Error error={error} />;
        }

        // extract result data
        const { hits, aggregations } = rawData as MsearchMultiSearchItem;

        const renderProps: TReactiveComponentRendererProps = {
          data: hits.hits || [],
          aggregations: aggregations || {},
          setQuery,
          value,
          resultStats
        };

        // render result
        return props.render ? (
          props.render(renderProps)
        ) : (
          <RenderDataComponent {...renderProps}>{children}</RenderDataComponent>
        );
      }}
    />
  );
};
