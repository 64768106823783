import { uniqueId } from 'lodash-es';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { CalendarRange } from 'react-bootstrap-icons';
import styled from 'styled-components';

const validateDate = (date: string): boolean => {
  if (!date) return false;
  return !Number.isNaN(new Date(date.replace(/-/g, '/')));
};

const ValidationInputGroup = styled(InputGroup)`
  input:invalid {
    color: var(--bs-danger);
  }
`;

interface IDateRange {
  start: string;
  end: string;
};

export interface IDateRangeProps {
  label?: string;
  startLabel?: string;
  endLabel?: string;
  value?: IDateRange | string;
  onChange?: (value: IDateRange) => void;
}

export interface IDateRangeRef {
  clear: () => void;
};

const prepareRange = (value: string | IDateRange) => {
  if (!value)
    return {
      start: '',
      end: ''
    };
  if (typeof value === 'string') {
    const [start, end] = value.split(' - ');
    return {
      start,
      end
    };
  }
  return value;
};

const DateRange = forwardRef<IDateRangeRef, IDateRangeProps>(
  (
    {
      label = '',
      startLabel = '',
      endLabel = '',
      value = {
        start: undefined,
        end: undefined
      },
      onChange = () => {}
    }: IDateRangeProps,
    ref: React.ForwardedRef<IDateRangeRef>
  ) => {
    const dateRange = prepareRange(value);
    const [startDate, setStartDate] = React.useState<string>(dateRange.start);
    const [endDate, setEndDate] = React.useState<string>(dateRange.end);

    const startDateRef = React.useRef<HTMLInputElement>();
    const endDateRef = React.useRef<HTMLInputElement>();

    const id = uniqueId('date-range-');

    useEffect(() => {
      const dateRange = prepareRange(value);
      setStartDate(dateRange.start);
      setEndDate(dateRange.end);
    }, [value]);

    useImperativeHandle(
      ref,
      () => ({
        clear: () => {
          setStartDate('');
          setEndDate('');
        }
      }),
      [startDate, endDate]
    );

    const checkEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
      return e.target.validity.valid && validateDate(e.target.value);
    };

    const handleChangeStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
      endDateRef.current && (endDateRef.current.min = e.target.value);
      setStartDate(e.target.value);
      if (onChange && checkEvent(e)) {
        onChange({
          start: e.target.value,
          end: endDate
        });
      }
    };

    const handleChangeEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
      startDateRef.current && (startDateRef.current.max = e.target.value);
      setEndDate(e.target.value);
      if (onChange && checkEvent(e)) {
        onChange({
          start: startDate,
          end: e.target.value
        });
      }
    };

    return (
      <ValidationInputGroup size="sm" className="mb-3">
        <InputGroup.Text>
          <CalendarRange size={16} title={label} />
        </InputGroup.Text>
        <Form.Control
          id={`${id}-start`}
          ref={startDateRef}
          title={startLabel}
          type="date"
          min="1970-01-01"
          value={startDate}
          onChange={handleChangeStartDate}
        />
        <Form.Control
          id={`${id}-end`}
          ref={endDateRef}
          title={endLabel}
          type="date"
          max={new Date().toLocaleDateString('fr-ca')}
          value={endDate}
          onChange={handleChangeEndDate}
        />
      </ValidationInputGroup>
    );
  }
);

export default DateRange;
