import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { format, getWeek } from 'date-fns';
import { object, string } from 'prop-types';
import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import Table from 'react-bootstrap/Table';
import { Translation } from 'react-i18next';
import {
  buildDataSourceSeries,
  buildDataSourceStatsOptions,
} from './ChartUtils';

const renderStatsForum = (args) => {
  const { loading, aggregations, options } = args;
  if (loading) return <div>Loading...</div>;
  if (!aggregations) return <div>No data</div>;

  const { week, month, year } = aggregations;

  const weekSeries = buildDataSourceSeries(week.data, 'histogram');
  const monthSeries = buildDataSourceSeries(month.data, 'histogram');
  const yearSeries = buildDataSourceSeries(year.data, 'histogram');

  const weekDocCount = week.doc_count ? `# ${week.doc_count}` : '-';
  const monthDocCount = month.doc_count ? `# ${month.doc_count}` : '-';
  const yearDocCount = year.doc_count ? `# ${year.doc_count}` : '-';
  const now = new Date();

  return (
    <Translation ns="charts">
      {(i18n) => (
        <Table>
          <tbody>
            <tr>
              <th>
                {`${i18n('CW')}${getWeek(now, {
                  weekStartsOn: 1,
                  firstWeekContainsDate: 4,
                })}`}
              </th>
              <td>
                <div>{weekDocCount}</div>
                {weekSeries.length > 0 && (
                  <div className="mt-3">
                    <ReactApexChart
                      options={options}
                      series={weekSeries}
                      type="bar"
                      height={350}
                      width={600}
                    />
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <th>{format(now, 'LLLL')}</th>
              <td>
                <div>{monthDocCount}</div>
                <div className="mt-3">
                  <ReactApexChart
                    options={options}
                    series={monthSeries}
                    type="bar"
                    height={350}
                    width={600}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>{format(now, 'yyyy')}</th>
              <td>
                <div>{yearDocCount}</div>
                <div className="mt-3">
                  <ReactApexChart
                    options={options}
                    series={yearSeries}
                    type="bar"
                    height={350}
                    width={600}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </Translation>
  );
};

const chartDefaultQuery = (args) => {
  const { keywordField, dateField } = args;
  return {
    track_total_hits: true,
    query: {
      range: {
        [dateField]: {
          gte: 'now/y',
        },
      },
    },
    size: 0,
    aggs: {
      year: {
        filter: {
          range: {
            [dateField]: {
              lte: 'now/y',
              gte: 'now/y',
            },
          },
        },
        aggs: {
          data: {
            terms: { field: keywordField },
            aggs: {
              histogram: {
                date_histogram: {
                  field: dateField,
                  min_doc_count: 0,
                  calendar_interval: 'year',
                  format: 'yyyy',
                },
              },
            },
          },
        },
      },
      month: {
        filter: {
          range: {
            [dateField]: {
              lte: 'now/M',
              gte: 'now/M',
            },
          },
        },
        aggs: {
          data: {
            terms: { field: keywordField },
            aggs: {
              histogram: {
                date_histogram: {
                  field: dateField,
                  min_doc_count: 0,
                  calendar_interval: 'month',
                  format: 'MMM yyyy',
                },
              },
            },
          },
        },
      },
      week: {
        filter: {
          range: {
            [dateField]: {
              lte: 'now/w',
              gte: 'now/w',
            },
          },
        },
        aggs: {
          data: {
            terms: {
              field: keywordField,
            },
            aggs: {
              histogram: {
                date_histogram: {
                  field: dateField,
                  min_doc_count: 0,
                  calendar_interval: 'day',
                  format: 'MMM dd',
                  extended_bounds: {
                    min: 'now/w',
                    max: 'now/w+7d',
                  },
                },
              },
            },
          },
        },
      },
    },
  };
};

const defaultChartProps = {
  type: 'bar',
  height: 350,
};

function DataSourceStats(props) {
  const {
    dateField = 'publication_date',
    keywordField = 'datasource.keyword',
    chartProps = defaultChartProps,
    ...rest
  } = props;

  const options = useMemo(() => buildDataSourceStatsOptions(), []);

  return (
    <ReactiveComponent
      dataField={keywordField}
      defaultQuery={() => chartDefaultQuery({
        dateField,
        keywordField,
      })}
      /* render={(args) => renderStatsForum({ ...args, options })} */
      {...rest}
    >
      {(args) => renderStatsForum({ ...args, options })}
    </ReactiveComponent>
  );
}

DataSourceStats.propTypes = {
  dateField: string.isRequired,
  keywordField: string,
  chartProps: object,
};

export default DataSourceStats;
