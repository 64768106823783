import { useEffect, useState } from 'react';

export const basicTitle = 'Carwatch by Pumacy';

export const usePageTitle = (
  title: string
): [pageTitle: string, setPageTitle: (title: string) => void] => {
  const [pageTitle, setPageTitle] = useState(title);
  useEffect(() => {
    document.title = `${basicTitle} - ${title}`;
    return () => {
      document.title = basicTitle;
    };
  }, [pageTitle, title]);
  return [pageTitle, setPageTitle];
};
