import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from '../utils/Localization';

const ComingSoon = ({ className }) => {
  const i18n = useTranslation();
  return <div className={className}>{i18n('Available soon...')}</div>;
};

ComingSoon.propTypes = {
  className: PropTypes.string.isRequired,
};

export default styled(ComingSoon)`
  font-size: 2em;
  font-weight: bold;
  padding-top: 5em;
  text-align: center;
`;
