function getCommonUrlParameters(location) {
  const params = new URLSearchParams(location.search);
  const supportedKeys = new Set([
    'q',
    'refine_make',
    'refine_model',
    'refine_component',
  ]);

  const filteredParams = new URLSearchParams(
    Array.from(params.entries()).filter(([key]) => supportedKeys.has(key)),
  );

  return filteredParams.toString() === ''
    ? ''
    : `?${filteredParams.toString()}`;
}

export default getCommonUrlParameters;
