import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../utils/Localization';

const DisplayText = styled.span`
  blockquote {
    padding: 0.3rem;
    background-color: antiquewhite;
    font-style: italic;
  }
`;

const EllipsisText = ({
  text,
  length,
  showShowAll = true
}: {
  text: string;
  length: number;
  showShowAll?: boolean;
}): JSX.Element => {
  const [showAll, setShowAll] = React.useState(text.length <= length);
  const [displayText, setDisplayText] = React.useState('');
  const t = useTranslation();

  React.useEffect(() => {
    if (showAll) setDisplayText(text);
    else {
      setDisplayText(text.slice(0, length) + (length > 0 ? ' &#8230;' : ''));
    }
  }, [showAll, text, length]);

  return (
    <>
      <DisplayText dangerouslySetInnerHTML={{ __html: displayText }} />
      {showShowAll && !showAll && (
        <span>
          &nbsp;
          <span
            title={t('more')}
            role="link"
            tabIndex={-1}
            onClick={() => setShowAll((v) => !v)}
            onKeyUp={() => {}}
          >
            {`[${t('more')}]`}
          </span>
        </span>
      )}
      {showShowAll && text.length > length && showAll && (
        <span>
          &nbsp;
          <span
            title={t('less')}
            role="link"
            tabIndex={-1}
            onClick={() => setShowAll((v) => !v)}
            onKeyUp={() => {}}
          >
            &nbsp;
            {`[${t('less')}]`}
          </span>
        </span>
      )}
    </>
  );
};

export default EllipsisText;
