import React from 'react';
import { WebSocketContext } from './socket.io';

export const withFavoritesWs =
  (Component: React.ComponentType<any>) =>
  (props: React.PropsWithChildren<any>) => {
    return (
      <WebSocketContext namespace="favorites">
        <Component {...props} />
      </WebSocketContext>
    );
  };

export const withCommentsWs =
  (Component: React.ComponentType<any>) =>
  (props: React.PropsWithChildren<any>) =>
    (
      <WebSocketContext namespace="comments">
        <Component {...props} />
      </WebSocketContext>
    );

export type TSocketHOCOptions = {
  namespace?: string;
};

export const withWs =
  (Component: React.ComponentType<any>, options: TSocketHOCOptions = {}) =>
  (props: React.PropsWithChildren<any>) => {
    return (
      <WebSocketContext namespace={options.namespace}>
        <Component {...props} />
      </WebSocketContext>
    );
  };
