import { PropTypes } from 'prop-types';
import React from 'react';

import styled from 'styled-components';

const StyledStickyRefinements = styled.div`
  width: 20rem;
  height: 85vh;
`;

export default function Refinements(props) {
  const { children } = props;
  return (
    <div className="bg-white me-2 p-1 rounded-1">
      <StyledStickyRefinements className="flex-shrink-1 overflow-auto sticky-top">
        <aside className="p-2 pe-4">{children}</aside>
      </StyledStickyRefinements>
    </div>
  );
}

Refinements.propTypes = {
  children: PropTypes.node.isRequired,
};
