import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSocket, withFavoritesWs } from '../../websocket';
import SimpleSpinner from '../SimpleSpinner';
import { IFavoriteProps } from './rli.interfaces';

interface IStarProps {
  favorite: boolean;
  onClick: () => void;
  className?: string;
  loading?: boolean;
  error?: boolean;
  iconClassName?: string;
}

export const Star = styled(
  ({
    favorite,
    iconClassName,
    onClick,
    className,
    loading,
    error
  }: IStarProps): JSX.Element => (
    <div
      aria-label="Favorite"
      aria-checked={favorite}
      className={`text-warning ${className}`}
      role="switch"
      tabIndex={-1}
      onClick={onClick}
      onKeyUp={() => {}}
    >
      {error && <i className="bi bi-exclamation-triangle text-danger" />}
      {loading && <SimpleSpinner />}
      {!loading && <i className={iconClassName} />}
    </div>
  )
).attrs(({ favorite }) => ({
  iconClassName: favorite ? 'bi bi-star-fill' : 'bi bi-star'
}))`
  &:hover {
    cursor: pointer;
  }
`;

const stripHTML = (originalString: string): string =>
  originalString?.replace(/(<([^>]+)>)/gi, '');

function Favorite({ name: htmlName, entityRef }: IFavoriteProps): JSX.Element {
  const { socket, subscribeEvent } = useSocket();
  const [favorite, setFavorite] = useState();
  const name = stripHTML(htmlName);

  useEffect(() => {
    const unsubscribe = [
      subscribeEvent('get-user-favorite', (value: any) => {
        if (value?._id === entityRef) {
          if (value.name) setFavorite(value);
          else setFavorite(null);
        }
      }),
      subscribeEvent('create-user-favorite', (value: any) => {
        if (value?._id === entityRef && value?.result === 'success') {
          socket.then((s) =>
            s?.emit('get-user-favorite', {
              _id: entityRef,
              entityref: entityRef
            })
          );
        }
      }),
      subscribeEvent('delete-user-favorite', (value: any) => {
        if (value?._id === entityRef && value?.result === 'success') {
          socket.then((s) =>
            s?.emit('get-user-favorite', {
              _id: entityRef,
              entityref: entityRef
            })
          );
        }
      })
    ];
    socket.then((s) =>
      s?.emit('get-user-favorite', { _id: entityRef, entityref: entityRef })
    );
    return () => {
      unsubscribe.forEach((u) => u());
    };
  }, [entityRef, socket, subscribeEvent]);

  const toggleFavorite = async () => {
    if (favorite) {
      socket.then((s) =>
        s?.emit('delete-user-favorite', {
          _id: entityRef,
          entityref: entityRef
        })
      );
    } else {
      socket.then((s) =>
        s?.emit('create-user-favorite', {
          _id: entityRef,
          entityref: entityRef,
          name
        })
      );
    }
  };

  return (
    <Star
      /* loading={addFavsState.loading || removeFavsState.loading}
      error={addFavsState.error || removeFavsState.error} */
      favorite={!!favorite}
      onClick={toggleFavorite}
    />
  );
}

export default withFavoritesWs(Favorite);
