import { DataSearchProps } from '@appbaseio/reactivesearch/lib/components/search/DataSearch';

export const defaultHighlight = (highlightConfig?: any) => ({
  pre_tags: ['<mark>'],
  post_tags: ['</mark>'],
  fields: {
    '*': highlightConfig || {
      number_of_fragments: 0
    }
  }
});

export const dataSearchQuery = (
  value: string,
  props: DataSearchProps,
  ignoreNested = false
): any => {
  if (!value?.length) return { query: { match_all: {} } };
  const {
    dataField,
    highlight,
    highlightConfig = { number_of_fragments: 0 },
    queryString = false
  } = props;

  const nestedField: string[] = ['documents.content'];
  const nestedPath: string = 'documents';
  const nestedSource: string[] = ['documents.filename'];

  const queryType = queryString ? 'query_string' : 'simple_query_string';

  const nestedQuery = !ignoreNested && {
    path: nestedPath,
    query: {
      bool: {
        must: [
          {
            [queryType]: {
              query: value,
              fields: [...nestedField],
              boost: 0.5
            }
          }
        ]
      }
    },
    inner_hits: {
      _source: { includes: [...nestedSource] },
      highlight: defaultHighlight(highlightConfig)
    }
  };

  const query: Record<string, any> = {
    query: {
      bool: {
        should: [
          {
            [queryType]: {
              query: value,
              fields: dataField && [...dataField]
            }
          }
        ]
      }
    }
  };

  if (!ignoreNested) {
    query.query.bool.should.push({
      nested: nestedQuery
    });
  }

  if (highlight) {
    query.highlight = defaultHighlight(highlightConfig);
  }

  return query;
};
