export const apiUrl = `${process.env.appconfig?.api_url}/v1/comments/${process.env.appconfig?.instance_name}`;

export const getComments = async ({ accessToken }) => {
  const resp = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (resp.status !== 200) {
    throw new Error(await resp.text());
  }

  const json = await resp.json();
  return json;
};

export const getCommentsByDocument = async ({ accessToken }, { documentRef }) => {
  const body = {
    entityref: documentRef,
  };
  const resp = await fetch(`${apiUrl}/entity`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  });

  if (resp.status !== 200) {
    throw new Error(await resp.text());
  }

  const json = await resp.json();
  return json;
};

export const getCommentsByUserEmailInContent = async ({ accessToken }, { userEmail }) => {
  const resp = await fetch(`${apiUrl}/by-content-include?content=${encodeURIComponent(userEmail)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (resp.status !== 200) {
    throw new Error(await resp.text());
  }

  const json = await resp.json();
  return json;
};

export const postComment = async ({ accessToken }, { commentText, documentRef, parentId }) => {
  const body = {
    comment: commentText,
    entityref: documentRef,
    parentid: parentId,
  };
  const resp = await fetch(`${apiUrl}/comment`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  });

  if (resp.status !== 201) {
    throw new Error(await resp.text());
  }

  return resp.ok;
};

export const deleteComment = async ({ accessToken }, { commentId }) => {
  const resp = await fetch(`${apiUrl}/comment/${commentId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (resp.status !== 200) {
    throw new Error(await resp.text());
  }

  return resp.ok;
};

export const updateComment = async ({ accessToken }, { commentId, commentText }) => {
  const body = {
    comment: commentText,
  };
  const resp = await fetch(`${apiUrl}/comment/${commentId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  });

  if (resp.status !== 200) {
    throw new Error(await resp.text());
  }

  return resp.ok;
};
