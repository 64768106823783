/**
 * Replaces special characters in a string with their escaped equivalents.
 *
 * @param {string} str - The input string to process.
 * @return {string} The processed string with escaped special characters.
 */
export const replaceRegexSpecialChars = (str) => str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
/**
 * Replaces every alphabetical character in the input string with a case-insensitive regex pattern.
 * e.g. "abC" -> "[aA][bB][cC]"
 *
 * @param {string} str - The input string to be transformed.
 * @return {string} The transformed string with case-insensitive regex patterns
 * for each alphabetical character.
 */
export const transFormInsensitiveRegex = (str) => str.replace(/[a-zA-Z]/g, (l) => `[${l.toUpperCase()}${l.toLowerCase()}]`);

// eslint-disable-next-line max-len
export const toCaseInsensitiveExpr = (str) => str && transFormInsensitiveRegex(replaceRegexSpecialChars(str));
/**
 * Returns a regular expression for a case-insensitive prefix match based on the input string.
 *
 * @param {string} str - The input string to be transformed.
 * @return {string} The case-insensitive prefix expression.
 */
export const toCaseInsensitivePrefixExpr = (str) => str && `${toCaseInsensitiveExpr(str)}.*`;

export const filteredCaseInsensitiveTermsAgg = ({
  dataField,
  filterQuery,
  aggFilterValue,
  aggSize = 10,
  sortBy = '_count',
  sortOrder = 'desc',
}) => {
  const aggName = `${dataField}_filtered`;
  const terms = {
    field: dataField,
    size: aggSize,
  };
  if (sortBy && sortOrder) {
    terms.order = { [sortBy]: sortOrder };
  }
  if (aggFilterValue) {
    terms.include = toCaseInsensitivePrefixExpr(aggFilterValue);
  }
  const agg = {
    [aggName]: {
      filter: filterQuery ?? { ...filterQuery },
      aggs: {
        [dataField]: {
          terms,
        },
      },
    },
  };
  return agg;
};
