import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { StyledToggleIconRight } from './StyledToggleIcon';

export const LazyPanel = (props: React.PropsWithChildren<any>): JSX.Element => {
  const { title, collapsed = false, rendered = true, children } = props;
  const [open, setOpen] = useState<boolean>(!collapsed);

  if (!rendered) return null;

  return (
    <>
      <div
        role="button"
        tabIndex={-1}
        onClick={() => setOpen((open) => !open)}
        onKeyUp={() => setOpen((open) => !open)}
        className="d-flex align-items-center fw-bold"
        aria-expanded={open}
      >
        <StyledToggleIconRight open={open} />
        &nbsp;
        {title}
      </div>
      <Collapse in={open} unmountOnExit appear>
        <div>{children}</div>
      </Collapse>
    </>
  );
};

export default LazyPanel;
