import { useCallback, useEffect, useState } from 'react';

const useContextMenu = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const showMenu = useCallback((event: MouseEvent, items: any) => {
    event.preventDefault();
    setMenuPosition({ x: event.pageX, y: event.pageY });
    setMenuItems(items);
    setMenuVisible(true);
  }, []);

  const hideMenu = useCallback(() => {
    setMenuVisible(false);
  }, []);

  useEffect(() => {
    const handleDocumentDblClick = (event: MouseEvent) => {
      hideMenu();
    };
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') hideMenu();
    };

    document.addEventListener('dblclick', handleDocumentDblClick);
    document.addEventListener('keyup', handleEscape);
    return () => {
      document.removeEventListener('dblclick', handleDocumentDblClick);
      document.removeEventListener('keyup', handleEscape);
    };
  }, [hideMenu]);

  return { menuVisible, menuItems, menuPosition, showMenu, hideMenu };
};

export default useContextMenu;
