import {
  split, replace, capitalize, isArray, join, toLower, 
} from 'lodash-es';

export function formatLabel(label) {
  function capitalizeWord(word) {
    return (
      word === 'AND'
      || word === 'AIR'
      || word === 'SUN'
      || word.match(/^(\w+[-]\w+|\w+\d{2,}|\w{1,3})$/) === null
    );
  }

  return split(replace(label, ':', ' / '), ' ')
    .map((word) => (capitalizeWord(word) ? capitalize(word) : word))
    .join(' ');
}

export function formatComponent(component) {
  return capitalize(join(split(toLower(component), ':'), ' / '));
}

export function joinValue(value, separator = ',') {
  return isArray(value) ? join(value, separator) : value;
}

export const capitalCase = (str) => str && str.charAt(0).toUpperCase() + str.slice(1);
