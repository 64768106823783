import { Accordion } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledAccordion = styled(Accordion)`
  .accordion-button {
    font-size: inherit;
    padding: 0.5rem 1rem;
  }
  .accordion-body {
    padding: 0.5rem 1rem;
  }
`;
