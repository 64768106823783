/* eslint-disable no-unused-expressions */
import { func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ChatSquareDots, ChatSquareDotsFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAccessToken } from '../../auth/Auth0';
import { useSocket, WebSocketContext } from '../../websocket';

const Comments = ({ entityRef, onClick }) => {
  const [comments, setComments] = useState([]);
  const { t } = useTranslation('comments');
  const { socket, subscribeEvent } = useSocket();

  useEffect(() => {
    const unsubscribe = [
      subscribeEvent('get-comments-by-entity', (value) => {
        if (value?._id === entityRef) {
          if (!value.error) {
            setComments(value.comments);
          }
        }
      }),
    ];
    socket.then((s) => s?.emit('get-comments-by-entity', { _id: entityRef, entityref: entityRef }));
    return () => {
      unsubscribe.forEach((fn) => fn());
    };
  }, [entityRef, socket, subscribeEvent]);

  const Icon = comments?.length ? ChatSquareDotsFill : ChatSquareDots;

  return (
    <Icon
      role={onClick ? 'menuitem' : ''}
      size={16}
      title={`${t('Comments')} (${
        comments.length ? `${comments.length}+` : 0
      })`}
      onClick={onClick}
    />
  );
};

Comments.propTypes = {
  entityRef: string.isRequired,
  onClick: func,
};

const StyledComments = styled(Comments)``;
export default (props) => {
  const accessToken = useAccessToken();
  return (
    <WebSocketContext namespace="comments" jwtToken={accessToken}>
      <StyledComments {...props} />
    </WebSocketContext>
  );
};
