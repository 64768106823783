import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Col,
  Container,
  Image,
  Nav,
  NavDropdown,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useAppState } from './AppStateProvider';
import { NavigationItem } from './NavigationItem';
import { useUserProfile } from './auth/Auth0';
import UserProfile from './auth/UserProfile';
import { renderDev } from './components/Develop';
import ModalDialog from './components/ModalDialog';
import IndexStatsModal from './components/System/IndexMonitor';
import IndexStatusModal from './components/System/IndexStatus';
import { isSmallScreen } from './isSmallScreen';
import { useViewport } from './react/ViewPort';
import { useTranslation } from './utils/Localization';

export function filterAvatar(picture) {
  return picture
    === 'https://s.gravatar.com/avatar/894742d2e7f19d143d373548ceffcc3c?s=480&r=pg&d=https%3A%2F%2Fplexalytics.com%2Fwp-content%2Fuploads%2F2017%2F10%2FIcon_Plexalytics-weisserHintergrund-e1508841861611.png'
    ? null
    : picture;
}

export function UserMenu({ variant = '' }) {
  const auth0 = useAuth0();
  const i18n = useTranslation();
  const userProfile = useUserProfile();
  const {
    showMessage, showWarning, showError, showSuccess, 
  } = useAppState();
  const { width } = useViewport();

  const smallScreen = isSmallScreen(width);
  const {
    email, name, nickname, picture, user_metadata, 
  } = userProfile;
  const avatar = filterAvatar(user_metadata?.picture || picture);
  const displayname = name || nickname;

  const avatarTag = avatar ? (
    <div style={{ width: '2.5rem', height: '2.5rem' }}>
      <Image src={avatar} alt={displayname} roundedCircle fluid />
    </div>
  ) : (
    <i className="fs-2 text-primary bi-person-fill" />
  );

  if (smallScreen) {
    return (
      <Nav className="justify-content-end">
        <hr />
        <NavigationItem
          label={i18n('FAQ')}
          pathname="/v3/faq"
          target="_blank"
        />

        <hr />
        <IndexStatusModal
          title={i18n('Index Status')}
          trigger={<Nav.Link>{i18n('Index Status')}</Nav.Link>}
        />

        {/* <IndexStatsModal
          title={i18n('Index Statistics')}
          trigger={<Nav.Link>{i18n('Index Statistics')}</Nav.Link>}
        />
 */}
        <hr />
        {renderDev(
          <ModalDialog
            title={i18n('User Profile')}
            triggerElement={<Nav.Link>{i18n('User Profile')}</Nav.Link>}
          >
            <UserProfile />
          </ModalDialog>,
        )}
        <Nav.Link
          onClick={(e) => {
            e.preventDefault();
            auth0.logout({
              returnTo: `${window.location.origin}/logout`,
            });
          }}
        >
          {i18n('Logout')}
        </Nav.Link>
      </Nav>
    );
  }

  UserMenu.propTypes = {
    variant: PropTypes.string,
  };

  return (
    <Nav className="justify-content-end">
      {avatarTag}
      <NavDropdown align="end" menuVariant={variant}>
        <NavDropdown.Header>
          <Container>
            <Row className="flex-nowrap">
              <Col>{avatarTag}</Col>
              <Col>
                <b>{name || nickname}</b>
                {email !== name && (
                  <>
                    <br />
                    {email}
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </NavDropdown.Header>
        <NavDropdown.Divider />
        <small>
          <NavDropdown.Item as="div">
            <NavLink to="/v3/faq" target="_blank">
              {i18n('FAQ')}
            </NavLink>
          </NavDropdown.Item>

          <IndexStatusModal
            title={i18n('Index Status')}
            trigger={
              <NavDropdown.Item>{i18n('Index Status')}</NavDropdown.Item>
            }
          />

          {/* <IndexStatsModal
            title={i18n('Index Statistics')}
            trigger={
              <NavDropdown.Item>{i18n('Index Statistics')}</NavDropdown.Item>
            }
          /> */}

          {process.env.NODE_ENV === 'development' && (
            <>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={(e) => {
                  e.preventDefault();
                  showMessage('Message');
                }}
              >
                Message Test
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={(e) => {
                  e.preventDefault();
                  showWarning('Warning');
                }}
              >
                Warning Test
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={(e) => {
                  e.preventDefault();
                  showError('Error');
                }}
              >
                Error Test
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={(e) => {
                  e.preventDefault();
                  showSuccess('Success');
                }}
              >
                Success Test
              </NavDropdown.Item>
            </>
          )}
          <NavDropdown.Divider />
          <NavDropdown.ItemText className="text-end">
            <OverlayTrigger
              placement="left"
              overlay={(
                <Popover>
                  <Popover.Header>Version Info</Popover.Header>
                  <Popover.Body>
                    <div>
                      <b>Version: </b>
                      {process.env.VERSION}
                    </div>
                    <div>
                      <b>Build date: </b>
                      {process.env.BUILD_DATE}
                    </div>
                  </Popover.Body>
                </Popover>
              )}
            >
              <i className="bi-info-circle" />
            </OverlayTrigger>
          </NavDropdown.ItemText>
          <NavDropdown.Divider />
          {renderDev(
            <ModalDialog
              title={i18n('User Profile')}
              triggerElement={
                <NavDropdown.Item>{i18n('User Profile')}</NavDropdown.Item>
              }
            >
              <UserProfile />
            </ModalDialog>,
          )}
          <NavDropdown.Item
            onClick={(e) => {
              e.preventDefault();
              auth0.logout({
                logoutParams: {
                  returnTo: `${window.location.origin}/logout`,
                },
              });
            }}
          >
            {i18n('Logout')}
          </NavDropdown.Item>
        </small>
      </NavDropdown>
    </Nav>
  );
}
