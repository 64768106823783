/* eslint-disable no-unused-expressions */
import { string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAccessToken } from '../../auth/Auth0';
import { msgSuccess } from '../../events/appmessages';
import { useSocket, WebSocketContext } from '../../websocket';
import { renderError } from '../ErrorBoundary';
import { AddComment } from './AddComment';
import { Comment } from './Comment';
import { ReplyComment } from './ReplyComment';

const CommentList = ({ entityRef }) => {
  const [comments, setComments] = useState([]);
  const [error, setError] = useState();
  const { t } = useTranslation('comments');
  const { /* connected,  */socket, subscribeEvent } = useSocket();

  useEffect(() => {
    const unsubscribe = [
      subscribeEvent('get-comments-by-entity', (value) => {
        if (value?._id === entityRef) {
          if (value.error) {
            setError(value.error);
          } else {
            setComments(value.comments);
          }
        }
      }),
      subscribeEvent('create-comment', (value) => {
        if (value?._id === entityRef && value.error) {
          setError(value.error);
        }
        msgSuccess(t('Comment added'));
      }),
      subscribeEvent('delete-comment', (value) => {
        if (value?._id === entityRef && value.error) {
          setError(value.error);
        }
        msgSuccess(t('Comment deleted'));
      }),
    ];
    socket.then((s) => s?.emit('get-comments-by-entity', {
      _id: entityRef,
      entityref: entityRef,
    }));
    return () => {
      unsubscribe.forEach((fn) => fn());
    };
  }, [entityRef, socket, subscribeEvent, t]);

  /* useEffect(() => {
    if (connected) {
      socket.then((s) => s.emit('get-comments-by-entity', {
        _id: entityRef,
        entityref: entityRef,
      }));
    }
  }, [connected, entityRef, socket]); */

  const addComment = (comment) => {
    socket.then((s) => s?.emit('create-comment', {
      _id: entityRef,
      entityref: entityRef,
      ...comment,
    }));
  };

  const deleteComment = (commentId) => {
    // eslint-disable-next-line no-alert
    if (window.confirm(t('Delete comment?'))) {
      socket.then((s) => {
        s?.emit('delete-comment', {
          _id: entityRef,
          entityref: entityRef, // reference for update comments
          id: commentId,
        });
      });
    }
  };

  if (error) return renderError(error);

  return (
    <div className="comment-list">
      {comments.length === 0 && <div>{t('No comments')}</div>}

      <ListGroup>
        {comments.map((comment) => (
          <ListGroup.Item key={comment.id}>
            <Comment
              comment={comment}
              handleDeleteComment={deleteComment}
              replyComponent={(
                <ReplyComment
                  parentId={String(comment.id)}
                  onClick={addComment}
                />
              )}
            />
          </ListGroup.Item>
        ))}
      </ListGroup>

      <div className="my-2">
        <AddComment onClick={addComment} />
      </div>
    </div>
  );
};

CommentList.propTypes = {
  entityRef: string.isRequired,
};

export default (props) => {
  const accessToken = useAccessToken();
  return (
    <WebSocketContext namespace="comments" jwtToken={accessToken}>
      <CommentList {...props} />
    </WebSocketContext>
  );
};
