import { ReactiveList } from '@appbaseio/reactivesearch';
import React, { useEffect, useState } from 'react';
import { FormSelect } from 'react-bootstrap';
import styled from 'styled-components';
import { useLocalState } from '../react/Hooks';
import { ReactiveSearch } from '../reactivesearch/types';
import { useTranslation } from '../utils/Localization';
import { defaultHighlight } from './Search/queries';

interface ISortOption extends ReactiveSearch.sortOption {
  key: string;
}

const defaultSortOptions: ISortOption[] = [
  {
    label: 'Relevance',
    dataField: '_score',
    sortBy: 'desc',
    key: 'score#desc'
  },
  {
    label: 'Newest',
    dataField: 'publication_date',
    sortBy: 'desc',
    key: 'date#desc'
  },
  {
    label: 'Oldest',
    dataField: 'publication_date',
    sortBy: 'asc',
    key: 'date#asc'
  }
];

export const transformFilters = (args: object) =>
  args instanceof Object
    ? Object.fromEntries(
        Object.entries(args)
          .filter(([, value]) => value)
          .map(([key, value]) => [key, value?.value])
      )
    : {};

const StyledReactiveList = styled(ReactiveList)`
  [class$='pagination'] {
    a[class*='-Button'] {
      min-height: auto;
      padding: 0.1em 0.5em;
      font-size: 0.8rem;
    }
  }
`;

const StyledFormSelect = styled(FormSelect).attrs((props) => ({
  className: `w-10 flex-shrink-1 w-auto mx-2 ${props.className || ''}`
}))`
  line-height: 1rem;
  font-size: 0.75rem;
`;

export const NoResults = () => {
  const i18n = useTranslation();
  return <div className="text-center">{i18n('No results found')}</div>;
};

interface ISearchResultListProps
  extends React.ComponentProps<typeof ReactiveList> {
  exportElement?: React.ReactElement;
  statsElement?: React.ReactElement | (() => React.ReactElement);
  sortOptions?: ISortOption[]; // extends ReactiveList sortOptions type
}

function SearchResultList(props: ISearchResultListProps): JSX.Element {
  const {
    componentId,
    exportElement,
    statsElement,
    sortOptions = defaultSortOptions,
    defaultQuery,
    ...rest
  } = props;
  const [persistentSortOption, setPersistentSortOption] = useLocalState(
    `${componentId}-sort`,
    defaultSortOptions[0]
  );
  const [sortOption, setSortOption] = useState<ISortOption>(
    sortOptions.find((o) => o.key === persistentSortOption?.key) ||
      defaultSortOptions[0]
  );
  const i18n = useTranslation();
  const defaultSortOption = sortOptions.length > 1 && {
    [sortOptions[1].dataField]: { order: sortOptions[1].sortBy }
  };
  const sort = [
    {
      [sortOption.dataField]: { order: sortOption.sortBy }
    },
    { ...defaultSortOption } // default sort
  ];

  const handleSortOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const so = sortOptions.find((o: ISortOption) => o.key === e.target.value);
    setSortOption(so);
  };

  useEffect(() => {
    setPersistentSortOption(sortOption);
  }, [sortOption, setPersistentSortOption]);

  const toolbar = (
    <div className="d-flex justify-content-end mb-2 align-items-center">
      <div className="flex-grow-1 d-flex align-items-center mx-2">
        {typeof statsElement === 'function' ? statsElement() : statsElement}
      </div>
      {exportElement}
      {sortOptions.length > 1 && (
        <StyledFormSelect
          onChange={handleSortOptionChange}
          size="sm"
          value={sortOption.key}
        >
          {sortOptions.map((option) => (
            <option key={option.key} value={option.key}>
              {i18n(option.label)}
            </option>
          ))}
        </StyledFormSelect>
      )}
    </div>
  );

  const defaultQueryTransformed = {
    ...{
      track_total_hits: true,
      timeout: '500ms',
      sort
    },
    highlight: defaultHighlight(),
    ...(defaultQuery ? defaultQuery() : {})
  };

  return (
    <>
      {toolbar}
      <StyledReactiveList
        componentId={componentId}
        className="searchresultlist"
        defaultQuery={() => defaultQueryTransformed}
        paginationAt="both"
        scrollOnChange={false}
        renderNoResults={NoResults}
        {...rest}
      />
    </>
  );
}

export default SearchResultList;
