import React from 'react';
import { useTranslation } from '../../utils/Localization';
import { ILabeledTextWithContext } from './rli.interfaces';

export const MetaData = ({
  label,
  value,
  context,
  children
}: ILabeledTextWithContext): JSX.Element => {
  const i18n = useTranslation(context);
  if (!children && !value) return null;
  return (
    <div className="d-flex flex-wrap me-4">
      {label && <span className="fw-bold me-2">{`${i18n(label)}:`}</span>}
      {value && (
        <span
          dangerouslySetInnerHTML={{
            __html: Array.isArray(value) ? [].join.call(value, ', ') : value
          }}
        />
      )}
      {typeof children !== 'function' ? children : children()}
    </div>
  );
};

export default MetaData;
