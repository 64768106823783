import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from '../../utils/Localization';
import { useConfig } from '../../Config';
import {
  heatmapChartsQuery,
  toHeatmapSeries,
  heatmapOptions,
} from './HeatmapChartsUtils';

const StyledRow = ({ children }) => (
  <Row className="justify-content-md-center" style={{}}>
    {children}
  </Row>
);

const StyledColumn = ({ children, classNameAdd }) => (
  <Col className={`${classNameAdd} border p-2`}>{children}</Col>
);

const ComponentHeatmap = (props) => {
  const i18n = useTranslation();
  const { data } = props.series;
  const options = { ...heatmapOptions };
  options.colors = props.series.colors;
  if (data.length === 0) {
    return (
      <div
        style={{
          textAlign: 'center',
        }}
      >
        {i18n('No incidents found')}
      </div>
    );
  }
  return (
    <ReactApexChart
      options={options}
      series={[{ data }]}
      type="treemap"
      height={600}
    />
  );
};

export default function HeatmapCharts({ react }) {
  const config = useConfig();
  const i18n = useTranslation();
  const prefix = `cw3_cst_${config.customer.name}`;

  return (
    <div>
      <ReactiveComponent
        componentId="heatmap_charts"
        defaultQuery={() => heatmapChartsQuery(prefix)}
        react={react}
        render={(args) => {
          if (!args.aggregations) return null;
          const heatMapRecallsData = toHeatmapSeries(
            args.aggregations,
            'recalls-etc',
          );
          const heatMapComplaintsData = toHeatmapSeries(
            args.aggregations,
            'complaints',
          );
          const heatMapMentionsData = toHeatmapSeries(
            args.aggregations,
            'mentions',
          );
          return (
            <Container fluid className="p-3">
              <StyledRow>
                <StyledColumn classNameAdd="col-4">
                  <h6 style={{ textAlign: 'center' }}>
                    <b>
                      <span
                        style={{
                          color: '#ff4040',
                          marginLeft: '5px',
                          marginRight: '5px',
                        }}
                      >
                        &#9679;
                      </span>
                      {i18n('Recalls')}
                      {' '}
                      |
                      <span
                        style={{
                          color: '#808080',
                          marginLeft: '5px',
                          marginRight: '5px',
                        }}
                      >
                        &#9679;
                      </span>
                      {i18n('Investigations')}
                      {' '}
                      |
                      <span
                        style={{
                          color: '#468499',
                          marginLeft: '5px',
                          marginRight: '5px',
                        }}
                      >
                        &#9679;
                      </span>
                      {i18n('Bulletins')}
                    </b>
                  </h6>
                  <ComponentHeatmap series={heatMapRecallsData} />
                </StyledColumn>
                <StyledColumn classNameAdd="col-4">
                  <h6 style={{ textAlign: 'center' }}>
                    <b>
                      <span
                        style={{
                          color: '#ffa32d',
                          marginLeft: '5px',
                          marginRight: '5px',
                        }}
                      >
                        &#9679;
                      </span>
                      {i18n('Complaints')}
                    </b>
                  </h6>
                  <ComponentHeatmap series={heatMapComplaintsData} />
                </StyledColumn>
                <StyledColumn classNameAdd="col-4">
                  <h6 style={{ textAlign: 'center' }}>
                    <b>
                      <span
                        style={{
                          color: '#1db899',
                          marginLeft: '5px',
                          marginRight: '5px',
                        }}
                      >
                        &#9679;
                      </span>
                      {i18n('Mentions')}
                    </b>
                  </h6>
                  <ComponentHeatmap series={heatMapMentionsData} />
                </StyledColumn>
              </StyledRow>
            </Container>
          );
        }}
      />
    </div>
  );
}
