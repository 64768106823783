import React from 'react';

export interface IAppConfig {
  customer_name: string;
  instance_name: string;
  connection: string;
  api_url: string;
}

export interface IConfig {
  customer: {
    name: string;
  };
  instanceName: string;
  connection: string;
  getIndexName: (index: string, prefix?: string) => string;
}

export const appConfig: IAppConfig = process.env
  .appconfig as unknown as IAppConfig;

const instanceName =
  appConfig.instance_name || appConfig?.customer_name || 'demo';

const Configuration: IConfig = Object.freeze({
  customer: {
    name: instanceName
  },
  instanceName,
  connection: appConfig.connection,
  getIndexName(index: string, prefix = 'cw_cst_') {
    return `${prefix}${this.customer.name}_${index}`;
  }
});

export const ConfigContext = React.createContext(Configuration);
export const useConfig = () => React.useContext(ConfigContext);
export const withConfig = (Component: any) => (props: any) =>
  <Component config={Configuration} {...props} />;
