import { useAccessToken } from '@/auth';
import { appConfig } from '@/Config';
import React, { useEffect, useMemo, useState } from 'react';

import io, { Socket } from 'socket.io-client';

// const SOCKET_URL = '/';

interface ISocketContext {
  socket: Promise<Socket>;
  connected: boolean;
  subscribeEvent: (event: any, handler: any) => () => unknown;
}

const SocketContext = React.createContext<ISocketContext>(undefined);

const wsInstances = new Map();

function getWsInstance(
  namespace: string | undefined,
  jwtToken: string,
  cb?: any
): Socket {
  if (!wsInstances.has(namespace)) {
    const socket = io(`/${namespace}`, {
      query: { instance: appConfig.instance_name },
      transports: ['websocket'],
      path: '/gw',
      auth: { token: jwtToken }
    });
    socket.on('connect', () => {
      cb && cb(socket);
    });
    wsInstances.set(namespace, socket);
  }

  const socket = wsInstances.get(namespace);
  cb && cb(socket);
  return socket;
}

export const WebSocketContext = ({
  namespace,
  children
}: {
  namespace?: string;
  children?: React.ReactNode;
}) => {
  const [socket, setSocket] = useState<Socket>();
  const [connected, setConnected] = useState(false);
  const jwtToken = useAccessToken();

  useEffect(() => {
    if (jwtToken) {
      const ws = getWsInstance(namespace, jwtToken, (s: Socket) =>
        setSocket(s)
      );
      const fn = () => setConnected(ws.connected); // connection state listener
      ws.on('connect', fn);
      ws.on('disconnect', fn);
      return () => {
        ws.off('connect', fn);
        ws.off('disconnect', fn);
      };
    }
    return () => {};
  }, [jwtToken, namespace]);

  const value = useMemo<ISocketContext>(
    () => ({
      socket: Promise.resolve<Socket>(socket), // return promise for further socket impl
      connected,
      subscribeEvent: (event: any, handler: any) => {
        socket && socket.on(event, handler);
        return () => {
          socket && socket.off(event, handler);
        };
      }
    }),
    [connected, socket]
  );

  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  );
};

export const useSocket = () => React.useContext(SocketContext);
