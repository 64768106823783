import { groupBy, map, uniqueId } from 'lodash-es';
import React, { ComponentProps } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import ErrorBoundary from '../ErrorBoundary';
import { IAttachedDocument } from './rli.interfaces';

export const AttachmentsWrapper = (props: ComponentProps<any>) => (
  <div className="px-4">
    <ErrorBoundary>
      <ResultListAttachments {...props} />
    </ErrorBoundary>
  </div>
);

const ResultListAttachment = ({
  attachment,
  highlights = []
}: {
  attachment: IAttachedDocument;
  highlights: string[];
}): React.ReactElement => {
  const { filename, download } = attachment;
  return (
    <div key={filename}>
      <div>
        <a href={download} target="_blank" rel="noreferrer">
          {filename}
        </a>
      </div>
      {highlights.length !== 0 && (
        <blockquote className="small">
          {highlights.map((hlText) => (
            <div
              key={uniqueId('hl')}
              dangerouslySetInnerHTML={{ __html: hlText }}
            />
          ))}
        </blockquote>
      )}
    </div>
  );
};

export default function ResultListAttachments({
  attachments,
  attachmentDocs
}: {
  attachments: IAttachedDocument[];
  attachmentDocs: any[];
}) {
  const grouped = groupBy(attachments, (thehit) => thehit.description);
  return (
    <ListGroup>
      {map(grouped, (groupHits, groupName) => {
        const att = map(groupHits, (doc: IAttachedDocument) => {
          const hl =
            attachmentDocs &&
            [...attachmentDocs].find(
              (h) => h._source.filename === doc.filename
            );
          const highlights = hl?.highlight?.['documents.content'];
          return (
            <ResultListAttachment
              key={doc.filename}
              attachment={doc}
              highlights={highlights}
            />
          );
        });

        return (
          <ListGroupItem key={uniqueId('rl_attachment')}>
            {groupName && groupName !== 'undefined' && (
              <div>
                <strong>{groupName}</strong>
              </div>
            )}
            <div>{att}</div>
          </ListGroupItem>
        );
      })}
    </ListGroup>
  );
}

ResultListAttachments.Wrapper = AttachmentsWrapper;
