import { uniqueId } from 'lodash-es';
import PropTypes from 'prop-types';
import React, {
  createContext,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import styled from 'styled-components';
import MessageBus, { MessageTypes } from './events/appmessages';

const StyledToastContainer = styled(ToastContainer)`
  padding: 4rem 1rem 1rem 1rem;
  z-index: 2000;

  .messages_header {
    font-size: 0.8rem;
  }

  .messages_body {
    font-size: 0.8rem;
  }
`;

const Messages = forwardRef((props, ref) => {
  const [messages, setMessages] = useState([]);

  useImperativeHandle(ref, () => ({
    addMessage(message, title = 'Message') {
      setMessages([
        ...messages,
        {
          type: 'message',
          variant: 'message',
          title,
          message,
        },
      ]);
    },
    addWarning(message, title = 'Warning') {
      setMessages([
        ...messages,
        {
          type: 'message',
          variant: 'warning',
          title,
          message,
        },
      ]);
    },
    addError(message, title = 'Error') {
      setMessages([
        ...messages,
        {
          type: 'error',
          variant: 'danger',
          title,
          message,
        },
      ]);
    },
    addSuccess(message, title = 'Success') {
      setMessages([
        ...messages,
        {
          type: 'success',
          variant: 'success',
          title,
          message,
        },
      ]);
    },
  }));

  const deleteMessage = (m) => {
    setMessages(messages.filter((v) => v !== m));
  };

  return (
    <StyledToastContainer position="top-end">
      {messages.map((m) => (
        <Toast
          key={uniqueId('toast')}
          bg={m.variant}
          onClose={() => deleteMessage(m)}
          delay={5000}
          autohide={m.type !== 'error'}
        >
          <Toast.Header className="messages_header">
            <strong className="me-auto">{m.title}</strong>
          </Toast.Header>
          <Toast.Body className="messages_body">{m.message}</Toast.Body>
        </Toast>
      ))}
    </StyledToastContainer>
  );
});

export const AppStateContext = createContext();

export const AppStateProvider = ({ children }) => {
  // do not use any states here. all child components will be reredered on state change.

  const messages = useRef();

  /* proxy functions */
  function showMessage(...args) {
    if (messages.current) messages.current.addMessage(args);
  }

  function showWarning(...args) {
    if (messages.current) messages.current.addWarning(args);
  }

  function showError(...args) {
    if (messages.current) messages.current.addError(args);
  }

  function showSuccess(...args) {
    if (messages.current) messages.current.addSuccess(args);
  }

  useEffect(() => {
    const {
      MESSAGE, ERROR, WARNING, SUCCESS, 
    } = MessageTypes;
    const unsubscribe = [
      MessageBus.subscribe(MESSAGE, showMessage),
      MessageBus.subscribe(SUCCESS, showSuccess),
      MessageBus.subscribe(WARNING, showWarning),
      MessageBus.subscribe(ERROR, showError),
    ];
    return () => {
      unsubscribe.forEach((u) => u());
    };
  }, []);

  const context = useMemo(
    () => ({
      showError,
      showMessage,
      showSuccess,
      showWarning,
    }),
    [],
  );

  return (
    <AppStateContext.Provider value={context}>
      <Messages ref={messages} />
      {children}
    </AppStateContext.Provider>
  );
};

AppStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAppState = () => React.useContext(AppStateContext);
export const withAppState = (Component) => (props) => {
  const appState = React.useContext(AppStateContext);
  return <Component appState={appState} {...props} />;
};

export default AppStateProvider;
