/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { confirmable, createConfirmation } from 'react-confirm';

const Confirmation = (props: {
  show: boolean;
  proceed: Function;
  title: string;
  confirmation: React.ReactNode;
  okLabel?: string;
  cancelLabel?: string;
}): JSX.Element => (
  <div className="static-modal">
    <Modal
      animation={false}
      show={props.show}
      onHide={() => props.proceed(false)}
      backdrop
    >
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.confirmation}</Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.proceed(false)}>
          {props.cancelLabel || 'cancel'}
        </Button>
        <Button className="button-l" onClick={() => props.proceed(true)}>
          {props.okLabel || 'ok'}
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
);

export const Confirmable = confirmable(Confirmation);
export const showConfirm = createConfirmation(Confirmable);
