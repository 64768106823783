import PropTypes, { func, string } from 'prop-types';
import React, { useEffect } from 'react';
import { Accordion, Button, ListGroup } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { useAccessToken } from '../../auth/Auth0';
import { useTranslation } from '../../utils/Localization';
import { useSocket, WebSocketContext } from '../../websocket';
import { showConfirm } from '../Confirm';
import { StyledAccordion } from './StyledAccordion';

const RemoveFavorite = (props) => {
  const { title, name, onClick } = props;
  const i18n = useTranslation('favorites');
  async function removeFavorite() {
    if (
      await showConfirm({
        title,
        confirmation: (
          <>
            <p className="text-danger">{i18n('confirm-delete')}</p>
            <span>{name}</span>
          </>
        ),
      })
    ) {
      onClick();
    }
  }
  return (
    <Button
      as="a"
      size="xs"
      type="button"
      variant="link"
      title={title}
      onClick={() => removeFavorite()}
    >
      <Trash size="1rem" />
    </Button>
  );
};

RemoveFavorite.propTypes = {
  name: string.isRequired,
  title: string.isRequired,
  onClick: func.isRequired,
};

const SidebarFavorites = (props) => {
  // const { favorites } = useFavorites();
  const { socket, subscribeEvent } = useSocket();
  const [favorites, setFavorites] = React.useState([]);
  const [error, setError] = React.useState(false);
  const i18n = useTranslation('favorites');

  useEffect(() => {
    const unsubscribeGetUserFavorites = subscribeEvent(
      'user-favorites',
      (value) => {
        if (value.error) {
          setError(value.error);
        } else {
          setFavorites(value.favorites);
        }
      },
    );
    const unsubscribeDeleteUserFavorite = subscribeEvent(
      'delete-user-favorite',
      (value) => {
        if (value.error) {
          setError(value.error);
        }
      },
    );
    socket.then((s) => s?.emit('user-favorites'));
    return () => {
      unsubscribeGetUserFavorites();
      unsubscribeDeleteUserFavorite();
    };
  }, [socket, subscribeEvent]);

  function removeFavorite(entityref) {
    return () => {
      socket.then((s) => s.emit('delete-user-favorite', { entityref }));
    };
  }

  const sides = favorites?.reduce(
    (acc, favorite) => {
      const category = Object.keys(acc).find((key) => favorite.entityref.includes(`/${key.toLowerCase()}`)) || 'Others';
      acc[category].push(favorite);
      return acc;
    },
    {
      Recalls: [],
      Complaints: [],
      Investigations: [],
      Bulletins: [],
      Mentions: [],
      Others: [],
    },
  );

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  const { onLinkClick } = props;
  return (
    <StyledAccordion flush>
      {Object.keys(sides)
        .filter((s) => sides[s].length > 0)
        .map((k) => (
          <Accordion.Item key={k} eventKey={k}>
            <Accordion.Header as="div">
              {`${i18n(k)} (${sides[k].length})`}
            </Accordion.Header>
            <Accordion.Body>
              <ListGroup variant="flush">
                {sides[k].map((f) => (
                  <ListGroup.Item key={f.entityref} className="d-flex">
                    <Link
                      onClick={onLinkClick}
                      to={f.entityref}
                      style={{ flex: 1 }}
                      reloadDocument
                    >
                      {f.name}
                    </Link>
                    {/*  <RemoveFavorite
                      name={f.name}
                      title={i18n('Remove Favorite')}
                      entityRef={f.entityref}
                      className="mx-1"
                    /> */}
                    <RemoveFavorite
                      name={f.name}
                      title={i18n('Remove Favorite')}
                      entityRef={f.entityref}
                      className="mx-1"
                      onClick={removeFavorite(f.entityref)}
                    />
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
        ))}
    </StyledAccordion>
  );
};

SidebarFavorites.propTypes = {
  onLinkClick: PropTypes.func.isRequired,
};

export default (props) => {
  const accessToken = useAccessToken();
  return (
    <WebSocketContext namespace="favorites" jwtToken={accessToken}>
      <SidebarFavorites {...props} />
    </WebSocketContext>
  );
};
