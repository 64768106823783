import { join, sortBy } from 'lodash-es';

export const joinAndSort = (value, separator) => (typeof value === 'string' ? value : join(sortBy(value), separator));

export function findLabelInKVMap(array, key) {
  const mappingObj = array.find((obj) => obj.key === key);
  if (mappingObj == null) {
    return `WRONG KEY: ${key}`;
  } 
  return mappingObj.value;
}
