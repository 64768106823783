import { node } from 'prop-types';
import React from 'react';

export const viewportContext = React.createContext({});

export const ViewportProvider = ({ children }) => {
  const [viewPort, setViewPort] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleWindowResize = () => {
    setViewPort({ width: window.innerWidth, height: window.innerHeight });
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={viewPort}>
      {children}
    </viewportContext.Provider>
  );
};

ViewportProvider.propTypes = {
  children: node.isRequired,
};

export const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};
