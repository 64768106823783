/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';

const defaultOptions = {
  visualMap: {
    left: 'right',
    min: 0,
    max: 1000000,
    inRange: {
      color: [
        '#313695',
        '#4575b4',
        '#74add1',
        '#abd9e9',
        '#e0f3f8',
        '#ffffbf',
        '#fee090',
        '#fdae61',
        '#f46d43',
        '#d73027',
        '#a50026',
      ],
    },
    text: ['High', 'Low'],
    // calculable: true,
  },
  tooltip: {
    trigger: 'item',
    showDelay: 0,
    transitionDuration: 0.2,
  },
  series: [
    {
      name: 'USA Issues',
      type: 'map',
      roam: true,
      map: 'usa', // name of the map
      emphasis: {
        label: {
          show: true,
        },
      },
      data: [],
    },
  ],
};

const GeoMapUSA = ({ data, style, className = '' }) => {
  const [statesMapping, setStatesMapping] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    import(/* webpackChunkName: "geo_usa" */ './usa.json')
      .then((json) => {
        echarts.registerMap(
          'usa', // name of the map, see default options
          json,
          // move distant states
          {
            Alaska: {
              left: -131,
              top: 25,
              width: 15,
            },
            Hawaii: {
              left: -110,
              top: 18,
              width: 5,
            },
            'Puerto Rico': {
              left: -76,
              top: 26,
              width: 2,
            },
          },
        );
      })
      .then(() => import('./us_states_mapping.json'))
      .then((mapping) => setStatesMapping(mapping))
      .catch((err) => setError(err));
  }, []);

  if (error) return <div>{error.message}</div>;
  if (!statesMapping) return null;

  const options = { ...defaultOptions };
  let max = 0;
  // map aggregation data to series format
  // get max value for color schema
  options.series[0].data = data.map((d) => {
    max = Math.max(max, d.value);
    return {
      ...d,
      ...{ name: statesMapping[d.name], key: d.name },
    };
  });
  options.visualMap.max = max;

  return statesMapping ? (
    <ReactEChartsCore
      echarts={echarts}
      option={options}
      className={className}
      style={{ ...{ height: '100%', width: '100%' }, ...style }}
    />
  ) : null;
};

export default GeoMapUSA;
