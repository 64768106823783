import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAccessToken, useUserProfile } from './auth/Auth0';
import { useConfig } from './Config';

export default (): JSX.Element => {
  const { pathname, search, hash } = useLocation();
  const currentRoute = useRef<Partial<Location>>({});
  const profile = useUserProfile();
  const config = useConfig();
  const accessToken = useAccessToken();

  useEffect(() => {
    // runs on location, i.e. route, change
    (async () => {
      if (!profile) return;
      if (
        currentRoute.current.pathname === pathname
        && currentRoute.current.search === search
        && currentRoute.current.hash === hash
      ) {
        return;
      }
      currentRoute.current = { pathname, search, hash };
      const { email, user_id } = profile;
      const userData = {
        ...currentRoute.current,
        email,
        user_id,
        event: 'routeChange',
      };

      if (accessToken) {
        const requestInfo = {
          method: 'POST',
          body: JSON.stringify(userData),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        };

        // fire and forget
        fetch(`${config.connection}_logging`, requestInfo).catch((err) => {
          process.env.NODE_ENV === 'development' && console.error(err);
        });
      }
    })();
  }, [hash, pathname, search, accessToken, config.connection, profile]);

  return null;
};
