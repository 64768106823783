import React from 'react';
import { IHeaderProps } from './rli.interfaces';

export default function Header({
  title,
  optionElement = <span />
}: IHeaderProps): JSX.Element {
  return (
    <div className="row">
      <div className="col text-truncate" style={{ width: 0 }} title={title}>
        <span>{title}</span>
      </div>
      <div className="col-auto">{optionElement}</div>
    </div>
  );
}
