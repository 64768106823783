import { useAuth0 } from '@auth0/auth0-react';
import { useAsyncState } from '../react/Hooks';
import { useEffect } from 'react';

export default function useIdToken() {
  const { getIdTokenClaims } = useAuth0();
  const [idToken, setIdToken] = useAsyncState(null);
  useEffect(() => {
    async function getIdToken() {
      // eslint-disable-next-line no-underscore-dangle
      setIdToken((await getIdTokenClaims()).__raw);
    }
    getIdToken();
  });
  return idToken;
}
