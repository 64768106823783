/* eslint-disable react/jsx-props-no-spreading */
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NoAccess from '../pages/NoAccess';
import { useIsPathAllowed } from './Auth0';
import Auth0LoginTrigger from './Auth0LoginTrigger';

/**
 * Renders the appropriate component/element based on the user's authentication status
 * and the allowed path.
 *
 * @param {Object} props - The props object containing component, element, children, and ```rest```.
 * ```rest``` will be forwarded to the component/children.
 * @return {JSX.Element}
 * The component to be rendered based on the user's authentication status and the allowed path
 */
const PrivateRoute = (props: {
  component?: React.ComponentType<any>;
  element?: React.ReactElement;
  children?: React.ReactElement | ((...args: any) => React.ReactElement);
}): JSX.Element => {
  const { component: Component, element: Element, children, ...rest } = props;
  const { isAuthenticated } = useAuth0();
  const { pathname } = useLocation();
  const isPathAllowed = useIsPathAllowed(pathname);

  if (isPathAllowed) {
    if (Component) return <Component {...rest} />;
    if (Element) {
      return Element;
    }
    if (children)
      return typeof children === 'function' ? children({ ...rest }) : children;
    return <Outlet />;
  }
  if (isAuthenticated) {
    return <NoAccess />;
  }
  return <Auth0LoginTrigger />;
};

export default PrivateRoute;
