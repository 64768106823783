import { arrayOf, element, oneOfType } from 'prop-types';

export default function Dev({ children }) {
  return process.env.NODE_ENV === 'development' && children;
}

Dev.propTypes = {
  children: oneOfType([arrayOf(element), element]).isRequired,
};

/**
 * Render given component in development mode.
 * @param {*} Component
 * @returns render result
 */
export function renderDev(Component) {
  return process.env.NODE_ENV === 'development' ? Component : null;
}
