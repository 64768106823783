import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { string } from 'prop-types';
import React from 'react';
import assert from '../../utils/assert';
import { isDevelopment } from '../Develop';
import { ReactiveComponentProps } from '@appbaseio/reactivesearch/lib/components/basic/ReactiveComponent';

export const MaxDateEqualsFilter = (
  props: { dateField: string } & ReactiveComponentProps
): JSX.Element => {
  const { dateField, value } = props;
  return (
    <ReactiveComponent
      {...props}
      customQuery={() => ({
        query: {
          range: {
            [dateField]: {
              lte: value
            }
          }
        }
      })}
    />
  );
};

export const MaxTodayFilter = (
  props: { dateField: string } & ReactiveComponentProps
) => <MaxDateEqualsFilter {...props} value="now/d" />;
