import React from 'react';
import IFrame from '../../components/IFrame';
import { getLanguage } from '../../utils/Localization';

async function exists(url) {
  const result = await fetch(url, { method: 'HEAD' });
  return result.ok;
}
const Faq = () => {
  let lang = getLanguage();
  if (lang !== 'de') lang = 'en';
  const source = `/static/faq/faq.${lang}.html`;
  return <IFrame title="Faq" source={source} />;
};

export default Faq;
