import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Link45deg } from 'react-bootstrap-icons';
import { MetaData } from '.';

export const ExternalReference = ({ hit }) => {
  const { official_id, external_ref, external_reference } = hit;
  const formRef = React.useRef();
  const officialId = useMemo(() => {
    if (external_ref) {
      return (
        <a
          href={external_ref}
          aria-label="External Reference"
          target="_blank"
          rel="noreferrer"
          className="d-inline-block ms-1"
        >
          {official_id || <Link45deg />}
        </a>
      );
    }
    if (external_reference?.url) {
      return (
        <form
          ref={formRef}
          action={external_reference.url}
          target="_blank"
          method={external_reference.request_method}
        >
          <span
            role="link"
            tabIndex={-1}
            onKeyUp={() => formRef.current.submit()}
            onClick={(e) => {
              formRef.current.submit();
              e.preventDefault();
            }}
          >
            {official_id}
          </span>
        </form>
      );
    }
    return official_id;
  }, [official_id, external_ref, external_reference]);

  return <MetaData label="Official ID">{officialId}</MetaData>;
};
ExternalReference.propTypes = {
  hit: PropTypes.object.isRequired,
};
