import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

import { useLocalState } from '../react/Hooks';
import { StyledToggleIconLeft } from './StyledToggleIcon';

export interface ITogglePanelProps {
  initialOpen?: boolean;
  label?: string;
  labelClass?: string;
  collapseProps?: object;
  children?: React.ReactNode;
}
export function SimpleTogglePanel({
  initialOpen = true,
  label,
  labelClass,
  collapseProps = {},
  children
}: ITogglePanelProps): JSX.Element {
  const [open, setOpen] = useState(initialOpen);
  return (
    <>
      <div className="d-flex my-2 border-bottom">
        <div className={`${labelClass || 'w-100 fw-bold'}`}>{label || ''}</div>
        <Button
          variant="icon"
          onClick={() => setOpen(!open)}
          aria-expanded={open}
        >
          <StyledToggleIconLeft open={open} />
        </Button>
      </div>
      <Collapse in={open} {...collapseProps}>
        <div>{children}</div>
      </Collapse>
    </>
  );
}

export function StatefulTogglePanel({
  initialOpen = true,
  label,
  labelClass,
  collapseProps = {},
  children
}: ITogglePanelProps): JSX.Element {
  const [open, setOpen] = useLocalState(`stp-${label}-open`, initialOpen);
  return (
    <>
      <div className="d-flex my-2 border-bottom">
        <div className={`${labelClass || 'w-100 fw-bold'}`}>{label || ''}</div>
        <Button
          variant="icon"
          onClick={() => setOpen(!open)}
          aria-expanded={open}
        >
          <StyledToggleIconLeft open={open} />
        </Button>
      </div>
      <Collapse in={open} {...collapseProps}>
        <div>{children}</div>
      </Collapse>
    </>
  );
}

export default function TogglePanel(
  props: { stateful?: boolean } & ITogglePanelProps
): JSX.Element {
  const { stateful, ...rest } = props;
  return stateful ? (
    <StatefulTogglePanel {...rest} />
  ) : (
    <SimpleTogglePanel {...rest} />
  );
}
