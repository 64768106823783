const defaultConfig = {
  onError: (...args: any) => console.error(...args)
};

export interface IEventBus {
  subscribe: (key: string | Symbol, handler: Function) => () => void;
  unsubscribe: (key: string | Symbol, handler: Function) => void;
  once: (key: string | Symbol, handler: Function) => void;
  emit: (key: string | Symbol, payload: any) => void;
}

const EventBus = (name: string, config = defaultConfig): IEventBus => {
  if (!config?.onError) {
    throw Error(`eventbus "${name}": onError is required in config object`);
  }

  const bus = new Map<string | Symbol, Set<Function>>();

  const subscribe = (key: string | Symbol, handler: Function) => {
    const handlers = bus.get(key);
    if (handlers) {
      handlers.add(handler);
    } else {
      bus.set(key, new Set([handler]));
    }

    // return unsubscribe function
    return () => {
      unsubscribe(key, handler);
    };
  };

  const emit = (key: string | Symbol, payload: any) => {
    const handlers = bus.get(key);
    if (handlers) {
      for (const handler of handlers) {
        try {
          handler(payload);
        } catch (e) {
          config?.onError(e);
        }
      }
    } else if (process.env.NODE_ENV !== 'production') {
      console.warn(`eventbus "${name}": no handlers found for key:`, key);
    }
  };

  const unsubscribe = (key: string | Symbol, handler: Function) => {
    const handlers = bus.get(key);
    if (handlers) {
      handlers.delete(handler);
      if (handlers.size === 0) {
        bus.delete(key);
      }
    }
  };

  const once = (key: string | Symbol, handler: Function) => {
    const handleOnce = (payload: any) => {
      handler(payload);
      unsubscribe(key, handleOnce);
    };
    subscribe(key, handleOnce);
  };

  return {
    emit,
    subscribe,
    unsubscribe,
    once
  };
};

const busses = new Map();

export const getEventBus = (context: string) => {
  if (!busses.has(context)) {
    busses.set(context, EventBus(context));
  }
  return busses.get(context);
};

EventBus.get = getEventBus;

export default EventBus;
