import React, { useCallback, useEffect, useState } from 'react';
import { useAccessToken, useIdToken } from '../../../auth/Auth0';
import { useAsyncError } from '../../ErrorBoundary';
import { useAsyncState } from '../../../react/Hooks';
import { API_ENDPOINT } from './Api';

const Definitions = () => {};

/* export const useAsyncCall = (asyncFunction, immediate = false) => {
  const [value, setValue] = useState();
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState();

  const execute = useCallback(() => {
    setBusy(true);
    setValue(null);
    setError(null);

    return asyncFunction()
      .then((response) => {
        setValue(response);
        setBusy(false);
      })
      .catch((err) => {
        setError(err);
        setBusy(false);
      });
  }, [asyncFunction]);

  useEffect(() => {
    if (immediate) execute();
  }, [execute, immediate]);

  return {
    value,
    busy,
    error,
    execute,
  };
}; */

const QUALITY_GOOD = 10;
const QUALITY_NEUTRAL = 0;
const QUALITY_BAD = -10;

const defaultDefinitions = [
  { value: 'mechanic', quality: QUALITY_GOOD },
  { value: 'driver', quality: QUALITY_GOOD },
  { value: 'bimmer', quality: QUALITY_GOOD },
  { value: 'representative', quality: QUALITY_NEUTRAL },
  { value: 'gasbag', quality: QUALITY_NEUTRAL },
  { value: 'spammer', quality: QUALITY_BAD },
  { value: 'hater', quality: QUALITY_BAD },
  { value: 'cheater', quality: QUALITY_BAD },
];

export const fetchDefinitions = async (accessToken, idToken) => {
  const response = await fetch(`${API_ENDPOINT}/definitions`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      id_token: idToken,
    },
  });
  const json = await response.json();
  return json;
};

export const useDefinitions = () => {
  const [def, setDef] = useAsyncState();
  const [error, setError] = useAsyncState();
  const accessToken = useAccessToken();
  const idToken = useIdToken();

  React.useEffect(() => {
    if (accessToken) {
      fetchDefinitions(accessToken, idToken)
        .then((definitions) => {
          setDef(definitions);
        })
        .catch((err) => {
          setError(err);
        });
    }
  }, [accessToken, idToken, setDef, setError]);

  return { definitions: def /* || defaultDefinitions */, error };
};

export default Definitions;
