import React, {
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState
} from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { useConfig } from '../../Config';
import DebouncedInput from '../DebouncedInput';
import { SearchInputFieldProps } from './types';
import { useValidateQuery } from './useValidateQuery';

const StyledInputControl = styled(DebouncedInput).attrs((props) => ({
  className: `${props.className} ${
    !props.$valid ? props.$invalidClassName : ''
  }`
}))``;

function SearchInputField({
  value = '',
  acceptOnEnter = true,
  onAccept,
  innerClass = {},
  placeholder,
  onError = () => {},
  onLoading = () => {}
}: SearchInputFieldProps): JSX.Element {
  const [inputValue, setInputValue] = useState<string>(value);
  const config = useConfig();
  const lastKey = useRef<string>('');
  const { valid, loading, error } = useValidateQuery(
    config.connection,
    inputValue
  );

  /* apply given input value */
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  /* handle input keyup */
  const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = (e) => {
    lastKey.current = e.key;
    if (acceptOnEnter && e.key === 'Enter') {
      valid && onAccept(inputValue);
    }
  };

  /* provide errors */
  useEffect(() => {
    if (error) {
      onError(error);
    }
  }, [error, onError]);

  /* provide loading state */
  useEffect(() => {
    onLoading(loading);
  }, [loading, onLoading]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (lastKey.current === 'Enter') {
      valid && onAccept(inputValue);
    }
    if (!acceptOnEnter) {
      valid && onAccept(inputValue);
    }
  }, [loading, valid]);

  return (
    <>
      <StyledInputControl
        value={inputValue}
        onChange={setInputValue}
        className={innerClass.input}
        onKeyUp={handleKeyUp}
        placeholder={placeholder}
        spellCheck="false"
        type="text"
        size="sm"
        inputComponent={Form.Control}
        $valid={valid !== false || inputValue.length === 0}
        $invalidClassName={innerClass.invalidQueryClass}
      />
    </>
  );
}

export default SearchInputField;
