import { PropTypes } from 'prop-types';
import React from 'react';

import Content from './Content';
import Refinements from './Refinements';

function Page(props) {
  const { children } = props;
  return (
    <div className="overflow-hidden">
      <div className="container-fluid d-flex pb-6">{children}</div>
    </div>
  );
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Object.assign(Page, { Refinements, Content });
