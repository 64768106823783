/* eslint-disable react/no-danger */
import { DateRange } from '@appbaseio/reactivesearch';
import styled from 'styled-components';

export { default as CWSelectedFilters } from './CWSelectedFilters';
export { default as DateHistogramRefinement } from './DateHistogramRefinement';
export * from './DefaultRefinements';
export { default as FilterValue } from './FilterValue';
export { default as InputFieldRefinement } from './InputFieldRefinement';
export { default as MultiListRefinement } from './MultiListRefinement';
export { default as OfficialId } from './OfficialId';
export { default as RefineId } from './RefineId';
export { default as DefinedFilters } from './DefinedFilters';
export { default as CustomerRefinements } from './CustomerRefinements';
export { default as TreeRefinement } from './TreeRefinement';
export { default as InputRefinement } from './InputRefinement';
export { default as SavedFilters } from './SavedFilters';
export { default as SaveFilters } from './SaveFilters';

export const DateRangeSC = styled(DateRange)`
  & > div > div {
    border-color: transparent transparent lightgray transparent;
  }
`;
