import React from 'react';
import { useTranslation } from '../../utils/Localization';
import MultiListRefinement, {
  FilterableMultiListRefinement,
} from './MultiListRefinement';

export const DataSourceRefinement = (props) => {
  const i18n = useTranslation();
  const i18nDS = useTranslation('datasources');
  return (
    <MultiListRefinement
      componentId="refine_datasource"
      dataField="datasource.keyword"
      title={i18n('Data Source')}
      filterLabel={i18n('Data Source')}
      translateFunc={i18nDS}
      URLParams
      {...props}
    />
  );
};

export const MakeRefinement = (props) => {
  const i18n = useTranslation();
  return (
    <FilterableMultiListRefinement
      componentId="refine_make"
      filterField="facets.make"
      dataField="facets.make.keyword"
      title={i18n('Make')}
      filterLabel={i18n('Make')}
      URLParams
      {...props}
    />
  );
};

export const ModelRefinement = (props) => {
  const i18n = useTranslation();
  return (
    <FilterableMultiListRefinement
      componentId="refine_model"
      filterField="facets.model"
      dataField="facets.model.keyword"
      title={i18n('Model')}
      filterLabel={i18n('Model')}
      URLParams
      {...props}
    />
  );
};

export const ComponentRefinement = (props) => {
  const i18n = useTranslation();
  return (
    <FilterableMultiListRefinement
      componentId="refine_component"
      filterField="facets.component"
      dataField="facets.component.keyword"
      title={i18n('Component')}
      filterLabel={i18n('Component')}
      URLParams
      {...props}
    />
  );
};
