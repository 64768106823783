import React from 'react';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import Card from 'react-bootstrap/Card';
import PieChartComponent from './PieChartComponent';
import { DefaultRenderWrapper } from '../../reactivesearch/ReactComponentRenderWrapper';
import * as t from '../../utils/Localization';

export default function BasicPieChartComponent({
  componentId, react = {}, label, field, 
}) {
  if (!field) {
    throw Error('missing field property!');
  }
  if (!componentId) {
    throw Error('missing component id!');
  }
  return (
    <ReactiveComponent
      componentId={componentId}
      defaultQuery={() => ({
        aggs: {
          data: {
            terms: {
              field: field,
              size: 10,
            },
          },
        },
        size: 0,
      })}
      react={react}
      render={(props) => {
        // eslint-disable-next-line react/prop-types
        const { setQuery, ...rest } = props;
        return (
          <Card>
            {label && (
              <Card.Header>
                <div className="fw-bold">{label}</div>
              </Card.Header>
            )}
            <Card.Body>
              <DefaultRenderWrapper
                {...props}
                loader={<div>{t.t('Loading chart...')}</div>}
                element={(
                  <PieChartComponent
                    onClick={(value) => {
                      setQuery({
                        query: { term: { [field]: value } },
                        value,
                      });
                    }}
                    {...rest}
                  />
                )}
              />
            </Card.Body>
          </Card>
        );
      }}
      URLParams
      showFilter
    />
  );
}
