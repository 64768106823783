import React from 'react';
import { string } from 'prop-types';
import * as t from '../../utils/Localization';

export default function LoaderText(props: { text?: string }): JSX.Element {
  const { text } = props;
  return (
    <span className="loaderText">{text || t.t('Fetching results...')}</span>
  );
}

LoaderText.propTypes = {
  text: string,
};
