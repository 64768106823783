import deepmerge from 'deepmerge';

export const chartOptions = {
  chart: {
    stacked: true,
    height: 380,
    width: '100%',
    type: 'bar',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  series: [],
  xaxis: {
    type: 'category',
  },
  yaxis: {
    type: 'numeric',
  },
};

export const chartTheme = {
  theme: {
    palette: 'palette1',
    mode: 'dark',
    animations: {
      enabled: false,
    },
    fontFamily: 'Open Sans, Helvetica, Arial, sans-serif',
  },
  chart: {
    fontFamily: 'Open Sans, Helvetica, Arial, sans-serif',
  },
  xaxis: {
    labels: {
      style: {
        fontSize: '1em',
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        fontSize: '1em',
      },
    },
  },
  noData: {
    text: 'No Data',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: '1em',
    },
  },
};

const merge = (...args) => {
  const combineMerge = (target, source, options) => {
    const destination = target.slice();

    source.forEach((item, index) => {
      if (typeof destination[index] === 'undefined') {
        destination[index] = options.cloneUnlessOtherwiseSpecified(
          item,
          options,
        );
      } else if (options.isMergeableObject(item)) {
        destination[index] = merge(target[index], item, options);
      } else if (target.indexOf(item) === -1) {
        destination.push(item);
      }
    });
    return destination;
  };
  let merged = {};
  for (let i = 0; i < args.length; i += 1) {
    const obj = args[i];
    merged = deepmerge(merged, obj, { arrayMerge: combineMerge });
  }
  return merged;
};

export function buildDataSourceStatsOptions(customOptions = {}) {
  return merge(chartOptions, chartTheme, customOptions);
}

export function buildDataSourceSeries(agg, dateHistogramAgg) {
  if (!agg || !dateHistogramAgg) return [];
  const series = [].map.call(agg.buckets, (b) => ({
    name: b.key_as_string || b.key,
    data: [].map.call(b[dateHistogramAgg]?.buckets, (d) => ({
      x: d.key_as_string || d.key,
      y: d.doc_count,
    })),
  }));
  return series;
}
