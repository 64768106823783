import { useAppState } from '@/AppStateProvider';
import { SearchResponse, SearchTotalHits } from '@/elasticsearch/types';
import { pick } from 'lodash-es';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import {
  Button,
  ButtonGroup,
  Form,
  InputGroup,
  Modal,
  Table
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FacetSearchInput from './FacetSearchInput';
import { useSearchCasesApi } from './SearchCases.api';
import { ISearchCase } from './SearchCases.types';

const daterangemap = {
  none: '',
  'last week': 'now/w-1w',
  'last month': 'now/M-1M',
  'last year': 'now/y-1y'
};

const SearchCasesDialog = forwardRef(({}, ref) => {
  const { showError, showSuccess } = useAppState();
  const [searchCaseId, setSearchCaseId] = useState<string>(null);
  const [show, setShow] = useState(false);
  const [editable, setEditable] = useState(false);
  const [testQueryResult, setTestQueryResult] = useState<SearchResponse>(null);

  useImperativeHandle(ref, () => ({
    open: (id: ISearchCase['id'], editable: boolean = false) => {
      setSearchCaseId(id);
      setEditable(editable);
    },
    close: () => setSearchCaseId(null)
  }));

  const api = useSearchCasesApi();

  const emptySearchCase = useMemo<ISearchCase>(
    () => ({
      name: '',
      context: [api.context],
      make: [],
      model: [],
      component: [],
      daterange: undefined,
      fulltext: ''
    }),
    [api.context]
  );

  const [searchCase, setSearchCase] = useState<ISearchCase>(emptySearchCase);

  const facets = useMemo(
    () => pick(searchCase, ['make', 'model', 'component']),
    [searchCase]
  );

  useEffect(() => {
    if (searchCaseId === '') {
      setSearchCase(emptySearchCase);
      setShow(true);
      return;
    }
    if (api.available && searchCaseId) {
      api
        .getSearchCase(searchCaseId)
        .then((sc) => {
          setSearchCase({ ...emptySearchCase, ...sc });
          setShow(true);
        })
        .catch((e) => {
          if (e instanceof Response) {
            showError(
              `Error while loading search case ${searchCaseId}: ${e.statusText}`
            );
          } else {
            showError(`Error while loading search case ${searchCaseId}`);
          }
          setSearchCaseId(null);
          setSearchCase(emptySearchCase);
        });
    }
  }, [searchCaseId, api]);

  const handleSave = useCallback(() => {
    api.available &&
      api
        .saveSearchCase(searchCase)
        .then(() => {
          setShow(false);
          setSearchCaseId(null);
          showSuccess(t('SearchCase saved'));
          api.eventBus.emit('update-searchcases', {});
        })
        .catch((e) => {
          if (e instanceof Response) {
            showError(
              `Error while saving search case ${searchCaseId}: ${e.statusText}`
            );
          } else {
            showError(`Error while saving search case ${searchCaseId}`);
          }
        });
  }, [api, searchCase]);

  const handleDelete = useCallback(() => {
    api.available &&
      confirm(`Delete search case "${searchCase.name}"?`) &&
      api
        .deleteSearchCase(searchCaseId)
        .then(() => {
          setShow(false);
          setSearchCaseId(null);
          showSuccess(t('SearchCase deleted'));
          api.eventBus.emit('update-searchcases', {});
        })
        .catch((e) => {
          if (e instanceof Response) {
            showError(
              `Error while deleting search case ${searchCaseId}: ${e.statusText}`
            );
          } else {
            showError(`Error while deleting search case ${searchCaseId}`);
          }
        });
  }, [api, searchCase]);

  const handleRunQuery = useCallback(() => {
    api.available &&
      api
        .testQuery(searchCase)
        .then((resp) => {
          setTestQueryResult(resp);
        })
        .catch((e) => {
          console.error(e);
        });
  }, [api, searchCase]);

  const handlePropChange = (
    prop: keyof ISearchCase,
    value: string | string[]
  ) => setSearchCase((f) => ({ ...f, [prop]: value }));

  const { t } = useTranslation('searchcases-form');

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        setSearchCaseId(null);
        setTestQueryResult(null);
      }}
      centered
      keyboard
      animation={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <InputGroup size="sm">
          <InputGroup.Text className="text-nowrap">
            {t('SearchCase Name')}:
          </InputGroup.Text>
          <Form.Control
            type="text"
            placeholder={t('SearchCase Name')}
            value={searchCase.name}
            onChange={(e) => handlePropChange('name', e.target.value)}
            disabled={!editable}
          />
        </InputGroup>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <fieldset disabled={!editable}>
              <legend>{t('Date Range')}</legend>
              {Object.entries(daterangemap).map(([key, value]: any, i) => (
                <Form.Check
                  key={i}
                  as="input"
                  inline
                  type="radio"
                  name="daterange"
                  value={value}
                  label={t(key)}
                  checked={searchCase.daterange === value}
                  onChange={(e) =>
                    handlePropChange('daterange', e.target.value)
                  }
                />
              ))}
            </fieldset>
          </Form.Group>
          <Form.Group className="mb-3">
            <fieldset disabled={!editable}>
              <legend>{t('Facets')}</legend>
              <Table>
                <tbody>
                  {Object.entries(facets).map(
                    ([facet, values]: [
                      facet: keyof ISearchCase,
                      values: string[]
                    ]) => (
                      <tr key={facet}>
                        <th>{t(facet)}</th>
                        <td>
                          <FacetSearchInput
                            index={api.suggestionIndex}
                            dataField={`facets.${facet}.keyword`}
                            values={values as string[]}
                            onChange={(v) => handlePropChange(facet, v)}
                            editable={editable}
                          />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </fieldset>
          </Form.Group>
          <Form.Group className="mb-3">
            <fieldset disabled={!editable}>
              <legend>{t('Full Text Search')}</legend>
              <Form.Control
                as="textarea"
                size="sm"
                rows={2}
                placeholder={t('Full Text Search')}
                value={searchCase.fulltext}
                onChange={(e) => handlePropChange('fulltext', e.target.value)}
                disabled={!editable}
              />
            </fieldset>
          </Form.Group>
          {editable && (
            <Form.Group className="mb-3">
              <fieldset>
                <legend>{t('Test Query')}</legend>
                {testQueryResult && (
                  <div>
                    {`${t('Hit count')}: ${
                      (testQueryResult?.hits.total as SearchTotalHits)
                        .relation === 'eq'
                        ? ''
                        : '>'
                    }
                  ${(testQueryResult?.hits.total as SearchTotalHits).value}`}
                  </div>
                )}
                <Button
                  type="button"
                  size="sm"
                  variant="secondary"
                  onClick={handleRunQuery}
                >
                  {t('Run Query')}
                </Button>
              </fieldset>
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup size="sm">
          {editable && (
            <Button type="button" onClick={() => handleSave()}>
              {t('Save')}
            </Button>
          )}
          <Button
            type="button"
            variant="secondary"
            onClick={() => {
              setShow(false);
              setSearchCaseId(null);
              setTestQueryResult(null);
            }}
          >
            {t('Cancel')}
          </Button>
        </ButtonGroup>
        <Button type="button" size="sm" variant="danger" onClick={handleDelete}>
          {t('Delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default SearchCasesDialog;
