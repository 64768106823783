/* eslint-disable import/prefer-default-export */
import { StateProvider } from '@appbaseio/reactivesearch';
import millify from 'millify';
import React from 'react';
import { useTranslation } from '../../utils/Localization';
import CsvExport from '../Exporter/CsvExport';

export const ExportResults = (props: { filters?: object; type?: string }) => {
  const { filters = {}, type } = props;
  return <CsvExport type={type} filters={filters} />;
};

export const ResultStats = (props: {
  limit?: number;
  targetComponentId?: string;
}) => {
  const i18n = useTranslation('reactivesearch');
  const { limit = Number.MAX_VALUE } = props;
  const { targetComponentId = 'resultlist' } = props;
  return (
    <StateProvider
      componentIds={[targetComponentId]}
      includeKeys={['isLoading', 'error', 'resultStats']}
    >
      {({ searchState }) => {
        const resultListState = searchState?.[targetComponentId];
        if (!resultListState) return null;
        const { resultStats: stats, isLoading, error } = resultListState;
        if (isLoading || error) {
          return null;
        }
        const numberOfResults = stats?.numberOfResults;
        if (!numberOfResults) return null;
        stats.showMore = '';
        stats.numberOfResults =
          numberOfResults >= limit
            ? `&ge; ${millify(numberOfResults, { precision: 3 })}`
            : '';
        const msg = i18n('renderStats', {
          ...stats,
          ...{ numberOfResults: millify(numberOfResults, { precision: 1 }) }
        });
        return (
          <div
            className="fst-italic fs-7"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: msg }}
          />
        );
      }}
    </StateProvider>
  );
};
