import loadable from '@loadable/component';
import React from 'react';
import Loading from '../components/Loading';
import { NoAccess } from '../pages';
import { TExtendedRouteObject } from '../types';

const loadPage = (importFunc: any) =>
  loadable(importFunc, {
    fallback: <Loading />
  });

export type CustomerRoutes = {
  [key: string]: TExtendedRouteObject[];
};

export const customerRoutes: CustomerRoutes = {
  archimedes: [
    {
      menucategory: 'others',
      label: 'NHTSA Components',
      path: '/archimedes/componentstree',
      Component: loadPage(() =>
        import(
          /* webpackChunkName: "archimedes.components" */ '../pages/archimedes/ComponentsTree'
        ).catch(() => ({ default: NoAccess }))
      )
    }
  ],
  demo: [
    {
      menucategory: 'others',
      label: 'Crashes',
      path: '/demo/crashes',
      Component: loadPage(() =>
        import(
          /* webpackChunkName: "demo.crashviewer" */ '../pages/CrashViewer'
        ).catch(() => ({ default: NoAccess }))
      )
    }
  ],
  borgwarner: [
    {
      menucategory: 'others',
      label: 'Claims',
      path: '/claims',
      Component: loadPage(() =>
        import(
          /* webpackChunkName: "borgwarner.claims" */ '../pages/borgwarner/Claims'
        ).catch(() => ({
          default: NoAccess
        }))
      )
    },
    {
      menucategory: 'others',
      label: 'Lessons Learnt',
      path: '/v3/lessonslearnt',
      Component: loadPage(() =>
        import(
          /* webpackChunkName: "borgwarner.lessonslearnt" */ '../pages/borgwarner/LessonsLearnt'
        ).catch(() => ({
          default: NoAccess
        }))
      )
    }
  ],
  yanfeng: [
    {
      menucategory: 'others',
      label: 'Defects',
      path: '/yanfeng/demo/defects',
      Component: loadPage(() =>
        import(
          /* webpackChunkName: "yanfeng.defects" */ '../pages/yanfeng/DefectsFieldData'
        ).catch(() => ({
          default: NoAccess
        }))
      )
    }
  ]
};
