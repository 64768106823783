import { StateProvider } from '@appbaseio/reactivesearch';
import styled from 'styled-components';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Badge, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const StyledBadge = styled(Badge)`
  color: white;
  font-size: 0.8rem;
  font-weight: normal;
  letter-spacing: 0.5px;
`;

export function mapActiveFilters(searchState) {
  const af = {};
  Object.entries(searchState)
    .filter(([, value]) => value?.value !== undefined && value?.value !== null)
    .forEach(([key, value]) => Object.assign(af, { [key]: value?.value }));
  return af;
}

const ActiveFilters = forwardRef((props, ref) => {
  const { t } = useTranslation('refinements');
  const state = useRef([]);
  useImperativeHandle(
    ref,
    () => ({
      getValue() {
        return state?.current;
      },
    }),
    [],
  );
  return (
    <StateProvider includeKeys={['isLoading', 'error', 'value']}>
      {({ searchState }) => {
        const activeFilters = mapActiveFilters(searchState);
        state.current = activeFilters;
        return (
          <Stack gap={1}>
            {Object.entries(activeFilters).map(([label, value]) => (
              <div key={label}>
                <strong>{t(label)}</strong>
                &nbsp;
                {Array.isArray(value) ? (
                  [].map.call(value, (v) => (
                    <StyledBadge key={v} bg="primary" className="ms-1 mb-1">
                      {v}
                    </StyledBadge>
                  ))
                ) : (
                  <StyledBadge bg="primary" className="ms-1 mb-1">
                    {value}
                  </StyledBadge>
                )}
              </div>
            ))}
          </Stack>
        );
      }}
    </StateProvider>
  );
});
const SelectedRefinements = forwardRef((props, ref) => {
  const activeFilters = useRef([]);
  useImperativeHandle(
    ref,
    () => ({
      activeRefinements: activeFilters.current.getValue,
    }),
    [],
  );
  return <ActiveFilters ref={activeFilters} />;
});

export default SelectedRefinements;
