import { uniqueId } from 'lodash-es';
import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  useEffect,
  useState
} from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Check } from 'react-bootstrap-icons';

const SuggestInput = ({
  value,
  onChange,
  onAccept,
  suggestions = [],
  acceptOnEnter
}: {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onAccept?: (value: string) => void;
  suggestions: string[];
  acceptOnEnter?: boolean;
}) => {
  const dlId = uniqueId('datalist');
  const [filteredValue, setFilteredValue] = useState([]);

  useEffect(() => {
    setFilteredValue(
      suggestions.filter((v) => v.toLowerCase().startsWith(value.toLowerCase()))
    );
  }, [value]);

  const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      acceptOnEnter && onAccept && onAccept(e.currentTarget.value);
    }
  };

  return (
    <InputGroup size="sm">
      <Form.Control
        type="text"
        value={value}
        onChange={onChange}
        onKeyUp={handleKeyUp}
        list={dlId}
        spellCheck={false}
      />
      <datalist id={dlId}>
        {filteredValue.slice(0, 5).map((v) => (
          <option key={v}>{v}</option>
        ))}
      </datalist>
      <InputGroup.Text
        role="menuitem"
        onClick={() => onAccept && onAccept(value)}
      >
        <Check />
      </InputGroup.Text>
    </InputGroup>
  );
};

export default SuggestInput;
