import React from 'react';
import { useTranslation } from '../../utils/Localization';
import { ILabeledTextWithContext } from './rli.interfaces';

interface IMetaTextProps extends ILabeledTextWithContext {
  children?: React.ReactNode | (() => React.ReactNode);
}

export const MetaText = ({
  label,
  value,
  context,
  children
}: IMetaTextProps): JSX.Element => {
  const i18n = useTranslation(context);
  if (!children && !value) return null;
  return (
    <span className="pe-4">
      {label && (
        <>
          <span className="fw-bold me-2">{`${i18n(label)}:`}</span>
          <br />
        </>
      )}
      {value && (
        <span
          dangerouslySetInnerHTML={{
            __html: Array.isArray(value) ? [].join.call(value, ', ') : value
          }}
        />
      )}
      {typeof children !== 'function' ? children : children()}
    </span>
  );
};

export default MetaText;
