import { useAppState } from '@/AppStateProvider';
import { usePageContext } from '@/context/PageContext';
import { useSocket, withWs } from '@/websocket';
import { StateProvider } from '@appbaseio/reactivesearch';
import React, { useEffect } from 'react';
import { Save } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

const SaveFilters = (props: any) => {
  const { showError, showSuccess } = useAppState();
  const { context } = usePageContext();
  const { socket, subscribeEvent } = useSocket();
  const { t } = useTranslation('saved-refinements');
  const isContextActive = context || props.context;

  useEffect(() => {
    const unsubscribe = subscribeEvent('create', (value: any) => {
      if (value.error) {
        showError(t('Error creating filter'));
      } else {
        showSuccess(t('Filter created'));
      }
    });
    return () => {
      unsubscribe();
    };
  }, [socket, subscribeEvent]);

  const handleSave = (activeFilters: any) => {
    const filterName = prompt(t('input-name'))?.trim();
    if (filterName) {
      const filter = {
        name: filterName,
        context,
        values: activeFilters
      };
      socket.then((s) => s?.emit('create', filter));
    }
  };

  if (!isContextActive) {
    process.env.NODE_ENV === 'development' && console.error('no context set');
    return null;
  }

  return (
    <>
      <StateProvider includeKeys={['isLoading', 'error', 'value']}>
        {({
          searchState
        }: {
          searchState: { [key: string]: Record<string, any> };
        }) => {
          const activeFilters = Object.entries(searchState)
            .filter(
              ([, value]) => value?.value !== undefined && value?.value !== null
            )
            .reduce((acc: any[], [key, value]: any) => {
              const { value: v } = value;
              acc.push({ name: key, value: JSON.stringify(v) });
              return acc;
            }, []);
          return (
            activeFilters.length > 0 && (
              <Save
                title={t('save-refinement')}
                role="menuitem"
                onClick={() => handleSave(activeFilters)}
              />
            )
          );
        }}
      </StateProvider>
    </>
  );
};

export default withWs(SaveFilters, {
  namespace: 'refinements'
});
