import {
  DataSearch,
  DynamicRangeSlider,
  ReactiveList,
  SelectedFilters,
} from '@appbaseio/reactivesearch';
import React from 'react';

import join from 'lodash-es/join';
import sortBy from 'lodash-es/sortBy';
import uniqueId from 'lodash-es/uniqueId';

import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';

import { Page } from '../components/Layout/Page';
import MultiListRefinement from '../components/Refinements/MultiListRefinement';
import * as t from '../utils/Localization';

import { useConfig } from '../Config';
import BasicPieChartComponent from '../components/Charts/BasicPieChartComponent';
import ControlledTabs from '../components/ControlledTabs';
import NoResults from '../components/NoResults';
import { MetaData, MetaText } from '../components/ResultListItem';
import { SimpleTogglePanel } from '../components/SimpleTogglePanel';
import ReactiveApp from '../reactivesearch/ReactiveApp';
import {
  getLabelInjStatus,
  getLabelMAIS,
  getLabelMortality,
} from '../utils/crashviewer/Mappings';

const interactiveComponentIds = [
  'refine_crashYear',
  'refine_manufacturer',
  'refine_make',
  'refine_model',
  'refine_modelYear',
  'refine_death',
  'refine_injstatus',
  'refine_mortality',
  'refine_mais',
  'fulltextsearch',
  'SearchResult',
  'chart_make',
  'chart_model',
];

const ResultListItem = (hit) => {
  const {
    crash__CASENUMBER,
    vinderived__Make,
    crash__CRASHMONTH,
    crash__CRASHYEAR,
    gen_source_url,
    crash__CASEID,
    crash__VEHICLES,
    crash__CRASHTIME,
    crash__DAYOFWEEK,
    crash__SUMMARY,
    gen_vehicles,
  } = hit;
  return (
    <Card key={uniqueId('card-rl')} className="card-rl mb-3">
      <Card.Header>
        {`${crash__CASENUMBER}: ${join(
          sortBy(vinderived__Make),
          ', ',
        )} (${crash__CRASHMONTH}/${crash__CRASHYEAR})`}
        <a
          className="ms-2"
          href={gen_source_url}
          target="_blank"
          rel="noreferrer"
          aria-label="Open Source Link"
        >
          <i className="bi bi-link" />
        </a>
      </Card.Header>
      <Card.Body>
        <div className="d-flex flex-row flex-wrap mb-1">
          <MetaData label="Case ID" value={crash__CASEID} />
          <MetaData label="Vehicles" value={crash__VEHICLES} />
          <MetaData label="Crash time" value={crash__CRASHTIME} />
          <MetaData label="Day of Week" value={crash__DAYOFWEEK} />
          <br />
          <MetaText label="Summary" value={crash__SUMMARY} />
        </div>
        <div className="">
          <div className="fw-bold text-decoration-underline mb-2">Vehicles</div>
          <ol className="list-group list-group-numbered">
            {[]
              .concat(gen_vehicles)
              .sort((a, b) => a.vinderived__VEHNO - b.vinderived__VEHNO)
              .map((v) => (
                <li className="list-group-item d-flex justify-content-between align-items-start">
                  <div className="ms-2 me-auto">
                    <div className="d-flex flex-row flex-wrap">
                      <MetaData label="Brand" value={v.vinderived__Make} />
                      <MetaData label="Model" value={v.vinderived__Model} />
                      <MetaData
                        label="Model Year"
                        value={v.vinderived__ModelYear}
                      />
                      <MetaData
                        label="Vehicle Type"
                        value={v.vinderived__VehicleType}
                      />
                      <MetaData
                        label="Manufacturer"
                        value={v.vinderived__ManufacturerCommonName}
                      />
                    </div>
                    <div className="d-flex flex-column">
                      {[]
                        .concat(v.gen_occupants)
                        .filter((o) => !!o)
                        .sort((a, b) => a.occ__OCCNO - b.occ__OCCNO)
                        .map((o) => (
                          <div>
                            <MetaData label="Occ." value={o.occ__OCCNO} />
                            <MetaData
                              label="Mortality"
                              value={getLabelMortality(o.occ__MORTALITY)}
                            />
                            <MetaData
                              label="Severity"
                              value={getLabelMAIS(o.occ__MAIS)}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </li>
              ))}
          </ol>
        </div>
        <SimpleTogglePanel
          initialOpen={false}
          label="Source"
          labelClass="fw-normal"
        >
          <code>
            <pre>{JSON.stringify(hit, null, 2)}</pre>
          </code>
        </SimpleTogglePanel>
      </Card.Body>
    </Card>
  );
};
export default function CrashViewer() {
  const config = useConfig();
  return (
    <ReactiveApp app="cw_cst_demo_crashviewer" url={config.connection}>
      <Page>
        <Page.Refinements>
          <DynamicRangeSlider
            componentId="refine_crashYear"
            dataField="crash__CRASHYEAR"
            title="Crash Year"
            stepValue={1}
            showHistogram
            showFilter
            interval={1}
            URLParams
            innerClass={{
              title: 'h6 fw-bold',
            }}
            react={{ and: interactiveComponentIds }}
          />
          <MultiListRefinement
            componentId="refine_manufacturer"
            dataField="vinderived__ManufacturerCommonName.keyword"
            title={t.t('Manufacturer')}
            renderNoResults={NoResults}
            react={{ and: interactiveComponentIds }}
          />
          <MultiListRefinement
            componentId="refine_make"
            dataField="vinderived__Make.keyword"
            title={t.t('Make')}
            renderNoResults={NoResults}
            react={{ and: interactiveComponentIds }}
          />
          <MultiListRefinement
            componentId="refine_model"
            dataField="vinderived__Model.keyword"
            title={t.t('Model')}
            renderNoResults={NoResults}
            react={{ and: interactiveComponentIds }}
          />
          <MultiListRefinement
            componentId="refine_modelYear"
            dataField="vinderived__ModelYear"
            title={t.t('Model Year')}
            renderNoResults={NoResults}
            react={{ and: interactiveComponentIds }}
          />
          <MultiListRefinement
            componentId="refine_death"
            dataField="occ__DEATH"
            title={t.t('Deaths')}
            renderNoResults={NoResults}
            react={{ and: interactiveComponentIds }}
          />
          <MultiListRefinement
            componentId="refine_injstatus"
            dataField="occ__INJSTATUS"
            title={t.t('InjStatus')}
            renderNoResults={NoResults}
            react={{ and: interactiveComponentIds }}
            translateFunc={getLabelInjStatus}
          />
          <MultiListRefinement
            componentId="refine_mortality"
            dataField="occ__MORTALITY"
            title={t.t('Mortality')}
            renderNoResults={NoResults}
            react={{ and: interactiveComponentIds }}
            translateFunc={getLabelMortality}
          />
          <MultiListRefinement
            componentId="refine_mais"
            dataField="occ__MAIS"
            title={t.t('Severity')}
            renderNoResults={NoResults}
            react={{ and: interactiveComponentIds }}
            translateFunc={getLabelMAIS}
          />
        </Page.Refinements>
        <Page.Content>
          <DataSearch
            componentId="fulltextsearch"
            dataField={['crash__SUMMARY']}
            highlight
            highlightField={['crash__SUMMARY']}
            className="mb-2"
            URLParams
            debounce={300}
            searchOperators
            react={{ and: interactiveComponentIds }}
          />
          <SelectedFilters className="mb-2" />
          <ControlledTabs param="tab" defaultTab="searchresults">
            <Tab eventKey="globals" title={t.t('Globals')}>
              <div className="container fluid">
                <div className="row">
                  <div className="col">
                    <BasicPieChartComponent
                      componentId="chart_make"
                      react={{ and: interactiveComponentIds }}
                      label={t.t('Make')}
                      field="vinderived__Make.keyword"
                    />
                  </div>
                  <div className="col">
                    <BasicPieChartComponent
                      componentId="chart_model"
                      react={{ and: interactiveComponentIds }}
                      label={t.t('Model')}
                      field="vinderived__Model.keyword"
                    />
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="searchresults" title={t.t('Search Results')}>
              <ReactiveList
                componentId="SearchResult"
                renderItem={ResultListItem}
                react={{ and: interactiveComponentIds }}
              />
            </Tab>
          </ControlledTabs>
        </Page.Content>
      </Page>
    </ReactiveApp>
  );
}
