/* eslint-disable react/no-danger */
import { uniqueId } from 'lodash-es';
import PropTypes, {
  array, number, object, string, 
} from 'prop-types';
import React from 'react';
import { Button, Card, Stack } from 'react-bootstrap';
import styled from 'styled-components';
import { useElastic } from '../../elasticsearch';
import { Source } from '../Develop';
import EllipsisText from '../EllipsisText';
import { LazyPanel } from '../LazyPanel';
import { MetaData, MetaDate, MetaText } from '../ResultListItem';
import { useDefinitions } from './VehicleChain/Definitions';
import PostRating from './VehicleChain/PostRating';
import LoaderText from '../Loader/LoaderText';
import { renderError } from '../ErrorBoundary';

const PostContextEntry = styled.div.attrs((props) => ({
  className: `post-context ${props.$initialPost ? '' : 'ms-2'} ${
    props.className || ''
  }`,
}))`
  .post-context__content {
    font-style: italic;
  }
  .post-context__meta {
    font-size: 0.75rem;
  }
`;

function PostContext(props) {
  const { url = '', index, size = 100 } = props;
  const [urlNoQuery] = url.split('?');
  const { data, error, isLoading } = useElastic(
    index,
    {
      _source: [
        'author',
        'page_url',
        'subject',
        'body',
        'body_no_tags',
        'timestamp',
        'post_type',
      ],
      query: {
        bool: {
          should: [
            { prefix: { 'page_url.keyword': urlNoQuery } },
            { prefix: { page_url: urlNoQuery } },
          ],
        },
      },
      size,
      sort: [{ timestamp: 'asc' }],
    },
    [urlNoQuery, index],
  );

  if (isLoading) {
    return <LoaderText />;
  }

  if (error) {
    return renderError(error);
  }

  const hits = data?.hits?.hits
    .map((h) => ({
      _id: h._id,
      ...h._source,
    }))
    .sort((a) => (a.post_type === 'initial' ? -1 : 1)) || []; // initial post first

  return (
    <Stack gap={3} className="my-2">
      {hits.map((item) => (
        <PostContextEntry
          $initialPost={item.post_type === 'initial'}
          key={item._id}
        >
          <span className="post-context__content">
            <EllipsisText text={item.body_no_tags || item.body} length={1000} />
          </span>
          <Stack gap={1} direction="horizontal" className="post-context__meta">
            <MetaData
              label="Author"
              value={item.author}
              context="rli-socialweb"
            />
            <MetaDate label="Published" value={item.timestamp} />
          </Stack>
        </PostContextEntry>
      ))}
    </Stack>
  );
}

PostContext.propTypes = {
  url: string.isRequired,
  index: string.isRequired,
  size: number,
};

/**
 * Formats a string by replacing newline characters with HTML line breaks.
 *
 * If the input is an array, recursively formats each item and joins them with
 * HTML line breaks.
 *
 * @param {string|string[]} str - The input string or array of strings to format.
 * @return {string} The formatted string with HTML line breaks.
 */
function formatBody(str) {
  if (!str) return str;
  if (Array.isArray(str)) return str.map((s) => formatBody(s)).join('<br />');
  return str.replace(/(?:\r\n|\r|\n|\\n)/g, '<br />');
}

export const MentionsResultListItem = ({ hit, definitions = [] }) => {
  const {
    author,
    page_url,
    subject,
    body,
    body_no_tags,
    forum_path,
    highlight: {
      subject: highlightedSubject,
      body_no_tags: highlightedBodyNoTags,
    } = {},
    timestamp,
    is_new,
  } = hit;
  const site = page_url?.replace(/https?:\/\/([^/]+).*/, '$1');
  const bodyText = formatBody(highlightedBodyNoTags || body_no_tags || body);
  return (
    <Card key={uniqueId('card-rl')} className="card-rl mb-3">
      <Card.Header>
        <span
          dangerouslySetInnerHTML={{ __html: highlightedSubject || subject }}
        />
        {is_new && '*'}
      </Card.Header>
      <Card.Body>
        <div className="d-flex">
          <div className="flex-grow-1">
            <div className="d-flex flex-row flex-wrap">
              <MetaDate label="Published" value={timestamp} />
              <MetaData label="Source">
                <a
                  href={page_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Open link in new tab"
                >
                  {site}
                </a>
              </MetaData>
            </div>
            <div className="d-flex flex-row flex-wrap">
              <MetaData label="Author" value={author} context="rli-socialweb" />
              <MetaData
                label="Breadcrump"
                value={forum_path}
                context="rli-socialweb"
              />
            </div>
            <div className="d-flex flex-row flex-wrap">
              <MetaText label="Content">
                <EllipsisText text={bodyText} length={1000} />
              </MetaText>
            </div>
            {process.env.NODE_ENV === 'development' && (
              <Source sourceObject={hit} />
            )}
          </div>
          {process.env.NODE_ENV === 'development'
            && definitions?.length > 0 && (
              <div className="ms-2" style={{ textAlign: 'right' }}>
                <PostRating
                  definitions={definitions}
                  postAuthor={hit.author}
                  postId={hit._id}
                  postIndex={hit._index}
                />
                <hr />
                <div>
                  <Button
                    style={{ whiteSpace: 'nowrap' }}
                    onClick={() => {
                      // eslint-disable-next-line no-alert
                      alert('Saved to VehicleChain Blockchain');
                    }}
                  >
                    Save to Chain
                  </Button>
                </div>
              </div>
          )}
        </div>
      </Card.Body>
      <Card.Footer>
        <LazyPanel collapsed title="Post Context">
          <PostContext url={page_url} index={hit._index} />
        </LazyPanel>
      </Card.Footer>
    </Card>
  );
};

MentionsResultListItem.propTypes = {
  hit: object.isRequired,
  definitions: array,
};

export default Object.assign(
  ({ hits }) => {
    const { definitions /* , error */ } = useDefinitions();
    return (
      <div>
        {hits
          && [].map.call(hits, (hit) => (
            <MentionsResultListItem
              key={hit._id}
              hit={hit}
              definitions={definitions}
            />
          ))}
      </div>
    );
  },
  { propTypes: { hit: PropTypes.object.isRequired } },
);
