import styled from 'styled-components';

export const StyledToggleIconLeft = styled.span<{ open: boolean }>`
  transform: rotate(${(props) => (props.open ? 0 : 90)}deg);
  transition: transform 0.2s ease-in-out;
  background-image: var(--caret-down-fill);
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  height: 12px;
  width: 12px;
`;

export const StyledToggleIconRight = styled.span<{ open: boolean }>`
  transform: rotate(${(props) => (props.open ? 0 : -90)}deg);
  transition: transform 0.2s ease-in-out;
  background-image: var(--caret-down-fill);
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  height: 12px;
  width: 12px;
`;
