import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { useConfig } from './Config';
import router from './router/router';

function Main(): JSX.Element {
  const { instanceName } = useConfig();
  return (
    <>
      <RouterProvider router={router(instanceName)} />
    </>
  );
}

export default Main;
