import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from '../../utils/Localization';
import MileageLayerChart from '../Charts/MileageLayerChart';

const MileageChartCard = (props) => {
  const [interval, setInterval] = useState('month');
  const i18n = useTranslation();
  const { react } = props;

  return (
    <Card {...props}>
      <Card.Header>
        <div className="d-flex">
          <div className="flex-grow-1 fw-bold pe-2">
            {i18n('Date Received vs. Mileage')}
          </div>
          <div>
            <span className="me-2">{i18n('Interval')}</span>
            <select
              style={{ fontSize: '0.875rem' }}
              name="interval"
              onChange={(event) => {
                setInterval(event.target.value);
              }}
              value={interval}
            >
              <option value="month">{i18n('Month')}</option>
              <option value="quarter">{i18n('Quarter')}</option>
              <option value="year">{i18n('Year')}</option>
            </select>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <MileageLayerChart
          chartId="mileage_chart"
          interval={interval}
          chartProps={{ height: 400 }}
          react={react}
        />
      </Card.Body>
    </Card>
  );
};

export default MileageChartCard;
