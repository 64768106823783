import React from 'react';

import { useAccessToken } from '../auth/Auth0';
import { useConfig } from '../Config';

// eslint-disable-next-line import/prefer-default-export
export function useReactiveProps(app) {
  const accessToken = useAccessToken();
  const config = useConfig();
  const [reactiveProps, setReactiveProps] = React.useState();
  React.useEffect(() => {
    if (config && accessToken) {
      setReactiveProps({
        app,
        url: config.connection,
        headers: { Authorization: `Bearer ${accessToken}` },
      });
    } else {
      setReactiveProps(undefined);
    }
  }, [app, accessToken, config]);
  return reactiveProps;
}
