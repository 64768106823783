import logo from '@assets/images/logo.png';
import { useAuth0 } from '@auth0/auth0-react';
import { string } from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from '../utils/Localization';

function LoginScreen({ className }) {
  const { loginWithRedirect, error } = useAuth0();
  const i18n = useTranslation();
  return (
    <div className={className}>
      <div className="title">
        <img className="logo" src={logo} alt="CarWatch Logo" />
        {i18n('Welcome to CarWatch')}
      </div>
      <div className="info">{i18n('Please log in.')}</div>
      <div className="error">{i18n(error)}</div>
      <div className="controls">
        <Button
          size="sm"
          variant="primary"
          type="button"
          onClick={loginWithRedirect}
        >
          {i18n('Login')}
        </Button>
      </div>
    </div>
  );
}

LoginScreen.propTypes = {
  className: string,
};

export default styled(LoginScreen)`
  position: fixed;
  inset: 0;
  width: fit-content;
  height: fit-content;
  margin: auto;
  box-shadow: 0 1px 3px 0 gray;
  padding: 2rem;

  .title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    color: var(--bs-gray);
    margin-bottom: 1rem;

    img.logo {
      margin-right: 0.5rem;
      height: 2rem;
    }
  }

  .info,
  .error {
    font-size: 0.8rem;
    text-align: center;
  }

  .error {
    color: red;
  }

  .controls {
    margin-top: 1rem;
    text-align: center;

    button {
      color: var(--bs-white);
    }
  }
`;
