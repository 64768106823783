import { getISOWeek } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { useConfig } from './Config';
import { customerRoutes, mapCustomerInstances } from './router';
import { TNavigationItem } from './types';
import getCommonUrlParameters from './utils/GetCommonUrlParameters';

export function useV3Menu(): {
  menu: TNavigationItem[];
  other: TNavigationItem[];
} {
  const location = useLocation();
  const config = useConfig();
  const commonUrlParameters = getCommonUrlParameters(location);
  const currentWeek = getISOWeek(
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const publicationDateFilter = `${
    commonUrlParameters ? '&' : '?'
  }refine_publication_date=["1900"%2C"${new Date().getFullYear()}"]`;
  return {
    menu: [
      {
        label: 'Recalls',
        pathname: '/v3/recalls',
        search: `${commonUrlParameters}${publicationDateFilter}`
      },
      {
        label: 'Investigations',
        pathname: '/v3/investigations',
        search: `${commonUrlParameters}${publicationDateFilter}`
      },
      {
        label: 'Complaints',
        pathname: '/v3/complaints',
        search: `${commonUrlParameters}${publicationDateFilter}`
      },
      {
        label: 'Bulletins',
        pathname: '/v3/bulletins',
        search: `${commonUrlParameters}${publicationDateFilter}`
      },
      {
        label: 'Articles',
        pathname: '/v3/articles'
      },
      {
        label: 'Mentions',
        pathname: '/v3/mentions',
        search: `${commonUrlParameters}${publicationDateFilter}`
      },
      {
        label: 'Reports',
        pathname: '/v3/reports',
        search: `?type=recalls&refine_cw_year=${new Date().getFullYear()}&refine_cw=${currentWeek}&tab=charts`
      }
    ],
    other: [
      {
        label: 'Litigations',
        pathname: '/litigations'
      },
      ...(customerRoutes[mapCustomerInstances(config.instanceName)] ?? [])
        .filter((cr) => cr.menucategory === 'others')
        .map(({ label, path }) => ({
          label,
          pathname: path
        }))
    ]
  };
}
