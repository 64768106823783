import React from 'react';

const Spinner = () => (
  <div className="text-center align-middle my-5">
    <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

export default Spinner;
