import { get, join, map } from 'lodash-es';
import { SearchHit } from '../../elasticsearch/types';
import { formatComponent } from '../../utils/StringTools';

export function getComponent(component: string | string[] | undefined): string
{
  if (component === undefined)
  {
    return undefined;
  }

  if (typeof component === 'string')
  {
    return formatComponent(component);
  }

  return join(map(component, formatComponent), ' | ');
}

export function getHighlightValue(hitResult: SearchHit, fieldName: string): string
{
  return get(
    hitResult,
    `highlight.${fieldName}`,
    /* default */
    get(hitResult, `_source?.${fieldName}`, get(hitResult, fieldName)),
  );
}
