import { get, size } from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import * as t from '../../utils/Localization';
import { useTranslation } from '../../utils/Localization';
import { formatComponent, joinValue } from '../../utils/StringTools';
import { renderDev } from '../Develop/DevComponent';
import Source from '../Develop/Source';
import EllipsisText from '../EllipsisText';
import ErrorBoundary from '../ErrorBoundary';
import { Section } from '../Layout/Card';
import { LazyPanel } from '../LazyPanel';
import {
  Favorite,
  MetaData,
  MetaDate,
  MetaText,
  ResultListAttachments,
  useEntityLink,
} from '../ResultListItem';
import ResultListItem from '../ResultListItem/ResultListItem';
import RelatedInvComplaints from './RelatedInvComplaints';
import RelatedInvRecalls from './RelatedInvRecalls';

function RelatedRecalls({ hit }) {
  // eslint-disable-next-line react/prop-types
  const { campaign_number, recall_campaign_number } = hit;
  const campaignNo = recall_campaign_number || campaign_number;
  if (Array.isArray(campaignNo)) {
    return [].map.call(campaignNo, (cn) => (
      <RelatedInvRecalls key={cn} campaignNo={cn} />
    ));
  }
  return <RelatedInvRecalls campaignNo={campaign_number} />;
}

export default function InvestigationsResultListItem({ hit }) {
  const i18nDS = useTranslation('datasources');
  const attachments = hit.documents;
  const innerHits = (hit.inner_hits && hit.inner_hits.documents?.hits?.hits) || [];
  const cardTitle = hit.subject || hit.defect;
  const defect = hit['highlight.defect'] || hit.defect || 'n.a.';
  const summary = hit['highlight.summary'] || hit.summary || 'n.a.';
  const odinos = get(hit, 'misc.potential_odinos', []);

  const RelatedComplaints = React.useCallback(
    () => (
      <div className="px-4">
        <ErrorBoundary>
          <RelatedInvComplaints odinos={odinos} />
        </ErrorBoundary>
      </div>
    ),
    [odinos],
  );

  const sideLink = useEntityLink(hit._id);

  return (
    <ResultListItem
      entityId={hit._id}
      title={cardTitle}
      options={<Favorite entityRef={sideLink} name={cardTitle} />}
    >
      <Section>
        <MetaData label="Make" value={joinValue(hit.facets?.make, ' / ')} />
        <MetaData label="Model" value={joinValue(hit.facets?.model, ' / ')} />
      </Section>
      <Section>
        <MetaDate
          label="Opened Date"
          context="rli-investigations"
          value={hit.opened_date}
        />
        <MetaDate
          label="Closed Date"
          context="rli-investigations"
          value={hit.closed_date}
        />
        <MetaData label="Source" value={i18nDS(hit.datasource)} />
      </Section>
      <Section>
        <MetaData label="Recall ID">
          {/* <RelatedInvRecalls campaignNo={hit.campaign_number} /> */}
          <RelatedRecalls hit={hit} />
        </MetaData>
        <MetaData label="Official ID">{hit.official_id}</MetaData>
        {/* <MetaData label="Investigation ID" value={actionId} /> */}
      </Section>
      <Section>
        <MetaData label="Manufacturer" value={hit.misc?.manufacturer} />
        <MetaData label="Component(s)" value={formatComponent(hit.component)} />
      </Section>
      <Section>
        <MetaText label="Defect">
          <EllipsisText text={defect} length={1000} />
        </MetaText>
      </Section>
      <Section>
        <MetaText label="Summary">
          <EllipsisText text={summary} length={1000} />
        </MetaText>
      </Section>
      <LazyPanel
        collapsed
        rendered={size(odinos) > 0}
        title={`${t.t('Complaints')} (${size(odinos)})`}
        content={RelatedComplaints}
      />
      <LazyPanel
        collapsed
        rendered={size(attachments) > 0}
        title={`${t.t('Investigation Attachments', 'rli-recalls')} (${size(
          attachments,
        )})`}
      >
        <ResultListAttachments.Wrapper
          attachments={attachments}
          attachmentDocs={innerHits}
        />
      </LazyPanel>
      {renderDev(<Source sourceObject={hit} />)}
    </ResultListItem>
  );
}

InvestigationsResultListItem.propTypes = {
  hit: PropTypes.object.isRequired,
};
