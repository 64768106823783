import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import React from 'react';
import { getLanguage } from '../utils/Localization';

export const formatDate = (
  value?: string | Date | number,
  formatStr?: string
): string => {
  const locale = getLanguage();
  const dformat = formatStr || (locale === 'de' ? 'd.M.yyyy' : 'M/d/yyyy');

  return value
    ? format(value, dformat, { locale: locale === 'de' ? de : undefined })
    : '';
};

export const FormatDate = ({ value }: { value?: string | Date | number }) => (
  <span className="formatdate">{formatDate(value)}</span>
);

export default FormatDate;
