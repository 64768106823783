import { format } from 'date-fns';
import { func, node, object } from 'prop-types';
import React from 'react';
import { ListGroup, Stack } from 'react-bootstrap';
import { Reply, Trash } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useUserProfile } from '../../auth/Auth0';
import { ReplyComment } from './ReplyComment';

/*
  $replyOpen: transient property is starting with $
*/
const StyledReplyPanel = styled.div`
  max-height: ${(props) => (props.$replyOpen ? '100px' : 0)};
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  padding: ${(props) => (props.$replyOpen ? '0.5rem 0' : 0)};
`;

export const Comment = ({ comment, handleDeleteComment, replyComponent }) => {
  const { user_id } = useUserProfile();
  const { t } = useTranslation('comments');
  const [replyOpen, setReplyOpen] = React.useState(false);
  const isRoot = !comment.parentid;
  return (
    <>
      <div className="d-flex">
        <div className="me-auto px-1">
          <div>{comment.comment}</div>
          <sup className="text-secondary">
            {`${format(comment.modified_at, 'dd/MM/yyyy, HH:mm')}, ${
              comment.email
            }`}
          </sup>
        </div>
        <div className="flex-shrink-1">
          <Stack direction="vertical" gap={1}>
            {user_id === comment.userid && (
              <Trash
                size={16}
                role="button"
                onClick={() => handleDeleteComment(comment.id)}
              />
            )}
            {isRoot && (
              <Reply
                size={16}
                role="button"
                onClick={() => setReplyOpen((o) => !o)}
              />
            )}
          </Stack>
        </div>
      </div>
      {replyComponent && (
        <StyledReplyPanel $replyOpen={replyOpen}>
          {replyComponent}
        </StyledReplyPanel>
      )}
      {comment.answers?.length > 0 && (
        <div className="fw-bold">
          {`${t('Answers')} (${comment.answers?.length})`}
        </div>
      )}
      <ListGroup className="comment_answers ms-2">
        {comment.answers?.map((answer) => (
          <ListGroup.Item key={answer.id}>
            <Comment
              key={answer.id}
              comment={answer}
              handleDeleteComment={handleDeleteComment}
              replyComponent={
                !answer.parentid && <ReplyComment parentId={answer.id} />
              }
            />
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
};

Comment.propTypes = {
  comment: object.isRequired,
  handleDeleteComment: func.isRequired,
  replyComponent: node,
};
