const recallsColors = ['#ff4040', '#ff5353', '#ff6666', '#ff7979', '#ff8c8c', '#ff9f9f', '#ffb2b2', '#ffc5c5', '#ffd8d8'];
const investigationsColors = ['#808080', '#8c8c8c', '#999999', '#a6a6a6', '#b2b2b2', '#bfbfbf', '#cccccc', '#d8d8d8', '#e5e5e5'];
const mentionsColors = ['#1db899', '#33bfa3', '#4ac6ad', '#60cdb7', '#77d4c1', '#8edbcc', '#a4e2d6', '#bbe9e0', '#d1f0ea'];
const bulletinsColors = ['#468499', '#5890a3', '#6a9cad', '#7da8b7', '#90b5c1', '#a2c1cc', '#b5cdd6', '#c7dae0', '#dae6ea'];
const complaintsColors = ['#ffa32d', '#ffac42', '#ffb556', '#ffbe6c', '#ffc781', '#ffd196', '#ffdaab', '#ffe3c0', '#ffecd5'];
const defaultColor = '#008FFB';

/*Bright Colors
const recallColor = '#C71E1D';
const investigationColor = '#FF8900';
const complaintColor = '#15607A';*/

const componentsAggs = {
  data: {
    terms: {
      field: 'facets.found_components.keyword',
      order: {
        _count: 'desc'
      },
      size: 50
    },
    aggs: {
      emerging: {
        terms: {
          field: 'facets.found_emerging_problem.keyword'
        }
      },
      catastrophic: {
        terms: {
          field: 'facets.found_catastrophic_problem.keyword'
        }
      }
    }
  },
};

export const heatmapChartsQuery = (prefix = 'cw3_cst_demo') => ({
  query: {
    bool: {
      filter: {
        exists: {
          field: 'facets.found_components',
        },
      },
    },
  },
  size: 0,
  aggs: {
    recalls: {
      filter: {
        term: {
          _index: `${prefix}_recalls`,
        },
      },
      aggs: componentsAggs,
    },
    investigations: {
      filter: {
        term: {
          _index: `${prefix}_investigations`,
        },
      },
      aggs: componentsAggs,
    },
    complaints: {
      filter: {
        term: {
          _index: `${prefix}_complaints`,
        },
      },
      aggs: componentsAggs,
    },
    bulletins: {
      filter: {
        term: {
          _index: `${prefix}_bulletins`,
        },
      },
      aggs: componentsAggs,
    },
    mentions: {
      filter: {
        term: {
          _index: `${prefix}_socialweb*`,
        },
      },
      aggs: componentsAggs,
    },
  },
});

export const heatmapOptions = {
  legend: {
    show: true
  },
  chart: {
    height: 350,
    type: 'treemap',
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: '12px',
    },
    formatter: function (text, op) {
      return [text, op.value]
    },
    offsetY: -4
  },
  plotOptions: {
    treemap: {
      distributed: true,
      enableShades: false
    }
  }
};

function addColor(dataPoint, componentColorArray) {
  if (dataPoint.catastrophic.buckets.length > 0) {
    const colorIndex =
      dataPoint.doc_count >= 5 ? 0 : dataPoint.doc_count >= 3 ? 1 : 2;
    return componentColorArray[colorIndex];
  }
  else if (dataPoint.emerging.buckets.length) {
    const colorIndex =
      dataPoint.doc_count >= 5 ? 3 : dataPoint.doc_count >= 3 ? 4 : 5;
    return componentColorArray[colorIndex];
  }
  else {
    const colorIndex =
      dataPoint.doc_count >= 5 ? 6 : dataPoint.doc_count >= 3 ? 7 : 8;
    return componentColorArray[colorIndex];
  }
}

export function toHeatmapSeries(args, dataType) {
  let data = [];
  let colors = [];
  if (dataType.toLowerCase() === 'recalls-etc') {
    let recallsBuckets = args.recalls.data.buckets;
    let investigationsBuckets = args.investigations.data.buckets;
    let bulletinsBuckets = args.bulletins.data.buckets;
    recallsBuckets.map((b) => {
      data.push({ x: b.key, y: b.doc_count });
      colors.push(addColor(b, recallsColors));
    });
    investigationsBuckets.map((b) => {
      data.push({ x: b.key, y: b.doc_count });
      colors.push(addColor(b, investigationsColors));
    });
    bulletinsBuckets.map((b) => {
      data.push({ x: b.key, y: b.doc_count });
      colors.push(addColor(b, bulletinsColors));
    });
  }
  if (dataType.toLowerCase() === 'complaints') {
    let complaintsBuckets = args.complaints.data.buckets;
    complaintsBuckets.map((b) => {
      data.push({ x: b.key, y: b.doc_count });
      colors.push(addColor(b, complaintsColors));
    })
  }
  if (dataType.toLowerCase() === 'mentions') {
    let mentionsBuckets = args.mentions.data.buckets;
    mentionsBuckets.map((b) => {
      data.push({ x: b.key, y: b.doc_count });
      colors.push(addColor(b, mentionsColors));
    })
  }
  if (dataType.toLowerCase() === 'default') {
    let defaultBuckets = args.data.buckets;
    defaultBuckets.map((b) => {
      data.push({ x: b.key, y: b.doc_count });
    })
    colors.push(defaultColor);
  }
  return { colors, data };
}