/* eslint-disable no-console */
import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Toast from 'react-bootstrap/Toast';

export const useAsyncError = () => {
  // eslint-disable-next-line no-unused-vars
  const [_, setError] = React.useState();
  return React.useCallback(
    (e: Error) => {
      setError(() => {
        throw e;
      });
    },
    [setError]
  );
};

export function renderError(error: Error): JSX.Element {
  return (
    <Alert variant="danger">
      <div className="fw-bold">Something went wrong.</div>
      <details className="text-prewrap">
        {error.name}: {error?.message}
      </details>
    </Alert>
  );
}

type ErrorBoundaryProps = {
  children?: React.ReactNode;
  silent?: boolean;
  error?: Error | null;
};

type ErrorBoundaryState = {
  error: Error | null;
  errorInfo: any;
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    error: null,
    errorInfo: null
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo
    });
    // eslint-disable-next-line no-unused-expressions
    process.env.NODE_ENV === 'development' &&
      console.error('-- did catch:', error, errorInfo.componentStack);
  }

  render() {
    const { props, state } = this;
    if (state.errorInfo && props.silent) return null;
    if (state.errorInfo) {
      return renderError(state.error);
    }
    // eslint-disable-next-line react/prop-types
    return props.children;
  }
}

export default ErrorBoundary;
