import { defaultHighlight } from '../Search/queries';
import { ISearchCase } from './SearchCases.types';

function getDateFieldForContext(context: string) {
  switch (context) {
    case 'investigations':
      return 'opened_date';
    case 'mentions':
      return 'timestamp';
    default:
      return 'publication_date';
  }
}

export function buildElasticSearchQuery(sc: ISearchCase, context?: string) {
  let query: any = {
    match_all: {}
  };

  const facetQuery = ['make', 'model', 'component'].reduce(
    (acc, f: keyof ISearchCase) => {
      if (sc?.[f]?.length > 0) {
        acc.push({
          terms: { [`facets.${f}.keyword`]: sc[f] }
        });
      }
      return acc;
    },
    []
  );

  const fullTextQuery =
    sc.fulltext?.length > 0
      ? [
          {
            query_string: {
              query: sc.fulltext
            }
          }
          /* SM: inner hits conflict with full text search component, query is getting invalid */
          /* {
            bool: {
              should: [
                {
                  query_string: {
                    query: sc.fulltext
                  }
                },
                {
                  nested: {
                    path: 'documents',
                    query: {
                      query_string: {
                        query: sc.fulltext
                      }
                    },
                    inner_hits: {
                      _source: {
                        includes: ['documents.filename']
                      },
                      highlight: defaultHighlight()
                    }
                  }
                }
              ]
            }
          } */
        ]
      : [];

  if (
    facetQuery.length > 0 ||
    sc.fulltext?.length > 0 ||
    sc.daterange?.length > 0
  ) {
    query = {
      bool: {
        must: [...facetQuery, ...fullTextQuery]
      }
    };
    sc.daterange?.length > 0 &&
      query.bool.must.push({
        range: {
          [getDateFieldForContext(context)]: {
            gte: sc.daterange
          }
        }
      });
  }

  return query;
}
