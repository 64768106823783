import { ErrorBoundary } from '@appbaseio/reactivesearch';
import { without } from 'lodash-es';
import React, { useRef } from 'react';
import { Tab } from 'react-bootstrap';
import { EPageContext, withPageContext } from '@/context/PageContext';
import { definedSearchFields } from '@/components/Search/FullTextSearch';
import { useConfig } from '../../Config';
import { BulletinsResultListItem } from '../../components/Bulletins';
import ControlledTabs from '../../components/ControlledTabs';
import { renderError } from '../../components/ErrorBoundary';
import { Page } from '../../components/Layout/Page';
import {
  CWSelectedFilters,
  DateHistogramRefinement,
  DefinedFilters,
  OfficialId,
  RefineId,
  SavedFilters,
} from '../../components/Refinements';
import {
  CustomerRefinements,
  useCustomerRefinementIds,
} from '../../components/Refinements/CustomerRefinements';
import { MaxTodayFilter } from '../../components/Refinements/DateFilters';
import DateRangeRefinement from '../../components/Refinements/DateRangeRefinement';
import {
  ComponentRefinement,
  DataSourceRefinement,
  MakeRefinement,
  ModelRefinement,
} from '../../components/Refinements/DefaultRefinements';
import { componentIds as customRefinementsIds } from '../../components/Refinements/DetectedRefinements';
import ScrollTarget from '../../components/Refinements/ScrollTarget';
import {
  ExportResults,
  ResultStats,
} from '../../components/ResultListItem/ResultStats';
import { FullTextSearch } from '../../components/Search';
import { SearchCases } from '../../components/SearchCases';
import SearchResultList, {
  transformFilters,
} from '../../components/SearchResultList';
import { ResultListSpinner } from '../../components/Spinners';
import ReactiveApp from '../../reactivesearch/ReactiveApp';
import { useTranslation } from '../../utils/Localization';
import { usePageTitle } from './PageTitle';
import { SearchCasesRefinementWithPanel } from '@/components/SearchCases/SearchCasesRefinement';

const defaultInteractiveComponentIds = [
  'q',
  'refine_max_today',
  'refine_id',
  'refine_make',
  'refine_model',
  'refine_component',
  'refine_officialid',
  'refine_datasource',
  'refine_publication_date',
  'refine_publication_date_range',
  'resultlist',
  'refine_public_date_filter',
  'refine_searchcases',
  ...customRefinementsIds,
];

function Bulletins() {
  const interactiveComponentIds = useCustomerRefinementIds(
    defaultInteractiveComponentIds,
  );
  const config = useConfig();
  const i18n = useTranslation();
  usePageTitle(i18n('Bulletins'));
  const indexName = config.getIndexName('bulletins', 'cw3_cst_');
  const filters = useRef({});

  return (
    <ReactiveApp app={indexName}>
      <MaxTodayFilter
        componentId="refine_max_today"
        dateField="publication_date"
      />
      <Page>
        <Page.Refinements>
          <ScrollTarget targetId="customer-refinements" />
          <SavedFilters />
          <DefinedFilters context="bulletins" />
          <hr className="border border-3 rounded-pill" />
          <RefineId componentId="refine_id" />
          <ErrorBoundary>
            <SearchCasesRefinementWithPanel
              componentId="refine_searchcases"
              context="bulltins"
              suggestionIndex={indexName}
              showFilter
              filterLabel="SearchCase"
            />
            {/* <SearchCases
              context="bulletins"
              componentId="refine_searchcases"
              react={{
                and: without(interactiveComponentIds, 'refine_searchcases'),
              }}
            /> */}
          </ErrorBoundary>
          <hr className="border border-3 rounded-pill" />
          <DateHistogramRefinement
            componentId="refine_publication_date"
            react={{
              and: without(interactiveComponentIds, 'refine_publication_date'),
            }}
            dataField="publication_date"
            label={i18n('Publication Date')}
            filterLabel={i18n('Publication Date')}
            URLParams
            loader="Loading ..."
          />
          <ErrorBoundary>
            <DateRangeRefinement
              componentId="refine_publication_date_range"
              dataField="publication_date"
              startLabel="Start Date"
              endLabel="End Date"
              filterLabel="Publication Date Range"
              URLParams
            />
            {/* <DateRangeSC
              componentId="refine_publication_date_range"
              dataField="publication_date"
              URLParams
              dayPickerInputProps={{
                inputProps: {
                  style: { padding: '0.1rem 0.3rem', minHeight: 'inherit' },
                },
              }}
              react={{
                and: without(
                  interactiveComponentIds,
                  'refine_publication_date_range',
                ),
              }}
            /> */}
          </ErrorBoundary>
          <hr />
          <OfficialId
            componentId="refine_officialid"
            label={i18n('Official ID')}
          />
          <hr />
          <DataSourceRefinement
            react={{
              and: without(interactiveComponentIds, 'refine_datasource'),
            }}
          />
          <MakeRefinement
            react={{ and: without(interactiveComponentIds, 'refine_make') }}
          />
          <ModelRefinement
            react={{ and: without(interactiveComponentIds, 'refine_model') }}
          />
          <ComponentRefinement
            react={{
              and: without(interactiveComponentIds, 'refine_component'),
            }}
          />
          <hr id="customer-refinements" />
          <ErrorBoundary>
            <CustomerRefinements react={interactiveComponentIds} />
          </ErrorBoundary>
        </Page.Refinements>
        <Page.Content>
          <FullTextSearch
            componentId="q"
            className="mb-3"
            dataField={definedSearchFields('bulletins')}
          />
          <CWSelectedFilters
            onChange={(args) => {
              filters.current = transformFilters(args);
            }}
            context="bulletins"
          />
          <ControlledTabs param="tab" defaultTab="searchresults">
            <Tab eventKey="searchresults" title={i18n('Search Results')}>
              <SearchResultList
                pagination
                componentId="resultlist"
                dataField="publication_date"
                exportElement={
                  <ExportResults type="bulletins" filters={filters.current} />
                }
                statsElement={<ResultStats />}
                react={{ and: interactiveComponentIds }}
                renderResultStats={() => {}}
                innerClass={{ sortOptions: 'mb-2' }}
              >
                {({ data, error, loading }) => {
                  if (error) {
                    return renderError(error);
                  }
                  if (loading) {
                    return <ResultListSpinner />;
                  }
                  return (
                    <div>
                      {data.map((hit) => (
                        <BulletinsResultListItem key={hit._id} hit={hit} />
                      ))}
                    </div>
                  );
                }}
              </SearchResultList>
            </Tab>
          </ControlledTabs>
        </Page.Content>
      </Page>
    </ReactiveApp>
  );
}

export default withPageContext(Bulletins, EPageContext.BULLETINS, 'Bulletins');
