export default {
  en: {
    // default
    translation: {},
    // contexts
    indices: {
      cw3_src_nhtsa_recalls: 'US Recalls (v3)',
      cw3_src_nhtsa_bulletins: 'US Bulletins (v3)',
      cw3_src_nhtsa_investigations: 'US Investigations (v3)',
      cw3_src_nhtsa_complaints: 'US Complaints (v3)',
      cw_src_nhtsa_recalls: 'US Recalls',
      cw_src_nhtsa_bulletins: 'US Bulletins',
      cw_src_nhtsa_investigations: 'US Investigations',
      cw_src_nhtsa_complaints: 'US Complaints',
      cw_src_accp_recalls: 'Asean Recalls',
      cw_src_adac_recalls: 'ADAC Recalls',
      cw_src_china_bulletins: 'China Bulletins (Qiche)',
      cw_src_china_complaints: 'China Complaints (Qiche)',
      cw_src_china_recalls: 'China Recalls (Qiche)',
      cw_src_dpac_recalls: 'China Recalls (DPAC)',
      cw_src_india_recalls: 'India Recalls',
      cw_src_ireland_recalls: 'Ireland Recalls',
      cw_src_japanese_recalls: 'Japanese Recalls',
      cw_src_kba_recalls: 'KBA Recalls',
      cw_src_korea_recalls: 'Korea Recalls',
      cw_src_mlit_complaints: 'Japan Complaints',
      cw_src_mlit_recalls: 'Japan Recalls',
      cw_src_nz_recalls: 'New Zealand Recalls',
      cw_src_rapex_recalls: 'RAPEX',
      cw_src_tc_investigations: 'Kanada Investigations',
      cw_src_tc_recalls: 'Kanada Recalls',
      cw_src_uae_recalls: 'Emirates Recalls',
      cw_src_uk_recalls: 'UK Recalls',
      cw_src_accc_recalls: 'Australia Recalls',
    },
    modal: {
      Close: 'Close',
    },
    refinements: {
      q: 'Full Text',
      refine_datasource: 'Datasource',
      refine_make: 'Make',
      refine_model: 'Model',
      refine_component: 'Component',
    },
    searchcases: {
      'defined-filters-title': 'Saved Searches',
      'input-name': 'Filter Name',
      'confirm-delete': 'Delete stored Filter',
      'save-search-case': 'Save selected filters',
      'delete-search-case': 'Delete',
    },
    'saved-refinements': {
      'refinements-filters-title': 'Saved Searches',
      'input-name': 'Filter Name',
      'confirm-delete': "Delete Filter '{{name}}'?",
      'save-refinement': 'Save Filter',
      'delete-refinement': 'Delete Filter',
    },
    'searchcases-form': {
      'SearchCase Name': 'Name',
      'Date Range': 'Date Range',
      Facets: 'Facets',
      make: 'Make',
      model: 'Model',
      component: 'Component',
      none: 'no limit',
      'last week': 'Last week',
      'last month': 'Last month',
      'last year': 'Last year',
      'Full Text Search': 'Full Text Search',
      'Test Query': 'Test Query',
      'Run Query': 'Run query',
      'Hit count': 'Hit count',
      Save: 'Save',
      Delete: 'Delete',
      Cancel: 'Cancel',
    },
    reports: {
      'facets.found_components_seating': 'Seating',
      'facets.found_components_electronics': 'Electronics',
      'facets.found_components_interior': 'Interiors',
      'facets.found_components_safety': 'Safety',
      'facets.found_competitors_yfs': 'Seating',
      'facets.found_competitors_yfe': 'Electronics',
      'facets.found_competitors_yfai': 'Interiors',
      'facets.found_competitors_yfss': 'Safety',
    },
    'search-input': {
      'placeholder-simple': 'Search (e.g. crash +tire)',
      'placeholder-extended': 'Extended Search (e.g. subject:"crash" AND defect:"tire")',
    },
  },
  de: {
    translation: {},
    indices: {},
    modal: {
      Close: 'Schließen',
    },
    refinements: {
      q: 'Volltext',
      refine_datasource: 'Datenquelle',
      refine_make: 'Marke',
      refine_model: 'Modell',
      refine_component: 'Komponente',
    },
    searchcases: {
      'defined-filters-title': 'Gespeicherte Filter',
      'input-name': 'Filtername',
      'confirm-delete': 'Bestätigung Filter löschen',
      'save-search-case': 'Ausgewählte Filter speichern',
      'delete-search-case': 'Löschen',
    },
    'searchcases-form': {
      'SearchCase Name': 'Name',
      'Date Range': 'Zeitraum',
      Facets: 'Filter',
      make: 'Marke',
      model: 'Modell',
      component: 'Komponente',
      none: 'Keine Einschränkung',
      'last week': 'Letzte Woche',
      'last month': 'Letzter Monat',
      'last year': 'Letztes Jahr',
      'Full Text Search': 'Volltextsuche',
      'Test Query': 'Testabfrage',
      'Run Query': 'Abfrage ausführen',
      'Hit count': 'Trefferanzahl',
      Save: 'Speichern',
      Delete: 'Löschen',
      Cancel: 'Abbrechen',
    },
    'saved-refinements': {
      'refinements-filters-title': 'Gespeicherte Filter',
      'input-name': 'Filtername',
      'confirm-delete': "Filter '{{name}}' löschen?",
      'save-refinement': 'Filter speichern',
      'delete-refinement': 'Filter löschen',
    },
    'customer-refinements': {
      'facets.found_components_seating': 'YF - Components (Seating)',
      'facets.found_components_electronics': 'YF - Components (Electronics)',
      'facets.found_components_interior': 'YF - Components (Interiors)',
      'facets.found_components_safety': 'YF - Components (Safety)',
      'facets.found_competitors_yfs': 'YF - Competitors (Seating)',
      'facets.found_competitors_yfe': 'YF - Competitors (Electronics)',
      'facets.found_competitors_yfai': 'YF - Competitors (Interiors)',
      'facets.found_competitors_yfss': 'YF - Competitors (Safety)',
    },
    reports: {
      'facets.found_components_seating': 'Seating',
      'facets.found_components_electronics': 'Electronics',
      'facets.found_components_interior': 'Interiors',
      'facets.found_components_safety': 'Safety',
      'facets.found_competitors_yfs': 'Seating',
      'facets.found_competitors_yfe': 'Electronics',
      'facets.found_competitors_yfai': 'Interiors',
      'facets.found_competitors_yfss': 'Safety',
    },
    'search-input': {
      'placeholder-simple': 'Suche (z.B. crash +tire)',
      'placeholder-extended': 'Erweiterte Suche (z.B. subject:"crash" AND defect:"tire")',
    },
    comments: {
      'No comments': 'Keine Kommentare',
      'Add comment': 'Kommentar hinzufügen',
      'Delete comment': 'Kommentar löschen',
      'Reply comment': 'Antwort auf Kommentar',
      'Delete comment?': 'Kommentar löschen?',
      Add: 'Speichern',
      Reply: 'Antwort speichern',
      Answers: 'Antworten',
    },
    resultlistitem: {
      Comments: 'Kommentare',
    },
  },
};
