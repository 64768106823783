import get from 'lodash-es/get';
import isEqual from 'lodash-es/isEqual';
import size from 'lodash-es/size';
import PropTypes from 'prop-types';
import React from 'react';
import * as t from '../../utils/Localization';
import { useTranslation } from '../../utils/Localization';
import { joinValue } from '../../utils/StringTools';
import Source from '../Develop/Source';
import { Section } from '../Layout/Card';
import { LazyPanel } from '../LazyPanel';
import {
  Favorite,
  MetaData,
  MetaDate,
  MetaText,
  ResultListAttachments,
  ResultListItem,
  getHighlightValue,
  useEntityLink,
} from '../ResultListItem';
import { BodyOptions } from '../ResultListItem/DefaultBodyOptions';
import { RelatedInvestigationsPanel } from './RelatedInvestigations';
import { ExternalReference } from '../ResultListItem/ExternalReference';

function RecallsResultListItem({ hit }) {
  const [native, setNative] = React.useState(false);
  const i18nDS = useTranslation('datasources');

  function getFieldValue(fieldName) {
    if (native) {
      /* get native language value if available */
      return get(hit, `native.${fieldName}`, get(hit, fieldName));
    }
    return get(hit, fieldName);
  }

  function getTitle() {
    const title = [
      hit.facets.make
        ? joinValue(hit.facets?.make, ' / ')
        : t.t('Various Makes'),
      hit.facets.model
        ? joinValue(hit.facets?.model, ' / ')
        : t.t('Various Models'),
    ];
    return title.join(': ');
  }

  const toggleLanguageButton = hit.native !== undefined ? (
    <div
      role="button"
      tabIndex="0"
      onClick={() => setNative(!native)}
      onKeyUp={() => {}}
      title={t.t('Toggle Language')}
      aria-label="Toggle Language"
      className={`${native ? 'text-primary' : 'text-secondary'}`}
    >
      <i className="bi bi-translate" />
    </div>
  ) : null;

  const attachments = hit.documents;
  const innerHits = hit.inner_hits?.documents.hits?.hits || [];
  const title = getTitle();

  const make = hit.facets?.make || hit.make || t.t('Various');
  const model = hit.facets?.model || hit.model || t.t('Various');
  const component = hit.facets?.component || hit.component;

  const sideLink = useEntityLink(hit._id);

  return (
    <ResultListItem
      entityId={hit._id}
      title={title}
      options={<Favorite entityRef={sideLink} name={title} />}
      bodyOptions={(
        <BodyOptions context="recalls" itemId={hit._id} title={title}>
          {toggleLanguageButton}
        </BodyOptions>
      )}
    >
      {() => (
        <>
          <Section>
            <MetaData label="Subject" value={hit.subject} />
          </Section>
          <Section>
            <MetaData
              label="Make"
              value={getHighlightValue(hit, 'facets.make') || hit.make}
            />
            <MetaData
              label="Model"
              value={getHighlightValue(hit, 'facets.model') || hit.model}
            />
            <MetaData label="Model Year(s)" value={hit.model_year} />
          </Section>
          <Section>
            <MetaDate label="Date received" value={hit.publication_date} />
            <MetaData label="Source" value={i18nDS(hit.datasource)} />
            {hit.country && (
              <MetaData label="Country">
                <span
                  className="d-inline-block"
                  title={t.t('"Country" or "Country"/"Notifying Country"')}
                >
                  {t.t(hit.country)}
                </span>
              </MetaData>
            )}
          </Section>
          <Section>
            <MetaDate
              label="Begin manufacturing"
              value={hit.manufacturing_start_date}
            />
            <MetaDate
              label="End manufacturing"
              value={hit.manufacturing_end_date}
            />
          </Section>
          <Section>
            <MetaData
              label="Manufacturer Reference"
              value={hit.manufacturer_reference}
            />
            <MetaData
              label="Manufacturer"
              value={get(hit, 'misc.manufacturer')}
            />
            <ExternalReference hit={hit} />
            {/* <MetaData label="Official ID">
              {!hit.external_ref && hit.official_id}
              {hit.external_ref && (
                <a
                  href={hit.external_ref}
                  aria-label="External Reference"
                  target="_blank"
                  rel="noreferrer"
                  className="d-inline-block ms-1"
                >
                  {hit.official_id}
                </a>
              )}
            </MetaData> */}
            <MetaData
              label="Units affected"
              value={hit.affected_units < 0 ? undefined : hit.affected_units}
            />
            <MetaData
              label="Units affected (global)"
              value={
                hit.affected_units_global < 0
                  ? undefined
                  : hit.affected_units_global
              }
            />
            {/* <MetaData
          label="Component(s)"
          value={capitalize(getComponent(hit.component))}
        /> */}
            <MetaData
              label="Component(s)"
              value={getHighlightValue(hit, 'facets.component')}
            />
          </Section>

          {/* RAPEX */}
          <Section>
            <MetaData label="Risc Type" value={hit.risc_type} />
            <MetaData label="Risc Level" value={hit.risc_level} />
            <MetaData
              label="Company Recall Code"
              value={hit.company_recall_code}
            />
          </Section>
          {hit.ec_type_approval && (
            <Section>
              <MetaText label="EC type approval/models">
                <ul>
                  {Array.isArray(hit.ec_type_approval)
                    && hit.ec_type_approval.map((ta) => <li key={ta}>{ta}</li>)}
                </ul>
              </MetaText>
            </Section>
          )}

          <Section>
            <MetaText label="Defect" value={getFieldValue('defect')} />
            <MetaText
              label="Description"
              value={getFieldValue('description')}
            />
            <MetaText
              label="Consequences"
              value={getFieldValue('defect_consequence')}
            />
            <MetaText
              label="Corrective Actions"
              value={getFieldValue('remedy')}
            />
            <MetaText label="Notes" value={getFieldValue('notes')} />
          </Section>
          {/* disabled by request (trello)
          <RelatedComplaintsPanel
            datasource={hit.datasource}
            make={make}
            model={model}
            component={component}
          />
          */}
          <RelatedInvestigationsPanel campaignNumber={hit.campaign_number} />
          {size(attachments) > 0 ? (
            <LazyPanel
              collapsed
              title={`${t.t('Recall Attachments', 'rli-recalls')} (${size(
                attachments,
              )})`}
            >
              <ResultListAttachments.Wrapper
                attachments={attachments}
                attachmentDocs={innerHits}
              />
            </LazyPanel>
          ) : null}
          {process.env.NODE_ENV === 'development' && (
            <Source sourceObject={hit} />
          )}
        </>
      )}
    </ResultListItem>
  );
}

RecallsResultListItem.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default React.memo(RecallsResultListItem, (prev, next) => isEqual(prev, next));
