// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--bg-rating-good:0,153,51;--bg-rating-neutral:230,230,0;--bg-rating-bad:255,69,0;--bg-rating-unchecked-opacity:0.3}.KP2H7bU1MAOlCyBtLn3m{background-color:rgba(var(--bg-rating-good),var(--bg-rating-unchecked-opacity));color:var(--bs-black)}.BnOUP2QKRc7qmRNJm01_{background-color:rgba(var(--bg-rating-good),1);color:var(--bs-white);text-decoration:underline}.aApbkxBMqxb7uCSXuxCd{background-color:rgba(var(--bg-rating-bad),var(--bg-rating-unchecked-opacity));color:var(--bs-black)}.ov5HGOYfpoqRXbXrZNAl{background-color:rgba(var(--bg-rating-bad),1);color:var(--bs-white);text-decoration:underline}.ikCAqrXcQbZgEjMPEjuX{background-color:rgba(var(--bg-rating-neutral),var(--bg-rating-unchecked-opacity));color:var(--bs-black)}.J1Jzt2jN0XawXn8WWmRF{background-color:rgba(var(--bg-rating-neutral),.9);color:var(--bs-black);text-decoration:underline}`, "",{"version":3,"sources":["webpack://./src/components/Mentions/VehicleChain/PostRating.module.css"],"names":[],"mappings":"AAAA,MACE,yBAAA,CACA,6BAAA,CACA,wBAAA,CACA,iCACF,CAEA,sBACE,+EAAA,CAIA,qBAFF,CAKA,sBACE,8CAAA,CACA,qBAAA,CACA,yBAFF,CAKA,sBACE,8EAAA,CAIA,qBALF,CAQA,sBACE,6CAAA,CACA,qBAAA,CACA,yBALF,CAQA,sBACE,kFAAA,CAIA,qBARF,CAWA,sBACE,kDAAA,CACA,qBAAA,CACA,yBARF","sourceRoot":""}]);
// Exports
var _1 = `KP2H7bU1MAOlCyBtLn3m`;
export { _1 as "bg-good" };
var _2 = `BnOUP2QKRc7qmRNJm01_`;
export { _2 as "bg-good__checked" };
var _3 = `aApbkxBMqxb7uCSXuxCd`;
export { _3 as "bg-bad" };
var _4 = `ov5HGOYfpoqRXbXrZNAl`;
export { _4 as "bg-bad__checked" };
var _5 = `ikCAqrXcQbZgEjMPEjuX`;
export { _5 as "bg-neutral" };
var _6 = `J1Jzt2jN0XawXn8WWmRF`;
export { _6 as "bg-neutral__checked" };
export default ___CSS_LOADER_EXPORT___;
