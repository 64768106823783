/* eslint-disable import/prefer-default-export */

/**
 * Download content in browser.
 * @see https://dev.to/nombrekeff/download-file-from-blob-21ho
 * 
 * @param {Blob} blob 
 * @param {string} name 
 */
export function downloadBlob(blob, name = 'download.dat') {
  // Convert your blob into a Blob URL 
  // (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  // Remove link from body
  document.body.removeChild(link);
}

/**
 * 
 * @param {string | object} json 
 * @param {string} filename 
 */
export function downloadJson(json = {}, filename = 'download.json') {
  let data;
  if (typeof json === 'object') data = JSON.stringify(json);
  else if (typeof json === 'string') data = json;
  else throw new Error('invalid parameter type: object or string espected');

  const jsonBlob = new Blob([data], { type: 'application/json' });
  downloadBlob(jsonBlob, filename);
}

/**
 * 
 * @param {string} json 
 * @param {string} filename 
 */
export function downloadCSV(csv, filename = 'download.csv') {
  const csvBlob = new Blob([csv], { type: 'text/csv' });
  downloadBlob(csvBlob, filename);
}
