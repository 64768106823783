import { DataSearch } from '@appbaseio/reactivesearch';
import classNames from 'classnames';
import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import {
  ArrowReturnLeft,
  ExclamationCircleFill,
  Hourglass,
  InputCursorText,
  Search,
  Toggles
} from 'react-bootstrap-icons';
import { Translation } from 'react-i18next';
import { useTranslation } from '../../utils/Localization';
import { dataSearchQuery } from './queries';
import SearchInputField from './SearchInputField';
import { DataSearchProps } from '@appbaseio/reactivesearch/lib/components/search/DataSearch';

const InputModeIndicator = ({
  acceptOnEnter,
  translateFunc: t
}: {
  acceptOnEnter: boolean;
  translateFunc: (text: string) => string;
}) =>
  acceptOnEnter ? (
    <ArrowReturnLeft size={12} title={t('Search on Enter')} />
  ) : (
    <InputCursorText size={12} title={t('Search while typing')} />
  );

export default ({
  componentId = 'q',
  dataField = '__ignore__',
  className,
  ignoreNested = false
}: {
  componentId?: string;
  dataField: DataSearchProps['dataField'];
  className?: string;
  ignoreNested?: boolean;
}): JSX.Element => {
  const i18n = useTranslation();
  const [dsValue, setDsValue] = React.useState<string>('');
  const [queryString, setQueryString] = React.useState<boolean>(false);
  const [acceptOnEnter, setAcceptOnEnter] = React.useState<boolean>(true);
  const [showMenu, setShowMenu] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const handleInputChange = (value: string) => {
    setDsValue(value);
  };
  return (
    <Translation ns="search-input">
      {(t) => (
        <div className={className}>
          <InputGroup>
            <InputGroup.Text className="text-secondary">
              {loading ? (
                <Hourglass size={12} title={t('Loading...')} />
              ) : error ? (
                <ExclamationCircleFill
                  size={12}
                  className="text-danger"
                  title={t('Query validation error: ' + error)}
                />
              ) : (
                <Search size={12} />
              )}
            </InputGroup.Text>
            <SearchInputField
              value={dsValue}
              onAccept={setDsValue}
              acceptOnEnter={acceptOnEnter}
              innerClass={{
                invalidQueryClass: 'border-danger',
                input: queryString && 'bg-lightyellow'
              }}
              placeholder={
                queryString
                  ? t('placeholder-extended')
                  : t('placeholder-simple')
              }
              onError={setError}
              onLoading={setLoading}
            />
            <InputGroup.Text className="text-secondary">
              <InputModeIndicator
                acceptOnEnter={acceptOnEnter}
                translateFunc={t}
              />
            </InputGroup.Text>
            <InputGroup.Text className="text-secondary">
              <Toggles
                size={12}
                role="button"
                onClick={() => setShowMenu((v) => !v)}
                title={t('Toggle Query type (simple vs extended')}
              />
            </InputGroup.Text>
          </InputGroup>

          <div
            className={classNames('mt-2', {
              'visually-hidden': !showMenu
            })}
          >
            <Form.Check
              checked={queryString}
              type="switch"
              label={t('extended syntax')}
              onChange={() => setQueryString((v) => !v)}
              className="small"
            />
            <Form.Check
              checked={!acceptOnEnter}
              type="switch"
              label={t('search as you type')}
              onChange={() => setAcceptOnEnter((v) => !v)}
              className="small"
            />
          </div>

          <DataSearch
            className="visually-hidden"
            componentId={componentId}
            dataField={dataField}
            customQuery={(value, { dataField, ...rest }) =>
              dataField === '__ignore__'
                ? dataSearchQuery(value, rest, ignoreNested)
                : dataSearchQuery(value, { dataField, ...rest }, ignoreNested)
            }
            value={dsValue}
            onChange={handleInputChange}
            icon={<i className="bi bi-search" />}
            URLParams
            showFilter
            filterLabel={i18n('Full Text')}
            searchOperators
            autosuggest={false}
            highlight
            highlightConfig={{ number_of_fragments: 0 }}
            queryString={queryString}
          />
        </div>
      )}
    </Translation>
  );
};

export const definedSearchFields = (type: string) =>
  ({
    recalls: [
      'defect^3.0',
      'subject^6.0',
      'defect_consequence^2.0',
      'remedy^2.0',
      'campaign_number^1.0',
      'facets.*^5.0'
    ],
    complaints: ['complaint', 'complaint_description', 'facets.*'],
    investigations: ['defect', 'subject', 'summary', 'campaign_number.keyword'],
    bulletins: ['summary'],
    mentions: ['body*', 'subject']
  }[type] || ['*']);
