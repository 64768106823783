import React, { useEffect } from 'react';
import * as t from '../utils/Localization';

export const basicTitle = 'Carwatch by Pumacy';

type TPageContext = EPageContext | string;
export interface IPageContext {
  context?: TPageContext;
}

const pageContext = React.createContext<IPageContext>({});

export const PageContextProvider = pageContext.Provider;

export enum EPageContext {
  RECALLS = 'recalls',
  COMPLAINTS = 'complaints',
  BULLETINS = 'bulletins',
  REPORTS = 'reports',
  INVESTIGATIONS = 'investigations',
  MENTIONS = 'mentions'
}

export const PageContext = (props: {
  context: TPageContext;
  title?: string;
  children: any;
}) => {
  useEffect(() => {
    // set page title
    document.title = props.title
      ? `${basicTitle} - ${t.t(props.title)}`
      : basicTitle;
  }, [props.title]);

  return (
    <PageContextProvider value={{ context: props.context }}>
      {props.children}
    </PageContextProvider>
  );
};

export default pageContext;

export const usePageContext = () => React.useContext(pageContext);

export const withPageContext =
  (
    Component: React.ComponentType<any>,
    context: TPageContext,
    title?: string
  ) =>
  (props: any) => {
    return (
      <PageContext context={context} title={title}>
        <Component {...props} />
      </PageContext>
    );
  };
