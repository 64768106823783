import { get, isEmpty, map } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export default function BarChartComponent({
  value,
  loading,
  aggregations,
  width = '100%',
  height = '300px',
  onClick = () => {},
  children,
  showBarLabels = false,
  layout = 'horizontal',
}) {
  const diagram = React.useRef(null);
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    if (data && value) {
      onClick(value, data.find((d) => d.name === value) || {});
    }
  }, [data, value, onClick]);

  React.useEffect(() => {
    if (diagram.current) {
      setData(
        map(get(aggregations, 'data.buckets'), (b) => ({
          name: b.key_as_string || b.key,
          key: b.key,
          timestamp: b.key,
          value: b.doc_count,
        })),
      );
    }
  }, [aggregations]);

  const barLabels = useMemo(
    () => showBarLabels
      && (layout === 'vertical' ? (
        <>
          <LabelList dataKey="name" position="insideLeft" fill="#555" />
          <LabelList dataKey="value" position="right" />
        </>
      ) : (
        <LabelList dataKey="value" position="top" fill="#555" />
      )),
    [layout, showBarLabels],
  );

  const axes = useMemo(
    () => (layout === 'vertical' ? (
      <>
        <XAxis type="number" />
        <YAxis type="category" dataKey="name" hide reversed />
      </>
    ) : (
      <>
        <XAxis dataKey="name" />
        <YAxis />
      </>
    )),
    [layout],
  );

  return (
    <div
      ref={diagram}
      style={{
        width,
        height,
        opacity: loading ? 0.3 : 1,
      }}
    >
      {!isEmpty(data) && (
        <ResponsiveContainer>
          <BarChart
            data={data}
            layout={layout}
            isAnimationActive={false}
            onClick={(selected) => {
              /* if (!selected.activePayload) {
                // eslint-disable-next-line no-alert
                alert('reset filter to select range');
                return;
              } */
              if (!loading && selected.activePayload) {
                onClick(
                  // label, payload
                  selected.activePayload[0]?.payload?.name,
                  selected.activePayload[0]?.payload || {},
                );
              }
            }}
            margin={{
              top: 0,
              right: 50,
              left: 10,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray={2} />
            {axes}
            <Tooltip isAnimationActive={false} />
            <Legend autoReverse />
            <Bar
              dataKey="value"
              name="No. of Issues"
              fill="#6cbace"
              isAnimationActive={false}
            >
              {barLabels}
            </Bar>
            {children}
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}

BarChartComponent.propTypes = {
  value: PropTypes.any,
  loading: PropTypes.bool,
  aggregations: PropTypes.any,
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  showBarLabels: PropTypes.bool,
  layout: PropTypes.string,
};
