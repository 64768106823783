export { useElastic } from './useElastic';
export const getByIdsQuery = (ids: string | string[]) =>
  Array.isArray(ids) && {
    query: {
      bool: {
        must: {
          ids: {
            values: [...ids]
          }
        }
      }
    }
  };
