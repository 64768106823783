import { func } from 'prop-types';
import React, { useRef } from 'react';
import {
  Button, Col, Form, Row, 
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const AddComment = ({ onClick }) => {
  const commentFieldRef = useRef();
  const { t } = useTranslation('comments');

  const handleAddComment = () => {
    const commentText = commentFieldRef.current.value;
    if (commentText) {
      if (onClick) {
        onClick({ comment: commentText });
        commentFieldRef.current.value = '';
      }
    }
  };

  return (
    <Row lg="auto">
      <Col lg>
        <Form.Control
          ref={commentFieldRef}
          type="text"
          as="textarea"
          placeholder={t('Add comment')}
          size="sm"
          rows={2}
          cols={100}
        />
      </Col>
      <Col lg={1} className="py-1">
        <Button
          variant="primary"
          size="sm"
          onClick={handleAddComment}
          className="text-nowrap"
        >
          {t('Add')}
        </Button>
      </Col>
    </Row>
  );
};

AddComment.propTypes = {
  onClick: func.isRequired,
};
