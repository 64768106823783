import React from 'react';
import { useTranslation } from '../../utils/Localization';

export function handleObject(value: any) {
  let label =
    (typeof value.label === 'string' ? value.label : value.value) ||
    value.key ||
    value.distance ||
    null;
  if (value.location) {
    label = `${value.location} - ${label}`;
  }
  // Detect if value is from a chart with chartType as custom
  if (value && typeof value === 'object') {
    if (value.mainLabel || value.secondaryLabel) {
      if (value.mainLabel) {
        label = `${value.mainLabel}`;
      } else {
        label = `${value.secondaryLabel}`;
      }
    }
  }
  return label;
}

const FilterValue = ({ value = null }): JSX.Element => {
  const i18n = useTranslation();
  if (!value) return null;

  // eslint-disable-next-line no-nested-ternary
  const displayValue =
    typeof value === 'string' || typeof value === 'number'
      ? i18n(value)
      : Array.isArray(value)
      ? value.map((v) => i18n(v, {}, { context: 'datasources' })).join(', ')
      : handleObject(value);

  return <span>{displayValue}</span>;
};

export default FilterValue;
