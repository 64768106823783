import { ReactiveComponent } from '@appbaseio/reactivesearch';
import React from 'react';
import Chart from 'react-apexcharts';
import uniqueId from 'lodash/uniqueId';
import { layerChartColors } from '../../utils/colors';

function buildLayerChartSeries(buckets) {
  const series = [];
  const m = new Map();

  buckets.forEach((b0) => {
    b0.layers.buckets.forEach((b1) => {
      if (!m.has(b1.key)) {
        m.set(b1.key, []);
      }
      m.get(b1.key).push([b0.key, b1.doc_count]);
    });
  });

  m.forEach((v, k) => {
    series.push({ name: k, data: v });
  });
  return series;
}

const layerChartOptions = {
  chart: {
    stacked: true,
    animations: { enabled: false },
  },
  colors: layerChartColors,
  fill: {
    /* type: 'gradient', */
    gradient: {
      shadeIntensity: 0.4,
      opacityFrom: 0.9,
      opacityTo: 0.9,
    },
  },
  stroke: {
    curve: 'smooth',
    width: 1,
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'center',
  },
  dataLabels: {
    enabled: false,
  },
  theme: {
    /* palette: 'palette2', */
    /* monochrome: {
      enabled: true,
      color: '#662E9B',
      shadeTo: 'light',
    }, */
  },
};

export const LayerChart = ({
  chartId = uniqueId('layerchart'),
  chartProps = {},
  field = 'publication_date',
  rangeField = 'misc.miles',
  interval = 'month',
  react = undefined,
}) => (
  <ReactiveComponent
    componentId={chartId}
    defaultQuery={() => ({
      /* query: {
        range: {
          publication_date: {
            gte: 'now-10y/y',
          },
        },
      }, */
      size: 0,
      aggs: {
        data: {
          date_histogram: {
            field,
            interval,
          },
          aggs: {
            layers: {
              range: {
                field: rangeField,
                ranges: [
                  {
                    key: 'no mileage available',
                    to: 0,
                  },
                  {
                    key: '<25k',
                    from: 1,
                    to: 25000,
                  },
                  {
                    key: '25k-50k',
                    from: 25000,
                    to: 50000,
                  },
                  {
                    key: '50k-75k',
                    from: 50000,
                    to: 75000,
                  },
                  {
                    key: '75k-100k',
                    from: 75000,
                    to: 100000,
                  },
                  {
                    key: '100k-125k',
                    from: 100000,
                    to: 125000,
                  },
                  {
                    key: '125k-150k',
                    from: 125000,
                    to: 150000,
                  },
                  {
                    key: '>150k',
                    from: 150000,
                  },
                ],
              },
            },
          },
        },
      },
    })}
    render={(props) => {
      const { aggregations, loading, error } = props;
      if (error) return 'Error';
      if (!aggregations) return null;

      const data0 = aggregations.data.buckets;

      const options = { ...layerChartOptions };
      options.xaxis = {
        type: 'datetime',
      };
      options.chart = { ...options.chart };
      options.chart.id = chartId;

      const series = buildLayerChartSeries(data0);

      return (
        <>
          <div className="position-relative">
            {loading && (
              <span className="position-absolute top-0 start-0 badge bg-secondary">
                Loading...
              </span>
            )}
          </div>
          <Chart
            options={options}
            series={series}
            type="area"
            {...chartProps}
          />
        </>
      );
    }}
    react={react}
  />
);

const MileageLayerChart = ({ interval = 'month', ...rest }) => (
  <LayerChart
    field="publication_date"
    rangeField="mileage"
    interval={interval}
    {...rest}
  />
);

export default MileageLayerChart;
