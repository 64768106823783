import React from 'react';
import { LightbulbFill, LightbulbOffFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useSocket, withCommentsWs } from '../websocket';

const ConnectionState = (): JSX.Element => {
  const { connected } = useSocket();
  const { t } = useTranslation('connection-state');
  return (
    <div className="btn btn-sm">
      {connected ? (
        <LightbulbFill color="lightgreen" size={12} title={t('connected')} />
      ) : (
        <LightbulbOffFill
          color="lightgrey"
          size={12}
          title={t('disconnected')}
        />
      )}
    </div>
  );
};

export default withCommentsWs(ConnectionState);
