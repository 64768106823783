import { ReactiveBase } from '@appbaseio/reactivesearch';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAccessToken, useUserProfile } from '../auth/Auth0';
import { useReactiveProps } from './ReactiveProps';
import { prepareRequest } from './prepareRequest';
import defaultTheme from './reactivesearch.theme.json';

function ReactiveApp(props) {
  const {
    app, children, theme, ...rest 
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const reactiveProps = useReactiveProps(app);
  const userProfile = useUserProfile();
  const { isAuthenticated } = useAuth0();
  const accessToken = useAccessToken();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (!reactiveProps) return <div />;

  return (
    <ReactiveBase
      {...reactiveProps}
      theme={theme || defaultTheme}
      transformRequest={(req) => prepareRequest(req, accessToken)}
      getSearchParams={() => {
        const current = location.search;
        return current;
      }}
      credentials="nobodycares"
      setSearchParams={(url) => {
        const u = new URL(url);
        const value = `${u.pathname}${u.search}`;
        navigate(value);
      }}
      appbaseConfig={{
        enableAppbase: true,
        recordAnalytics: true, // enables header "x-search-query" (full text search value)
        userId: userProfile.email, // enables header "x-user-id",
      }}
      {...rest}
    >
      {children}
    </ReactiveBase>
  );
}

ReactiveApp.propTypes = {
  app: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default withAuthenticationRequired(ReactiveApp);
