import { useEffect, useState } from 'react';
import { BehaviorSubject, catchError, merge, of, switchMap } from 'rxjs';
import { useAccessToken } from '../../auth/Auth0';

interface IValidateQueryState {
  loading: boolean;
  valid: boolean;
  error: string;
}

const buildPostQuery = (queryString: string) => ({
  query: { bool: { must: { query_string: { query: queryString } } } }
});

const validateQuery = (
  elasticUrl: string,
  accessToken: string,
  query: string,
  abortController?: AbortController
) => {
  const endpoint = '_validate/query';
  if (!query) {
    Promise.resolve({ valid: true });
  }
  return fetch(`${elasticUrl}_all/${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(buildPostQuery(query)),
    signal: abortController && abortController.signal
  });
};

export const useValidateQuery = (
  elasticUrl: string,
  query: string
): IValidateQueryState => {
  const accessToken = useAccessToken();
  const [state, setState] = useState({
    loading: false,
    valid: true,
    error: null
  });
  const [subject, setSubject] = useState<BehaviorSubject<string>>(null);

  /* input validation effect */
  useEffect(() => {
    if (subject === null) {
      setSubject(new BehaviorSubject<string>(''));
    } else {
      const observeInput$ = subject.pipe(
        switchMap((term: string) =>
          merge(
            of({
              loading: true,
              error: null
            }),
            validateQuery(elasticUrl, accessToken, term).then(
              async (response) => {
                if (response.ok) {
                  return response.json().then((data) => ({
                    loading: false,
                    valid: data.valid
                  }));
                }
                return {
                  loading: false,
                  error: response.statusText
                };
              }
            )
          )
        ),
        catchError((error) => of({ loading: false, error: error.message }))
      );

      const subscribe = observeInput$.subscribe((newState) => {
        setState((s) => Object.assign({}, s, newState));
      });

      return () => {
        subscribe.unsubscribe();
      };
    }
  }, [subject]);

  useEffect(() => {
    if (subject) {
      subject.next(query);
    }
  }, [query, subject]);

  return state;
};
