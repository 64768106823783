import EventBus from './eventbus';

const MessageBus = EventBus.get('messagebus');

export const MessageTypes = Object.freeze({
  MESSAGE: Symbol('message'),
  SUCCESS: Symbol('success'),
  WARNING: Symbol('warning'),
  ERROR: Symbol('error'),
});

export function msgSuccess(message) {
  MessageBus.emit(MessageTypes.SUCCESS, message);
}

export function msgWarning(message) {
  MessageBus.emit(MessageTypes.WARNING, message);
}

export function msgError(message) { 
  MessageBus.emit(MessageTypes.ERROR, message);
}

export function msg(message) { 
  MessageBus.emit(MessageTypes.MESSAGE, message);
}

export default MessageBus;
