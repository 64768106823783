import { usePageContext } from '@/context/PageContext';
import { useSocket, withWs } from '@/websocket';
import React, { useEffect, useReducer } from 'react';
import { Stack } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { SimpleTogglePanel } from '../SimpleTogglePanel';

const dispatcher = (state: any, action: any) => {
  if (action.data.error !== undefined) {
    console.log(action.data.error);
    return state;
  }
  switch (action.event) {
    case 'find-by-user':
      return { ...state, refinements: action.data };
    default:
      return state;
  }
};

const SavedFilters = (props: {
  context?: string;
  onSelect?: Function;
}): JSX.Element => {
  const { t } = useTranslation('saved-refinements');
  const { context } = usePageContext();
  const { socket, subscribeEvent } = useSocket();
  const [state, dispatch] = useReducer(dispatcher, {
    refinements: []
  });

  useEffect(() => {
    const unsubscribe = subscribeEvent('find-by-user', (data: any) => {
      dispatch({ event: 'find-by-user', data });
    });
    socket.then((s) =>
      s?.emit('find-by-user', { context: props.context || context })
    );
    return () => {
      unsubscribe();
    };
  }, [socket, subscribeEvent]);

  const handleDelete = (id: any, name: string) => {
    if (confirm(t('confirm-delete', { name })))
      socket.then((s) =>
        s?.emit('delete', { id, context: props.context || context })
      );
  };

  const handleSelect = (id: any) => {
    const refinement = state.refinements.find((d: any) => d.id === id);
    if (!refinement) return;

    if (props.onSelect) {
      const searchState = (refinement.values as any[]).reduce(
        (acc: any, r: any) => {
          acc[r.name] = JSON.parse(r.value);
          return acc;
        },
        {}
      );
      props.onSelect(searchState);
    } else {
      const params = new URLSearchParams();
      (refinement.values as any[]).forEach(({ name, value }: any) => {
        if (value) params.append(name, value);
      });
      window.location.replace(`?${params.toString()}`);
    }
  };

  return (
    <SimpleTogglePanel label={t('refinements-filters-title')}>
      {/* <JSONOut json={state} /> */}
      <Stack>
        {state.refinements?.map((d: any) => (
          <div key={d.id} className="d-flex flex-row">
            <div
              role="menuitem"
              className="flex-grow-1"
              onClick={() => handleSelect(d.id)}
            >
              {d.name}
            </div>
            <div>
              <Trash
                title={t('delete-refinement')}
                role="menuitem"
                size={16}
                onClick={() => handleDelete(d.id, d.name)}
              />
            </div>
          </div>
        ))}
      </Stack>
    </SimpleTogglePanel>
  );
};

export default withWs(SavedFilters, {
  namespace: 'refinements'
});
