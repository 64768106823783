export const comboHistoryChartsQuery = (prefix = 'cw3_cst_demo', index) => ({
  query: {
    bool: {
      filter: {
        exists: {
          field: 'facets.found_components',
        },
      },
    },
  },
  size: 0,
  aggs: {
    data: {
      filter: {
        term: {
          _index: `${prefix}_${index}`,
        },
      },
      aggs: {
        cw_year: {
          terms: {
            field: 'facets.cw_year.keyword',
            size: 2,
            order: { "_key": "desc" }
          },
          aggs: {
            cw: {
              terms: {
                field: 'facets.cw.keyword',
                size: 55,
                order: { "_key": "desc" }
              },
              aggs: {
                emerging: {
                  filter: {
                    exists: { field: 'facets.found_emerging_problem.keyword' }
                  }
                },
                catastrophic: {
                  filter: {
                    exists: { field: 'facets.found_catastrophic_problem.keyword' }
                  }
                }
              }
            }
          }
        },
      },
    },
  },
});

export function getOptions(categories) {
  const options = {
    chart: {
      height: 350,
      type: 'line',
    },
    stroke: {
      width: [2, 2, 2],
      curve: 'smooth', 
      lineCap: 'round',
    },
    labels: categories,
    xaxis: {
      type: 'category',
      tickAmount: 5, 
      labels: {
        style: {
          colors: ['#333'], 
          fontSize: '12px', 
        }
      }
    },
    yaxis: {
      title: {
        text: 'Count',
        style: {
          color: '#333', 
          fontSize: '12px', 
        }
      },
      labels: {
        style: {
          colors: '#333', 
          fontSize: '12px',
        }
      }
    },
    grid: {
      borderColor: '#ddd', 
      padding: {
        left: 30, 
        right: 20,
      }
    },
    tooltip: {
      shared: true,
      intersect: false, 
      style: {
        fontSize: '14px',
      }
    },
    legend: {
      position: 'bottom', 
      horizontalAlign: 'center', 
      markers: {
        width: 12, 
        height: 12,
      },
      itemMargin: {
        vertical: 3, 
      },
    },
    fill: {
      type: 'gradient', 
      gradient: {
        shade: 'light',
        type: 'horizontal', 
        shadeIntensity: 0.25, 
        opacityFrom: 1, 
        opacityTo: 1, 
        stops: [0, 100], 
      }
    },
  };
  return options;
}



export function toComboHistorySeries(args) {
  let yearBuckets = args?.data.cw_year.buckets;
  let categories = [];
  let totalCount = [];
  let catastrophicCount = [];
  let emergingCount = [];
  yearBuckets.map((y) => {
    let cw_year = y.key;
    y.cw.buckets.map((w) => {
      totalCount.unshift(w.doc_count);
      let week = w.key;
      let cat_string = `${cw_year} W${week}`;
      categories.unshift(cat_string);
      const catastrophic = w.catastrophic ? w.catastrophic.doc_count : 0;
      catastrophicCount.unshift(catastrophic);
      const emerging = w.emerging?.doc_count ?? 0;
      emergingCount.unshift(emerging);
    })
  });
  const dataSeries = [{
    name: 'High Priority',
    type: 'column',
    data: catastrophicCount,
    color: '#FF6347'
  }, {
    name: 'Emerging',
    type: 'column',
    data: emergingCount,
    color: '#FFD700'
  }, {
    name: 'Total Count',
    type: 'line',
    data: totalCount,
    color: '#4682B4'
  }]
  return { categories, dataSeries }
}
