import { without } from 'lodash-es';
import { array, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import NoResults from '../NoResults';
import { CustomerNameTitle } from './DetectedRefinements';
import { FilterableMultiListRefinement } from './MultiListRefinement';

export const useCustomerRefinements = () => {
  const [refinements, setRefinements] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const r = await import(
          /* webpackChunkName: "refinements" */
          `./refinements_${process.env.appconfig?.instance_name}.json`
        );
        setRefinements(r.default);
      } catch (e) {
        setRefinements([]);
      }
    })();
  }, []);
  return refinements;
};

export const useCustomerRefinementIds = (defaultRefinementIds = []) => {
  const refinements = useCustomerRefinements();
  const [refinementIds, setRefinementsIds] = useState(defaultRefinementIds);
  useEffect(() => {
    const rids = refinements.map((r) => r.component_id);
    setRefinementsIds([...rids, ...defaultRefinementIds]);
  }, [defaultRefinementIds, refinements]);
  return refinementIds;
};

export const CustomerRefinements = ({
  className = '',
  react = [],
  ...rest
}) => {
  const refinements = useCustomerRefinements();
  const { t } = useTranslation('customer-refinements');

  const refinementsIds = refinements.map((r) => r.component_id);

  const refinementsList = refinements.map((r) => (
    <FilterableMultiListRefinement
      key={r.label}
      title={t(r.label)}
      componentId={r.component_id}
      filterField={r.field}
      dataField={r.keyword_field}
      renderNoResults={NoResults}
      filterLabel={t(r.label)}
      URLParams
      react={{
        and: without([...refinementsIds, ...react], r.component_id)
      }}
      {...rest}
    />
  ));

  return (
    <div
      style={{ backgroundColor: 'var(--bs-gray-200)' }}
      className="p-2 rounded"
    >
      <div className={className}>
        <CustomerNameTitle title="specific">
          {({ title }) => (
            <h6 className="text-decoration-underline">
              <b>{title}</b>
            </h6>
          )}
        </CustomerNameTitle>
        {refinementsList}
      </div>
    </div>
  );
};

CustomerRefinements.propTypes = {
  className: string,
  react: array
};

export default styled(CustomerRefinements)`
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px; */
  background-color: var(--facettes-bg-color);
  padding: 0.5rem;
`;
