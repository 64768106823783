import React, { useCallback, useEffect } from 'react';
import { getByIdsQuery } from '../../elasticsearch';
import { useSocket, withFavoritesWs } from '../../websocket';
import SearchResultList from '../SearchResultList';

const FavoritesResultList = (props) => {
  const [favorites, setFavorites] = React.useState([]);
  const { subscribeEvent, socket } = useSocket();

  useEffect(() => {
    const unsubscribeGetUserFavorites = subscribeEvent(
      'user-favorites',
      (value) => {
        setFavorites(value.favorites);
      },
    );
    socket.then((s) => s?.emit('user-favorites'));
    return () => {
      unsubscribeGetUserFavorites();
    };
  }, [subscribeEvent, socket]);

  const queryFunc = useCallback(
    () => getByIdsQuery(
      favorites?.length
        ? favorites
          .map((f) => {
            const fid = /refine_id="([^"]*)"/.exec(f.entityref)?.[1];
            return fid && decodeURIComponent(fid);
          })
          .filter((fid) => !!fid)
        : ['---no id---'],
    ),
    [favorites],
  );

  return <SearchResultList {...props} defaultQuery={queryFunc} />;
};

export default withFavoritesWs(FavoritesResultList);
