import { useEffect, useState } from 'react';
import { useAccessToken, useUserProfile } from '../../auth/Auth0';
import { getCommentsByUserEmailInContent } from './api';

export const useMentionedInComments = () => {
  const accessToken = useAccessToken();
  const profile = useUserProfile();
  const [comments, setComments] = useState([]);

  useEffect(() => {
    if (accessToken) {
      getCommentsByUserEmailInContent(
        { accessToken },
        { userEmail: profile?.email },
      )
        .then(setComments)
        .catch((error) => console.error(error));
    }
  }, [accessToken, profile?.email]);

  return comments;
};
