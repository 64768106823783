const emergencyAggs = {
  found_all: {
    terms: {
      field: 'region.keyword',
    },
  },
  found_catastrophic_problem: {
    filter: {
      exists: {
        field: 'facets.found_catastrophic_problem',
      },
    },
    aggs: {
      regions: {
        terms: {
          field: 'region.keyword',
        },
      },
    },
  },
  found_emerging_problem: {
    filter: {
      exists: {
        field: 'facets.found_emerging_problem',
      },
    },
    aggs: {
      regions: {
        terms: {
          field: 'region.keyword',
        },
      },
    },
  },
  found_other: {
    filter: {
      bool: {
        must_not: [
          {
            exists: {
              field: 'facets.found_catastrophic_problem',
            },
          },
          {
            exists: {
              field: 'facets.found_emerging_problem',
            },
          },
        ],
      },
    },
    aggs: {
      regions: {
        terms: {
          field: 'region.keyword',
        },
      },
    },
  },
};

export const regionChartsQuery = (prefix = 'cw3_cst_demo') => ({
  query: {
    bool: {
      filter: {
        exists: {
          field: 'facets.found_components',
        },
      },
    },
  },
  size: 0,
  aggs: {
    recalls_etc: {
      filter: {
        terms: {
          _index: [
            `${prefix}_recalls`,
            `${prefix}_investigations`,
            `${prefix}_bulletins`,
          ],
        },
      },
      aggs: emergencyAggs,
    },
    complaints: {
      filter: {
        term: {
          _index: `${prefix}_complaints`,
        },
      },
      aggs: emergencyAggs,
    },
    mentions: {
      filter: {
        term: {
          _index: `${prefix}_socialweb*`,
        },
      },
      aggs: emergencyAggs,
    },
  },
});

export const regionBarChartOptions = {
  plotOptions: {
    bar: {
      horizontal: true,
      columnWidth: '40%',
      barHeight: '40%',
      colors: {
        backgroundBarColors: [],
      },
    },
  },
  xaxis: {
    labels: {
      show: false,
    },
  },
  yaxis: { showForNullSeries: false },
  noData: {
    text: 'No Data',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: '14px',
      fontFamily: undefined,
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'light',
      type: 'vertical',
      shadeIntensity: 0.5,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100],
    }
  },
  tooltip: {
    x: {
      show: true,
    },
    y: {
      title: {
        formatter() {
          return 'Incidents:';
        },
      },
    },
  },
  chart: { toolbar: { show: false } },
};

export function toSeries(agg) {
  const { buckets } = agg.regions;
  const series = buckets.map((b) => ({ x: b.key, y: b.doc_count }));
  return { data: series };
}
