import { ReactiveComponentProps } from '@appbaseio/reactivesearch/lib/components/basic/ReactiveComponent';
import React from 'react';
import { useTranslation } from '../../utils/Localization';
import TogglePanel from '../SimpleTogglePanel';
import InputRefinement from './InputRefinement';

const OfficialId = ({ componentId }: { componentId: string }) => {
  const i18n = useTranslation();
  return (
    <InputRefinement
      componentId={componentId}
      dataField="official_id.keyword"
      URLParams
      showFilter
      filterLabel={i18n('Official ID')}
      inputProps={{ placeholder: i18n('Official ID'), size: 'sm' }}
    />
  );
};

export default (props: ReactiveComponentProps & { label: string }) => {
  const { label, ...rest } = props;
  return (
    <TogglePanel stateful initialOpen label={label}>
      <OfficialId {...rest} />
    </TogglePanel>
  );
};
