import React from 'react';
import { Outlet } from 'react-router-dom';
import { AppStateProvider } from './AppStateProvider';
import { StyledNavigation } from './Navigation';
import { Auth0Wrapper } from './auth/Auth0';
import ErrorBoundary from './components/ErrorBoundary';
import RouteObserver from './RouteObserver';

export function Layout(): JSX.Element {
  return (
    <ErrorBoundary>
      <Auth0Wrapper>
        <AppStateProvider>
          <RouteObserver />
          <StyledNavigation />
          <Outlet />
        </AppStateProvider>
      </Auth0Wrapper>
    </ErrorBoundary>
  );
}
