import React, { useCallback } from 'react';
import { getByIdsQuery } from '../../elasticsearch';
import SearchResultList from '../SearchResultList';
import { useMentionedInComments } from './useMentionedInComments';

const MentionedInCommentsResultList = (props) => {
  const comments = useMentionedInComments();
  const queryFunc = useCallback(
    () => getByIdsQuery(
      comments?.length
        ? comments.map((f) => {
          const mid = /refine_id="([^"]*)"/.exec(f.entityref)[1];
          return mid ? decodeURIComponent(mid) : mid;
        })
        : ['---no id---'],
    ),
    [comments],
  );
  return <SearchResultList {...props} defaultQuery={queryFunc} />;
};

export default MentionedInCommentsResultList;
