import { useAuth0 } from '@auth0/auth0-react';
import { node } from 'prop-types';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useUserRoutes } from '../auth/Auth0';
import Auth0LoginTrigger from '../auth/Auth0LoginTrigger';

export function PrivateSection(props) {
  const { children, renderNoAccess } = props;
  const { isAuthenticated } = useAuth0();
  const { pathname: path } = useLocation();
  const userSections = useUserRoutes();

  if (!isAuthenticated) return <Auth0LoginTrigger />;

  const renderPath = userSections.some((section) => {
    if (section.endsWith('*')) {
      return path.startsWith(section.slice(0, -1));
    } 
    return path === section;
  });

  return renderPath ? (
    children ? (
      typeof children === 'function' ? (
        children()
      ) : (
        children
      )
    ) : (
      <Outlet />
    )
  ) : (
    renderNoAccess
  );
}

PrivateSection.propTypes = {
  children: node,
  renderNoAccess: node,
};
