import { IAuth0User } from '@/auth/types';
import { SearchResultList, SimpleTogglePanel } from '@/components';
import AsyncComponentLoader from '@/components/Loader/AsyncLoader';
import { ReactiveComponentRenderer } from '@/components/ReactiveComponentRenderer';
import { SearchCasesRefinementWithPanel } from '@/components/SearchCases/SearchCasesRefinement';
import {
  AggregationsStringTermsAggregate,
  AggregationsStringTermsBucket
} from '@/elasticsearch/types';
import useContextMenu from '@/react/useContextMenu';
import { useTranslation } from '@/utils/Localization';
import millify from 'millify';
import { string } from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Hourglass, X } from 'react-bootstrap-icons';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { SimpleBarChart } from '../../components/Charts/ApexCharts';
import { RecallsResultListItem } from '../../components/Recalls';
import { CWSelectedFilters } from '../../components/Refinements';
import FullTextSearchComponent, {
  definedSearchFields
} from '../../components/Search/FullTextSearch';
import { useConfig } from '../../Config';
import { useInstanceUsers } from '../../components/System/useInstanceUsers';
import ReactiveApp from '../../reactivesearch/ReactiveApp';

const ModalContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  left: 20%;
  right: 20%;
  top: calc(50% - 50px);
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 gray;
  padding: 2rem;
`;

function ModalContent({ onClose }: { onClose: () => void }) {
  return (
    <ModalContainer>
      <div>I&#39;m a modal dialog</div>
      <button type="button" onClick={onClose}>
        Close
      </button>
    </ModalContainer>
  );
}

function PortalExample() {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <button type="button" onClick={() => setShowModal(true)}>
        Show modal using a portal
      </button>
      {showModal &&
        createPortal(
          <ModalContent onClose={() => setShowModal(false)} />,
          document.body
        )}
    </>
  );
}

const DataSourceBarChartRefinement = (props: any) => {
  const dataField = 'datasource.keyword';
  const i18nDS = useTranslation('datasources');
  const { componentId } = props;
  return (
    <ReactiveComponentRenderer
      componentId={componentId}
      defaultQuery={() => ({
        size: 0,
        aggs: {
          [dataField]: {
            terms: {
              field: dataField,
              size: 100
              /* min_doc_count: 10000, */
            }
          }
        }
      })}
      filterLabel="Datasource"
      URLParams
      render={({ aggregations, value, setQuery }) => (
        <SimpleBarChart
          buckets={
            (aggregations[dataField] as AggregationsStringTermsAggregate)
              .buckets as AggregationsStringTermsBucket[]
          }
          value={value}
          onSelect={(v) =>
            v && v.length
              ? setQuery({
                  query: { terms: { [dataField]: v } },
                  value: v
                })
              : setQuery(null)
          }
          multiSelect
          chartProps={{ height: 300 }}
          chartOptions={{
            plotOptions: { bar: { horizontal: true } },
            xaxis: {
              labels: { formatter: (l: any) => millify(l) },
              tickAmount: 1
            },
            yaxis: {
              labels: {
                formatter(l: any) {
                  if (typeof l === 'string') {
                    const mapped = i18nDS(l);
                    return mapped.length > 20
                      ? `${mapped.slice(0, 16)}...`
                      : mapped;
                  }
                  return l;
                },
                minWidth: 100
              }
            },
            dataLabels: { enabled: false },
            tooltip: {
              x: {
                formatter(l: any) {
                  if (typeof l === 'string') {
                    const mapped = i18nDS(l);
                    return mapped;
                  }
                  return l;
                }
              }
            }
          }}
        />
      )}
    />
  );
};

DataSourceBarChartRefinement.propTypes = {
  componentId: string.isRequired
};

const InstanceUsers = () => {
  const users = useInstanceUsers();

  return (
    <ul>
      {users
        .sort((u1, u2) => u1.email.localeCompare(u2.email))
        .map((u) => (
          <li key={u.user_id}>{u.name || `*${u.email}`}</li>
        ))}
    </ul>
  );
};

type TUser = IAuth0User;

const ContextMenu = styled(
  ({
    className,
    items,
    onSelect
  }: {
    className?: string;
    items: TUser[];
    onSelect: (v: TUser) => void;
  }) => {
    const select = useRef<HTMLSelectElement>(null);
    useEffect(() => {
      select.current?.focus();
    }, []);
    const handleSelect = (user_id: string) => {
      onSelect(items.find((i: any) => i.user_id === user_id));
    };

    return (
      <div
        className={className}
        role="menu"
        aria-labelledby="contextmenu-label"
      >
        <div className="d-flex flex-row px-1 align-items-center">
          <div aria-label="Select User" className="fw-bold flex-grow-1">
            Select User
          </div>
          <X role="button" onClick={() => handleSelect(null)} />
        </div>
        <Form.Select
          ref={select}
          onDoubleClick={(e) =>
            handleSelect((e.target as HTMLSelectElement).value)
          }
          onKeyUp={(e) =>
            e.key === 'Enter' &&
            handleSelect((e.target as HTMLSelectElement).value)
          }
          size="sm"
          htmlSize={10}
        >
          {items.map((item: TUser) => (
            <option key={item.user_id} value={item.user_id}>
              {item.name || `*${item.email}`}
            </option>
          ))}
        </Form.Select>
      </div>
    );
  }
)<{ $position: any }>`
  position: absolute;
  top: ${(p) => p.$position.y}px;
  left: ${(p) => p.$position.x}px;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 0.2em;
  min-width: 100px;
  overflow-y: auto;
`;

const ContextMenuExample = () => {
  const textarea = useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = useState('');
  const { menuVisible, menuItems, menuPosition, showMenu, hideMenu } =
    useContextMenu();
  const users = useInstanceUsers();

  const handleSelect = useCallback(
    (v: TUser) => {
      if (textarea.current && v) {
        const { selectionStart, selectionEnd, value } = textarea.current;
        const text = `@${v.email}`;
        setValue(
          `${value.substring(0, selectionStart)}${text}${value.substring(
            selectionEnd,
            value.length
          )}`
        );
        textarea.current.scrollBy(0, 1);
      }

      hideMenu();
      textarea.current.focus();
    },
    [textarea]
  );

  return (
    <>
      <Form.Control
        ref={textarea}
        as="textarea"
        size="sm"
        value={value}
        onKeyDown={(e) => {
          if (e.key === '@') {
            const { x, y } = textarea.current.getBoundingClientRect();
            const me = new MouseEvent('contextmenu', {
              bubbles: true,
              cancelable: false,
              view: window,
              button: 2,
              buttons: 0,
              clientX: x + 10,
              clientY: y + 10
            });
            textarea.current.dispatchEvent(me);
          }
        }}
        onChange={(e) => setValue(e.target.value)}
        rows={3}
        contextMenu="true"
        placeholder="Comment"
        onContextMenu={(e: any) => {
          e.preventDefault();
          showMenu(e, menuItems);
        }}
      />
      {menuVisible && (
        <ContextMenu
          items={users}
          $position={menuPosition}
          onSelect={handleSelect}
        />
      )}
    </>
  );
};

export default () => {
  const config = useConfig();
  const indexName = config.getIndexName('recalls', 'cw3_cst_');

  return (
    <ReactiveApp app={indexName}>
      <Container fluid>
        <Row>
          <Col sm={3}>
            <ContextMenuExample />
            <SimpleTogglePanel label="Auth0 Users" initialOpen={false}>
              <InstanceUsers />
            </SimpleTogglePanel>
            <SearchCasesRefinementWithPanel
              componentId="refinement_searchcases"
              context="recalls"
              suggestionIndex={indexName}
              showFilter
              filterLabel="SearchCase"
            />
            <hr />
            <PortalExample />
            <hr />
            <AsyncComponentLoader
              asyncFunction={async () => {
                const response = await fetch(
                  'https://jsonplaceholder.typicode.com/todos/1'
                );
                return response.json();
              }}
              render={(data) => <div>{data.title}</div>}
              renderLoader={() => <Hourglass />}
            />
            <hr />
            <DataSourceBarChartRefinement componentId="rbc_datasources" />
          </Col>
          <Col sm={8}>
            <FullTextSearchComponent
              dataField={definedSearchFields('recalls')}
            />
            <CWSelectedFilters context="recalls" />
            <hr />
            <SearchResultList
              componentId="searchresults"
              dataField="publication_date"
              react={{
                and: [
                  'q',
                  'renderTest',
                  'rbc_datasources',
                  'refinement_searchcases'
                ]
              }}
              renderItem={(hit) => (
                <RecallsResultListItem key={hit._id} hit={hit} />
              )}
            />
          </Col>
        </Row>
      </Container>
    </ReactiveApp>
  );
};
