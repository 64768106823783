import { StateProvider } from '@appbaseio/reactivesearch';
import { object, string } from 'prop-types';
import React, { useEffect } from 'react';
import { Trash } from 'react-bootstrap-icons';
import { useTranslation, withTranslation } from 'react-i18next';
import { useAppState } from '../../AppStateProvider';
import {
  SearchCasesContext,
  useSearchCases,
} from '../SearchCases/SearchCasesContext';
import { SimpleTogglePanel } from '../SimpleTogglePanel';
import { mapActiveFilters } from './SelectedRefinements';

export const DefinedFilters = ({ activeFilters }) => {
  const { t } = useTranslation('searchcases');
  const { showError } = useAppState();

  const {
    searchCases,
    deleteSearchCase,
    fetchSearchCases,
    updateSearchCase,
    error,
  } = useSearchCases();

  useEffect(() => {
    fetchSearchCases();
  }, [fetchSearchCases]);

  const handleSaveSearchCase = async () => {
    // eslint-disable-next-line no-alert
    const name = prompt(t('input-name'));
    if (name) {
      try {
        await updateSearchCase(name, { name, refinements: activeFilters });
        await fetchSearchCases();
      } catch (e) {
        showError(`Searchcases: ${e.message}`);
      }
    }
  };

  const applyRefinements = (refinements) => {
    if (!refinements) {
      // eslint-disable-next-line no-alert
      alert('No Refinements in this Search Case'); // this should never happen
      return;
    }
    const params = new URLSearchParams();
    Object.entries(refinements).forEach(([key, value]) => {
      if (value) params.append(key, JSON.stringify(value));
    });
    window.location.replace(`?${params.toString()}`);
    // below code is not working properly, filters are set but not applied
    // navigate({ pathname: '.', search: `?${params.toString()}` }, { replace: true });
  };

  const handleDeleteSearchCase = async (name) => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (name && confirm(t('confirm-delete'))) {
      await deleteSearchCase(name);
    }
  };

  if (error) {
    showError(`Searchcases: ${error.message}`);
  }

  return (
    <>
      <div>
        {searchCases
          && searchCases
            .filter((sc) => sc._source.refinements)
            .map((sc) => (
              <div key={sc._id} className="d-flex">
                <div
                  role="menuitem"
                  className="flex-grow-1"
                  onClick={() => applyRefinements(sc._source.refinements)}
                  onKeyUp={() => applyRefinements(sc._source.refinements)}
                  tabIndex="-1"
                >
                  {sc._source.name}
                </div>
                <div
                  role="menuitem"
                  className=""
                  onClick={() => handleDeleteSearchCase(sc._id)}
                  onKeyUp={() => handleDeleteSearchCase(sc._id)}
                  tabIndex="-1"
                  aria-label="Delete Searchcase"
                >
                  <Trash
                    title={t('delete-search-case')}
                    height="16px"
                    width="16px"
                  />
                </div>
              </div>
            ))}
      </div>

      {Object.keys(activeFilters).length !== 0 && (
        <div className="d-flex justify-content-end">
          <i
            role="link"
            title={t('save-search-case')}
            onClick={handleSaveSearchCase}
            className="bi bi-save"
            aria-hidden="true"
          />
        </div>
      )}
    </>
  );
};

DefinedFilters.propTypes = {
  activeFilters: object.isRequired,
};

export default Object.assign(
  withTranslation('searchcases')(
    ({ context, t }) => (
      <SearchCasesContext context={context} immediateFetch={false}>
        <StateProvider includeKeys={['isLoading', 'error', 'value']}>
          {({ searchState }) => {
            const activeFilters = mapActiveFilters(searchState);
            return (
              <SimpleTogglePanel
                label={`${t('defined-filters-title')} (legacy)`}
              >
                <DefinedFilters activeFilters={activeFilters} />
              </SimpleTogglePanel>
            );
          }}
        </StateProvider>
      </SearchCasesContext>
    ),
    {
      propTypes: {
        context: string.isRequired,
      },
    },
  ),
);
