import { forEach, isArray, isNaN } from 'lodash-es';
import React from 'react';
import { FormatDate, formatDate } from '../FormatDate';
import { MetaData } from './MetaData';
import { ILabeledTextWithContext } from './rli.interfaces';

export const MetaDate = ({
  label,
  context,
  value
}: ILabeledTextWithContext): JSX.Element => {
  if (isArray(value)) {
    const dates: string[] = [];
    forEach(value, (v) => {
      const d = formatDate(v);
      if (d) dates.push(d);
    });
    if (dates.length === 0) return null;
    const datesString = dates.join(', ');
    return <MetaData label={label} context={context} value={datesString} />;
  }
  const date = value;
  return date !== undefined &&
    date !== null &&
    !isNaN(new Date(date).valueOf()) ? (
    <MetaData label={label} context={context}>
      <FormatDate value={date} />
    </MetaData>
  ) : null;
};

export default MetaDate;
