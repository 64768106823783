import React, { useRef } from 'react';
import { Stack } from 'react-bootstrap';
import { Eye, PencilSquare, PlusLg } from 'react-bootstrap-icons';
import { ISearchCase } from './SearchCases.types';
import SearchCasesDialog from './SearchCasesDialog';

const SearchCasesList = ({
  searchCases,
  onSelect
}: {
  searchCases: ISearchCase[];
  onSelect: (sc: ISearchCase) => void;
}) => {
  const scDialog = useRef(null);
  return (
    <>
      <Stack>
        {searchCases
          .filter((s: any) => !s.refinements)
          .map((s) => (
            <div key={s.id} className="d-flex flex-wrap align-items-center">
              <div
                role="menuitem"
                onClick={() => {
                  onSelect(s);
                }}
                title={s.name}
                className="flex-grow-1"
              >
                {s.name}
              </div>
              <Eye
                role="menuitem"
                className="mx-1"
                onClick={() => {
                  scDialog.current.open(s.id);
                }}
              />
              <PencilSquare
                role="menuitem"
                className="mx-1"
                onClick={() => {
                  scDialog.current.open(s.id, true);
                }}
              />
            </div>
          ))}
      </Stack>

      <div className="text-end">
        <PlusLg
          role="menuitem"
          onClick={() => {
            scDialog.current.open('', true);
          }}
        />
      </div>

      <SearchCasesDialog ref={scDialog} />
    </>
  );
};

export default SearchCasesList;
