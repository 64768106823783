/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import {
  filter, findIndex, forEach, isArray, isEmpty, keys, 
} from 'lodash-es';
import React, { useEffect } from 'react';

import { Eye, PencilSquare } from 'react-bootstrap-icons';
import styled from 'styled-components';
import * as t from '../../utils/Localization';

import { useAsyncCall } from '../../react/Hooks';
import assert from '../../utils/assert';
import { renderDev } from '../Develop/DevComponent';
import Error from '../Error';
import { LoaderText } from '../Loader';
import { ReactiveComponentRenderer } from '../ReactiveComponentRenderer';
import TogglePanel from '../SimpleTogglePanel';
import SearchCaseEdit from './SearchCaseEdit';
import { SearchCasesContext, useSearchCases } from './SearchCasesContext';

function buildSearchCaseQuery(searchCase = {}) {
  function isBoolOp(operator, val) {
    // Has {bool: must: []} ?
    if (!val.bool || !val.bool[operator]) return false;

    // Doesn't have other stuff ?
    return keys(val).length === 1 && keys(val.bool).length === 1;
  }

  function flattenBool(operator, arr) {
    // Flatten bool.must
    let newArr = [];
    forEach(arr, (node) => {
      if (isBoolOp(operator, node)) {
        newArr = newArr.concat(node.bool[operator]);
      } else {
        newArr.push(node);
      }
    });
    return newArr;
  }

  function boolHelper(val, operator) {
    const isArr = isArray(val);
    if (isArr) {
      // Remove empty filters
      val = filter(val, (f) => !isEmpty(f));
      if (val.length === 1) {
        if (operator !== 'must_not') return val[0];
        [val] = val; // Unbox array
      } else if (val.length === 0) {
        return {};
      } else if (
        (operator === 'must' || operator === 'should')
        && findIndex(val, isBoolOp.bind(null, operator)) !== -1
      ) {
        val = flattenBool(operator, val);
      }
    }
    return {
      bool: {
        [operator]: val,
      },
    };
  }

  // eslint-disable-next-line no-unused-vars
  function wildcard(key, value, boost = 1.0) {
    return { wildcard: { [key]: { value, boost } } };
  }

  function bool(operator, value) {
    return boolHelper(value, operator);
  }

  function queryString(value) {
    return { query_string: { query: value } };
  }

  function addWildcard(arr, value, key, fieldNames, boost) {
    assert(Array.isArray(fieldNames), 'fieldNames must be an array');
    assert(fieldNames.length, 'fieldNames must not be empty');

    // const wildcards = [];
    if (value[key]?.length) {
      const sqs = {
        simple_query_string: {
          query: value[key]
            .map((v) => {
              const trimmedValue = v.trim();
              const isPhrase = trimmedValue.startsWith('"') && trimmedValue.endsWith('"');
              return !isPhrase && /[^a-zA-Z*?]/.test(trimmedValue)
                ? `"${trimmedValue.trim()}"`
                : trimmedValue.trim();
            })
            .join(' '),
          fields: fieldNames,
          default_operator: 'or',
          boost,
        },
      };
      arr.push(sqs);
      /* value[key].forEach((wc) => {
        fieldNames.forEach((fn) => {
          wildcards.push(wildcard(fn, wc, boost));
        });
      }); */
    }
    /* if (wildcards.length) {
      arr.push(bool('should', wildcards));
    } */
  }

  const sc = searchCase._source ?? searchCase;

  const target = [];
  const AddWildcard = addWildcard.bind(null, target, sc);
  AddWildcard('make', ['facets.make'], 1.2);
  AddWildcard('model', ['facets.model'], 1.2);
  AddWildcard(
    'component',
    ['facets.component', 'facets.found_components'],
    1.2,
  );
  AddWildcard('issue', [
    'body',
    'subject',
    'defect',
    'defect_description',
    'complaint',
  ]);

  // full text search
  const qq = [];
  const fieldNames = ['fulltext'];
  Object.keys(sc)
    .filter((k) => fieldNames.indexOf(k) !== -1)
    .forEach((key) => {
      const v = sc[key];
      if (isArray(v) && v.length) qq.push([...v].join(' '));
      else if (String(v).length) qq.push(v);
    });
  if (!sc.daterange && !target.length && !qq.length) return undefined;

  let query = qq.length
    ? bool('should', [bool('must', target), queryString(qq.join(' '))])
    : bool('must', target);

  if (sc.daterange) {
    // wrap query in additional bool to make sure daterange is in range
    query = bool('must', [
      {
        range: {
          publication_date: {
            gte: sc.daterange,
          },
        },
      },
      query,
    ]);
  }

  return query;
}

const SearchCaseIcon = styled(
  ({
    title, icon, onClick = () => {}, className, 
  }) => (
    <div
      style={{ flex: '0 0 1rem' }}
      className={`${className} text-end`}
      role="link"
      tabIndex="0"
      onKeyUp={() => {}}
      onClick={onClick}
      title={title}
      aria-label={title}
    >
      {icon}
    </div>
  ),
)`
  &:hover {
    opacity: 0.7;
  }
`;

const SearchCasesSelectItem = ({
  searchCases = [],
  onClick = () => {},
  onEdit = () => {},
}) => (
  <ul className="refinementlist p-0 mb-0">
    {searchCases.map((item) => {
      if (item._source.refinements) {
        return null; // filter out saved refinements
      }
      const itemId = item._id ?? item.id;
      const itemData = item._source ?? item;
      const title = process.env.NODE_ENV === 'development'
        ? JSON.stringify(itemData)
        : itemData.name;
      return (
        <li key={itemId} className="d-flex align-items-stretch">
          <div
            role="button"
            onClick={() => onClick(itemId)}
            onKeyUp={() => {}}
            className="ps-2 flex-fill text-break"
            tabIndex="0"
            title={title}
          >
            {itemData.name}
          </div>
          {renderDev(
            <SearchCaseIcon
              /* title={t.t('View Search Case', 'searchcase')} */
              title={JSON.stringify(itemData)}
              icon={<Eye />}
              onClick={() => {}}
            />,
          )}
          <SearchCaseIcon
            title={t.t('Edit Search Case', 'searchcase')}
            icon={<PencilSquare />}
            onClick={() => onEdit(itemData)}
          />
        </li>
      );
    })}
  </ul>
);

const SearchCasesNew = (props) => {
  const [open, setOpen] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState({});

  const {
    searchCases,
    deleteSearchCase,
    fetchSearchCases,
    updateSearchCase,
    error,
  } = useSearchCases();
  const { busy, execute } = useAsyncCall(fetchSearchCases);

  const onSubmit = (data, e) => {
    updateSearchCase(data.name, data).finally(() => {
      setSelectedData({});
      setOpen(false);
      e.target.reset();
    });
  };

  const onEdit = (data) => {
    if (!open) {
      setSelectedData(data);
      setOpen(true);
    }
  };

  const onNew = () => {
    setSelectedData({ daterange: '' });
    setOpen(true);
  };

  const onDelete = (data) => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm(`Delete "${data.name}"?`)) {
      deleteSearchCase(data.name).finally(() => {
        setSelectedData({});
        setOpen(false);
      });
    }
  };

  useEffect(() => {
    execute();
  }, [execute]);

  if (busy) return <LoaderText />;
  // eslint-disable-next-line no-console
  if (error) console.error(error?.toString());
  if (error && process.env.NODE_ENV === 'development') {
    return <Error error={error.toString()} />;
  }

  if (!searchCases) return null;

  return (
    <>
      <ReactiveComponentRenderer
        filterLabel={t.t('Search Case', 'searchcase')}
        showFilter
        {...props}
        defaultQuery={() => ({ query: { match_all: {} }, aggs: {} })}
        render={({ setQuery, ...rest }) => (
          <SearchCasesSelectItem
            onClick={(name) => {
              const query = buildSearchCaseQuery(
                searchCases.find((e) => (e._id ?? e.id) === name),
              );
              if (query !== undefined) {
                setQuery({ query, value: name });
              }
            }}
            onEdit={onEdit}
            searchCases={searchCases}
            {...rest}
          />
        )}
      />
      {/* <ReactiveComponent
        filterLabel={t.t('Search Case', 'searchcase')}
        showFilter
        render={({
          loading, error: err, setQuery, ...rest 
        }) => {
          if (loading) {
            return <div>Fetching Results.</div>;
          }
          if (err) {
            throw Error(err);
          }
          return (
            <SearchCasesSelectItem
              onClick={(name) => {
                const query = buildSearchCaseQuery(
                  searchCases.find((e) => (e._id ?? e.id) === name),
                );
                if (query !== undefined) {
                  setQuery({ query, value: name });
                }
              }}
              onEdit={onEdit}
              searchCases={searchCases}
              {...rest}
            />
          );
        }}
        {...props}
      /> */}
      <div className="d-flex align-items-stretch">
        <div className="ps-2 flex-fill" />
        <div
          style={{ flex: '0 0 1rem' }}
          role="link"
          tabIndex="0"
          onKeyUp={() => {}}
          onClick={onNew}
          title={t.t('Add Search Case', 'searchcase')}
          aria-label="Add Search Case"
        >
          <i className="bi bi-plus-square-fill" />
        </div>
      </div>
      <SearchCaseEdit
        data={selectedData}
        open={open}
        onSubmit={onSubmit}
        onClose={() => setOpen(false)}
        onDelete={onDelete}
      />
    </>
  );
};

export default ({ context, ...props }) => {
  const i18n = t.useTranslation('searchcases');
  return (
    <TogglePanel stateful initialOpen={false} label={i18n('Search cases')}>
      <SearchCasesContext context={context} immediateFetch={false}>
        <SearchCasesNew {...props} />
      </SearchCasesContext>
    </TogglePanel>
  );
};
