import { useAccessToken, useUserProfile } from '@/auth';
import { IAuth0User } from '@/auth/types';
import { appConfig } from '@/Config';
import { useEffect, useState } from 'react';

export const usersApi = appConfig?.api_url + '/system/users';

export const useInstanceUsers = () => {
  const accessToken = useAccessToken();
  const user = useUserProfile();
  const [users, setUsers] = useState<IAuth0User[]>([]);

  useEffect(() => {
    // gets current user email domain
    const mailDomain = user.email?.split('@')[1];
    if (accessToken && mailDomain) {
      // gets users by email domain
      fetch(usersApi + '/search_by_email_domain', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          domain: mailDomain
        })
      })
        .then((response) => {
          if (!response.ok) throw response;
          return response.json();
        })
        .then((json) =>
          setUsers(
            json?.data?.users /* if totals included */ ||
              json?.data /* if not included */
          )
        )
        .catch((err) => {
          console.error(err);
          setUsers([]);
        });
    }
  }, [accessToken]);
  return users;
};
