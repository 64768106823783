import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from '../../utils/Localization';

const ComboAnalysis = ({ data, onSelect = () => {} }) => {
  const i18n = useTranslation();
  const [selected, setSelected] = React.useState(null);
  const handleClick = (name) => {
    if (name === selected) setSelected(null);
    else setSelected(name);
  };
  React.useEffect(() => {
    if (!onSelect) return;
    onSelect(selected);
  }, [onSelect, selected]);
  const visual = data.map((d) => {
    const { key: name, doc_count: count } = d;
    const columns = name.split('|').map((n) => <td key={n}>{n}</td>);
    return (
      <tr
        key={name}
        onClick={() => handleClick(name)}
        className={selected === name ? 'fw-bold bg-primary' : ''}
      >
        {columns}
        <td>{count}</td>
      </tr>
    );
  });
  return (
    <Table responsive hover size="sm" className="caption-top">
      <caption>Caption</caption>
      <thead>
        <tr>
          <th>{i18n('Make')}</th>
          <th>{i18n('Model')}</th>
          <th>{i18n('Component')}</th>
          <th>{i18n('Count')}</th>
        </tr>
      </thead>
      <tbody>{visual}</tbody>
    </Table>
  );
};

ComboAnalysis.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func,
};

export default ComboAnalysis;
