/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';

import { LoaderText } from '../components/Loader';

function renderError(error) {
  return (
    <div>
      Something went wrong with ResultList!
      <br />
      Error details
      <br />
      {JSON.stringify(error)}
    </div>
  );
}

export function DefaultRenderWrapper(props) {
  const {
    loading, error, element: Element, loader, 
  } = props;
  if (error) return renderError(error);
  if (loading) return loader || <LoaderText />;
  if (Element) return Element;
  return null;
}

DefaultRenderWrapper.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  element: PropTypes.element,
  loader: PropTypes.element,
};
