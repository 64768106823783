import uniqueId from 'lodash/uniqueId';
import React, { useEffect } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useAccessToken, useIdToken, useUserProfile } from '../../../auth/Auth0';
import { useTranslation } from '../../../utils/Localization';
import { getRating, saveRating } from './Api';
import style from './PostRating.module.css';

const mapQuality = (q) => {
  switch (q) {
    case -10:
      return 'bg-bad';
    case 10:
      return 'bg-good';
    default:
      return 'bg-neutral';
  }
};

const mapLabel = (l) => {
  switch (l) {
    /* case 'vehicle_owner':
      return 'O'; */
    case 'driver':
      return 'D';
    case 'gasbag':
      return 'G';
    case 'cheater':
      return 'C';
    case 'hater':
      return 'H';
    case 'spammer':
      return 'S';
    case 'bimmer':
      return 'B';
    case 'mechanic':
      return 'M';
    case 'representative':
      return 'R';
    default:
      return 'unknown';
  }
};

export default function PostRating(props) {
  const {
    postAuthor, postId, postIndex, definitions, 
  } = props;
  const [ratingValue, setRatingValue] = React.useState([]);
  const accessToken = useAccessToken();
  const idToken = useIdToken();

  const profile = useUserProfile();
  const i18n = useTranslation('vehiclechain');

  useEffect(() => {
    if (accessToken && idToken && definitions) {
      getRating(accessToken, idToken, postId, postIndex, profile.email).then(({ result: hit }) => {
        const ratings = (hit && hit.length && hit[0]._source.ratings) || [];
        setRatingValue(ratings.map((r) => r.value));
      });
    }
  }, [accessToken, definitions, idToken, postId, postIndex, profile.email]);

  if (definitions && definitions.length === 0) return <div>{i18n('Ratings unavailable')}</div>;
  if (!definitions) return null;

  const handleChange = async (value) => {
    setRatingValue(value);
    const { error } = await saveRating(
      accessToken,
      idToken,
      value,
      postAuthor,
      profile.email,
      postId,
      postIndex,
    );
    if (error) {
      alert('Save Rating failed');
    }
  };

  return (
    <div>
      <ToggleButtonGroup
        size=""
        type="checkbox"
        value={ratingValue}
        onChange={handleChange}
      >
        {definitions?.map((d) => {
          const id = uniqueId('post-rate-');
          const checked = ratingValue.findIndex((value) => value === d.value) !== -1;
          const styleClass = `${mapQuality(d.quality)}${checked ? '__checked' : ''}`;
          return (
            <ToggleButton
              id={id}
              key={id}
              title={i18n(d.value)}
              variant="xs"
              /* checked={checked} */
              value={d.value}
              className={style[styleClass]}
            >
              {mapLabel(d.value)}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </div>
  );
}
