import React from 'react';
import { Alert } from 'react-bootstrap';

const Error = ({ error }: { error: Error }): JSX.Element => (
  <Alert variant="danger">
    <div className="fw-bold">Something went wrong.</div>
    {process.env.NODE_ENV === 'development' && (
      <details className="text-prewrap">{JSON.stringify(error)}</details>
    )}
  </Alert>
);

export default Error;
