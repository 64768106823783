// Mappings for the Data in OCC.CSV

export const mappingInjStatus = [
    {key:0, value:"Not Injured"},
    {key:1, value:"Injured"},
    {key:7, value:"Injured, Details Unknown"},
    {key:9, value:"Unknown if Injured"}
]

export const mappingMAIS = [
    {key:0, value:"0 - undefined"},
    {key:1, value:"1 - Minor"},
    {key:2, value:"2 - Moderate"},
    {key:3, value:"3 - Serious"},
    {key:4, value:"4 - Severe"},
    {key:5, value:"5 - Critical"},
    {key:6, value:"6 - Maximal"},
    {key:9, value:"9 - undefined"},
    {key:99, value:"99 - undefined"}
]

export const mappingMortality = [
    {key:0, value:"Not Fatal"},
    {key:1, value:"Fatal"},
    {key:2, value:"Fatal — ruled disease (specify)"}
]

