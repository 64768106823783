// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.z6lr1ms8sY09nziDuXFQ{max-width:none!important;width:90%!important}`, "",{"version":3,"sources":["webpack://./src/components/System/IndexMonitor.module.css"],"names":[],"mappings":"AAAA,sBAEE,wBAAA,CADA,mBAEF","sourceRoot":""}]);
// Exports
var _1 = `z6lr1ms8sY09nziDuXFQ`;
export { _1 as "modal-90w" };
export default ___CSS_LOADER_EXPORT___;
