import { string } from 'prop-types';
import React from 'react';
import { ArrowDownCircle } from 'react-bootstrap-icons';
import { useTranslation } from '../../utils/Localization';

function scrollToCustomerRefinements(targetId) {
  document.getElementById(targetId)?.scrollIntoView({
    behavior: 'smooth',
  });
}

const ScrollTarget = ({ targetId }) => {
  const i18n = useTranslation('refinements');
  return (
    <div className="text-end">
      {i18n('Customer Refinements')}
      <ArrowDownCircle
        role="button"
        className="ms-2"
        onClick={() => scrollToCustomerRefinements(targetId)}
        title={i18n('Customer Refinements')}
      />
    </div>
  );
};

ScrollTarget.propTypes = {
  targetId: string.isRequired,
};

export default ScrollTarget;
