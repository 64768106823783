import {
  ReactiveComponent,
  ReactiveList,
  SingleDropdownList,
} from '@appbaseio/reactivesearch';
import {
  addDays,
  endOfWeek,
  endOfYear,
  format,
  getISOWeek,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { number, oneOfType, string } from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';
import {
  Col, Container, Row, Stack, Tab, 
} from 'react-bootstrap';
import { Translation } from 'react-i18next';
import { NavLink, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { EPageContext, withPageContext } from '@/context/PageContext';
import { useConfig } from '../../Config';
import { BulletinsResultListItem } from '../../components/Bulletins';
import { ComplaintsResultListItem } from '../../components/Complaints';
import ControlledTabs from '../../components/ControlledTabs';
import { renderError } from '../../components/ErrorBoundary';
import { InvestigationsResultListItem } from '../../components/Investigations';
import { Page } from '../../components/Layout/Page';
import { LoaderSpinner } from '../../components/Loader';
import { MentionsResultListItem } from '../../components/Mentions/MentionsResultListItem';
import { useDefinitions } from '../../components/Mentions/VehicleChain/Definitions';
import { RecallsResultListItem } from '../../components/Recalls';
import { CWSelectedFilters } from '../../components/Refinements';
import { CustomerNameTitle } from '../../components/Refinements/DetectedRefinements';
import ComboHistoryChart from '../../components/Reports/ComboHistoryChart';
import HeatmapCharts from '../../components/Reports/HeatmapCharts';
import RegionBarCharts from '../../components/Reports/RegionBarCharts';
import { FullTextSearch } from '../../components/Search';
import { transformFilters } from '../../components/SearchResultList';
import ReactiveApp from '../../reactivesearch/ReactiveApp';
import { useTranslation } from '../../utils/Localization';
import { usePageTitle } from './PageTitle';

function getEmergencyFieldName(category) {
  let emergency;
  switch (category) {
    case 'pi':
      emergency = 'facets.found_catastrophic_problem';
      break;
    case 'ep':
      emergency = 'facets.found_emerging_problem';
      break;
    case 'oi':
      emergency = '';
      break;
    default:
      emergency = category;
  }
  return emergency;
}

function setSearchParams(params) {
  const searchParams = new URLSearchParams(window.location.search);
  // eslint-disable-next-line no-restricted-syntax
  for (const k in params) {
    if (params[k] === undefined || params[k] === null || params[k] === 'null') searchParams.delete(k);
    else searchParams.set(k, params[k]);
  }
  return searchParams.toString();
}

// eslint-disable-next-line react/prop-types
const ReportLink = styled(
  ({
    type, category, userGroup, competitorGroup, children, className, 
  }) => {
    const [searchParams] = useSearchParams();
    const paramType = searchParams.get('type') ?? undefined;
    const paramCategory = searchParams.get('category') ?? undefined;
    const paramUserGroup = searchParams.get('userGroup') ?? undefined;
    const paramCompetitorGroup = searchParams.get('competitorGroup') ?? undefined;

    const linkStyleFunc = (isActive) => (isActive
      && (type === paramType || type === null)
      && (category === paramCategory || category === null)
      && (userGroup === paramUserGroup || userGroup === null)
      && (competitorGroup === paramCompetitorGroup || competitorGroup === null)
      ? 'active'
      : '');

    return (
      <div className={`${className} bg-light rounded px-3`}>
        <NavLink
          to={`?${setSearchParams({
            type,
            category,
            userGroup,
            competitorGroup,
          })}`}
          className={linkStyleFunc}
        >
          {children}
        </NavLink>
      </div>
    );
  },
)`
  &:hover {
    background-color: var(--bs-gray-300) !important;
  }
`;

const GetDateInterval = ({ year, weekNumber }) => {
  const formatDate = (date) => format(date, 'dd/MM/yyyy');

  const renderDateInterval = (startDate, endDate) => (
    <h6>
      {`Date Interval: ${formatDate(startDate)} - ${formatDate(endDate)}`}
    </h6>
  );

  if (!year && !weekNumber) {
    return null;
  }
  if (year && !weekNumber) {
    const startDate = startOfYear(new Date(year, 0, 1));
    const endDate = endOfYear(new Date(year, 0, 1));
    return <div>{renderDateInterval(startDate, endDate)}</div>;
  }
  const januaryFourth = new Date(year, 0, 4);
  const startDate = startOfWeek(addDays(januaryFourth, (weekNumber - 1) * 7));
  const endDate = endOfWeek(startDate);
  return <div>{renderDateInterval(startDate, endDate)}</div>;
};

GetDateInterval.propTypes = {
  year: oneOfType([number, string]),
  weekNumber: oneOfType([number, string]),
};

GetDateInterval.defaultProps = {
  year: null,
  weekNumber: null,
};

let customerCategories = [];
try {
  customerCategories = JSON.parse(
    process.env.appconfig?.reports_categories || '[]',
  );
} catch (e) {
  console.error(e.message);
}

let competitorCategories = [];
try {
  competitorCategories = JSON.parse(
    process.env.appconfig?.reports_competitors || '[]',
  );
} catch (e) {
  console.error(e.message);
}

const ReportMenuCol = styled(Col).attrs((props) => ({
  className: `${props.className}`,
}))`
  a {
    text-decoration: none;
    color: black;
    font-size: 0.8rem;
  }
  .active {
    font-weight: bold;
    text-decoration: underline;
  }
  h6 {
    font-weight: bold;
  }
`;

const Reports = () => {
  const config = useConfig();
  const indexName = config.getIndexName('*', 'cw3_cst_');
  const i18n = useTranslation();
  const i18n_r = useTranslation('reports');

  const filters = useRef({});

  /* page title */
  usePageTitle(i18n('Reports'));

  /* router */
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const category = searchParams.get('category');
  const userGroup = searchParams.get('userGroup');
  const competitorGroup = searchParams.get('competitorGroup');

  /* component filter values */
  const filterIndex = type && config.getIndexName(type, 'cw3_cst_');
  const filterCategory = category && getEmergencyFieldName(category);
  const filterUserGroup = userGroup;
  const filterCompetitorGroup = competitorGroup;

  let currentWeek = getISOWeek(new Date(new Date() - 7 * 24 * 60 * 60 * 1000));
  currentWeek = currentWeek < 10 ? `0${currentWeek}` : `${currentWeek}`;

  /* component state */
  const [cw, setCw] = useState(
    searchParams.has('refine_cw')
      && !Number.isNaN(Number(searchParams.get('refine_cw')))
      ? Number(searchParams.get('refine_cw'))
      : currentWeek,
  );
  const [cwYear, setCwYear] = useState(
    searchParams.has('refine_cw_year')
      ? searchParams.get('refine_cw_year')
      : new Date().getFullYear(),
  );

  /* mentions */
  const { definitions } = useDefinitions();

  // filter year and week values
  const now = new Date();
  const thisYear = now.getFullYear();
  const thisWeek = getISOWeek(new Date(now - 7 * 24 * 60 * 60 * 1000));

  const dataFields = useMemo(
    () => ({
      recalls: [
        'complaint',
        'defect',
        'defect_consequence',
        'remedy',
        'campaign_number',
        'facets.*',
      ],
      complaints: ['complaint', 'facets.*'],
      investigations: [
        'defect',
        'subject',
        'summary',
        'campaign_number.keyword',
        'facets.*',
      ],
      bulletins: ['summary', 'facets.*'],
      mentions: ['body*', 'subject', 'facets.*'],
    }[type] || ['facets.*']),
    [type],
  );

  return (
    <ReactiveApp app={indexName}>
      <Page>
        <Page.Refinements>
          <SingleDropdownList
            componentId="refine_cw_year"
            dataField="facets.cw_year.keyword"
            sortBy="desc"
            showSearch={false}
            title="Year"
            value={cwYear}
            onChange={(value) => {
              setCwYear(Number(value));
              setCw(null);
            }}
            showCount={false}
            URLParams
            filterLabel="Year"
            customQuery={(value) => {
              if (!value || value === 0) return null;
              return {
                query: {
                  term: {
                    'facets.cw_year.keyword': `${value}`,
                  },
                },
              };
            }}
            transformData={(data) => [].filter.call(data, (d) => Number(d.key) <= thisYear)}
          />
          <br />
          <SingleDropdownList
            componentId="refine_cw"
            dataField="facets.cw.keyword"
            sortBy="desc"
            showSearch={false}
            title="Calendar Week"
            react={{ and: ['refine_cw_year'] }}
            style={{ visibility: cwYear ? 'visible' : 'hidden' }}
            value={cw}
            onChange={(value) => {
              setCw(Number(value));
            }}
            showCount={false}
            URLParams
            filterLabel="Calendar Week"
            customQuery={(value) => {
              if (!value || value === 0) return null;
              const termValue = value < 10 ? `0${value}` : value;
              return {
                query: {
                  term: {
                    'facets.cw.keyword': termValue,
                  },
                },
              };
            }}
            transformData={(data) => [].filter.call(data, (d) => {
              if (Number(cwYear) === thisYear) {
                // filter only current year
                return Number(thisWeek) >= Number(d.key);
              }
              return true;
            })}
          />
          {/** invisible filters */}
          <ReactiveComponent
            componentId="index_filter"
            customQuery={() => {
              if (!filterIndex) return null;
              return {
                query: {
                  bool: {
                    filter: {
                      term: {
                        _index: filterIndex.endsWith('mentions')
                          ? filterIndex.replace('mentions', 'socialweb*')
                          : filterIndex,
                      },
                    },
                  },
                },
              };
            }}
          />
          <ReactiveComponent
            componentId="category_filter"
            customQuery={() => {
              if (filterCategory === undefined) return null;
              const query = {
                query: {
                  bool: {
                    filter: {
                      bool: {
                        must: [
                          {
                            exists: {
                              field: 'facets.found_components',
                            },
                          },
                        ],
                        must_not: [],
                      },
                    },
                  },
                },
              };

              const categories = [
                'facets.found_catastrophic_problem',
                'facets.found_emerging_problem',
              ];

              if (filterCategory !== null) {
                if (categories.includes(filterCategory)) {
                  query.query.bool.filter.bool.must.push({
                    exists: { field: filterCategory },
                  });
                } else {
                  categories.forEach((c) => {
                    query.query.bool.filter.bool.must_not.push({
                      exists: { field: c },
                    });
                  });
                }
              }

              return query;
            }}
          />
          <ReactiveComponent
            componentId="user_group_filter"
            customQuery={() => {
              if (filterUserGroup === undefined) return null;
              const query = {
                query: {
                  bool: {
                    filter: {
                      bool: {
                        must: [
                          {
                            exists: {
                              field: 'facets.found_components',
                            },
                          },
                        ],
                        must_not: [],
                      },
                    },
                  },
                },
              };

              const userGroups = [...customerCategories];

              if (filterUserGroup !== null) {
                if (userGroups.includes(filterUserGroup)) {
                  query.query.bool.filter.bool.must.push({
                    exists: { field: filterUserGroup },
                  });
                } else {
                  userGroups.forEach((c) => {
                    query.query.bool.filter.bool.must_not.push({
                      exists: { field: c },
                    });
                  });
                }
              }

              return query;
            }}
          />
          <ReactiveComponent
            componentId="competitor_filter"
            customQuery={() => {
              if (filterCompetitorGroup === undefined) return null;
              const query = {
                query: {
                  bool: {
                    filter: {
                      bool: {
                        must: [
                          {
                            exists: {
                              field: 'facets.found_components',
                            },
                          },
                        ],
                        must_not: [],
                      },
                    },
                  },
                },
              };

              const competitorGroups = [...competitorCategories];

              if (filterCompetitorGroup !== null) {
                if (competitorGroup.includes(filterCompetitorGroup)) {
                  query.query.bool.filter.bool.must.push({
                    exists: { field: filterCompetitorGroup },
                  });
                } else {
                  competitorGroups.forEach((c) => {
                    query.query.bool.filter.bool.must_not.push({
                      exists: { field: c },
                    });
                  });
                }
              }

              return query;
            }}
          />
        </Page.Refinements>
        <Page.Content>
          <Row>
            <Col className="align-self-end">
              <FullTextSearch
                componentId="q"
                dataField={dataFields}
                className="mb-3"
              />
              <CWSelectedFilters
                className="mb-2"
                onChange={(args) => {
                  filters.current = transformFilters(args);
                }}
                context="reports"
                showSaveFilters={false}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <GetDateInterval year={cwYear} weekNumber={cw} />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <ControlledTabs param="tab" defaultTab="charts">
                <Tab eventKey="charts" title={i18n('Incidents')}>
                  <RegionBarCharts
                    react={{
                      and: ['refine_cw_year', 'refine_cw', 'q', 'resultlist'],
                    }}
                  />
                </Tab>
                <Tab eventKey="heatmap" title={i18n('Components')}>
                  <HeatmapCharts
                    react={{
                      and: ['refine_cw_year', 'refine_cw', 'q', 'resultlist'],
                    }}
                  />
                </Tab>
                <Tab eventKey="search" title={i18n('Search Results')}>
                  <Row>
                    <Col>
                      {/** show selected filters */}
                      <h5 style={{ textAlign: 'center' }}>
                        {`${
                          i18n_r(category) && i18n_r(type)
                            ? `${i18n_r(category)} : ${i18n_r(type)}`
                            : i18n_r(category) || i18n_r(type) || ''
                        }`}
                      </h5>
                    </Col>
                  </Row>

                  <Row>
                    <ReportMenuCol className="col-2">
                      <h6>Incident Type</h6>
                      <Stack className="ps-2 fs-6" gap={2}>
                        <ReportLink
                          type="recalls"
                          category={category}
                          userGroup={userGroup}
                          competitorGroup={competitorGroup}
                        >
                          {i18n('Recalls')}
                        </ReportLink>
                        <ReportLink
                          type="investigations"
                          category={category}
                          userGroup={userGroup}
                          competitorGroup={competitorGroup}
                        >
                          {i18n('Investigations')}
                        </ReportLink>
                        <ReportLink
                          type="bulletins"
                          category={category}
                          userGroup={userGroup}
                          competitorGroup={competitorGroup}
                        >
                          {i18n('Bulletins')}
                        </ReportLink>
                        <ReportLink
                          type="complaints"
                          category={category}
                          userGroup={userGroup}
                          competitorGroup={competitorGroup}
                        >
                          {i18n('Complaints')}
                        </ReportLink>
                        <ReportLink
                          type="mentions"
                          category={category}
                          userGroup={userGroup}
                          competitorGroup={competitorGroup}
                        >
                          {i18n('Mentions')}
                        </ReportLink>
                      </Stack>
                      <hr />
                      <h6>Incident Category</h6>
                      <Stack className="ps-2 fs-6">
                        <ReportLink
                          userGroup={userGroup}
                          competitorGroup={competitorGroup}
                          type={type}
                        >
                          {i18n('All')}
                        </ReportLink>
                        <ReportLink
                          userGroup={userGroup}
                          competitorGroup={competitorGroup}
                          type={type}
                          category="pi"
                        >
                          {i18n('High Priority')}
                        </ReportLink>
                        <ReportLink
                          userGroup={userGroup}
                          competitorGroup={competitorGroup}
                          type={type}
                          category="ep"
                        >
                          {i18n('Emerging')}
                        </ReportLink>
                        {/* <ReportLink type={type} category="oi">
                          {i18n('Other')}
                        </ReportLink> */}
                      </Stack>

                      {customerCategories && customerCategories.length > 0 && (
                        <>
                          <hr />
                          <CustomerNameTitle title="Components">
                            {({ title }) => <h6>{title}</h6>}
                          </CustomerNameTitle>
                          <Stack className="ps-2 fs-6">
                            <ReportLink
                              type={type}
                              category={category}
                              competitorGroup={competitorGroup}
                            >
                              {i18n('All')}
                            </ReportLink>
                            <Translation ns="reports">
                              {(t) => (
                                <>
                                  {customerCategories.map((c) => (
                                    <ReportLink
                                      key={c}
                                      type={type}
                                      category={category}
                                      userGroup={c}
                                      competitorGroup={competitorGroup}
                                    >
                                      {t(c)}
                                    </ReportLink>
                                  ))}
                                </>
                              )}
                            </Translation>
                          </Stack>
                        </>
                      )}
                      {competitorCategories
                        && competitorCategories.length > 0 && (
                          <>
                            <hr />
                            <CustomerNameTitle title="Competitors">
                              {({ title }) => <h6>{title}</h6>}
                            </CustomerNameTitle>
                            <Stack className="ps-2 fs-6">
                              <ReportLink
                                type={type}
                                category={category}
                                userGroup={userGroup}
                              >
                                {i18n('All')}
                              </ReportLink>
                              <Translation ns="reports">
                                {(t) => (
                                  <>
                                    {competitorCategories.map((c) => (
                                      <ReportLink
                                        key={c}
                                        type={type}
                                        category={category}
                                        competitorGroup={c}
                                        userGroup={userGroup}
                                      >
                                        {t(c)}
                                      </ReportLink>
                                    ))}
                                  </>
                                )}
                              </Translation>
                            </Stack>
                          </>
                      )}
                    </ReportMenuCol>
                    <Col className="col-10">
                      <ReactiveList
                        componentId="result_list"
                        dataField="publication_date"
                        excludeFields={['documents.content']}
                        size={100}
                        react={{
                          and: [
                            'index_filter',
                            'category_filter',
                            'user_group_filter',
                            'competitor_filter',
                            'refine_cw_year',
                            'refine_cw',
                            'q',
                          ],
                        }}
                      >
                        {({ data, error, loading }) => {
                          if (error) return renderError(error);
                          if (loading) return <LoaderSpinner />;
                          if (!type) {
                            return (
                              <div>
                                <h6>
                                  <b>{i18n('Please select Incident Type.')}</b>
                                </h6>
                              </div>
                            );
                          }
                          return data.map((hit) => (
                            <>
                              {type === 'recalls' && (
                                <RecallsResultListItem
                                  key={hit._id}
                                  hit={hit}
                                />
                              )}
                              {type === 'bulletins' && (
                                <BulletinsResultListItem
                                  key={hit._id}
                                  hit={hit}
                                />
                              )}
                              {type === 'complaints' && (
                                <ComplaintsResultListItem
                                  key={hit._id}
                                  hit={hit}
                                />
                              )}
                              {type === 'investigations' && (
                                <InvestigationsResultListItem
                                  key={hit._id}
                                  hit={hit}
                                />
                              )}
                              {type === 'mentions' && (
                                <MentionsResultListItem
                                  key={hit._id}
                                  hit={hit}
                                  definitions={definitions}
                                />
                              )}
                            </>
                          ));
                        }}
                      </ReactiveList>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="history" title={i18n('History')}>
                  <Container fluid className="p-3">
                    <Row>
                      <Col
                        className="col-6"
                        style={{ borderRight: '1px solid #D0CFD0' }}
                      >
                        <h6 style={{ textAlign: 'center' }}>
                          <b>Recalls over Time</b>
                        </h6>
                        <ComboHistoryChart
                          componentId="combo_history_charts_recalls"
                          react={{
                            and: [
                              'refine_cw_year',
                              'refine_cw',
                              'q',
                              'resultlist',
                            ],
                          }}
                          index="recalls"
                        />
                      </Col>
                      <Col className="col-6">
                        <h6 style={{ textAlign: 'center' }}>
                          <b>Complaints over Time</b>
                        </h6>
                        <ComboHistoryChart
                          componentId="combo_history_charts_complaints"
                          react={{
                            and: [
                              'refine_cw_year',
                              'refine_cw',
                              'q',
                              'resultlist',
                            ],
                          }}
                          index="complaints"
                        />
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col
                        className="col-6"
                        style={{ borderRight: '1px solid #D0CFD0' }}
                      >
                        <h6 style={{ textAlign: 'center' }}>
                          <b>Investigations over Time</b>
                        </h6>
                        <ComboHistoryChart
                          componentId="combo_history_charts_investigations"
                          react={{
                            and: [
                              'refine_cw_year',
                              'refine_cw',
                              'q',
                              'resultlist',
                            ],
                          }}
                          index="investigations"
                        />
                      </Col>
                      <Col className="col-6">
                        <h6 style={{ textAlign: 'center' }}>
                          <b>Bulletins over Time</b>
                        </h6>
                        <ComboHistoryChart
                          componentId="combo_history_charts_bulletins"
                          react={{
                            and: [
                              'refine_cw_year',
                              'refine_cw',
                              'q',
                              'resultlist',
                            ],
                          }}
                          index="bulletins"
                        />
                      </Col>
                    </Row>
                  </Container>
                </Tab>
              </ControlledTabs>
            </Col>
          </Row>
        </Page.Content>
      </Page>
    </ReactiveApp>
  );
};

export default withPageContext(Reports, EPageContext.REPORTS, 'Reports');
