import useFetch from 'react-fetch-hook';
import { useAccessToken } from '../auth/Auth0';
import { useConfig } from '../Config';

interface IUseElasticOptions {
  method?: string;
  endpoint?: string;
}

type TQuery = string[][] | Record<string, any> | string | URLSearchParams;

export const useElastic = (
  index: string,
  query: TQuery,
  depends: useFetch.HookOptions['depends'],
  options: IUseElasticOptions = {}
): useFetch.FetchResult<unknown> => {
  const accessToken = useAccessToken();
  const method = (options?.method || 'post').toLowerCase();
  const endpoint = options?.endpoint || '_search';
  const body = options?.method !== 'get' && JSON.stringify(query);

  let url = `${useConfig().connection}${index}/${endpoint}`;
  if (method === 'get') {
    url = `${url}?${new URLSearchParams(query).toString()}`;
  }

  return useFetch(
    url,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      method,
      body
    },
    {
      depends: [
        !!accessToken,
        ...(Array.isArray(depends) ? depends : [depends])
      ]
    }
  );
};
