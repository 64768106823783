/* eslint-disable react/no-danger */
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { without } from 'lodash-es';
import React, { useRef } from 'react';
import { Tab } from 'react-bootstrap';
import { EPageContext, withPageContext } from '@/context/PageContext';
import { definedSearchFields } from '@/components/Search/FullTextSearch';
import { useConfig } from '../../Config';
import BarChartComponent from '../../components/Charts/BarChartComponent';
import ControlledTabs from '../../components/ControlledTabs';
import ErrorBoundary, { renderError } from '../../components/ErrorBoundary';
import { InvestigationsResultListItem } from '../../components/Investigations';
import { Page } from '../../components/Layout/Page';
import NoResults from '../../components/NoResults';
import {
  CWSelectedFilters,
  ComponentRefinement,
  CustomerRefinements,
  DateHistogramRefinement,
  DefinedFilters,
  MakeRefinement,
  ModelRefinement,
  OfficialId,
  SavedFilters,
} from '../../components/Refinements';
import { useCustomerRefinementIds } from '../../components/Refinements/CustomerRefinements';
import DateRangeRefinement from '../../components/Refinements/DateRangeRefinement';
import { componentIds as customRefinementsIds } from '../../components/Refinements/DetectedRefinements';
import MultiListRefinement from '../../components/Refinements/MultiListRefinement';
import RefineId from '../../components/Refinements/RefineId';
import ScrollTarget from '../../components/Refinements/ScrollTarget';
import {
  ExportResults,
  ResultStats,
} from '../../components/ResultListItem/ResultStats';
import { FullTextSearch } from '../../components/Search';
import SearchResultList, {
  transformFilters,
} from '../../components/SearchResultList';
import { ResultListSpinner } from '../../components/Spinners';
import ReactiveApp from '../../reactivesearch/ReactiveApp';
import * as t from '../../utils/Localization';
import { usePageTitle } from './PageTitle';
import { SearchCasesRefinementWithPanel } from '@/components/SearchCases/SearchCasesRefinement';

const defaultInteractiveComponentIds = [
  'q',
  'refine_id',
  'refine_datasource',
  'refine_region',
  'refine_make',
  'refine_model',
  'refine_component',
  'refine_recall_id',
  'refine_officialid',
  'refine_investigation_id',
  'refine_opened_date',
  'refine_opened_date_range',
  'refine_searchcases',
  'chart_openeddata_months',
  ...customRefinementsIds,
];

/* eslint-enable react/prop-types */

function Investigations() {
  const interactiveComponentIds = useCustomerRefinementIds(
    defaultInteractiveComponentIds,
  );
  const i18nDS = t.useTranslation('datasources');
  usePageTitle(t.t('Investigations'));
  const config = useConfig();
  // hold selected filter values
  const filters = useRef({});

  const indexName = config.getIndexName('investigations', 'cw3_cst_');

  return (
    <ReactiveApp app={indexName}>
      <Page>
        <Page.Refinements>
          <ErrorBoundary silent={false}>
            <ScrollTarget targetId="customer-refinements" />
            <SavedFilters />
            <DefinedFilters context="investigations" />
            <hr className="border border-3 rounded-pill" />
            <RefineId componentId="refine_id" />
            <ErrorBoundary>
              <SearchCasesRefinementWithPanel
                componentId="refine_searchcases"
                context="investigations"
                suggestionIndex={indexName}
                showFilter
                filterLabel="SearchCase"
              />
              {/* <SearchCases
                context="investigations"
                componentId="refine_searchcases"
                react={{
                  and: without(interactiveComponentIds, 'refine_searchcases'),
                }}
              /> */}
            </ErrorBoundary>
            <hr className="border border-3 rounded-pill" />
            <DateHistogramRefinement
              componentId="refine_opened_date"
              react={{
                and: without(interactiveComponentIds, 'refine_opened_date'),
              }}
              dataField="opened_date"
              label={t.t('Opened Date')}
              filterLabel={t.t('Opened Date')}
              URLParams
              loader="Loading ..."
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <DateRangeRefinement
              componentId="refine_opened_date_range"
              dataField="opened_date"
              startLabel="Start Date"
              endLabel="End Date"
              filterLabel="Opened Date Range"
              URLParams
            />
            {/* <DateRangeSC
              componentId="refine_opened_date_range"
              dataField="opened_date"
              URLParams
              dayPickerInputProps={{
                inputProps: {
                  style: { padding: '0.1rem 0.3rem', minHeight: 'inherit' },
                },
              }}
              react={{
                and: without(
                  interactiveComponentIds,
                  'refine_opened_date_range',
                ),
              }}
            /> */}
          </ErrorBoundary>
          <hr />
          <OfficialId
            componentId="refine_officialid"
            label={t.t('Official ID')}
          />
          {/* <hr />
          <InputFieldRefinement
            componentId="refine_investigation_id"
            dataField={['_id']}
            label={t.t('Investigation ID', 'rli-common')}
            filterLabel={t.t('Investigation ID', 'rli-common')}
          /> */}
          <MultiListRefinement
            componentId="refine_datasource"
            dataField="datasource"
            title={t.t('Data Source')}
            filterLabel={t.t('Data Source')}
            renderNoResults={NoResults}
            translateFunc={i18nDS}
            URLParams
            defaultQuery={() => ({
              timeout: '1s',
            })}
            react={{
              and: without(interactiveComponentIds, 'refine_datasource'),
            }}
          />
          <MakeRefinement
            react={{ and: without(interactiveComponentIds, 'refine_make') }}
          />
          <ModelRefinement
            react={{ and: without(interactiveComponentIds, 'refine_model') }}
          />
          <ComponentRefinement
            react={{
              and: without(interactiveComponentIds, 'refine_component'),
            }}
          />
          <hr id="customer-refinements" />
          <ErrorBoundary>
            <CustomerRefinements react={interactiveComponentIds} />
          </ErrorBoundary>
        </Page.Refinements>
        <Page.Content>
          <FullTextSearch
            componentId="q"
            className="mb-3"
            dataField={definedSearchFields('investigations')}
          />
          <CWSelectedFilters
            onChange={(args) => {
              filters.current = transformFilters(args);
            }}
            context="investigations"
          />
          <ControlledTabs param="tab" defaultTab="searchresults">
            <Tab eventKey="globals" title={t.t('Globals')}>
              <div className="container fluid">
                <div className="row">
                  <div className="col">
                    <ReactiveComponent
                      componentId="chart_openeddata_months"
                      filterLabel={t.t('Chart Issues/Month')}
                      defaultQuery={() => ({
                        query: {
                          range: {
                            opened_date: {
                              gte: 'now-20y/y',
                            },
                          },
                        },
                        size: 0,
                        aggs: {
                          data: {
                            date_histogram: {
                              field: 'opened_date',
                              calendar_interval: '1M',
                              format: 'yyyy-MM',
                            },
                          },
                        },
                      })}
                      react={{ and: interactiveComponentIds }}
                      render={({ aggregations, setQuery, ...rest }) => (
                        <BarChartComponent
                          aggregations={aggregations}
                          onClick={(selected, { timestamp }) => {
                            timestamp
                              && setQuery({
                                query: {
                                  range: {
                                    opened_date: {
                                      gte: `${timestamp}||/M`,
                                      lt: `${timestamp}||+1M/M`,
                                    },
                                  },
                                },
                                value: selected,
                              });
                          }}
                          {...rest}
                        />
                      )}
                      showFilter
                    />
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="searchresults" title={t.t('Search Results')}>
              <SearchResultList
                componentId="resultlist"
                excludeFields={['raw', 'documents.content']}
                dataField="opened_date"
                sortOptions={[
                  {
                    label: 'Relevance',
                    dataField: '_score',
                    sortBy: 'desc',
                    key: 'score#desc',
                  },
                  {
                    label: 'Newest',
                    dataField: 'opened_date',
                    sortBy: 'desc',
                    key: 'date#desc',
                  },
                  {
                    label: 'Oldest',
                    dataField: 'opened_date',
                    sortBy: 'asc',
                    key: 'date#asc',
                  },
                ]}
                exportElement={(
                  <ExportResults
                    type="investigations"
                    filters={filters.current}
                  />
                )}
                statsElement={<ResultStats />}
                render={({ data, error, loading }) => {
                  if (error) {
                    return renderError(error);
                  }
                  if (loading) {
                    return <ResultListSpinner />;
                  }
                  return (
                    <div>
                      {data.map((hit) => (
                        <InvestigationsResultListItem key={hit._id} hit={hit} />
                      ))}
                    </div>
                  );
                }}
                react={{ and: interactiveComponentIds }}
                pagination
                renderResultStats={() => {}}
                innerClass={{ sortOptions: 'mb-2' }}
              />
            </Tab>
          </ControlledTabs>
        </Page.Content>
      </Page>
    </ReactiveApp>
  );
}

export default withPageContext(
  Investigations,
  EPageContext.INVESTIGATIONS,
  'Investigations',
);
