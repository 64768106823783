import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { ReactiveComponentProps } from '@appbaseio/reactivesearch/lib/components/basic/ReactiveComponent';
import { highlight } from 'prismjs';
import React, { useEffect } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';
import DebouncedInput from '../DebouncedInput';

export type TQueryType =
  | 'match'
  | 'term'
  | 'fuzzy'
  | 'prefix'
  | 'wildcard'
  | 'regexp'
  | 'ids';

export interface IInputRefinementProps extends ReactiveComponentProps {
  dataField: string;
  queryType?: TQueryType;
  boost?: number;
  highlight?: boolean;
  inputProps?: FormControlProps;
  debounce?: number;
}

const highlightConfig = (dataField: string) => {
  return {
    fields: {
      [dataField]: {}
    },
    pre_tags: ['<mark>'],
    post_tags: ['</mark>']
  };
};

const queries: {
  [K in TQueryType]: (dataField: string, boost: number, value: any) => any;
} = {
  match: (dataField, boost, value) => ({
    match: { [dataField]: { query: value, boost } }
  }),
  term: (dataField, boost, value) => ({
    term: { [dataField]: { value, boost } }
  }),
  fuzzy: (dataField, boost, value) => ({
    fuzzy: { [dataField]: { value, boost } }
  }),
  prefix: (dataField, boost, value) => ({
    prefix: { [dataField]: { value, boost } }
  }),
  wildcard: (dataField, boost, value) => ({
    wildcard: { [dataField]: { value, boost } }
  }),
  regexp: (dataField, boost, value) => ({
    regexp: { [dataField]: { value, boost } }
  }),
  ids: (dataField, boost, value) => ({
    ids: { values: Array.isArray(value) ? value : [value] } // no boost
  })
};

const InputControl = (props: IInputRefinementProps & { setQuery: any }) => {
  const [value, setValue] = React.useState(props.value ?? '');
  const { setQuery, queryType = 'match', inputProps, dataField, boost } = props;

  useEffect(() => {
    value
      ? setQuery({
          query: queries[queryType](dataField, boost, value),
          value,
          options: {
            highlight: highlight && highlightConfig(dataField)
          }
        })
      : setQuery({});
  }, [value]);

  useEffect(() => {
    setValue(props.value ?? '');
  }, [props.value]);

  return (
    <DebouncedInput
      {...inputProps}
      value={value}
      onChange={setValue}
      inputComponent={Form.Control}
      spellCheck="false"
    />
  );
};

const InputRefinement = (props: IInputRefinementProps): JSX.Element => {
  const { queryType = 'match' } = props;
  return (
    <>
      <ReactiveComponent
        {...props}
        customQuery={(v) =>
          v && {
            query: queries[queryType](props.dataField, props.boost, v),
            highlight: highlight && highlightConfig(props.dataField)
          }
        }
      >
        {({ value, setQuery }) => (
          <InputControl {...props} setQuery={setQuery} value={value} />
        )}
      </ReactiveComponent>
    </>
  );
};

export default InputRefinement;
