import { definedSearchFields } from '@/components/Search/FullTextSearch';
import { SearchCasesRefinementWithPanel } from '@/components/SearchCases/SearchCasesRefinement';
import { EPageContext, withPageContext } from '@/context/PageContext';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { without } from 'lodash-es';
import React, { useRef } from 'react';
import {
  Card, Col, Container, Row, Tab,
} from 'react-bootstrap';
import { Brush } from 'recharts';
import styled from 'styled-components';
import {
  BarChartComponent,
  SimpleChartComponent,
} from '../../components/Charts';
import CWApexCharts from '../../components/Charts/CWApexCharts';
import DataSourceStats from '../../components/Charts/DataSourceStats';
import MentionedInCommentsResultList from '../../components/comments/MentionedInCommentsResultList';
import ControlledTabs from '../../components/ControlledTabs';
import Dev from '../../components/Develop/DevComponent';
import ErrorBoundary, { renderError } from '../../components/ErrorBoundary';
import { FavoritesResultList } from '../../components/favorites';
import { Page } from '../../components/Layout/Page';
import NoResults from '../../components/NoResults';
import { RecallsResultListItem } from '../../components/Recalls';
import {
  CWSelectedFilters,
  ComponentRefinement,
  DateHistogramRefinement,
  DefinedFilters,
  MakeRefinement,
  ModelRefinement,
  MultiListRefinement,
  OfficialId,
  RefineId,
  SavedFilters,
} from '../../components/Refinements';
import {
  CustomerRefinements,
  useCustomerRefinementIds,
} from '../../components/Refinements/CustomerRefinements';
import DateRangeRefinement from '../../components/Refinements/DateRangeRefinement';
import { componentIds as customRefinementsIds } from '../../components/Refinements/DetectedRefinements';
import ScrollTarget from '../../components/Refinements/ScrollTarget';
import {
  ExportResults,
  ResultStats,
} from '../../components/ResultListItem/ResultStats';
import { FullTextSearch } from '../../components/Search';
import SearchResultList, {
  transformFilters,
} from '../../components/SearchResultList';
import { ResultListSpinner } from '../../components/Spinners';
import { useConfig } from '../../Config';
import { DefaultRenderWrapper } from '../../reactivesearch/ReactComponentRenderWrapper';
import ReactiveApp from '../../reactivesearch/ReactiveApp';
import * as t from '../../utils/Localization';

const MLDefault = () => ({
  timeout: '1s',
});

const PieChartContainer = styled.div`
  min-width: 30rem;
  min-height: 30rem;
`;

const defaultInteractiveComponentIds = [
  'refine_id',
  'refine_campaign',
  'refine_make',
  'refine_make_input',
  'refine_model',
  'refine_component',
  'refine_component_manufacturer',
  'refine_searchcases',
  ...customRefinementsIds,
  'q',
  'chart_makes',
  'chart_models',
  'chart_months',
  'refine_publication_date',
  'refine_publication_date_range',
  'refine_datasource',
  'refine_region',
  'searchbox',
  'apexchart_makes',
  'apexchart_models',
  'apexchart_components',
  'apexchart_months',
];

function Recalls() {
  const interactiveComponentIds = useCustomerRefinementIds(
    defaultInteractiveComponentIds,
  );
  const i18nDS = t.useTranslation('datasources');
  const config = useConfig();
  const indexName = config.getIndexName('recalls', 'cw3_cst_');
  // hold selected filter values
  const filters = useRef({});

  return (
    <ReactiveApp app={indexName}>
      <Page>
        <Page.Refinements>
          <ScrollTarget targetId="customer-refinements" />
          <ErrorBoundary>
            <ErrorBoundary silent={false}>
              <SavedFilters />
              <DefinedFilters context="recalls" />
              <hr className="border border-3 rounded-pill" />
              <RefineId componentId="refine_id" />
              <ErrorBoundary>
                <SearchCasesRefinementWithPanel
                  componentId="refine_searchcases"
                  context="recalls"
                  suggestionIndex={indexName}
                  showFilter
                  filterLabel="SearchCase"
                />
                {/* <SearchCases
                  context="recalls"
                  componentId="refine_searchcases"
                  react={{
                    and: without(interactiveComponentIds, 'refine_searchcases'),
                  }}
                /> */}
              </ErrorBoundary>
              <hr className="border border-3 rounded-pill" />
              <DateHistogramRefinement
                componentId="refine_publication_date"
                react={{
                  and: without(
                    interactiveComponentIds,
                    'refine_publication_date',
                  ),
                }}
                dataField="publication_date"
                label={t.t('Publication Date')}
                filterLabel={t.t('Publication Date')}
                URLParams
                loader="Loading ..."
              />
            </ErrorBoundary>
            <ErrorBoundary>
              <DateRangeRefinement
                componentId="refine_publication_date_range"
                dataField="publication_date"
                startLabel="Start Date"
                endLabel="End Date"
                filterLabel="Publication Date Range"
                URLParams
              />
              {/* <DateRangeSC
                componentId="refine_publication_date_range"
                dataField="publication_date"
                URLParams
                dayPickerInputProps={{
                  inputProps: {
                    style: { padding: '0.1rem 0.3rem', minHeight: 'inherit' },
                  },
                }}
                filterLabel={t.t('Publication Date Range')}
                react={{
                  and: without(
                    interactiveComponentIds,
                    'refine_publication_date_range',
                  ),
                }}
              /> */}
            </ErrorBoundary>
            <hr />
            <OfficialId
              componentId="refine_campaign"
              label={t.t('Official ID')}
            />
            <MultiListRefinement
              componentId="refine_datasource"
              dataField="datasource"
              title={t.t('Data Source')}
              filterLabel={t.t('Data Source')}
              renderNoResults={NoResults}
              translateFunc={i18nDS}
              defaultQuery={MLDefault}
              react={{
                and: without(interactiveComponentIds, 'refine_datasource'),
              }}
              URLParams
            />
            <MakeRefinement
              react={{ and: without(interactiveComponentIds, 'refine_make') }}
            />
            <ModelRefinement
              react={{
                and: without(interactiveComponentIds, 'refine_model'),
              }}
            />
            <ComponentRefinement
              react={{
                and: without(interactiveComponentIds, 'refine_component'),
              }}
            />
            <hr id="customer-refinements" />
            <ErrorBoundary>
              <CustomerRefinements react={interactiveComponentIds} />
            </ErrorBoundary>
          </ErrorBoundary>
        </Page.Refinements>
        <Page.Content>
          <FullTextSearch
            componentId="q"
            className="mb-3"
            dataField={definedSearchFields('recalls')}
          />
          <CWSelectedFilters
            onChange={(args) => {
              filters.current = transformFilters(args);
            }}
            context="recalls"
          />
          <ControlledTabs param="tab" defaultTab="searchresults">
            <Tab eventKey="globals" title={t.t('Globals')}>
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col">
                    <SimpleChartComponent
                      componentId="chart_makes"
                      field="facets.make.keyword"
                      label={t.t('Make')}
                      react={{ and: interactiveComponentIds }}
                      chart={BarChartComponent}
                      chartProps={{ showBarLabels: true, layout: 'vertical' }}
                    />
                  </div>
                  <div className="col">
                    <SimpleChartComponent
                      componentId="chart_models"
                      field="facets.model.keyword"
                      label={t.t('Model')}
                      react={{ and: interactiveComponentIds }}
                      chart={BarChartComponent}
                      chartProps={{ showBarLabels: true, layout: 'vertical' }}
                    />
                  </div>
                  <div className="col">
                    <SimpleChartComponent
                      componentId="chart_components"
                      field="facets.component.keyword"
                      label={t.t('Component')}
                      react={{ and: interactiveComponentIds }}
                      chart={BarChartComponent}
                      chartProps={{ showBarLabels: true, layout: 'vertical' }}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col">
                    <Card>
                      <Card.Header>
                        <div className="fw-bold">
                          {t.t('Overview Issues / Month')}
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <ReactiveComponent
                          componentId="chart_months"
                          defaultQuery={() => ({
                            query: {
                              range: {
                                publication_date: {
                                  gte: 'now-1y/y',
                                },
                              },
                            },
                            size: 0,
                            aggs: {
                              data: {
                                date_histogram: {
                                  field: 'publication_date',
                                  calendar_interval: '1M',
                                  format: 'MMM yyyy',
                                },
                              },
                            },
                          })}
                          react={{ and: interactiveComponentIds }}
                          render={(props) => {
                            // eslint-disable-next-line react/prop-types
                            const { setQuery } = props;
                            return (
                              <DefaultRenderWrapper
                                {...props}
                                loader={<div>{t.t('Loading chart...')}</div>}
                                element={(
                                  <BarChartComponent
                                    onClick={(selected, { timestamp }) => {
                                      timestamp
                                        && setQuery({
                                          query: {
                                            range: {
                                              publication_date: {
                                                gte: `${timestamp}||/M`,
                                                lt: `${timestamp}||+1M/M`,
                                              },
                                            },
                                          },
                                          value: selected,
                                        });
                                    }}
                                    showBarLabels
                                    {...props}
                                  >
                                    <Brush height={16} />
                                  </BarChartComponent>
                                )}
                              />
                            );
                          }}
                          /* filter funktionieren aktuell nicht bei Reload der Seite */
                          URLParams
                          showFilter
                        />
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            </Tab>
            <Dev>
              <Tab eventKey="apexcharts" title={t.t('Globals (alternative)')}>
                <ErrorBoundary>
                  <Container fluid>
                    <Row xs="auto">
                      <Col className="mb-2">
                        <PieChartContainer>
                          <CWApexCharts.PieChartComponent
                            componentId="apexchart_makes"
                            field="facets.make.keyword"
                            title={t.t('Make')}
                            react={{
                              and: ['apexchart_models', 'apexchart_components'],
                            }}
                            URLParams
                            showFilter
                            filterLabel={t.t('Make')}
                          />
                        </PieChartContainer>
                      </Col>
                      <Col className="mb-2">
                        <PieChartContainer>
                          <CWApexCharts.PieChartComponent
                            componentId="apexchart_models"
                            field="facets.model.keyword"
                            title={t.t('Model')}
                            react={{
                              and: ['apexchart_makes', 'apexchart_components'],
                            }}
                            URLParams
                            showFilter
                            filterLabel={t.t('Model')}
                          />
                        </PieChartContainer>
                      </Col>
                      <Col className="mb-2">
                        <PieChartContainer>
                          <CWApexCharts.PieChartComponent
                            componentId="apexchart_components"
                            field="facets.component.keyword"
                            title={t.t('Component')}
                            react={{
                              and: ['apexchart_makes', 'apexchart_models'],
                            }}
                            URLParams
                            showFilter
                            filterLabel={t.t('Component')}
                          />
                        </PieChartContainer>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ReactiveComponent
                          componentId="apexchart_months"
                          defaultQuery={() => ({
                            query: {
                              range: {
                                publication_date: {
                                  gte: 'now-20y/y',
                                },
                              },
                            },
                            size: 0,
                            aggs: {
                              data: {
                                date_histogram: {
                                  field: 'publication_date',
                                  calendar_interval: '1M',
                                  format: 'yyyy-MM',
                                },
                              },
                            },
                          })}
                          render={(props) => (
                            <CWApexCharts.TimeSeriesChart
                              chartType="bar"
                              chartOptions={{
                                title: { text: t.t('Overview Issues / Month') },
                              }}
                              {...props}
                            />
                          )}
                          /* filter funktionieren aktuell nicht bei Reload der Seite */
                          URLParams
                          showFilter
                        />
                      </Col>
                    </Row>
                  </Container>
                </ErrorBoundary>
              </Tab>
            </Dev>
            <Tab eventKey="datasources" title={t.t('Datasources')}>
              <ErrorBoundary>
                <DataSourceStats
                  componentId="stats_forums"
                  dateField="publication_date"
                  react={{ and: [...interactiveComponentIds] }}
                />
              </ErrorBoundary>
            </Tab>
            <Tab eventKey="searchresults" title={t.t('Search Results')}>
              <SearchResultList
                componentId="resultlist"
                exportElement={
                  <ExportResults type="recalls" filters={filters.current} />
                }
                statsElement={<ResultStats />}
                excludeFields={['documents.content']}
                dataField="publication_date"
                render={({ loading, error, data }) => {
                  if (error) return renderError(error);
                  if (loading) {
                    return <ResultListSpinner />;
                  }
                  return (
                    <div>
                      <ErrorBoundary>
                        {data.map((hit) => (
                          <RecallsResultListItem key={hit._id} hit={hit} />
                        ))}
                      </ErrorBoundary>
                    </div>
                  );
                }}
                react={{ and: interactiveComponentIds }}
                pagination
                renderResultStats={() => {}}
                innerClass={{ sortOptions: 'mb-2' }}
              />
            </Tab>
            <Tab eventKey="favorites" title={t.t('Favorites')}>
              <FavoritesResultList
                componentId="resultlist_favorites"
                statsElement={
                  <ResultStats targetComponentId="resultlist_favorites" />
                }
                excludeFields={['documents.content']}
                dataField="publication_date"
                render={({ loading, error, data }) => {
                  if (error) return renderError(error);
                  if (loading) {
                    return <ResultListSpinner />;
                  }
                  return (
                    <div>
                      <ErrorBoundary>
                        {data.map((hit) => (
                          <RecallsResultListItem key={hit._id} hit={hit} />
                        ))}
                      </ErrorBoundary>
                    </div>
                  );
                }}
                react={{ and: interactiveComponentIds }}
                pagination
                renderResultStats={() => {}}
                innerClass={{ sortOptions: 'mb-2' }}
              />
            </Tab>
            <Tab eventKey="mentioned" title={t.t('Mentioned')}>
              <MentionedInCommentsResultList
                componentId="resultlist_mentioned"
                statsElement={
                  <ResultStats targetComponentId="resultlist_mentioned" />
                }
                excludeFields={['documents.content']}
                dataField="publication_date"
                render={({ loading, error, data }) => {
                  if (error) return renderError(error);
                  if (loading) {
                    return <ResultListSpinner />;
                  }
                  return (
                    <div>
                      <ErrorBoundary>
                        {data.map((hit) => (
                          <RecallsResultListItem key={hit._id} hit={hit} />
                        ))}
                      </ErrorBoundary>
                    </div>
                  );
                }}
                react={{ and: interactiveComponentIds }}
                pagination
                renderResultStats={() => {}}
                innerClass={{ sortOptions: 'mb-2' }}
              />
            </Tab>
          </ControlledTabs>
        </Page.Content>
      </Page>
    </ReactiveApp>
  );
}

export default withPageContext(Recalls, EPageContext.RECALLS, 'Recalls');
